import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import PonyWall from "./";
import { actions } from "features/Model/Enclosure/_ducks";
import isEmpty from "lodash/isEmpty";

const { setInitCode, updateCodeDrawer, updateCompositeCalc } = actions;

const mapStateToProps = (
    {
        form,
        user: { codeLib: { CrawlspaceWall: { codes = {} } = {} } = {} },
        enclosure: { calculatingRValue = {} } = {},
        upgradeLibrary: { currentPackage = {} },
        parametricAnalysis: { parametricAnalysis } = {},
    },
    { accessor, formName = "model", componentAccessor = accessor, id, selectedUpgrade, canBeEditedFromParametric }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const ecmInputMap = parametricAnalysis?.ecmInputMap || {};

    const allEcmInputMapUpgradesArr = ecmInputMap
        ? Object.keys(ecmInputMap)
              .map((key) =>
                  Object.entries(ecmInputMap[key])
                      .filter(([k, _]) => k !== "None")
                      .map(([k, v]) => ({ [k]: v }))
              )
              .flat()
        : {};

    const allEcmInputMapUpgrades = allEcmInputMapUpgradesArr.reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const modelCodes =
        allEcmInputMapUpgrades[selectedUpgrade]?.packageValues?.codes ||
        currentPackage?.upgrades?.[selectedUpgrade]?.codes ||
        modelSelector({ form }, "modelData.codes") ||
        {};

    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const isEnabled = modelSelector({ form }, `${componentAccessor}.wall.ponyWall.enabled`);
    const depthBelowGrade = modelSelector({ form }, `${componentAccessor}.wall.measurements.depth`);
    const totalWallHeight = modelSelector({ form }, `${componentAccessor}.wall.measurements.height`);
    const ponyWallHeight = modelSelector({ form }, `${componentAccessor}.wall.ponyWall.ponyWallHeight`);
    const expPerimeterValue = selector({ form }, `${accessor}.exposedSurfacePerimeter`);

    const { codeRef: selectedCodeRef } = selector({ form }, `${accessor}.wall.ponyWall.ponyWallInsType`) || {};
    const selectedNomRValue = selector({ form }, `${accessor}.wall.ponyWall.ponyWallInsType_nomRVal`) || 0;
    const selectedEffRValue = selector({ form }, `${accessor}.wall.ponyWall.ponyWallInsType_effRVal`) || 0;
    const compositePonyEffRValue =
        selector({ form }, `${accessor}.wall.ponyWall.ponyWallInsType_composite.effRVal`) || 0;
    const ponyWallCodeWarning = selector({ form }, `${accessor}.wall.ponyWall.ponyWallInsType_warning`) || "";
    const ponyWallHeightUnits = selector({ form }, `${accessor}.wall.ponyWall.ponyWallHeight_u`);
    const ponyWallHeightDrawing = selector({ form }, `${accessor}.wall.ponyWall.ponyWallHeight_drawingRef`) || {};
    const rValUnits = selector({ form }, `${accessor}.wall.ponyWall.ponyWallInsType_u`);

    const { window = {}, door = {} } = selector({ form }, `${accessor}.subcomponents`) || {};

    const { [id]: { ponyWallInsType: isCalculatingRValue = false } = {} } = calculatingRValue;

    const ponyHeightWarning = ponyWallHeight + depthBelowGrade >= totalWallHeight ? "ponyHeightWarning" : "";

    const ponyWallHeightEquation = selector({ form }, `${accessor}.wall.ponyWall.ponyWallHeight_eqn`);

    const subcompArea = [...Object.values(window), ...Object.values(door)].reduce(
        (sum, curr) => sum + curr?.measurements?.area || 0,
        0
    );

    const ponyWallArea = ponyWallHeight * expPerimeterValue;
    const ponyWallSubCompAreaWarning =
        isEnabled && subcompArea > ponyWallArea && ponyWallArea > 0 ? "ponyWallSubCompAreaWarning" : "";

    return {
        id,
        accessor,
        libCodes: codes,
        modelCodes,
        selectedCodeRef,
        modelUnits,
        rValUnits,
        ponyWallHeightUnits,
        selectedNomRValue,
        selectedEffRValue,
        isEnabled,
        ponyWallHeightDrawing: !isEmpty(ponyWallHeightDrawing),
        ponyWallCodeWarning,
        isCalculatingRValue,
        ponyHeightWarning,
        ponyWallSubCompAreaWarning,
        compositePonyEffRValue,
        formName,
        ponyWallHeightEquation,
        canBeEditedFromParametric,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({
    setInitCode: async (code, type) => {
        return dispatch(setInitCode(code, type));
    },
    updateCodeDrawer: (updates) => dispatch(updateCodeDrawer({ updates })),
    updateCompositeCalc: (updates) => dispatch(updateCompositeCalc(updates)),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PonyWall);
