import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import SelectorDrawer from "./";
import windowCodeTemplate from "features/Model/Enclosure/Templates/windowCode.json";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import isEmpty from "lodash/isEmpty";
import { windowCodeLogic } from "utils/codes";

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode, getWindowRValue } = enclosureActions;
const { updateUpgradeCodes } = upgradeActions;

const mapStateToProps = (
    {
        form,
        model: { modelId },
        user: { uid },
        enclosure: { initCodeValues: { windowType = {} } = {} } = {},
        upgradeLibrary: { currentPackage, selectedUpgrade },
    },
    {
        fieldAccessor,
        windowAccessor = fieldAccessor,
        modelFormChange,
        currentFormChange,
        change,
        isCodeLibrary = false,
        componentId,
    }
) => {
    const selector = formValueSelector("windowCode");
    const modelSelector = formValueSelector("model");
    const { id: windowGlazingTypeId, value: windowGlazingTypeValue } =
        selector({ form }, "windowCode.layers.glazingType") || {};
    const { value: windowCoatingsTintsValue } = selector({ form }, "windowCode.layers.coatingsTints") || {};
    const { value: windowFillTypeValue } = selector({ form }, "windowCode.layers.fillType") || {};
    const { value: windowSpacerTypeValue } = selector({ form }, "windowCode.layers.spacerType") || {};
    const { id: windowTypeId, value: windowTypeValue } = selector({ form }, "windowCode.layers.windowType") || {};
    const { id: windowFrameMatId, value: windowFrameMatValue } =
        selector({ form }, "windowCode.layers.frameMaterial") || {};
    const windowLabel = selector({ form }, "windowCode.label") || "";
    // const modelDefaultCodes = modelSelector({form}, 'modelData.defaultCodes.window');
    const modelCodes = modelSelector({ form }, "modelData.codes") || {};
    const components = modelSelector({ form }, "modelData.components") || {};

    const windowValue = `${windowGlazingTypeValue}${windowCoatingsTintsValue}${windowFillTypeValue}${windowSpacerTypeValue}${windowTypeValue}${windowFrameMatValue}`;

    const { codeRef: editingCodeRef = null, isLibCode = false } = windowType;

    return {
        windowLabel,
        windowAccessor,
        fieldAccessor: windowAccessor,
        modelFormChange,
        initialValues: {
            windowCode: isEmpty(windowType) ? windowCodeTemplate : windowType,
            addToLibrary: isLibCode,
        },
        windowGlazingTypeId,
        windowGlazingTypeValue,
        windowFillTypeValue,
        windowSpacerTypeValue,
        windowTypeValue,
        windowTypeId,
        windowFrameMatId,
        isSingleGlazing: parseFloat(windowGlazingTypeId) === 0,
        makeSpacerFillDefault: windowGlazingTypeValue === "1",
        windowValue,
        modelId,
        uid,
        change,
        editingCodeRef,
        isEditing: !!editingCodeRef,
        isLibCode,
        components,
        modelCodes,
        isCodeLibrary,
        isUpgradeComponent: windowAccessor.includes("fields"),
        currentFormChange,
        currentPackage,
        selectedUpgrade,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearForm: () => dispatch(reset("windowCode")),
    clearInitCode: () => dispatch(setInitCode({}, "windowType")),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        windowAccessor,
        modelFormChange,
        editingCodeRef,
        components,
        isCodeLibrary,
        isEditing,
        componentId,
        modelCodes,
        currentFormChange,
        currentPackage,
        selectedUpgrade,
        upgradeWhereToAddCode = {},
    }
) => {
    const { windowCode = {}, addToLibrary = false, setAsModelDefault = false, selectedComponents = [] } = form;
    const { codeRef, value, nominalRValue = null, label, isLibCode, ...rest } = windowCode;

    toggleOpen(false);

    const { ecmInputMapUpgrade, ecmInputMapUpgradeId, ecmInputMapUpgradeType } = upgradeWhereToAddCode || {};

    windowCodeLogic({
        componentCodeType: "Window",
        componentId,
        code: windowCode,
        codeType: "S",
        isCodeLibrary,
        isEditing,
        editingCodeRef,
        dispatch,
        getWindowRValue,
        modelFormChange,
        currentFormChange,
        fieldAccessor: windowAccessor,
        components,
        modelCodes,
        addToLibrary,
        addToCodeLib,
        uid,
        deleteCode,
        updateUpgradeCodes,
        setAsModelDefault,
        selectedComponents,
        currentPackage,
        selectedUpgrade,
        ecmInputMapUpgrade,
        ecmInputMapUpgradeId,
        ecmInputMapUpgradeType,
        isEcmInputMap: !isEmpty(upgradeWhereToAddCode),
    });

    // Clear window code defaults for next time
    dispatch(setInitCode({}, "windowType"));
    // Reset form
    dispatch(reset("windowCode"));
};

const form = reduxForm({
    form: "windowCode",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(SelectorDrawer);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
