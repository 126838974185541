import React, { useCallback, useState, useEffect } from "react";
import { isEmpty } from "lodash";
import moment from "moment";

import classes from "./style.module.scss";
import classNames from "classnames";
import { getAllCbatCsvFiles, getCbatBatchSize, startBatchRun } from "utils/parametric/api";
import { saveCbatZipFile } from "utils/parametric/index";

import NewBatchDrawer from "./NewBatchDrawer/index.jsx";
import SectionHeading from "components/SectionHeading";
import Details from "./Details";
import DetailsDrawer from "components/DetailsDrawer";
import MultiUploadInput from "components/Input/MultiUploadInput";
import Button from "components/Button";
import InputWithUnits from "components/Input/InputWithUnits";
import LoadingDots from "components/LoadingDots";
import Loading from "components/Loading";
import OpenIcon from "assets/images/icons/JSX/OpenDrawer";
import Export from "assets/images/icons/JSX/Export";
import ModelTags from "components/ModelTags";
import { PageRestricted } from "features/Navigation/PageNotFound";

const Batch = ({
    modelId = "",
    complete = false,
    estimatedTimeComplete = "",
    cbatToken = "",
    batchId = "",
    modelName = "",
    handleExport = () => {},
    exporting = false,
    buildType = "cbat",
}) => {
    let localTime = "";
    if (estimatedTimeComplete !== "") {
        const testDateUtc = moment.utc(estimatedTimeComplete);
        localTime = moment(testDateUtc).local().format("lll");
    }

    const progress = complete ? classes.simComplete : classes.simInProgress;
    const progressText = estimatedTimeComplete === "" ? "In progress" : `Est. completion time: ${localTime}`;

    return (
        <li className={classes.batch}>
            <div className={classes.batchTitle}>{modelName}</div>
            <div className={classes.batchId}>{`ID: ${cbatToken || batchId}`}</div>
            <div className={progress}>{complete ? "Complete" : progressText}</div>
            {buildType === "lret" ? <ModelTags tag={"RETROFIT_BATCH"} /> : <div></div>}
            {complete && (
                <div className={classes.actions}>
                    <Button
                        smallPadding
                        onClick={() => handleExport(batchId, modelName, buildType)}
                        disabled={exporting}
                        icon={Export}
                        className={classes.exportButton}
                    >
                        Download CSVs
                    </Button>
                </div>
            )}
        </li>
    );
};

const LegacyParametricAnalysis = ({ uid, batchDir = {}, fetchUserBatchDir, batchDirLoading, CBAT_INPUT }) => {
    const [newBatchOpen, toggleNewBatchOpen] = useState(false);
    const [detailsOpen, toggleDetailsOpen] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [files, setFiles] = useState([]);
    const [netZero, setNetZero] = useState(0);
    const [generating, setGenerating] = useState(false);
    // const [reloading, setReloading] = useState(false)
    const [complete, setComplete] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        if (uid) {
            fetchUserBatchDir(uid);
        }
    }, [uid, fetchUserBatchDir]);

    const { batches = {} } = batchDir;

    // const handleUpload = useCallback(async (data) => {
    //     setError("");
    //     setComplete(false);

    //     if (!data) {
    //         return;
    //     }

    //     setComplete(false);
    //     setFiles(data);
    // }, []);

    const handleStartNewBatchClick = () => {
        //toggle drawer open
        toggleNewBatchOpen(true);
    };

    const handleBatchSubmit = async ({ batchSize, modelId, cbatToken, ecmInputMap, buildType, params }) => {
        // setReloading(true)
        const response = await startBatchRun({ batchSize, modelId, cbatToken, ecmInputMap, buildType, params });
        if (isEmpty(response.err)) {
            fetchUserBatchDir(uid);
        }
        return response;
        // setReloading(false);
        // toggleNewBatchOpen(true);
    };

    const handleExport = async (batchId, modelName, buildType) => {
        setExporting(true);

        const batchSizeResponse = await getCbatBatchSize({ batchId });

        const { data: { numCombinations, requestLimit = 10000 } = {} } = batchSizeResponse || {};
        if (numCombinations <= requestLimit) {
            //Can download in one shot

            const results = await getAllCbatCsvFiles({ batchId, requestNum: 1, buildType });

            const { data = {}, status } = results || {};

            if (!isEmpty(data) && status === 200) {
                saveCbatZipFile(modelName, data);
            }
        } else if (numCombinations > requestLimit) {
            //batch exceeds 10000 in size, need to split it into chunks
            const requestCounts = Math.ceil(numCombinations / requestLimit);
            // const requestCounts = 2;
            const reqNums = Array.from({ length: requestCounts }, (_, i) => i + 1);

            const promises = reqNums.map(async (requestNum) => {
                const response = await getAllCbatCsvFiles({ batchId, requestNum: requestNum, buildType });
                return response.data || {};
            });

            const allResponses = await Promise.all(promises); //order assumed to be preserved.

            const csvData = {
                ArchData: "",
                EcmData: "",
                LocData: "",
                RefRunData: "",
                RunData: "",
            };

            allResponses.forEach((res) => {
                if (res.requestNum === 1) {
                    csvData.ArchData = res.ArchData;
                    csvData.EcmData = res.EcmData;
                    csvData.LocData = res.LocData;
                    csvData.RunData = res.RunData;
                    csvData.RefRunData = res.RefRunData;
                } else {
                    csvData.RunData = `${csvData.RunData}${res.RunData}`;
                }
            });

            saveCbatZipFile(modelName, csvData);

            // for (let requestNum = 1; requestNum <= requestCounts; requestNum++) {}
        }
        setExporting(false);
    };

    const infoBarClasses = classNames(classes.infoBar, {
        [classes.complete]: complete,
        [classes.generating]: generating,
        [classes.error]: error,
    });

    const infoBarTextMap = {
        complete: "Your report is complete! You should see it in your downloads folder.",
        generating: "Your report is generating. Please do not reload this page.",
        error: error,
    };

    const infoBarText = generating
        ? infoBarTextMap["generating"]
        : error
        ? infoBarTextMap["error"]
        : complete
        ? infoBarTextMap["complete"]
        : "";

    if (!CBAT_INPUT) {
        return <PageRestricted />;
    }

    return (
        <div>
            <NewBatchDrawer
                toggleOpen={toggleNewBatchOpen}
                isOpen={newBatchOpen}
                handleBatchSubmit={handleBatchSubmit}
                // modelChange={modelChange}
                // {...upgradeOpen}
            />
            <SectionHeading
                title="Parametric Analysis"
                subtitle={
                    <span style={{ maxWidth: "60rem", display: "inline-block" }}>
                        Generate parametric simulation outputs for NRCan's Cost Benefit Analysis Tool (CBAT)
                    </span>
                }
            />
            <div className={classes.contentWrapper}>
                <div className={classes.batchHistoryArea}>
                    <div className={classes.batchHeader}>
                        <h3>Simulations</h3>
                        <Button onClick={handleStartNewBatchClick} disabled={exporting}>
                            {generating ? "Running batch..." : "Start new batch"}
                        </Button>
                    </div>
                    {batchDirLoading && <Loading className={classes.loading} message="Loading Batch Runs" />}
                    {!batchDirLoading && isEmpty(batches) && (
                        <div className={classes.batchesEmpty}>
                            <h3>Run your first parametric analysis!</h3>
                            <p>Click the "Start new batch" button to upload your .h2k file and begin the analysis.</p>
                        </div>
                    )}
                    {!batchDirLoading && !isEmpty(batches) && (
                        <div className={classes.batchesBody}>
                            <ul>
                                {Object.entries(batches)
                                    .sort(([aKey, aValues], [bKey, bValues]) => {
                                        if (aValues.modelName.toLowerCase() < bValues.modelName.toLowerCase()) {
                                            return -1;
                                        }
                                        if (aValues.modelName.toLowerCase() > bValues.modelName.toLowerCase()) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                    .map(
                                        ([
                                            key,
                                            {
                                                modelName,
                                                modelId,
                                                complete,
                                                estimatedTimeComplete,
                                                cbatToken = "",
                                                buildType,
                                            },
                                        ]) => (
                                            <Batch
                                                key={key}
                                                modelId={modelId}
                                                batchId={key}
                                                cbatToken={cbatToken}
                                                modelName={modelName}
                                                complete={complete}
                                                handleExport={handleExport}
                                                estimatedTimeComplete={estimatedTimeComplete}
                                                exporting={exporting}
                                                buildType={buildType}
                                                // selectModel={selectModel}
                                                // name={name}
                                                // lastEdited={lastEdited}
                                                // startDelete={startDelete}
                                            />
                                        )
                                    )}
                            </ul>
                        </div>
                    )}

                    {infoBarText && (
                        <div className={infoBarClasses}>
                            <p>
                                {generating && <LoadingDots />}
                                {infoBarText}
                            </p>
                        </div>
                    )}
                </div>
                <div className={classes.instructionsArea}>
                    <div className={classes.header}>
                        <h3>Instructions</h3>
                        <span className={classes.details} onClick={() => toggleDetailsOpen(true)}>
                            Simulation Details <OpenIcon />
                        </span>
                    </div>

                    <p>
                        Upload a fully completed HOT2000 file (.h2k) to run a parametric analysis and generate the
                        required .csv input files to use in NRCan's Cost Benefit Analysis Tool (CBAT).
                    </p>
                    <p>
                        The .h2k file that you upload <strong>must</strong> contain simulation results generated in{" "}
                        <strong>ERS mode</strong>, and must not produce any errors when running a simulation or opening
                        the file.
                    </p>
                    <p>
                        The parametric analysis performs upwards of 144,000 unique simulations on your .h2k file, and
                        can take several hours. You will recieve an email notification once the simulation is complete,
                        at which point you will be able to download the required CSVs ffrom this page.
                    </p>
                </div>
                <div>
                    <DetailsDrawer
                        open={detailsOpen}
                        close={() => toggleDetailsOpen(false)}
                        title="Parametric Analysis Details"
                    >
                        <Details />
                        <div className={classes.buttons}>
                            <Button large type="hollow" onClick={() => toggleDetailsOpen(false)}>
                                Close
                            </Button>
                        </div>
                    </DetailsDrawer>
                </div>
            </div>
        </div>
    );
};

export default LegacyParametricAnalysis;
