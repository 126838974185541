import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isEmpty } from "lodash";

import { getUpgradesToAdd } from "utils/parametric";

import { selectUsersOrganization, selectUserUid } from "store/users/selectors";
import { selectSaveUpgradePackageLabels } from "store/upgradeLibrary/selectors";
import { selectParametricAnalysisData } from "store/parametricAnalysis/selectors";
import { fetchUpgradeLabels, fetchUpgradePackage } from "store/upgradeLibrary/thunk";

import Dialog, { CloseDialog } from "components/Dialog";
import Select from "components/Input/Select";
import Button from "components/Button";
import Loading from "components/Loading";

import classes from "./styles.module.scss";

const AddUpgradeFromLibraryDialog = ({
    isOpen,
    setIsAddingFromLibrary,
    userUid,
    userOrganization,
    fetchUpgradeLabels,
    upgradePackageLabels,
    fetchUpgradePackage,
    parametricAnalysisData,
    addUpgradeToParametricAnalysis,
}) => {
    const [isLoadingLabels, setIsLoadingLabels] = useState(false);
    const [isLoadingPackage, setIsLoadingPackage] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState({});

    const orgId = !isEmpty(userOrganization) ? Object.keys(userOrganization.memberOrgIds)[0] : null;

    const { ecmInputMap = {} } = parametricAnalysisData || {};

    useEffect(() => {
        setIsLoadingLabels(true);

        const fetchUpgradeLabelsAsync = async () => {
            await fetchUpgradeLabels(userUid, orgId);

            setIsLoadingLabels(false);
        };

        if (userUid && isOpen) {
            fetchUpgradeLabelsAsync(userUid, orgId);
        }
    }, [userUid, orgId, fetchUpgradeLabels, isOpen]);

    const organizedUpgradePackageLabels = upgradePackageLabels
        ? [
              ...upgradePackageLabels.upgradeLibMap,
              ...upgradePackageLabels.orgUpgradeLibMap.map(({ id, name }) => ({
                  name,
                  id,
                  isFromOrg: true,
              })),
          ]
        : [];

    const handleLoad = async () => {
        setIsLoadingPackage(true);

        const packageToAdd = await fetchUpgradePackage(
            userUid,
            selectedPackage.id,
            true,
            selectedPackage.isFromOrg ? orgId : null
        );

        // console.log("packageToAdd", packageToAdd);

        const upgradesToAdd = getUpgradesToAdd(packageToAdd.upgrades, ecmInputMap);

        upgradesToAdd.forEach((upgrade) => {
            const upgradeType = Object.values(upgrade)[0].packageValues.upgradeSubType;

            addUpgradeToParametricAnalysis(upgradeType, upgrade);
        });

        setIsLoadingPackage(false);
        setIsAddingFromLibrary(false);
    };

    return (
        <Dialog
            open={isOpen}
            classes={{
                rootClass: "",
                paperClass: classes.dialogPaper,
            }}
            onBackdropClick={() => setIsAddingFromLibrary(false)}
        >
            <CloseDialog closeActions={() => setIsAddingFromLibrary(false)} />
            {isLoadingLabels ? (
                <Loading />
            ) : (
                <>
                    <div className={classes.packageDialogContent}>
                        <h2>Add Upgrade Package</h2>
                        <Select
                            type="text"
                            name={"packageName"}
                            label={`Load Saved Package`}
                            placeholder="Select saved upgrade package"
                            //validate={validate}
                            disabled={!organizedUpgradePackageLabels}
                            hideLabel
                            search
                            options={
                                organizedUpgradePackageLabels
                                    ? organizedUpgradePackageLabels.map(({ id, name, isFromOrg }) => ({
                                          label: name,
                                          value: { name, id, isFromOrg: !!isFromOrg },
                                      }))
                                    : []
                            }
                            input={{
                                value: selectedPackage,
                                onChange: (val) => setSelectedPackage(val),
                            }}
                        />
                    </div>
                    <Button onClick={handleLoad}>{isLoadingPackage ? "Adding..." : "Add Upgrade Package"}</Button>
                </>
            )}
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    userUid: selectUserUid,
    userOrganization: selectUsersOrganization,
    upgradePackageLabels: selectSaveUpgradePackageLabels,
    parametricAnalysisData: selectParametricAnalysisData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUpgradeLabels: (uid, orgId) => dispatch(fetchUpgradeLabels(uid, orgId)),
    fetchUpgradePackage: (uid, packageId, isFromOrg, orgId) =>
        dispatch(fetchUpgradePackage(uid, packageId, isFromOrg, orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpgradeFromLibraryDialog);
