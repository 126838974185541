import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import isEmpty from "lodash/isEmpty";
import InputRow from "components/Input/Row";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import PVSystem from "./PVSystem/container";
import AddSystem from "./AddSystem/container";
import EmptyState from "components/EmptyState";

const windEnergyValidation = getValidation("windContribution");
const pvCapacityValidation = getValidation("pvSystemCapacity");

export default ({
    accessor,
    pvSystems,
    pvCapacityValue,
    windEnergyUnits,
    pvCapacityUnits,
    modelUnits,
    change,
    h2kMinor,
    isUpgrade = false,
    formName,
    canBeEditedFromParametric = true,
}) => {
    const [azimSubmitting, setAzimSubmitting] = useState(false);
    const numSystems = Object.keys(pvSystems).length;

    useEffect(() => {
        const totalCapacity = Object.keys(pvSystems).reduce((sum, id) => {
            const { array: { capacity = 0 } = {} } = pvSystems[id] || {};
            return sum + capacity;
        }, 0);

        if (!isNaN(totalCapacity) && totalCapacity > 0 && parseInt(h2kMinor) >= 11) {
            change(`${accessor}.pvCapacity`, parseFloat(totalCapacity.toFixed(2)));
        }
    }, [pvSystems]);

    return (
        <div className={!isUpgrade && sharedClasses.section}>
            <div className={classes.heading}>
                <div className={classes.title}>
                    <h4>Enter data for on-site renewable energy generation.</h4>
                </div>
            </div>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.windEnergyContribution`}
                    label="Annual Wind Energy"
                    placeholder={0.0}
                    validate={windEnergyValidation}
                    decimals={getDecimalPlaces("windContribution")}
                    change={change}
                    units={{
                        base: getBaseUnits("windContribution", modelUnits),
                        options: getUnitOptions("energy"),
                        selected: windEnergyUnits,
                        unitType: "energy",
                        accessor: `${accessor}.windEnergyContribution_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
                {h2kMinor >= 11 && (
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={`${accessor}.pvCapacity`}
                        label="PV System Capacity"
                        placeholder={0.0}
                        validate={pvCapacityValidation}
                        decimals={getDecimalPlaces("pvSystemCapacity")}
                        change={change}
                        setValue={pvCapacityValue}
                        units={{
                            base: getBaseUnits("pvSystemCapacity", modelUnits),
                            options: ["kW", "W"],
                            selected: pvCapacityUnits,
                            unitType: "power",
                            accessor: `${accessor}.pvCapacity_u`,
                        }}
                        disabled={!canBeEditedFromParametric}
                    />
                )}
                <Field
                    component={Checkbox}
                    name={`${accessor}.solarReady`}
                    label="Solar Ready"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                    disabled={!canBeEditedFromParametric}
                />
                {h2kMinor >= 11 && (
                    <Field
                        component={Checkbox}
                        name={`${accessor}.hasBatteryStorage`}
                        label="Battery Storage"
                        large
                        className={classes.checkbox}
                        type="checkbox"
                        disabled={!canBeEditedFromParametric}
                    />
                )}
            </InputRow>
            <div className={classes.title}>
                <h4>Photovoltaic Systems</h4>
                <AddSystem accessor={accessor} change={change} numSystems={numSystems} formName={formName} />
            </div>
            {isEmpty(pvSystems) && <EmptyState title="No photovoltaic systems to display" style={{}} />}
            {!isEmpty(pvSystems) &&
                Object.keys(pvSystems).map((id) => (
                    <PVSystem
                        accessor={`${accessor}.pvSystems.${id}`}
                        change={change}
                        key={id}
                        formName={formName}
                        isUpgrade={isUpgrade}
                        setAzimSubmitting={setAzimSubmitting}
                        azimSubmitting={azimSubmitting}
                    />
                ))}
        </div>
    );
};
