import React, { useState } from "react";
import classes from "./style.module.scss";
import { Field } from "redux-form";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import { maxLength, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { getDwhrManufacturers, getDwhrModels, getDwhrEff } from "utils/mechanicals";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import Delete from "assets/images/icons/JSX/Delete";
import ThreeDots from "assets/images/icons/JSX/ThreeDots";
import DropDown from "components/Dropdown";

const charLim100 = maxLength(100);
const dwhrMURBUnitsConnectedValidation = getValidation("dwhrMURBConnectedUnits");
const manufacturerValidation = getValidation("dwhrManufacturer");
const modelValidation = getValidation("dwhrModel");
const effValidation = getValidation("dwhrRatedEff");

const dwhrManufacturers = getDwhrManufacturers();

export default ({
    accessor,
    selectedManufacturer,
    effValue,
    effUnits,
    modelUnits,
    change,
    handleDelete,
    isMURB,
    zeroConnectedUnits,
    isDwhrParametric = false,
}) => {
    const [menuOpen, toggleMenu] = useState(false);
    const parentAccessor = accessor.replace(".drainWaterHeatRecovery", "");

    return (
        <>
            <div className={classes.heading}>
                <div className={classes.title}>
                    <h4>Drain Water Heat Recovery</h4>
                    {!isDwhrParametric && (
                        <span className={classes.menu} onClick={() => toggleMenu(!menuOpen)}>
                            <ThreeDots />
                        </span>
                    )}

                    <DropDown
                        className={classes.dropDown}
                        open={menuOpen}
                        toggleOpen={toggleMenu}
                        options={[
                            {
                                label: (
                                    <span>
                                        <Delete /> Delete
                                    </span>
                                ),
                                className: classes.delete,
                                onClick: handleDelete,
                            },
                        ]}
                    />
                </div>
            </div>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr" lastRow={!isMURB}>
                <Field
                    component={Select}
                    name={`${accessor}.manufacturer`}
                    options={dwhrManufacturers}
                    label="Manufacturer"
                    placeholder="Choose Manufacturer"
                    validate={manufacturerValidation}
                />
                <Field
                    component={Select}
                    name={`${accessor}.model`}
                    options={getDwhrModels(selectedManufacturer)}
                    label="Model"
                    placeholder="Choose Model"
                    validate={modelValidation}
                    onChange={(value) => {
                        change(
                            `${accessor}.efficiencyRating`,
                            getDwhrEff({
                                manufacturer: selectedManufacturer,
                                model: value,
                            })
                        );
                    }}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.efficiencyRating`}
                    label="Rated Efficiency"
                    placeholder={0.0}
                    disabled={true}
                    validate={effValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces("dwhrRatedEff")}
                    setValue={effValue}
                    change={change}
                    units={{
                        base: getBaseUnits("dwhrRatedEff", modelUnits),
                        options: getUnitOptions("fraction"),
                        selected: effUnits,
                        unitType: "fraction",
                        accessor: `${accessor}.efficiencyRating_u`,
                    }}
                    info={"Rated at 9.5 L/min"}
                />
                <Field
                    component={Select}
                    name={`${accessor}.preheatShowerTank`}
                    options={[
                        {
                            label: "Water heater only",
                            value: false,
                        },
                        {
                            label: "Water heater and shower",
                            value: true,
                        },
                    ]}
                    label="Preheated water delivered to:"
                    placeholder="Choose Option"
                />
            </InputRow>
            {isMURB && (
                <InputRow gridTemplate="1fr 1fr 1fr 1fr" lastRow>
                    <Field
                        component={Input}
                        name={`${parentAccessor}.connectedUnitsDwhr`}
                        type="number"
                        label="Number of units connected to DWHR"
                        placeholder={0}
                        validate={dwhrMURBUnitsConnectedValidation}
                        warning={isMURB && zeroConnectedUnits && "noConnectedMurbDwhrUnits"}
                    />
                </InputRow>
            )}
        </>
    );
};
