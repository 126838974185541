import React, { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import classes from "./style.module.scss";
import Drawer from "components/Drawer";
import MultiSelect from "components/Input/MultiSelect";
import Checkbox from "components/Input/Checkbox";
import Button from "components/Button";
import { selectUserModelDir, selectUsersOrganization, selectUserUid } from "store/users/selectors";
import { actions as userActions } from "store/users";
import { actions as modelActions } from "features/Model/_ducks";
import { getFolderFileOptions, getOrgMemberOptions } from "utils/fileSharing";

const { fetchUserOrganization } = userActions;
const { shareFolderOfModels } = modelActions;

const ShareFolderDrawer = ({
    toShare,
    close,
    uid,
    modelDir,
    organization,
    fetchUserOrganization,
    shareFolderOfModels,
    currentFolder = {},
}) => {
    const [selectedFileList, setSelectedFileList] = useState([]);
    const [selectedUserList, setSelectedUserList] = useState([]);
    const [sharePermissionAcknowledged, setSharePermissionAcknowledged] = useState(false);
    const [sharingLoading, setSharingLoading] = useState(false);

    const { folders = {}, models = {} } = modelDir;

    const { memberOrgIds = {}, details = {} } = organization;

    const { id: primaryMemberOrgId = "", name: primaryMemberOrgName = "" } =
        memberOrgIds?.[Object.keys(memberOrgIds)[0]] || {};

    const { [primaryMemberOrgId]: { members = {} } = {} } = details;

    const { name: currentFolderName = "" } = currentFolder;

    useEffect(() => {
        if (uid && organization?.memberOrgIds && !organization.details) {
            // console.log("uid/org", uid, organization);
            //only handling a single org at the moment
            fetchUserOrganization(uid, Object.keys(organization.memberOrgIds)[0]);
        }
    }, [uid, organization]);

    const handleShareClick = useCallback(async () => {
        setSharingLoading(true);
        // Await action completion before sharing another file
        if (currentFolderName !== "") {
            await shareFolderOfModels(selectedFileList, uid, primaryMemberOrgId, selectedUserList, currentFolderName);
        }

        setSharingLoading(false);
        setSelectedFileList([]);
        setSelectedUserList([]);
        close();
    }, [primaryMemberOrgId, selectedFileList, selectedUserList, uid, currentFolderName]);

    const files = Object.fromEntries(Object.entries(models).filter(([key, value]) => value.parentFolderId === toShare));

    // Automatically select all files in folder when initializing
    useEffect(() => {
        setSelectedFileList(Object.keys(files));
    }, []);

    // Get files and members for multiSelect
    let multiSelectFileOptions = [...getFolderFileOptions(files)];

    let multiSelectMemberOptions = [
        ...getOrgMemberOptions(members, {
            labelSuffix: (val) => (val.uid === uid ? " (you)" : ""),
            disabledCheck: (val) => val.uid === uid,
        }),
    ];

    return (
        <Drawer open={!!toShare} title="Share Folder Files" subtitle="" close={close}>
            <div className={classes.shareFolderContainer}>
                <div className={classes.orgHeader}>
                    <p>
                        Your organization: <strong>{primaryMemberOrgName || "None"}</strong>
                    </p>
                </div>
                <MultiSelect
                    selectAllName={"selectAll-files"}
                    label={"Select files from this folder to share:"}
                    options={multiSelectFileOptions}
                    input={{
                        value: selectedFileList,
                        onChange: (value) => setSelectedFileList(value),
                    }}
                    hasSelectAll={true}
                />
                <MultiSelect
                    selectAllName={"selectAll-users"}
                    label={"Select members of your organization that you would like to share this file with:"}
                    options={multiSelectMemberOptions}
                    input={{
                        value: selectedUserList,
                        onChange: (value) => setSelectedUserList(value),
                    }}
                    hasSelectAll={true}
                />
                <div className={classes.sharingConfirmationText}>
                    <Checkbox
                        name="permissionAcknowledged"
                        label="I have recieved permission from all relevant parties to share this folder and its content with the selected individuals"
                        large
                        input={{
                            value: sharePermissionAcknowledged,
                            onChange: () => setSharePermissionAcknowledged(!sharePermissionAcknowledged),
                        }}
                    />
                </div>
                <div className={classes.shareButtonContainer}>
                    <Button
                        className={classes.shareButton}
                        type="default"
                        onClick={handleShareClick}
                        disabled={
                            !sharePermissionAcknowledged ||
                            isEmpty(selectedUserList) ||
                            isEmpty(selectedFileList) ||
                            sharingLoading
                        }
                    >
                        {sharingLoading ? "Sharing..." : "Share File"}
                    </Button>
                </div>
            </div>
        </Drawer>
    );
};

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    organization: selectUsersOrganization,
    modelDir: selectUserModelDir,
});

const mapDispatchToProps = (dispatch, { history }) => ({
    fetchUserOrganization: (uid, orgId) => dispatch(fetchUserOrganization(uid, orgId)),
    shareFolderOfModels: async (selectedFileList, uid, primaryMemberOrgId, selectedUserList, folderName) =>
        await dispatch(shareFolderOfModels(selectedFileList, uid, primaryMemberOrgId, selectedUserList, folderName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareFolderDrawer);
