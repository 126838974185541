import React from "react";

import classes from "./styles.module.scss";

const ModelSummaryLayout = ({ title, children }) => {
    return (
        <div className={classes.modelSummaryLayout}>
            <span className={classes.modelSummarytitle}>{title}</span>
            {children}
        </div>
    );
};

export default ModelSummaryLayout;
