import React, { useCallback } from "react";
import globalStyles from "components/globalStyles.module.scss";
import classes from "./style.module.scss";
import { Field } from "redux-form";
import Checkbox from "components/Input/Checkbox";
import { required } from "utils/fieldValidation";
import { getOptions } from "utils/fields";
import { isEqual } from "lodash";
import { upgradeOptionsComponents, upgradeOptionsSystems } from "utils/upgrades";

import Button from "components/Button";
import Drawer from "components/Drawer";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";

const windowDirectionMap = {
    0: { key: "south", label: "South" },
    1: { key: "southEast", label: "Southeast" },
    2: { key: "east", label: "East" },
    3: { key: "northEast", label: "Northeast" },
    4: { key: "north", label: "North" },
    5: { key: "northWest", label: "Northwest" },
    6: { key: "west", label: "West" },
    7: { key: "southWest", label: "Southwest" },
};

const PriorityCostItem = ({ type, label, change, upgrades, windowDirections }) => {
    if (type === "heatingCooling") {
        return (
            <>
                <InputRow className={classes.group} gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        name={`upgradeSettings.heating.cost`}
                        label={`Heating Cost`}
                        placeholder={0.0}
                        decimals={2}
                        change={change}
                        units={{
                            base: "$",
                            selected: "$",
                            unitType: "cost",
                        }}
                    />
                    <Field
                        component={Input}
                        type="number"
                        name={`upgradeSettings.heating.priority`}
                        label={`Heating Priority`}
                        placeholder={0}
                        decimals={0}
                        quantity
                        change={change}
                    />
                </InputRow>
                <InputRow className={classes.group} gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        name={`upgradeSettings.cooling.cost`}
                        label={`Cooling Cost`}
                        placeholder={0.0}
                        decimals={2}
                        change={change}
                        units={{
                            base: "$",
                            selected: "$",
                            unitType: "cost",
                        }}
                    />
                    <Field
                        component={Input}
                        type="number"
                        name={`upgradeSettings.cooling.priority`}
                        label={`Cooling Priority`}
                        placeholder={0}
                        decimals={0}
                        quantity
                        change={change}
                    />
                </InputRow>
            </>
        );
    } else if (type === "window") {
        return (
            <div className={classes.group}>
                <InputRow gridTemplate="1fr 1fr">
                    <Field
                        component={InputWithUnits}
                        name={`upgradeSettings.window.overall.cost`}
                        label={`${label} Cost`}
                        placeholder={0.0}
                        decimals={2}
                        change={change}
                        units={{
                            base: "$",
                            selected: "$",
                            unitType: "cost",
                        }}
                        disabled={true}
                    />
                    <Field
                        component={Input}
                        type="number"
                        name={`upgradeSettings.window.overall.priority`}
                        label={`${label} Priority`}
                        placeholder={0}
                        decimals={0}
                        quantity
                        change={change}
                    />
                </InputRow>
                {windowDirections.sort().map((dirId, ind) => {
                    return (
                        <InputRow gridTemplate="1fr 1fr" id={ind}>
                            <Field
                                component={InputWithUnits}
                                name={`upgradeSettings.window.cost.${windowDirectionMap[dirId]?.key}`}
                                label={`${windowDirectionMap[dirId].label} Window Cost`}
                                placeholder={0.0}
                                decimals={2}
                                change={change}
                                units={{
                                    base: "$",
                                    selected: "$",
                                    unitType: "cost",
                                }}
                            />
                        </InputRow>
                    );
                })}
            </div>
        );
    }

    return (
        <InputRow className={classes.group} gridTemplate="1fr 1fr">
            <Field
                component={InputWithUnits}
                name={`upgradeSettings.${type}.cost`}
                label={`${label} Cost`}
                placeholder={0.0}
                decimals={2}
                change={change}
                units={{
                    base: "$",
                    selected: "$",
                    unitType: "cost",
                }}
            />
            <Field
                component={Input}
                type="number"
                name={`upgradeSettings.${type}.priority`}
                label={`${label} Priority`}
                placeholder={0}
                decimals={0}
                quantity
                change={change}
            />
        </InputRow>
    );
};

const PackageEditDrawer = React.memo(
    ({
        isOpen = false,
        toggleOpen,
        submitting,
        invalid,
        handleSubmit,
        reset,
        upgrades,
        change,
        h2kMinor,
        windowDirections,
        selectUpgrade,
        selectedPackage = "",
    }) => {
        const priorityOptions = Object.keys(upgrades).reduce((array, upgradeId) => {
            let { upgradeType = "" } = upgrades[upgradeId];

            if (upgradeType === "ceiling") {
                const { fields: { constructionType: { id: ceilingConstId = 0 } = {} } = {} } = upgrades[upgradeId];

                upgradeType = [2, 3].includes(ceilingConstId) ? "ceilingFlat" : "ceiling";
            }

            if (array.includes(upgradeType)) {
                return array;
            }

            return [...array, upgradeType];
        }, []);

        const allUpgrades = [
            ...upgradeOptionsComponents,
            { label: "Flat Ceiling", value: "ceilingFlat" },
            ...upgradeOptionsSystems,
        ];

        const handleClose = useCallback(() => {
            toggleOpen({});

            selectUpgrade(null);

            if (reset) reset();
        }, [toggleOpen, reset]);

        const isBase = selectedPackage === "base";

        const checkBoxSectionLabel = [9, 10, 11, 12].includes(h2kMinor) ? "Greener Homes" : "Resiliency Measures";
        const checkBoxSectionAccessor = [9, 10, 11, 12].includes(h2kMinor) ? "greenerHomes" : "resiliencyMeasures";

        return (
            <Drawer
                open={isOpen}
                title="Edit Package"
                subtitle={
                    isBase ? "Change your base house label." : "Change your package name and define upgrade priorities."
                }
                close={handleClose}
                classes={{
                    paperClass: classes.packageDrawerPaper,
                }}
            >
                <div className={classes.contentWrapper}>
                    <div className={classes.mainContent}>
                        <InputRow>
                            <Field
                                component={Input}
                                name={"name"}
                                label={`Package Name`}
                                placeholder="Name your package"
                                // validate={[required]}
                            />
                        </InputRow>
                        {!isBase && (
                            <>
                                <h3>Energy Upgrade Priorities</h3>
                                <div>
                                    {priorityOptions
                                        .sort((a, b) => {
                                            const isASystem = upgradeOptionsSystems.find(({ value }) => value === a);
                                            const isBSystem = upgradeOptionsSystems.find(({ value }) => value === b);

                                            if (isBSystem && !isASystem) {
                                                return -1;
                                            }

                                            if (!isBSystem && isASystem) {
                                                return 1;
                                            }

                                            return a > b ? 1 : a < b ? -1 : 0;
                                        })
                                        .map((type) => {
                                            const label = allUpgrades.find(({ value }) => value === type)?.label;

                                            return (
                                                <PriorityCostItem
                                                    type={type}
                                                    label={label}
                                                    change={change}
                                                    windowDirections={windowDirections}
                                                />
                                            );
                                        })}
                                </div>
                                {h2kMinor >= 10 && (
                                    <>
                                        <h3>{checkBoxSectionLabel}</h3>
                                        <InputRow gridTemplate="1fr 1fr">
                                            <Field
                                                component={Checkbox}
                                                name={`upgradeSettings.${checkBoxSectionAccessor}.basementSlabInsulated`}
                                                label="Basement slab insulated"
                                                large
                                                className={classes.bottomCheckBox}
                                                type="checkbox"
                                            />
                                            <Field
                                                component={Checkbox}
                                                name={`upgradeSettings.${checkBoxSectionAccessor}.backwaterValve`}
                                                label="Backwater valve with alarm"
                                                large
                                                className={classes.bottomCheckBox}
                                                type="checkbox"
                                            />
                                        </InputRow>
                                        <InputRow gridTemplate="1fr 1fr">
                                            <Field
                                                component={Checkbox}
                                                name={`upgradeSettings.${checkBoxSectionAccessor}.moistureProofCrawlSpace`}
                                                label="Moisture proof crawl space"
                                                large
                                                className={classes.bottomCheckBox}
                                                type="checkbox"
                                            />
                                            <Field
                                                component={Checkbox}
                                                name={`upgradeSettings.${checkBoxSectionAccessor}.sumpPump`}
                                                label="Sump pump with alarm and battery backup"
                                                large
                                                className={classes.bottomCheckBox}
                                                type="checkbox"
                                            />
                                        </InputRow>
                                        <InputRow gridTemplate="1fr 1fr">
                                            <Field
                                                component={Checkbox}
                                                name={`upgradeSettings.${checkBoxSectionAccessor}.waterproofing`}
                                                label="Waterproofing"
                                                large
                                                className={classes.bottomCheckBox}
                                                type="checkbox"
                                            />
                                            <Field
                                                component={Checkbox}
                                                name={`upgradeSettings.${checkBoxSectionAccessor}.smartThermostats`}
                                                label="Smart thermostats"
                                                large
                                                className={classes.bottomCheckBox}
                                                type="checkbox"
                                            />
                                        </InputRow>
                                        <InputRow gridTemplate="1fr 1fr">
                                            <Field
                                                component={Checkbox}
                                                name={`upgradeSettings.${checkBoxSectionAccessor}.roofingMembrane`}
                                                label="Adhesive waterproof ice and water barrier underlayment for roofs"
                                                large
                                                className={classes.bottomCheckBox}
                                                type="checkbox"
                                            />
                                            <Field
                                                component={Checkbox}
                                                name={`upgradeSettings.${checkBoxSectionAccessor}.minR10ContinuousExposedFloors`}
                                                label="Min. R10 continuous for exposed floors"
                                                large
                                                className={classes.bottomCheckBox}
                                                type="checkbox"
                                            />
                                        </InputRow>
                                        {h2kMinor >= 13 && (
                                            <InputRow gridTemplate="1fr 1fr">
                                                <Field
                                                    component={Checkbox}
                                                    name={`upgradeSettings.${checkBoxSectionAccessor}.evCharger`}
                                                    label="EV Charger"
                                                    large
                                                    className={classes.bottomCheckBox}
                                                    type="checkbox"
                                                />
                                                <Field
                                                    component={Checkbox}
                                                    name={`upgradeSettings.${checkBoxSectionAccessor}.elecPanelUpgraded`}
                                                    label="Electrical Panel Upgrade"
                                                    large
                                                    className={classes.bottomCheckBox}
                                                    type="checkbox"
                                                />
                                            </InputRow>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className={`${globalStyles.buttons} ${classes.drawerButtons}`}>
                        <Button large type="hollow" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                            {submitting ? "Applying Changes..." : "Apply Changes"}
                        </Button>
                    </div>
                </div>
            </Drawer>
        );
    },
    isEqual
);

export default PackageEditDrawer;
