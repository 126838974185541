import React, { useCallback, useEffect, useState } from "react";

import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";

import { categories } from "utils/enclosure";

import { types } from "features/Model/_ducks";
import { actions as modelActions } from "features/Model/_ducks";

import Loading from "components/Loading";
import { ModelRestricted } from "features/Navigation/PageNotFound";
import CodeDrawer from "./CodeDrawer/container";
import ModelTabs from "./ModelTabs/container";
import SnackbarAlert from "components/Alert";
import ChbaChecklistDrawerButtonModel from "components/ChbaChecklist/ChbaChecklistDrawerButtonModel";

import classes from "./style.module.scss";

//! Mechanicals ASHP perfomance curves
const Model = ({
    loading,
    modelId,
    history,
    fetchModelData,
    codeLib,
    fetchUserCodeLib,
    uid,
    storeysId,
    modelTakeoff,
    modelData,
    modelDir,
    CHBA_NZ_ADMIN = false,
    CHBA_NZEA = false,
    CHBA_NZ_ASSIST = false,
    chbaFileTagged = false,
    isNewSnapshot,
    isSnapshotUpdating,
    submitting,
    simulationRunning,
    isResultsLoading,
    isUpgradesLoading,
    isDrawingSaving,
    isSharingModel,
}) => {
    const dispatch = useDispatch();
    const pathCallback = useCallback(() => {
        return history.location.pathname;
    }, [history.location]);
    const [CHBAProjectChecklistOpen, toggleProjectChecklistOpen] = useState(false);
    const [isInitialLoading, setInitialLoading] = useState(true);

    const handleAddTakeoff = (compType = "", id, name = "") => {
        dispatch({
            type: types.ADD_TAKEOFF_COMP,
            comp: id,
            compType: compType || name,
        });
    };

    useEffect(() => {
        if (!modelId) {
            fetchModelData();
        }

        if (!loading) setInitialLoading(false);

        return () => {
            setInitialLoading(true);
        };
    }, [modelId, fetchModelData]);

    useEffect(() => {
        const unsubscribe = dispatch(modelActions.checkModelUpdates(modelId));

        return () => {
            if (unsubscribe) unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (modelData) {
            const storeyCategories = categories(storeysId) || [];

            for (let i = 0; i < storeyCategories.length; i++) {
                const { id, name } = storeyCategories[i];

                if (!modelTakeoff[id]) {
                    if (id === "foundation" && isEmpty(modelTakeoff[id])) {
                        handleAddTakeoff("basement", id);
                    }
                }

                if (id !== "foundation" && isEmpty(modelTakeoff[id])) {
                    handleAddTakeoff("", id, name);
                }
            }
        }
    }, [modelData]);

    useEffect(() => {
        if (isEmpty(codeLib)) {
            fetchUserCodeLib(uid);
        }
    }, [codeLib, uid, fetchUserCodeLib]);

    if (loading && isInitialLoading) {
        return <Loading className={classes.spinner} />;
    }

    if (!loading && !modelId) {
        return <ModelRestricted />;
    }

    const chbaRoadmapEnabled = (CHBA_NZ_ADMIN || CHBA_NZEA || CHBA_NZ_ASSIST) && chbaFileTagged;

    // for testing
    // const chbaRoadmapEnabled = true;

    // console.log(isNewSnapshot);

    return (
        <div style={{ position: "relative" }}>
            <SnackbarAlert
                open={
                    isNewSnapshot &&
                    !isSnapshotUpdating &&
                    !submitting &&
                    !simulationRunning &&
                    !loading &&
                    !isResultsLoading &&
                    !isUpgradesLoading &&
                    !isDrawingSaving &&
                    !isSharingModel
                }
                message={
                    "Changes to the model have been detected. Save your model to merge changes or refresh to discard changes."
                }
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                direction="down"
                severity="error"
                onExited={() => {}}
                top={"60px"}
            />
            {/* <button
                onClick={() => {
                    toggleProjectChecklistOpen(!CHBAProjectChecklistOpen);
                }}
                className={classes.checklistDrawer}
            >
                <div className={classes.drawerButtonText}>
                    <p>CHBA NZ Reno</p>
                </div>
            </button> */}
            {chbaRoadmapEnabled && (
                <ChbaChecklistDrawerButtonModel
                    open={CHBAProjectChecklistOpen}
                    toggleOpen={toggleProjectChecklistOpen}
                />
            )}
            <ModelTabs
                history={history}
                pathCallback={pathCallback}
                CHBAProjectChecklistOpen={CHBAProjectChecklistOpen}
                toggleProjectChecklistOpen={toggleProjectChecklistOpen}
                modelDir={modelDir}
                isNewSnapshot={isNewSnapshot && !isSnapshotUpdating && !submitting}
                fetchModelData={fetchModelData}
                isSyncing={loading}
            />
            <CodeDrawer />
        </div>
    );
};

export default Model;
