import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { syncParametricAnalysis } from "store/parametricAnalysis/thunk";

import Button from "components/Button";
import ModelInformation from "../ModelInformation";

import { ReactComponent as ResetIcon } from "assets/images/icons/Icon-Reset-blue.svg";

import classes from "./styles.module.scss";

const ModelSnapshot = ({
    lastModelSync,
    modelData,
    syncParametricAnalysis,
    analysisId,
    selectedModelId,
    canResyncModel = true,
}) => {
    const [isSyncing, setIsSyncing] = useState(false);

    return (
        <>
            <div className={classes.modelSnapshotTitleButtonContainer}>
                <div className={classes.titleWrapper}>
                    <span className={classes.title}>Model Snapshot</span>
                    <span className={classes.lastSync}>Last sync: {moment(lastModelSync).format("MMM D, YYYY")}</span>
                </div>
                <Button
                    className={isSyncing ? `${classes.syncDrawing}` : ""}
                    type="hollow"
                    icon={ResetIcon}
                    smallPadding
                    onClick={async () => {
                        if (!canResyncModel) return;

                        setIsSyncing(true);

                        await syncParametricAnalysis(analysisId, selectedModelId);

                        setIsSyncing(false);
                    }}
                    disabled={isSyncing || !canResyncModel}
                >
                    Resync Model
                </Button>
            </div>
            <ModelInformation modelData={modelData} />
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    syncParametricAnalysis: (analysisId, selectedModelId) =>
        dispatch(syncParametricAnalysis(analysisId, selectedModelId)),
});

export default connect(null, mapDispatchToProps)(ModelSnapshot);
