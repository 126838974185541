import React, { useState } from "react";
import classes from "../../style.module.scss";
import classNames from "classnames";
import DashboardItem from "..";
import { useLocation } from "react-router";
import { CHBA_RENO_COMM_ID } from "utils/communities/api";

import { DeleteDialog, DuplicateDialog, MoveDialog } from "components/Dashboard/Actions";

import { selectUserUid } from "store/users/selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { actions as userActions } from "store/users";
import { actions as communityActions } from "features/Community/_ducks";
import { selectCommunityLoading } from "features/Community/_ducks/selectors";

// const { moveCommunity, deleteCommunity } = communityActions;
const { removeFromCommDir } = userActions;

const Community = ({
    uid,
    ref,
    communityId,
    tags = [],
    sharedWithUser = false,
    isSelected,
    isDragging,
    isDropInProcess,
    setFolderItemsToDelete,
    multiSelectedItems,
    details, // { totalModels, region, lastEdited }
    disableActions = false,
    folders,
    rootDir,
    deleteCommunity,
    duplicateCommunity,
    moveCommunity,
    communityLoading,
    ...props
}) => {
    const { name } = details;
    const isCHBAComm = communityId === CHBA_RENO_COMM_ID;

    const { pathname } = useLocation();
    const path = pathname.split("/").filter((str) => ["", "dashboard", "communities"].includes(str) === false) || [];
    const currentFolderId = path[path.length - 1] || null;

    const [startDelete, toggleStartDelete] = useState(false);
    const [isDeleting, toggleDeleting] = useState(false);

    const [startDuplicate, toggleStartDuplicate] = useState(false);
    const [isDuplicating, toggleDuplicating] = useState(false);
    const [duplicateName, setDuplicateName] = useState("");

    const [startMove, toggleStartMove] = useState(false);

    return (
        <React.Fragment>
            <DashboardItem
                ref={ref}
                className={classNames({
                    [classes.selectedItem]: isSelected,
                    [classes.itemDragging]: isDragging && isSelected,
                    [classes.isDropInProcess]: isDropInProcess,
                })}
                itemId={communityId}
                sharedWithUser={isCHBAComm || sharedWithUser}
                itemType={"community"}
                details={details}
                tags={tags}
                actions={
                    isCHBAComm || disableActions
                        ? null
                        : [
                              // {
                              //     label: "Duplicate Community",
                              //     onClick: () => {
                              //         toggleStartDuplicate(true);
                              //         setDuplicateName(`${name} (1)`);
                              //     },
                              // },
                              {
                                  label: "Move Community",
                                  onClick: () => {
                                      toggleStartMove(true);
                                  },
                              },
                              {
                                  label: "Delete Community",
                                  onClick: () => {
                                      toggleStartDelete(true);
                                  },
                              },
                              {
                                  label: `Delete ${multiSelectedItems.length} files`,
                                  shouldShow: multiSelectedItems.length > 1,
                                  disabled: multiSelectedItems.includes(CHBA_RENO_COMM_ID), // Prevent deleting shared CHBA Community
                                  onClick: () => setFolderItemsToDelete(multiSelectedItems),
                              },
                          ]
                }
                {...props}
            />
            <DeleteDialog
                fileName={name}
                openDialog={startDelete}
                onClick={async () => {
                    toggleDeleting(true);
                    await deleteCommunity({ communityId, uid });
                }}
                onClose={() => toggleStartDelete(false)}
                loading={isDeleting}
                isFolder
            />
            <DuplicateDialog
                itemType={"community"}
                openDialog={startDuplicate}
                newDuplicateName={duplicateName}
                setDuplicateName={(newVal) => setDuplicateName(newVal)}
                onClick={async () => {
                    toggleDuplicating(true);
                    await duplicateCommunity(communityId, uid, currentFolderId, duplicateName);
                }}
                onClose={() => toggleStartDuplicate(false)}
                isLoading={isDuplicating}
            />
            <MoveDialog
                itemId={communityId}
                fileName={name}
                rootDir={rootDir}
                folders={folders}
                openDialog={startMove}
                itemLoading={communityLoading}
                handleMove={async (moveToId) => {
                    await moveCommunity(communityId, uid, moveToId);
                }}
                onClose={() => toggleStartMove(false)}
            />
        </React.Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    communityLoading: selectCommunityLoading,
});

const mapDispatchToProps = (dispatch, { history }) => ({
    deleteCommunity: async ({ communityId, uid }) => {
        // await dispatch(deleteCommunity({ communityId, uid }));
        // return dispatch(removeFromCommDir(communityId));
    },
    duplicateCommunity: async (communityId, uid, folderId, duplicateName) => {
        // return dispatch(duplicateCommunity(communityId, uid, folderId, duplicateName))
    },
    moveCommunity: async (communityId, uid, folderId, skipFetch = false) => {
        // return dispatch(moveCommunity(communityId, uid, folderId, skipFetch));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Community);
