import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Construction from "./";
import { actions } from "features/Model/Enclosure/_ducks";
const { updateDrawingChanges } = actions;

const mapStateToProps = ({ form }, { accessor, formName = "model", canBeEditedFromParametric }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const { id: doorTypeId, value: doorRValue } = selector({ form }, `${accessor}.doorType`) || {};
    const rValUnits = selector({ form }, `${accessor}.doorType_u`);
    const enerStar = selector({ form }, `${accessor}.energyStar`);
    const { component: drawingComponent = "", image: drawingImage } = selector({ form }, `${accessor}.drawing`) || {};

    return {
        accessor,
        doorTypeId,
        modelUnits,
        rValUnits,
        doorRValue,
        enerStar,
        drawingComponent,
        drawingImage,
        canBeEditedFromParametric,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({
    updateDrawingChanges: (componentId, updates) => dispatch(updateDrawingChanges({ componentId, updates })),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Construction);
