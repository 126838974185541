const base = ["overallCost", "preTaxRebate", "postTaxRebate", "tax", "labourCost"];

export const costTypesByUpgrade = {
    ceilingWithAttic: [...base, "matCost", "matCostArea"],
    cathedral: [...base, "matCost", "matCostArea"],
    wall: [...base, "matCost", "matCostArea"],
    expFloor: [...base, "matCost", "matCostArea"],
    floorHeader: [...base, "matCost", "matCostArea"],
    basementWall: [...base, "matCost", "matCostIntWalls", "matCostExtWalls"],
    basementSlab: [...base, "matCost", "matCostExtWalls", "matCostSlab"],
    crawlspace: [...base, "matCost", "matCostExtWalls", "matCostSlab"],
    slab: [...base, "matCost", "matCostExtWalls", "matCostSlab"],
    window: [...base, "matCost", "matCostWindow"],
    skylights: [...base, "matCost", "matCostWindow"],
    door: [...base, "matCost", "matCostDoor"],
    airTightness: [...base, "matCost"],
    ventilation: [...base, "equipCost", "matCostDuct", "electricalCost"],
    spaceHeating: [...base, "equipCost", "matCostDuct", "matCostHydronic", "electricalCost"],
    secondaryHeating: [...base, "equipCost", "matCostDuct", "matCostHydronic", "electricalCost"],
    spaceCooling: [...base, "equipCost", "matCostDuct", "matCostHydronic", "electricalCost"],
    primaryHotWater: [...base, "equipCost", "matCostDistPiping", "electricalCost"],
    dwhr: [...base, "equipCost"],
    generation: [...base, "equipCost", "equipCostSolar", "equipCostInv", "electricalCost"],
    bathroomFaucets: [...base, "matCost"],
    showerHeads: [...base, "matCost"],
    clothesWasher: [...base, "equipCost"],
    dishwasher: [...base, "equipCost"],
    lighting: [...base, "equipCost", "matCost"],
    monitoringSys: [...base, "equipCost", "electricalCost"],
};

export const overallCostTypes = [
    "electricalCost",
    "matCostDuct",
    "matCostHydronic",
    "matCostDistPiping",
    "equipCost",
    "overallCost",
    "preTaxRebate",
    "postTaxRebate",
    "labourCost",
    "matCost",
];

export const getCodeToLabel = (modelUnits) => {
    const units = modelUnits === "Metric" ? "m²" : "ft²";
    return {
        overallCost: "Overall Cost",
        labourCost: "Labour Cost",
        matCost: "Material Cost",
        matCostArea: `Material Cost ($/${units})`,
        matCostIntWalls: `Material Cost, Interior Walls ($/${units})`,
        matCostExtWalls: `Material Cost, Exterior Walls ($/${units})`,
        matCostSlab: `Material Cost, Slab($/${units})`,
        matCostWindow: `Material Cost ($/window)`,
        matCostDoor: `Material Cost ($/door)`,
        matCostDuct: "Material Cost, Ductwork",
        matCostHydronic: "Material Cost, Hydronic Distribution",
        matCostDistPiping: "Material Cost, Distribution Piping",
        equipCost: "Equip. Cost",
        equipCostSolar: "Equip. Cost, Solar PV ($/kW)",
        equipCostInv: "Equip. Cost, Inverter ($/kW)",
        electricalCost: "Electrical Cost",
        tax: "Tax (%)",
        preTaxRebate: "Rebate (Pre-Tax)",
        postTaxRebate: "Rebate (Post-Tax)",
    };
};
