import React from "react";
import classes from "../style.module.scss";
import Dialog from "components/Dialog";
import { CloseDialog } from "components/Dialog";
import Button from "components/Button";

const RemoveDialog = ({ item, startRemove, isRemoving, onClick, onClose }) => (
    <Dialog open={!!startRemove}>
        <CloseDialog closeActions={() => onClose()} />
        <div className={classes.dialog}>
            <h3>
                Are you sure you want to remove <strong>{item && item.name}</strong>?
            </h3>
            <div className={classes.footer}>
                <Button type="hollow" onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button onClick={() => onClick()} type="red" disabled={isRemoving}>
                    {isRemoving ? "Removing..." : "Remove"}
                </Button>
            </div>
        </div>
    </Dialog>
);

export default RemoveDialog;

export const RemoveMultipleFilesDialog = ({ filesToRemove, startRemove, isRemoving, onClick, onClose }) => {
    return (
        <Dialog open={!!startRemove}>
            <CloseDialog closeActions={() => onClose()} />
            <div className={classes.dialog}>
                <h3>
                    Are you sure you want to remove <strong>{filesToRemove.length} files</strong>?
                </h3>
                <div className={classes.footer}>
                    <Button type="hollow" onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button onClick={() => onClick()} type="red" disabled={isRemoving}>
                        {isRemoving ? "Removing..." : "Remove"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};