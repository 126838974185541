import React from "react";

import classes from "./styles.module.scss";

const Toggle = ({ active, onChange, disabled }) => {
    return (
        <>
            <label className={`${classes.toggle} ${disabled ? classes.toggleDisabled : ""}`}>
                <input
                    className={classes.toggleInput}
                    type="checkbox"
                    onChange={disabled ? () => {} : onChange}
                    checked={active}
                    // disabled={disabled}
                />
                <div className={classes.toggleTrack}>
                    <div className={classes.toggleControl}></div>
                </div>
            </label>
        </>
    );
};

export default Toggle;
