import React, { useState, useEffect } from "react";

import Tooltip from "components/Tooltip";

import classes from "./style.module.scss";

import WorkbookCheck from "assets/images/icons/WorkbookCheck.svg";

export default ({ value, onChange, isDisabled, howMuchFilesLeft = 0 }) => {
    // console.log(value)
    return (
        <label
            className={classes.checkboxWrapper}
            data-for="checkboxTip"
            data-tip={`${howMuchFilesLeft} more files must be uploaded to complete this item`}
        >
            <div
                className={`${classes.checkbox}`}
                data-for={`checkbox_tip`}
                style={{ pointerEvents: isDisabled ? "none" : "auto", cursor: "pointer" }}
            >
                {value === true ? (
                    <img style={{ borderRadius: "0.25rem" }} src={WorkbookCheck} alt="Checkmark" />
                ) : value === 'n/a' ? (
                    <div className={classes.null}>
                        <div className={classes.nullInner}></div>
                    </div>
                ) : (
                    <span></span>
                )}
                {/* <input value={value} className={classes.checkboxInput} type="checkbox" onChange={onChange} /> */}
                <div value={value} className={classes.checkboxInput} id="checkbox" onClick={onChange} />
            </div>
            <Tooltip id={"checkboxTip"} hide={howMuchFilesLeft <= 0} />
        </label>
    );
};
