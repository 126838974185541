const types = {
    CREATE_PARAMETRIC_ANALYSIS_START: "CREATE_PARAMETRIC_ANALYSIS_START",
    CREATE_PARAMETRIC_ANALYSIS_SUCCESS: "CREATE_PARAMETRIC_ANALYSIS_SUCCESS",
    CREATE_PARAMETRIC_ANALYSIS_ERROR: "CREATE_PARAMETRIC_ANALYSIS_ERROR",
    FETCH_PARAMETRIC_ANALYSIS_START: "FETCH_PARAMETRIC_ANALYSIS_START",
    FETCH_PARAMETRIC_ANALYSIS_SUCCESS: "FETCH_PARAMETRIC_ANALYSIS_SUCCESS",
    FETCH_PARAMETRIC_ANALYSIS_ERROR: "FETCH_PARAMETRIC_ANALYSIS_ERROR",
    DELETE_PARAMETRIC_ANALYSIS_START: "DELETE_PARAMETRIC_ANALYSIS_START",
    DELETE_PARAMETRIC_ANALYSIS_SUCCESS: "DELETE_PARAMETRIC_ANALYSIS_SUCCESS",
    DELETE_PARAMETRIC_ANALYSIS_ERROR: "DELETE_PARAMETRIC_ANALYSIS_ERROR",
    MOVE_PARAMETRIC_ANALYSIS_START: "MOVE_PARAMETRIC_ANALYSIS_START",
    MOVE_PARAMETRIC_ANALYSIS_SUCCESS: "MOVE_PARAMETRIC_ANALYSIS_SUCCESS",
    MOVE_PARAMETRIC_ANALYSIS_ERROR: "MOVE_PARAMETRIC_ANALYSIS_ERROR",
    CLEAR_PARAMETRIC_ANALYSIS: "CLEAR_PARAMETRIC_ANALYSIS",
    DUPLICATE_PARAMETRIC_ANALYSIS_START: "DUPLICATE_PARAMETRIC_ANALYSIS_START",
    DUPLICATE_PARAMETRIC_ANALYSIS_SUCCESS: "DUPLICATE_PARAMETRIC_ANALYSIS_SUCCESS",
    DUPLICATE_PARAMETRIC_ANALYSIS_ERROR: "DUPLICATE_PARAMETRIC_ANALYSIS_ERROR",
    BATCH_DELETE_PARAMETRIC_ANALYSIS_START: "BATCH_DELETE_PARAMETRIC_ANALYSIS_START",
    BATCH_DELETE_PARAMETRIC_ANALYSIS_SUCCESS: "BATCH_DELETE_PARAMETRIC_ANALYSIS_SUCCESS",
    BATCH_DELETE_PARAMETRIC_ANALYSIS_ERROR: "BATCH_DELETE_PARAMETRIC_ANALYSIS_ERROR",
    SYNC_PARAMETRIC_ANALYSIS_START: "SYNC_PARAMETRIC_ANALYSIS_START",
    SYNC_PARAMETRIC_ANALYSIS_SUCCESS: "SYNC_PARAMETRIC_ANALYSIS_SUCCESS",
    SYNC_PARAMETRIC_ANALYSIS_ERROR: "SYNC_PARAMETRIC_ANALYSIS_ERROR",
    SAVE_ECM_INPUT_MAP_START: "SAVE_ECM_INPUT_MAP_START",
    SAVE_ECM_INPUT_MAP_SUCCESS: "SAVE_ECM_INPUT_MAP_SUCCESS",
    SAVE_ECM_INPUT_MAP_ERROR: "SAVE_ECM_INPUT_MAP_ERROR",
    PIN_UNPIN_RECIPES_START: "PIN_UNPIN_RECIPES_START",
    PIN_UNPIN_RECIPES_SUCCESS: "PIN_UNPIN_RECIPES_SUCCESS",
    PIN_UNPIN_RECIPES_ERROR: "PIN_UNPIN_RECIPES_ERROR",
    UPDATE_PRICE_STATE_PARAMETRIC_START: "UPDATE_PRICE_STATE_PARAMETRIC_START",
    UPDATE_PRICE_STATE_PARAMETRIC_SUCCESS: "UPDATE_PRICE_STATE_PARAMETRIC_SUCCESS",
    UPDATE_PRICE_STATE_PARAMETRIC_ERROR: "UPDATE_PRICE_STATE_PARAMETRIC_ERROR",

    // Synchronous actions
    ADD_UPGRADE_TO_PARAMETRIC_ANALYSIS: "ADD_UPGRADE_TO_PARAMETRIC_ANALYSIS",
    REMOVE_UPGRADE_FROM_PARAMETRIC_ANALYSIS: "REMOVE_UPGRADE_FROM_PARAMETRIC_ANALYSIS",
    TOGGLE_UPGRADE_IN_PARAMETRIC_ANALYSIS: "TOGGLE_UPGRADE_IN_PARAMETRIC_ANALYSIS",
    UPDATE_ECM_INPUT_MAP: "UPDATE_ECM_INPUT_MAP",
    UPDATE_ECM_INPUT_MAP_UPGRADE: "UPDATE_ECM_INPUT_MAP_UPGRADE",
    UPDATE_VALIDATION_PRE_CHECK_RUN_STATUS: "UPDATE_VALIDATION_PRE_CHECK_RUN_STATUS",
    UPDATE_RUN_RESULTS: "UPDATE_RUN_RESULTS",

    // Folders
    CREATE_PARAMETRIC_ANALYSIS_FOLDER_START: "CREATE_PARAMETRIC_ANALYSIS_FOLDER_START",
    CREATE_PARAMETRIC_ANALYSIS_FOLDER_SUCCESS: "CREATE_PARAMETRIC_ANALYSIS_FOLDER_SUCCESS",
    CREATE_PARAMETRIC_ANALYSIS_FOLDER_ERROR: "CREATE_PARAMETRIC_ANALYSIS_FOLDER_ERROR",
    DELETE_PARAMETRIC_ANALYSIS_FOLDER_START: "DELETE_PARAMETRIC_ANALYSIS_FOLDER_START",
    DELETE_PARAMETRIC_ANALYSIS_FOLDER_SUCCESS: "DELETE_PARAMETRIC_ANALYSIS_FOLDER_SUCCESS",
    DELETE_PARAMETRIC_ANALYSIS_FOLDER_ERROR: "DELETE_PARAMETRIC_ANALYSIS_FOLDER_ERROR",
    MOVE_PARAMETRIC_ANALYSIS_FOLDER_START: "MOVE_PARAMETRIC_ANALYSIS_FOLDER_START",
    MOVE_PARAMETRIC_ANALYSIS_FOLDER_SUCCESS: "MOVE_PARAMETRIC_ANALYSIS_FOLDER_SUCCESS",
    MOVE_PARAMETRIC_ANALYSIS_FOLDER_ERROR: "MOVE_PARAMETRIC_ANALYSIS_FOLDER_ERROR",
    RENAME_PARAMETRIC_ANALYSIS_FOLDER_START: "RENAME_PARAMETRIC_ANALYSIS_FOLDER_START",
    RENAME_PARAMETRIC_ANALYSIS_FOLDER_SUCCESS: "RENAME_PARAMETRIC_ANALYSIS_FOLDER_SUCCESS",
    RENAME_PARAMETRIC_ANALYSIS_FOLDER_ERROR: "RENAME_PARAMETRIC_ANALYSIS_FOLDER_ERROR",
    BATCH_DELETE_PARAMETRIC_ANALYSIS_FOLDERS_START: "BATCH_DELETE_PARAMETRIC_ANALYSIS_FOLDERS_START",
    BATCH_DELETE_PARAMETRIC_ANALYSIS_FOLDERS_SUCCESS: "BATCH_DELETE_PARAMETRIC_ANALYSIS_FOLDERS_SUCCESS",
    BATCH_DELETE_PARAMETRIC_ANALYSIS_FOLDERS_ERROR: "BATCH_DELETE_PARAMETRIC_ANALYSIS_FOLDERS_ERROR",
};

export default types;
