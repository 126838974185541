import React, { useState } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field, unregisterField } from "redux-form";
import { getOptions, getFirstOption, getBaseUnits, getUnitOptions } from "utils/fields";
import {
    getEStarHrvManufacturerOptions,
    getEStarHrvModelOptions,
    getEStarHrvSpecs,
    getHrvDefaultFanPower,
} from "utils/mechanicals";
import { required, maxLength, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import isEmpty from "lodash/isEmpty";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import Button from "components/Button";
import Accordion from "components/Accordion";
import VentilatorStats from "./VentilatorStats/container";
import Drawer from "components/Drawer";
import AddIcon from "assets/images/icons/JSX/Add";
import Actions from "../Actions/container";

const charLim40 = maxLength(40);
const charLim100 = maxLength(100);
const supplyFlowValidation = getValidation("hrvSupply");
const exhaustFlowValidation = getValidation("hrvExhaust");
const fanPower1Validation = getValidation("hrvFanPower1");
const fanPower2Validation = getValidation("hrvFanPower2");
const temp1Validation = getValidation("hrvTempCondition1");
const temp2Validation = getValidation("hrvTempCondition2");
const eff1Validation = getValidation("hrvEff1");
const eff2Validation = getValidation("hrvEff2");
const preheaterValidation = getValidation("hrvPreheaterCapacity");
const lowTempValidation = getValidation("hrvLowTempVentReduction");
const coolingEffValidation = getValidation("hrvCoolingEff");

const operationScheduleValidation = getValidation("dryerOperationSchedule");

const ductSupplyLocationValidation = getValidation("hrvColdSupplyLocation");
const ductSupplyTypeValidation = getValidation("hrvColdSupplyType");
const ductSupplySealingValidation = getValidation("hrvColdSupplyDuctSealing");
const ductSupplyLengthValidation = getValidation("hrvColdSupplyDuctLength");
const ductSupplyDiameterValidation = getValidation("hrvColdSupplyDuctDiameter");
const ductSupplyInsValidation = getValidation("hrvColdSupplyDuctIns");
const ductExhaustLocationValidation = getValidation("hrvColdExhaustLocation");
const ductExhaustTypeValidation = getValidation("hrvColdExhaustType");
const ductExhaustSealingValidation = getValidation("hrvColdExhaustDuctSealing");
const ductExhaustLengthValidation = getValidation("hrvColdExhaustDuctLength");
const ductExhaustDiameterValidation = getValidation("hrvColdExhaustDuctDiameter");
const ductExhaustInsValidation = getValidation("hrvColdExhaustDuctIns");

export default ({
    accessor,
    hrvSystemObj,
    isSupplemental,
    ventTypeId,
    supplyFlowValue,
    exhaustFlowValue,
    supplyFlowUnits,
    exhaustFlowUnits,
    fanPower1Value,
    fanPower2Value,
    fanPower1Units,
    fanPower2Units,
    temp1Units,
    temp2Units,
    eff1Value,
    eff2Value,
    eff1Units,
    eff2Units,
    isDefaultFanpower,
    operationScheduleId,
    operationScheduleValue,
    operationScheduleUnits,
    preheaterUnits,
    lowTempUnits,
    coolingEffUnits,
    ductSupplyLengthUnits,
    ductSupplyDiameterUnits,
    ductSupplyInsUnits,
    ductExhaustLengthUnits,
    ductExhaustDiameterUnits,
    ductExhaustInsUnits,
    modelUnits,
    eStarManufacturerValue,
    eStarModelValue,
    change,
    isUpgrade = false,
    formName,
    contentOpen,
    canBeEditedFromParametric = true,
}) => {
    const [open, toggleOpen] = useState(false);

    const handleCreateSystem = ({ manufacturer, model }) => {
        change(accessor, {
            ...hrvSystemObj,
            ...getEStarHrvSpecs({ manufacturer, model }),
        });
    };

    if (isDefaultFanpower) {
        const { defaultFanPower1, defaultFanPower2 } = getHrvDefaultFanPower({
            supplyFlow: supplyFlowValue,
            exhaustFlow: exhaustFlowValue,
        });
        fanPower1Value = defaultFanPower1;
        fanPower2Value = defaultFanPower2;
    }

    return (
        <Accordion
            className={sharedClasses.accordion}
            heading={<span className={sharedClasses.heading}>HRV/ERV</span>}
            large
            stats={() => <VentilatorStats accessor={accessor} formName={formName} />}
            hoverActions={
                <Actions
                    label="HRV/ERV"
                    type="hrv"
                    accessor={accessor}
                    isSupplemental={isSupplemental}
                    change={change}
                    formName={formName}
                />
            }
            forceOpen={contentOpen}
        >
            <Button
                className={classes.createButton}
                onClick={() => toggleOpen(true)}
                icon={AddIcon}
                disabled={!canBeEditedFromParametric}
            >
                Create New System
            </Button>
            <Drawer
                open={open}
                close={() => toggleOpen(false)}
                preTitle="New System"
                title="Create New HRV/ERV"
                subtitle="Note that fields that are house-specific (e.g. duct location and lengths) will not be modified when adding an Energy Star System"
            >
                <div>
                    <InputRow>
                        <Field
                            component={Select}
                            name={`${accessor}.favourite.selected.favourite`}
                            options={[
                                {
                                    value: null,
                                    label: "None",
                                },
                            ]}
                            label="Favourite HRV/ERV System"
                            placeholder="Coming soon!"
                            disabled={true}
                        />
                    </InputRow>
                    <InputRow>
                        <Field
                            component={Select}
                            name={`${accessor}.eStar.selected.manufacturer`}
                            options={[
                                {
                                    value: false,
                                    label: "None",
                                },
                                ...getEStarHrvManufacturerOptions(),
                            ]}
                            label="Energy Star Manufacturer"
                            placeholder="Choose Manufacturer"
                            validate={charLim100}
                            info={"Select a manufacturer of an Energy Star HRV or ERV"}
                            search={true}
                            disabled={!canBeEditedFromParametric}
                        />
                    </InputRow>
                    <InputRow>
                        <Field
                            component={Select}
                            name={`${accessor}.eStar.selected.model`}
                            options={[
                                {
                                    value: false,
                                    label: "None",
                                },
                                ...getEStarHrvModelOptions(eStarManufacturerValue),
                            ]}
                            label="Energy Star Model"
                            placeholder="Choose Model"
                            validate={charLim100}
                            info={"Select an Energy Star certified model"}
                            search={true}
                            disabled={!canBeEditedFromParametric}
                        />
                    </InputRow>
                    <div className={classes.buttons}>
                        <Button large type="hollow" onClick={() => toggleOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleCreateSystem({
                                    manufacturer: eStarManufacturerValue,
                                    model: eStarModelValue,
                                });
                                toggleOpen(false);
                            }}
                        >
                            Create Energy Star HRV/ERV
                        </Button>
                    </div>
                </div>
            </Drawer>
            <h3>System Specifications</h3>
            <InputRow gridTemplate="2fr 2fr 1fr 1fr">
                <Field
                    component={Input}
                    name={`${accessor}.manufacturer`}
                    type="text"
                    label="Manufacturer"
                    placeholder=""
                    validate={charLim40}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={Input}
                    name={`${accessor}.model`}
                    type="text"
                    label="Model"
                    placeholder=""
                    validate={charLim40}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.isEnergyStar`}
                    label="Energy Star"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.isHVICertified`}
                    label="HVI Certified"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                    disabled={!canBeEditedFromParametric}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.supplyFlowrate`}
                    label="Supply Air Flow Rate"
                    placeholder={0.0}
                    validate={supplyFlowValidation}
                    decimals={getDecimalPlaces("ventSupply")}
                    change={change}
                    setValue={supplyFlowValue}
                    units={{
                        base: getBaseUnits("ventSupply", modelUnits),
                        options: getUnitOptions("airFlow"),
                        selected: supplyFlowUnits,
                        unitType: "airFlow",
                        accessor: `${accessor}.supplyFlowrate_u`,
                    }}
                    disabled={ventTypeId === 3 || ventTypeId === 2 || !canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.exhaustFlowrate`}
                    label="Exhaust Air Flow Rate"
                    placeholder={0.0}
                    validate={exhaustFlowValidation}
                    decimals={getDecimalPlaces("ventExhaust")}
                    change={change}
                    setValue={exhaustFlowValue}
                    units={{
                        base: getBaseUnits("ventExhaust", modelUnits),
                        options: getUnitOptions("airFlow"),
                        selected: exhaustFlowUnits,
                        unitType: "airFlow",
                        accessor: `${accessor}.exhaustFlowrate_u`,
                    }}
                    disabled={false}
                />
                <Field
                    component={Checkbox}
                    name={`${accessor}.isDefaultFanpower`}
                    label="Default Fan Power"
                    large
                    className={classes.checkbox}
                    type="checkbox"
                />
            </InputRow>
            <h4>Rating Condition 1</h4>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.fanPower1`}
                    label="Fan Power"
                    placeholder={0.0}
                    validate={fanPower1Validation}
                    decimals={getDecimalPlaces("hrvFanPower1")}
                    change={change}
                    setValue={fanPower1Value}
                    units={{
                        base: getBaseUnits("hrvFanPower1", modelUnits),
                        options: getUnitOptions("power"),
                        selected: fanPower1Units,
                        unitType: "power",
                        accessor: `${accessor}.fanPower1_u`,
                    }}
                    disabled={isDefaultFanpower || !canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.temperatureCondition1`}
                    label="Rated Temperature"
                    placeholder={0.0}
                    validate={temp1Validation}
                    decimals={getDecimalPlaces("hrvTempCondition1")}
                    change={change}
                    units={{
                        base: getBaseUnits("hrvTempCondition1", modelUnits),
                        options: getUnitOptions("temperature"),
                        selected: temp1Units,
                        unitType: "temperature",
                        accessor: `${accessor}.temperatureCondition1_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.efficiency1`}
                    label="Rated Efficiency"
                    placeholder={0.0}
                    validate={eff1Validation}
                    decimals={getDecimalPlaces("hrvEff1")}
                    change={change}
                    setValue={eff1Value}
                    units={{
                        base: getBaseUnits("hrvEff1", modelUnits),
                        options: getUnitOptions("fraction"),
                        selected: eff1Units,
                        unitType: "fraction",
                        accessor: `${accessor}.efficiency1_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
            </InputRow>
            <h4>Rating Condition 2</h4>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.fanPower2`}
                    label="Fan Power"
                    placeholder={0.0}
                    validate={fanPower2Validation}
                    decimals={getDecimalPlaces("hrvFanPower2")}
                    change={change}
                    setValue={fanPower2Value}
                    units={{
                        base: getBaseUnits("hrvFanPower2", modelUnits),
                        options: getUnitOptions("power"),
                        selected: fanPower2Units,
                        unitType: "power",
                        accessor: `${accessor}.fanPower2_u`,
                    }}
                    disabled={isDefaultFanpower || !canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.temperatureCondition2`}
                    label="Rated Temperature"
                    placeholder={0.0}
                    validate={temp2Validation}
                    decimals={getDecimalPlaces("hrvTempCondition2")}
                    change={change}
                    units={{
                        base: getBaseUnits("hrvTempCondition2", modelUnits),
                        options: getUnitOptions("temperature"),
                        selected: temp2Units,
                        unitType: "temperature",
                        accessor: `${accessor}.temperatureCondition2_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.efficiency2`}
                    label="Rated Efficiency"
                    placeholder={0.0}
                    validate={eff2Validation}
                    decimals={getDecimalPlaces("hrvEff2")}
                    change={change}
                    setValue={eff2Value}
                    units={{
                        base: getBaseUnits("hrvEff2", modelUnits),
                        options: getUnitOptions("fraction"),
                        selected: eff2Units,
                        unitType: "fraction",
                        accessor: `${accessor}.efficiency2_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
            </InputRow>
            <h4>Additional Specifications</h4>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.preheaterCapacity`}
                    label="Preheater Capacity"
                    placeholder={0.0}
                    validate={preheaterValidation}
                    decimals={getDecimalPlaces("hrvPreheaterCapacity")}
                    change={change}
                    units={{
                        base: getBaseUnits("hrvPreheaterCapacity", modelUnits),
                        options: getUnitOptions("power"),
                        selected: preheaterUnits,
                        unitType: "power",
                        accessor: `${accessor}.preheaterCapacity_u`,
                    }}
                    disabled={isDefaultFanpower || !canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.lowTempVentReduction`}
                    label="Low Temperature Ventilation Reduction"
                    placeholder={0.0}
                    validate={lowTempValidation}
                    decimals={getDecimalPlaces("hrvLowTempVentReduction")}
                    change={change}
                    units={{
                        base: getBaseUnits("hrvLowTempVentReduction", modelUnits),
                        options: getUnitOptions("fraction"),
                        selected: lowTempUnits,
                        unitType: "fraction",
                        accessor: `${accessor}.lowTempVentReduction_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.coolingEfficiency`}
                    label="Cooling Efficiency"
                    placeholder={0.0}
                    validate={coolingEffValidation}
                    decimals={getDecimalPlaces("hrvCoolingEff")}
                    change={change}
                    units={{
                        base: getBaseUnits("hrvCoolingEff", modelUnits),
                        options: getUnitOptions("fraction"),
                        selected: coolingEffUnits,
                        unitType: "fraction",
                        accessor: `${accessor}.coolingEfficiency_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
            </InputRow>
            {isSupplemental && (
                <>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={Select}
                            name={`${accessor}.operationSchedule`}
                            options={[
                                ...getOptions({ fieldKey: "dryerOperationSchedule" }),
                                {
                                    label: "User specified",
                                    value: {
                                        id: 4,
                                        value: operationScheduleValue,
                                    },
                                },
                            ]}
                            label="Operation Schedule"
                            placeholder="Choose Operation Schedule"
                            validate={operationScheduleValidation}
                            disabled={!canBeEditedFromParametric}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.operationSchedule.value`}
                            label="Daily Operation"
                            placeholder={0.0}
                            disabled={operationScheduleId !== 4 || !canBeEditedFromParametric}
                            validate={operationScheduleValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("dryerOperationSchedule")}
                            change={change}
                            setValue={operationScheduleValue}
                            units={{
                                base: getBaseUnits("dryerOperationSchedule", modelUnits),
                                options: getUnitOptions("time"),
                                selected: operationScheduleUnits,
                                unitType: "time",
                                accessor: `${accessor}.operationSchedule_u`,
                            }}
                        />
                    </InputRow>
                </>
            )}
            <h4>HRV Ducts</h4>
            <h4>Cold Air Supply</h4>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.ducts.supply.location`}
                    options={getOptions({ fieldKey: "hrvColdSupplyLocation" })}
                    label="Location"
                    placeholder="Choose Location"
                    validate={ductSupplyLocationValidation}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={Select}
                    name={`${accessor}.ducts.supply.type`}
                    options={getOptions({ fieldKey: "hrvColdSupplyType" })}
                    label="Type"
                    placeholder="Choose Type"
                    validate={ductSupplyTypeValidation}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={Select}
                    name={`${accessor}.ducts.supply.sealing`}
                    options={getOptions({ fieldKey: "hrvColdSupplyDuctSealing" })}
                    label="Sealing Characteristics"
                    placeholder="Choose Sealing Characteristics"
                    validate={ductSupplySealingValidation}
                    disabled={!canBeEditedFromParametric}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.ducts.supply.length`}
                    label="Length"
                    placeholder={0.0}
                    validate={ductSupplyLengthValidation}
                    decimals={getDecimalPlaces("hrvColdSupplyDuctLength")}
                    change={change}
                    units={{
                        base: getBaseUnits("hrvColdSupplyDuctLength", modelUnits),
                        options: getUnitOptions("length"),
                        selected: ductSupplyLengthUnits,
                        unitType: "length",
                        accessor: `${accessor}.ducts.supply.length_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.ducts.supply.diameter`}
                    label="Diameter"
                    placeholder={0.0}
                    validate={ductSupplyDiameterValidation}
                    decimals={getDecimalPlaces("hrvColdSupplyDuctDiameter")}
                    change={change}
                    units={{
                        base: getBaseUnits("hrvColdSupplyDuctDiameter", modelUnits),
                        options: getUnitOptions("length"),
                        selected: ductSupplyDiameterUnits,
                        unitType: "length",
                        accessor: `${accessor}.ducts.supply.diameter_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.ducts.supply.insulation`}
                    label="Insulation"
                    placeholder={0.0}
                    validate={ductSupplyInsValidation}
                    decimals={getDecimalPlaces("hrvColdSupplyDuctIns")}
                    change={change}
                    units={{
                        base: getBaseUnits("hrvColdSupplyDuctIns", modelUnits),
                        options: getUnitOptions("thermalResistance"),
                        selected: ductSupplyInsUnits,
                        unitType: "thermalResistance",
                        accessor: `${accessor}.ducts.supply.insulation_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
            </InputRow>
            <h4>Cold Air Exhaust</h4>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.ducts.exhaust.location`}
                    options={getOptions({ fieldKey: "hrvColdExhaustLocation" })}
                    label="Location"
                    placeholder="Choose Location"
                    validate={ductExhaustLocationValidation}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={Select}
                    name={`${accessor}.ducts.exhaust.type`}
                    options={getOptions({ fieldKey: "hrvColdExhaustType" })}
                    label="Type"
                    placeholder="Choose Type"
                    validate={ductExhaustTypeValidation}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={Select}
                    name={`${accessor}.ducts.exhaust.sealing`}
                    options={getOptions({ fieldKey: "hrvColdExhaustDuctSealing" })}
                    label="Sealing Characteristics"
                    placeholder="Choose Sealing Characteristics"
                    validate={ductExhaustSealingValidation}
                    disabled={!canBeEditedFromParametric}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.ducts.exhaust.length`}
                    label="Length"
                    placeholder={0.0}
                    validate={ductExhaustLengthValidation}
                    decimals={getDecimalPlaces("hrvColdExhaustDuctLength")}
                    change={change}
                    units={{
                        base: getBaseUnits("hrvColdExhaustDuctLength", modelUnits),
                        options: getUnitOptions("length"),
                        selected: ductExhaustLengthUnits,
                        unitType: "length",
                        accessor: `${accessor}.ducts.exhaust.length_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.ducts.exhaust.diameter`}
                    label="Diameter"
                    placeholder={0.0}
                    validate={ductExhaustDiameterValidation}
                    decimals={getDecimalPlaces("hrvColdExhaustDuctDiameter")}
                    change={change}
                    units={{
                        base: getBaseUnits("hrvColdExhaustDuctDiameter", modelUnits),
                        options: getUnitOptions("length"),
                        selected: ductExhaustDiameterUnits,
                        unitType: "length",
                        accessor: `${accessor}.ducts.exhaust.diameter_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.ducts.exhaust.insulation`}
                    label="Insulation"
                    placeholder={0.0}
                    validate={ductExhaustInsValidation}
                    decimals={getDecimalPlaces("hrvColdExhaustDuctIns")}
                    change={change}
                    units={{
                        base: getBaseUnits("hrvColdExhaustDuctIns", modelUnits),
                        options: getUnitOptions("thermalResistance"),
                        selected: ductExhaustInsUnits,
                        unitType: "thermalResistance",
                        accessor: `${accessor}.ducts.exhaust.insulation_u`,
                    }}
                    disabled={!canBeEditedFromParametric}
                />
            </InputRow>
        </Accordion>
    );
};
