import React, { useEffect, useState } from "react";
import classes from "../style.module.scss";
import { useHistory, useLocation } from "react-router";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
    selectParametricDir,
    selectIsParametricDirLoading,
    selectUserError,
    selectUserUid,
} from "store/users/selectors";
import Loading from "components/Loading";
import DashboardTable from "components/Dashboard/Table";
import EmptyDashboard from "features/ParametricAnalysis/EmptyDashboard";
import Button from "components/Button";
import DashboardLayout from "../";
import { actions as userActions } from "store/users";

import AddIcon from "assets/images/icons/JSX/Add";
import HeaderImg from "assets/images/parametricAnalysisHeader.svg";

const { fetchParametricDir } = userActions;

const ParametricAnalysisDashboard = ({ uid, error, parametricDir, parametricDirLoading }) => {
    const { pathname } = useLocation();
    const history = useHistory();

    const path =
        pathname.split("/").filter((str) => ["", "dashboard", "parametric-analysis"].includes(str) === false) || [];
    const currentFolderId = path[path.length - 1] || null;

    /* Loading state and cursor position for loading spinner */
    const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
    const [isLoading, setIsLoading] = useState(true);

    /* Fetch user's community directory data */
    useEffect(() => {
        if (uid) {
            fetchParametricDir(uid);
        }
    }, [uid]);

    /* Update loading state once directory has loaded */
    useEffect(() => {
        if (!parametricDirLoading || (!isEmpty(parametricDir) && !parametricDirLoading)) {
            setIsLoading(false);
        }
    }, [parametricDir]);

    const { analyses = {}, folders = {} } = parametricDir;

    const filteredFolders = !isEmpty(folders)
        ? Object.entries(folders)
              .filter(([key, { parentFolderId }]) => parentFolderId === currentFolderId)
              .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
              .map(([key, el]) => [key, { ...el, type: "folder" }])
        : [];

    const filteredParametricDir = !isEmpty(analyses)
        ? Object.entries(analyses)
              .filter(([key, { parentFolderId }]) =>
                  parentFolderId ? parentFolderId === currentFolderId : currentFolderId ? false : true
              )
              .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
              .map(([key, el]) => [key, { ...el, type: "analysis" }])
        : [];

    const allFoldersParametricAnalyses = [...filteredFolders, ...filteredParametricDir];

    return (
        <DashboardLayout
            isLoading={isLoading}
            cursorPosition={cursorPosition}
            setCursorPosition={setCursorPosition}
            folders={folders}
            dirLoading={parametricDirLoading}
            withHeader
            headerGradient={"linear-gradient(94.11deg, #0B91EA 13.72%, #0049C6 89.64%)"}
            headerImg={HeaderImg}
            headerButtonConainerContent={
                <>
                    <h3>Parametric Analysis</h3>
                    <Button
                        icon={AddIcon}
                        type="white"
                        large
                        onClick={() => history.push("/parametric-analysis/create")}
                        style={{ width: "fit-content" }}
                    >
                        Create New Parametric Analysis
                    </Button>
                </>
            }
        >
            {/* Loading background */}
            {parametricDirLoading && <Loading className={classes.loading} message="Loading Parametric Analyses" />}

            {/* Display table header and communities or folders in current folder */}
            {!parametricDirLoading && (
                <div className={classes.dashboardBody}>
                    {error && <p>{error}</p>}

                    <DashboardTable
                        history={history}
                        hasNestedPaths
                        rootDir={"Analysis Directory"}
                        rootPath={`/parametric-analysis/dashboard`}
                        folders={folders}
                        draggable
                        allowMultiSelect
                        setCursorPosition={setCursorPosition}
                        fetchDir={() => {}}
                        dirType={"parametricDir"}
                        allFoldersItems={allFoldersParametricAnalyses}
                        itemType={"analysis"}
                        setIsLoading={setIsLoading}
                    />
                </div>
            )}

            {!parametricDirLoading && isEmpty(allFoldersParametricAnalyses) && (
                <EmptyDashboard emptyText={`Create your first parametric analysis`} />
            )}
        </DashboardLayout>
    );
};

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    error: selectUserError,
    parametricDir: selectParametricDir,
    parametricDirLoading: selectIsParametricDirLoading,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ParametricAnalysisDashboard);
