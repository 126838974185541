import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import StandardCode from "./";
import expFloorCodeTemplate from "features/Model/Enclosure/Templates/expFloorCode.json";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import isEmpty from "lodash/isEmpty";
import { standardCodeLogic } from "utils/codes";
const { updateUpgradeCodes } = upgradeActions;

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode, getRValue } = enclosureActions;

const mapStateToProps = (
    {
        form,
        model: { modelId },
        user: { uid },
        enclosure: { initCodeValues: { expFloorInsType = {} } = {} } = {},
        upgradeLibrary: { currentPackage, selectedUpgrade },
    },
    { fieldAccessor, modelFormChange, currentFormChange, toggleOpen, isCodeLibrary = false, componentId }
) => {
    const selector = formValueSelector("expFloorCode");
    const { id: expFloorStructureId, value: expFloorStructureValue = 0 } =
        selector({ form }, "expFloorCode.layers.structureType") || {};
    const { value: expFloorTypeSizeValue } = selector({ form }, "expFloorCode.layers.componentTypeSize") || {};
    const { id: expFloorSpacingId, value: expFloorSpacingValue } =
        selector({ form }, "expFloorCode.layers.spacing") || {};
    const { value: expFloorFramingValue } = selector({ form }, "expFloorCode.layers.framing") || {};
    const { value: expFloorIns1Value } = selector({ form }, "expFloorCode.layers.insulationLayer1") || {};
    const { value: expFloorIns2Value } = selector({ form }, "expFloorCode.layers.insulationLayer2") || {};
    const { value: expFloorInteriorValue } = selector({ form }, "expFloorCode.layers.interior") || {};
    const { value: expFloorSheathingValue } = selector({ form }, "expFloorCode.layers.sheathing") || {};
    const { value: expFloorExteriorValue } = selector({ form }, "expFloorCode.layers.exterior") || {};
    const { id: expFloorDropFramingId, value: expFloorDropFramingValue } =
        selector({ form }, "expFloorCode.layers.dropFraming") || {};
    const expFloorLabel = selector({ form }, "expFloorCode.label") || "";

    const frameSpacingValue = expFloorSpacingId === null ? expFloorFramingValue : expFloorSpacingValue;

    const expFloorValue = `3${expFloorStructureValue
        .toString()
        .substr(
            -1
        )}${expFloorTypeSizeValue}${frameSpacingValue}${expFloorIns1Value}${expFloorIns2Value}${expFloorInteriorValue}${expFloorSheathingValue}${expFloorExteriorValue}${expFloorDropFramingValue}`;

    const { codeRef: editingCodeRef = null, isLibCode = false } = expFloorInsType;

    const modelSelector = formValueSelector("model");
    const modelCodes = modelSelector({ form }, "modelData.codes") || {};
    const components = modelSelector({ form }, "modelData.components") || {};

    return {
        expFloorLabel,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        initialValues: {
            expFloorCode: isEmpty(expFloorInsType) ? expFloorCodeTemplate : expFloorInsType,
            addToLibrary: isLibCode,
        },
        expFloorStructureId,
        expFloorTypeSizeValue,
        isSolid: parseFloat(expFloorStructureId) === 4,
        isPanel: parseFloat(expFloorStructureId) === 5,
        isDropFraming: parseFloat(expFloorDropFramingId) === 1,
        makeSpacingDefault: ["5", "6", "7", "8", "9", "A", "B", "C"].includes(expFloorTypeSizeValue),
        makeInsulationDefault: ["6", "7", "8", "9", "A", "B", "C"].includes(expFloorTypeSizeValue),
        makeDropFramingInsDefault:
            ["9", "A", "E", "F", "U", "M", "N", "O", "P", "Q", "R", "S", "T"].includes(expFloorIns1Value) &
            (parseFloat(expFloorDropFramingId) === 1),
        expFloorValue,
        modelId,
        uid,
        editingCodeRef,
        isEditing: !!editingCodeRef,
        isLibCode,
        expFloorSpacingId,
        expFloorIns1Value,
        toggleOpen,
        isCodeLibrary,
        isUpgradeComponent: fieldAccessor.includes("fields."),
        componentId,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearForm: () => dispatch(reset("expFloorCode")),
    clearInitCode: () => dispatch(setInitCode({}, "expFloorInsType")),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        editingCodeRef,
        isCodeLibrary,
        componentId,
        isEditing,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
        upgradeWhereToAddCode = {},
    }
) => {
    const { expFloorCode = {}, addToLibrary = false, setAsModelDefault = false, selectedComponents = [] } = form;

    toggleOpen(false);

    const { ecmInputMapUpgrade, ecmInputMapUpgradeId, ecmInputMapUpgradeType } = upgradeWhereToAddCode || {};

    standardCodeLogic({
        componentCodeType: "Floor",
        componentId,
        code: expFloorCode,
        formInputName: "expFloorInsType",
        codeType: "S",
        isCodeLibrary,
        isEditing,
        editingCodeRef,
        dispatch,
        getRValue,
        modelFormChange,
        currentFormChange,
        fieldAccessor,
        components,
        modelCodes,
        addToLibrary,
        addToCodeLib,
        uid,
        deleteCode,
        updateUpgradeCodes,
        setAsModelDefault,
        selectedComponents,
        currentPackage,
        selectedUpgrade,
        ecmInputMapUpgrade,
        ecmInputMapUpgradeId,
        ecmInputMapUpgradeType,
        isEcmInputMap: !isEmpty(upgradeWhereToAddCode),
    });

    // Clear wall code defaults for next time
    dispatch(setInitCode({}, "expFloorInsType"));
    // Reset form
    dispatch(reset("expFloorCode"));
};

const form = reduxForm({
    form: "expFloorCode",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(StandardCode);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
