import React, { useEffect, useRef, useCallback } from "react";
import classes from "./style.module.scss";
import Loading from "components/Loading";
import classNames from "classnames";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserError, selectUserUid } from "store/users/selectors";
import { Redirect, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const DashboardLayout = ({
    isLoading,
    cursorPosition,
    setCursorPosition,
    folders,
    dirLoading,
    className,
    withHeader,
    headerGradient,
    headerImg,
    headerButtonConainerContent,
    children,
}) => {
    const { pathname } = useLocation();
    const path =
        pathname
            .split("/")
            .filter((str) => ["", "dashboard", "communities", "parametric-analysis"].includes(str) === false) || [];

    /* Loading state and cursor position for loading spinner */
    const scrollContainerRef = useRef(null);

    // Get cursor position for loading spinner
    const onMouseMove = useCallback((e) => {
        const top = e.pageY - window.scrollY + 10;
        const left = e.pageX - window.scrollX + 10;

        setCursorPosition({ top, left });
    }, []);

    useEffect(() => {
        const handleMouseMove = (e) => {
            onMouseMove(e);
        };

        if (!isLoading) return;

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("wheel", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("wheel", handleMouseMove);
        };
    }, [isLoading, onMouseMove]);

    if (!isLoading && path.length > 0 && !folders[path[0]] && !dirLoading) {
        const dashboardURL = pathname
            .split("/")
            .filter((str) => ["", "dashboard", "communities", "parametric-analysis"].includes(str) === true)
            .join("/");
        return <Redirect to={dashboardURL} />;
    }

    return (
        <div
            className={classNames(classes.dashboard, className)}
            onMouseMove={onMouseMove}
            style={{ pointerEvents: isLoading ? "none" : "auto" }}
            ref={scrollContainerRef}
        >
            {withHeader && (
                <div className={classes.dashboardHead} style={{ background: headerGradient }}>
                    <div className={classes.headerButtonContainer}>
                        {headerButtonConainerContent}
                        <img className={classes.headerImage} src={headerImg} alt="header" />{" "}
                    </div>
                </div>
            )}
            {withHeader && <div className={classes.dashboardHeadSpacer} />}
            <div style={{ position: "fixed", pointerEvents: "none", ...cursorPosition }}>
                {isLoading && <Loading className={classes.smallerSpinner} subClassName={classes.smallSpinner} />}
            </div>

            {children}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    error: selectUserError,
});

const mapDispatchToProps = (dispatch, { history }) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
