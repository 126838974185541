import { createSelector } from "reselect";
import { initialState } from "./reducers";

export const selectModel = ({ model = initialState }) => model;

export const modelSelector = (path) =>
    createSelector([selectModel], (model) => {
        return !path ? "" : resolveObj(path, model);
    });

export const selectModelLoading = createSelector([selectModel], ({ modelLoading }) => modelLoading);

export const selectModelDetails = createSelector([selectModel], ({ modelDetails = {} }) => modelDetails);

export const selectModelName = createSelector(selectModel, ({ modelDetails: { name } }) => name);

export const selectModelId = createSelector(selectModel, ({ modelId }) => modelId);

export const selectModelOwnerId = createSelector(
    selectModel,
    ({ modelDetails: { owner: { uid = "" } = {} } = {} }) => uid
);

export const selectModelReview = createSelector([selectModel], ({ review = {} }) => review);

export const selectEnvelopeComponents = createSelector(
    [selectModel],
    ({ modelData: { components = {} } = {} }) => components
);

export const selectAirtightness = createSelector(
    [selectModel],
    ({ modelData: { airTightness = {} } = {} }) => airTightness
);

export const selectHeatingCooling = createSelector(
    [selectModel],
    ({ modelData: { heatingCooling = {} } = {} }) => heatingCooling
);

export const selectVentilation = createSelector(
    [selectModel],
    ({ modelData: { ventilation = {} } = {} }) => ventilation
);

export const selectDomesticHotWater = createSelector(
    [selectModel],
    ({ modelData: { domesticHotWater = {} } = {} }) => domesticHotWater
);

export const selectGeneration = createSelector([selectModel], ({ modelData: { generation = {} } = {} }) => generation);

export const selectProgram = createSelector([selectModel], ({ modelData: { program = {} } = {} }) => program);

export const selectBaseLoads = createSelector([selectModel], ({ modelData: { baseLoads = {} } = {} }) => baseLoads);

export const selectEnvelopeCodes = createSelector([selectModel], ({ modelData: { codes = {} } = {} }) => codes);

export const selectSpecifications = createSelector(
    [selectModel],
    ({ modelData: { specifications = {} } = {} }) => specifications
);

export const selectFuelPrices = createSelector(
    [selectModel],
    ({ modelData: { location: { fuelPrices = {} } = {} } = {} }) => fuelPrices
);

export const selectModelComponents = createSelector(
    [selectModel],
    ({ modelData: { components = {} } = {} }) => components
);

export const selectModelSimulationData = createSelector(
    [selectModel],
    ({ modelDetails: { lastCliRun, simulationRunning }, review }) => ({
        lastCliRun,
        simulationRunning,
        review,
    })
);

export const resolveObj = (path, obj) => {
    return path.split(".").reduce((cache, curr) => {
        return cache ? cache[curr] : null;
    }, obj);
};

export const selectModelTakeoff = createSelector([selectModel], ({ modelTakeoff }) => modelTakeoff);

export const selectIsSharingModel = createSelector([selectModel], ({ isSharingModel }) => isSharingModel);

export const selectModelData = createSelector([selectModel], ({ modelData }) => modelData);
