import { connect } from "react-redux";
import CodeDrawer from ".";
import { actions } from "features/Model/Enclosure/_ducks";

const { updateCodeDrawer } = actions;

const mapStateToProps = (
    {
        user: { uid } = {},
        model: { modelDetails: { owner: { uid: ownerId = uid } = {}, writePermission = [] } = {} } = {},
        enclosure: { codeDrawer = {} } = {},
    },
    { upgradeWhereToAddCode }
) => {
    const {
        id,
        isOpen = false,
        codeName,
        isEditing,
        componentType = "",
        codeType = "S",
        fieldAccessor,
        componentId,
        modelFormChange,
        currentFormChange = modelFormChange,
        drawingFormChange,
        isDrawing = false,
        windowAccessor = "",
        drawingSave,
        lastEdited,
    } = codeDrawer;

    return {
        id, // edit only
        isOpen,
        codeName,
        isEditing,
        componentType,
        codeType,
        fieldAccessor,
        componentId,
        modelFormChange,
        currentFormChange,
        drawingFormChange,
        isDrawing,
        windowAccessor,
        drawingSave,
        lastEdited,
        readOnly: ownerId !== uid && !writePermission.includes(uid),
        upgradeWhereToAddCode,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateCodeDrawer: (updates) => dispatch(updateCodeDrawer({ updates })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeDrawer);
