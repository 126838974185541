import { DashboardAPI } from "utils/api";

export const shareModelWithOrgMembers = async ({ modelId, uid, userList, orgId, permissionsType = "write" }) => {
    return DashboardAPI({
        method: "POST",
        url: "/shareModelWithOrgMembers",
        data: {
            modelId,
            uid,
            userList,
            orgId,
            permissionsType,
        },
    });
};
export const shareFolderModelsWithOrgMembers = async ({ selectedModelIds, uid, userList, orgId, sharedFolderName }) => {
    return DashboardAPI({
        method: "POST",
        url: "/shareFolderModelsWithOrgMembers",
        data: {
            selectedModelIds,
            uid,
            userList,
            orgId,
            sharedFolderName,
        },
    });
};

export const flagModelWithErrorForReview = async ({ modelId, uid, userMessage }) => {
    return DashboardAPI({
        method: "POST",
        url: "/flagModelWithErrorForReview",
        data: {
            modelId,
            uid,
            userMessage,
        },
    });
};

export const removeModelFromSharedWithDirs = async ({ modelId, sharedWith, uid }) => {
    return DashboardAPI({
        method: "POST",
        url: "/removeModelFromSharedWithDirs",
        data: {
            modelId,
            sharedWith,
            uid,
        },
    });
};

export const renameModelSharedWithDirs = async ({ modelId, sharedWith, uid, newName, lastEdited, address }) => {
    return DashboardAPI({
        method: "POST",
        url: "/renameModelSharedWithDirs",
        data: {
            modelId,
            sharedWith,
            uid,
            newName,
            lastEdited,
            address,
        },
    });
};

export const requestVoltaSnapOrganization = async ({ uid, userName, userEmail, orgName = "" }) => {
    return DashboardAPI({
        method: "POST",
        url: "/requestVoltaSnapOrganization",
        data: {
            uid,
            userName,
            userEmail,
            orgName,
        },
    });
};
