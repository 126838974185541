import React, { useCallback } from "react";
import { CodeDrawer as CodeDrawerForm, DrawingCodeDrawer } from "utils/codeLib";
import Drawer from "components/Drawer";
import EditIcon from "assets/images/icons/JSX/Edit";
import moment from "moment";
import InputRow from "components/Input/Row";
import Select from "components/Input/Select";
import globalStyles from "components/globalStyles.module.scss";
import { isEqual } from "lodash";

const drawerContent = ({ action, name, lastEdited }) =>
    ({
        create: {
            title: `Create ${name} Code`,
            subTitle: `Define the details of your new ${name.toLowerCase()} code`,
            icon: () => <EditIcon fill="#0eb0a9" />,
            introColor: "rgba(24, 193, 173, 0.1)",
        },
        edit: {
            title: `Edit ${name} Code`,
            subTitle: `Last Modified: ${lastEdited ? moment(lastEdited).format("MMM DD, YYYY") : "-"}`,
            icon: () => <EditIcon fill="#0eb0a9" />,
            introColor: "rgba(24, 193, 173, 0.1)",
        },
    }[action]);

const CodeDrawer = React.memo(
    ({
        id,
        isOpen,
        codeName = "",
        isEditing,
        componentType,
        codeType,
        updateCodeDrawer,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        drawingFormChange,
        componentId,
        isDrawing,
        windowAccessor,
        drawingSave,
        lastEdited,
        upgradeWhereToAddCode,
    }) => {
        //TODO: Save last edited to model (and get code library edited??)
        // Or we just add a different description

        const {
            title,
            subTitle,
            icon: Icon,
            introColor,
        } = drawerContent({
            action: isEditing ? "edit" : "create",
            name: codeName,
            lastEdited: lastEdited || "",
        });

        const toggleOpen = useCallback((value) => updateCodeDrawer({ isOpen: value }), [updateCodeDrawer]);
        const toggleCodeType = useCallback((value) => updateCodeDrawer({ codeType: value }), [updateCodeDrawer]);

        const handleClose = useCallback(() => {
            toggleOpen(false);
            toggleCodeType("S");
            //clearInitCode();
        }, [toggleCodeType, toggleOpen]);

        const handleUdefSave = useCallback(() => {
            toggleCodeType("S");
            toggleOpen(false);
        }, []);

        return (
            <Drawer
                open={isOpen}
                title={title}
                subtitle={subTitle}
                close={handleClose}
                icon={Icon}
                introColor={introColor}
            >
                {!isEditing && (
                    <InputRow gridTemplate="1fr" className={globalStyles.type}>
                        <Select
                            className={globalStyles.typeSelect}
                            name={"codeType"}
                            label="Code Type"
                            placeholder="Choose Code Type"
                            options={[
                                {
                                    label: "Standard",
                                    value: "S",
                                },
                                {
                                    label: "User Defined",
                                    value: "U",
                                },
                            ]}
                            disabled={false}
                            setValue={codeType}
                            input={{
                                onChange: (value) => {
                                    toggleCodeType(value);
                                },
                                value: codeType,
                            }}
                        />
                    </InputRow>
                )}
                {isDrawing ? (
                    <DrawingCodeDrawer
                        toggleOpen={toggleOpen}
                        componentId={componentId}
                        fieldAccessor={fieldAccessor}
                        componentType={componentType}
                        codeType={codeType}
                        handleClose={handleClose}
                        isFlat={componentType === "CeilingFlat"}
                        handleUdefSave={handleUdefSave}
                        modelFormChange={modelFormChange}
                        drawingFormChange={drawingFormChange}
                        windowAccessor={windowAccessor}
                        drawingSave={drawingSave}
                        upgradeWhereToAddCode={upgradeWhereToAddCode}
                    />
                ) : (
                    <CodeDrawerForm
                        toggleOpen={toggleOpen}
                        componentId={componentId}
                        fieldAccessor={fieldAccessor}
                        componentType={componentType}
                        codeType={codeType}
                        handleClose={handleClose}
                        isFlat={componentType === "CeilingFlat"}
                        handleUdefSave={handleUdefSave}
                        modelFormChange={modelFormChange}
                        currentFormChange={currentFormChange}
                        windowAccessor={windowAccessor}
                        drawingSave={drawingSave}
                        upgradeWhereToAddCode={upgradeWhereToAddCode}
                    />
                )}
            </Drawer>
        );
    },
    isEqual
);

export default CodeDrawer;
