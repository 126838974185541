import JSZip from "jszip";
import { saveAs } from "file-saver";
import { isEmpty } from "lodash";
import moment from "moment";

import { getCliResults } from "utils/results/api";
import {
    extractResultValues,
    getBarGraphResultsData,
    getUpgradeDhwRowLabels,
    getUpgradeEnvelopeRowLabels,
    getUpgradeHvacRowLabels,
    getUpgradeOtherRowLabels,
} from "utils/upgrades";

import { COLOURS_FOR_LEGEND, PARAMETRIC_RESULTS_TABLE, UPGRADE_SELECTION_OPTIONS } from "./constants";

import { ReactComponent as ParametricUpgradeIcon } from "assets/images/icons/ParametricUpgradeIcons.svg";
import { ReactComponent as ParametricPremadeIcon } from "assets/images/icons/ParametricPremadeIcon.svg";
import { prebuiltEcms } from "features/ParametricAnalysis/templates/preBuiltEcms";

const ecmCategoryOrder = [
    "ceilingWithAttic",
    "cathedral",
    "wall",
    "expFloor",
    "floorHeader",
    "basementWall",
    "basementSlab",
    "crawlspace",
    "slab",
    "window",
    "skylights",
    "door",
    "airTightness",
    "ventilation",
    "spaceHeating",
    "secondaryHeating",
    "spaceCooling",
    "primaryHotWater",
    "dwhr",
    "generation",
    "baseLoads",
];

export const saveCbatZipFile = (modelName, data) => {
    let zip = new JSZip();
    const foldername = `${modelName.split(".h2k")[0]}_CBAT_Inputs`;
    let atLeastOneFile = false;
    Object.keys(data).forEach((resKey) => {
        const filename = `${resKey}.csv`;

        if (data[resKey] !== "" && resKey !== "requestNum") {
            atLeastOneFile = true;
            zip.folder(foldername).file(filename, data[resKey]);
        }
    });

    if (atLeastOneFile) {
        zip.generateAsync({ type: "blob" }).then((blob) => {
            saveAs(blob, `${foldername}.zip`);
        });
    }
};

export const checkForResults = async (model, selectedModelId) => {
    const { lastCliRun: { cliId = {} } = {} } = model.modelDetails.modelDetails;
    const cliIds = Object.keys(cliId);

    let baseUpgradesResults = {};

    if (cliIds.length > 0) {
        const runIds = Object.values(cliId).map(({ runId = "" }) => runId);

        const resultsToCheck = [];

        await Promise.all(runIds.map((cliId) => getCliResults({ cliId, modelId: selectedModelId })))
            .then((results) => {
                results.forEach((result) => {
                    if (!isEmpty(result.data)) {
                        resultsToCheck.push({ ...result.data, id: cliId });
                    }
                });

                baseUpgradesResults = results.reduce((obj, { data }) => {
                    const { metadata: { upgradePackageKey = "" } = {} } = data;

                    if (!upgradePackageKey) {
                        return {
                            base: data,
                        };
                    }

                    return {
                        base: data,
                    };
                }, {});
            })
            .catch((error) => {
                return false;
            });

        if (resultsToCheck.length > 0) {
            return { hasResults: true, results: baseUpgradesResults };
        }

        return false;
    }
};

export const getUpgradeSelectionOptions = (upgrades, selectedUpgradeType) => {
    const upgradeOptions = [];

    Object.keys(upgrades).forEach((upgradeKey) => {
        const nestedUpgrades = upgrades[upgradeKey].upgrades;

        if (!nestedUpgrades) return;

        if (upgradeKey !== "base" && Object.keys(nestedUpgrades).length > 0) {
            Object.keys(nestedUpgrades).forEach((nestedUpgradeKey) => {
                const {
                    label = {},
                    upgradeType,
                    fields: { constructionType: { id: constructionId = 0 } = {} } = {},
                } = nestedUpgrades[nestedUpgradeKey];

                if (upgradeType === "ceiling") {
                    if ([2, 3].includes(constructionId) && selectedUpgradeType.type === "cathedral")
                        upgradeOptions.push({
                            value: {
                                upgradeKey: nestedUpgradeKey,
                                upgradeSubType: "cathedral",
                                upgradeType: "ceiling",
                                parentUpgradeKey: upgradeKey,
                            },
                            label: label[Object.keys(label)[0]] || "None",
                            labelIcon: ParametricUpgradeIcon,
                        });

                    if ([0, 1, 4].includes(constructionId) && selectedUpgradeType.type === "ceilingWithAttic")
                        upgradeOptions.push({
                            value: {
                                upgradeKey: nestedUpgradeKey,
                                upgradeSubType: "ceilingWithAttic",
                                upgradeType: "ceiling",
                                parentUpgradeKey: upgradeKey,
                            },
                            label: label[Object.keys(label)[0]] || "None",
                            labelIcon: ParametricUpgradeIcon,
                        });
                }

                if (upgradeType === "basement") {
                    if (selectedUpgradeType.type === "basementWall")
                        upgradeOptions.push({
                            value: {
                                upgradeKey: nestedUpgradeKey,
                                upgradeSubType: "basementWall",
                                upgradeType: "basement",
                                parentUpgradeKey: upgradeKey,
                            },
                            label: label["basementWall"],
                            labelIcon: ParametricUpgradeIcon,
                        });

                    if (selectedUpgradeType.type === "basementSlab")
                        upgradeOptions.push({
                            value: {
                                upgradeKey: nestedUpgradeKey,
                                upgradeSubType: "basementSlab",
                                upgradeType: "basement",
                                parentUpgradeKey: upgradeKey,
                            },
                            label: label["basementSlab"] || "None",
                            labelIcon: ParametricUpgradeIcon,
                        });
                }

                if (upgradeType === "window") {
                    if (selectedUpgradeType.type === "window" && !Object.keys(label).includes("skylights")) {
                        upgradeOptions.push({
                            value: {
                                upgradeKey: nestedUpgradeKey,
                                upgradeSubType: "window",
                                upgradeType: "window",
                                parentUpgradeKey: upgradeKey,
                            },
                            label: label[Object.keys(label)[0]] || "None",
                            labelIcon: ParametricUpgradeIcon,
                        });
                    }

                    if (selectedUpgradeType.type === "skylights" && Object.keys(label).includes("skylights"))
                        upgradeOptions.push({
                            value: { upgradeKey: nestedUpgradeKey, upgradeSubType: "skylights", upgradeType: "window" },
                            label: label[Object.keys(label)[0]] || "None",
                            labelIcon: ParametricUpgradeIcon,
                        });
                }

                if (upgradeType === "heatingCooling") {
                    if (selectedUpgradeType.type === "spaceHeating")
                        upgradeOptions.push({
                            value: {
                                upgradeKey: nestedUpgradeKey,
                                upgradeSubType: "spaceHeating",
                                upgradeType: "heatingCooling",
                                parentUpgradeKey: upgradeKey,
                            },
                            label: label["spaceHeating"] || "None",
                            labelIcon: ParametricUpgradeIcon,
                        });

                    if (selectedUpgradeType.type === "secondaryHeating")
                        upgradeOptions.push({
                            value: {
                                upgradeKey: nestedUpgradeKey,
                                upgradeSubType: "secondaryHeating",
                                upgradeType: "heatingCooling",
                                parentUpgradeKey: upgradeKey,
                            },
                            label: label["secondaryHeating"] || "None",
                            labelIcon: ParametricUpgradeIcon,
                        });

                    if (selectedUpgradeType.type === "spaceCooling")
                        upgradeOptions.push({
                            value: {
                                upgradeKey: nestedUpgradeKey,
                                upgradeSubType: "spaceCooling",
                                upgradeType: "heatingCooling",
                                parentUpgradeKey: upgradeKey,
                            },
                            label: label["spaceCooling"] || "None",
                            labelIcon: ParametricUpgradeIcon,
                        });
                }

                if (upgradeType === "domesticHotWater") {
                    if (selectedUpgradeType.type === "primaryHotWater")
                        upgradeOptions.push({
                            value: {
                                upgradeKey: nestedUpgradeKey,
                                upgradeSubType: "primaryHotWater",
                                upgradeType: "domesticHotWater",
                                parentUpgradeKey: upgradeKey,
                            },
                            label: label["primaryHotWater"] || "None",
                            labelIcon: ParametricUpgradeIcon,
                        });

                    if (selectedUpgradeType.type === "dwhr")
                        upgradeOptions.push({
                            value: {
                                upgradeKey: nestedUpgradeKey,
                                upgradeSubType: "dwhr",
                                upgradeType: "domesticHotWater",
                                parentUpgradeKey: upgradeKey,
                            },
                            label: label["dwhr"] || "None",
                            labelIcon: ParametricUpgradeIcon,
                        });
                }

                // bathroomFaucets, showerHeads, clothesWasher, dishwasher, lighting, monitoringSys
                // if (upgradeType === "baseLoads" && selectedUpgradeType.type === "baseLoads") {
                //     Object.keys(label).forEach((labelKey) => {
                //         if (labelKey === "bathroomFaucets")
                //             upgradeOptions.push({
                //                 value: {
                //                     upgradeKey: nestedUpgradeKey,
                //                     upgradeSubType: "bathroomFaucets",
                //                     upgradeType: "baseLoads",
                //                 },
                //                 label: label["bathroomFaucets"],
                //                 labelIcon: ParametricUpgradeIcon,
                //             });

                //         if (labelKey === "showerHeads")
                //             upgradeOptions.push({
                //                 value: {
                //                     upgradeKey: nestedUpgradeKey,
                //                     upgradeSubType: "showerHeads",
                //                     upgradeType: "baseLoads",
                //                 },
                //                 label: label["showerHeads"],
                //                 labelIcon: ParametricUpgradeIcon,
                //             });

                //         if (labelKey === "clothesWasher")
                //             upgradeOptions.push({
                //                 value: {
                //                     upgradeKey: nestedUpgradeKey,
                //                     upgradeSubType: "clothesWasher",
                //                     upgradeType: "baseLoads",
                //                 },
                //                 label: label["clothesWasher"],
                //                 labelIcon: ParametricUpgradeIcon,
                //             });

                //         if (labelKey === "dishwasher")
                //             upgradeOptions.push({
                //                 value: {
                //                     upgradeKey: nestedUpgradeKey,
                //                     upgradeSubType: "dishwasher",
                //                     upgradeType: "baseLoads",
                //                 },
                //                 label: label["dishwasher"],
                //                 labelIcon: ParametricUpgradeIcon,
                //             });

                //         if (labelKey === "lighting")
                //             upgradeOptions.push({
                //                 value: {
                //                     upgradeKey: nestedUpgradeKey,
                //                     upgradeSubType: "lighting",
                //                     upgradeType: "baseLoads",
                //                 },
                //                 label: label["lighting"],
                //                 labelIcon: ParametricUpgradeIcon,
                //             });

                //         if (labelKey === "monitoringSys")
                //             upgradeOptions.push({
                //                 value: {
                //                     upgradeKey: nestedUpgradeKey,
                //                     upgradeSubType: "monitoringSys",
                //                     upgradeType: "baseLoads",
                //                 },
                //                 label: label["monitoringSys"],
                //                 labelIcon: ParametricUpgradeIcon,
                //             });
                //     });
                // }

                if (
                    upgradeType === selectedUpgradeType.type &&
                    selectedUpgradeType.type !== "ceiling" &&
                    selectedUpgradeType.type !== "basement" &&
                    selectedUpgradeType.type !== "window" &&
                    selectedUpgradeType.type !== "skylights" &&
                    selectedUpgradeType.type !== "heatingCooling" &&
                    selectedUpgradeType.type !== "domesticHotWater" &&
                    selectedUpgradeType.type !== "baseLoads"
                ) {
                    upgradeOptions.push({
                        value: {
                            upgradeKey: nestedUpgradeKey,
                            upgradeType: selectedUpgradeType.type,
                            upgradeSubType: selectedUpgradeType.type,
                            parentUpgradeKey: upgradeKey,
                        },
                        label: label[Object.keys(label)[0]] || "None",
                        labelIcon: ParametricUpgradeIcon,
                    });
                }
            });
        }
    });

    return upgradeOptions;
};

export const getPrebuiltEcmUpgradeOptions = (category = "") => {
    const { [category]: ecmsInCategory = {} } = prebuiltEcms;

    const upgradeOptions = Object.keys(ecmsInCategory).map((ecmKey) => {
        const { label = "No label found", packageValues: { upgradeType, upgradeSubType } = {} } =
            ecmsInCategory[ecmKey];
        return {
            value: {
                upgradeKey: category,
                upgradeSubType: upgradeSubType,
                upgradeType: upgradeType,
                parentUpgradeKey: ecmKey,
                isPrebuilt: true,
            },
            label: label,
            labelIcon: ParametricPremadeIcon,
        };
    });

    return upgradeOptions;
};

export const getAllUpgradesPackages = (upgrades) => {
    const allUpgrades = {};

    Object.keys(upgrades).forEach((upgradeKey) => {
        const nestedUpgrades = upgrades[upgradeKey].upgrades;

        if (!nestedUpgrades) return;

        if (upgradeKey !== "base" && Object.keys(nestedUpgrades).length > 0) {
            Object.keys(nestedUpgrades).forEach((nestedUpgradeKey) => {
                allUpgrades[nestedUpgradeKey] = nestedUpgrades[nestedUpgradeKey];
            });
        }
    });

    return allUpgrades;
};

export const getUpgradeToAdd = (
    allUpgrades,
    selectedUpgradePackage,
    ecmInputMap,
    index = 0,
    addingNewfromDrawer = false
) => {
    const {
        upgradeKey,
        upgradeSubType,
        upgradeType,
        upgradeProcess,
        parentUpgradeKey = null,
        isPrebuilt = false,
    } = selectedUpgradePackage;

    let selectedUpgrade = addingNewfromDrawer ? allUpgrades : allUpgrades[upgradeKey];

    if (isPrebuilt) {
        selectedUpgrade = prebuiltEcms[upgradeKey]?.[parentUpgradeKey];
    }

    // console.log("upgradeSubType", ecmInputMap[upgradeSubType]);

    const lastEcmInputComponent = ecmInputMap[upgradeSubType]
        ? Object.entries(ecmInputMap[upgradeSubType])
              .sort((a, b) => a[1].order || 0 - b[1].order || 0)
              .pop()[1]
        : null;

    let orderOfLastComponent = lastEcmInputComponent ? lastEcmInputComponent.order || 0 : 0;

    const selectedUpgradePackageValues = Object.keys(selectedUpgrade).includes("packageValues")
        ? selectedUpgrade.packageValues
        : selectedUpgrade;

    const { label } = selectedUpgradePackageValues;
    const nestedLabel = selectedUpgrade.label[upgradeSubType] ? selectedUpgrade.label[upgradeSubType] : "";

    const upgradeLabel = label[upgradeSubType] ? label[upgradeSubType] : nestedLabel || "Label Error";

    if (upgradeSubType === "basementSlab") {
        return {
            [`upgrades-${moment().format("YYYYMMDDHHmmssSS")}-${index}`]: {
                label: upgradeLabel,
                active: true,
                passed: null,
                individualSavings: null,
                noChange: false,
                order: orderOfLastComponent + 1,
                packageValues: {
                    ...selectedUpgradePackageValues,
                    upgradeType,
                    upgradeSubType,
                    cost: !isEmpty(selectedUpgradePackageValues.cost)
                        ? { [upgradeSubType]: selectedUpgradePackageValues.cost[upgradeSubType] }
                        : { [upgradeSubType]: 0 },
                    fields: {
                        ...selectedUpgradePackageValues.fields,
                    },
                    label: { [upgradeSubType]: upgradeLabel },
                    selectedComponents: ["*"],
                    parentUpgradeKey,
                },
            },
        };
    }

    if (upgradeSubType === "basementWall") {
        return {
            [`upgrades-${moment().format("YYYYMMDDHHmmssSS")}-${index}`]: {
                label: upgradeLabel,
                active: true,
                passed: null,
                individualSavings: null,
                noChange: false,
                order: orderOfLastComponent + 1,
                packageValues: {
                    ...selectedUpgradePackageValues,
                    upgradeType,
                    upgradeSubType,
                    cost: !isEmpty(selectedUpgradePackageValues.cost)
                        ? { [upgradeSubType]: selectedUpgradePackageValues.cost[upgradeSubType] }
                        : { [upgradeSubType]: 0 },
                    fields: {
                        ...selectedUpgradePackageValues.fields,
                        floor: {},
                    },
                    label: { [upgradeSubType]: upgradeLabel },
                    selectedComponents: ["*"],
                    parentUpgradeKey,
                },
            },
        };
    }

    if (upgradeSubType === "dwhr" || upgradeSubType === "primaryHotWater") {
        return {
            [`upgrades-${moment().format("YYYYMMDDHHmmssSS")}-${index}`]: {
                label: upgradeLabel,
                active: true,
                passed: null,
                individualSavings: null,
                noChange: false,
                order: orderOfLastComponent + 1,
                packageValues: {
                    ...selectedUpgradePackageValues,
                    upgradeType,
                    upgradeSubType,
                    cost: !isEmpty(selectedUpgradePackageValues.cost)
                        ? { [upgradeSubType]: selectedUpgradePackageValues.cost[upgradeSubType] }
                        : { [upgradeSubType]: 0 },
                    fields: {
                        ...selectedUpgradePackageValues.fields,
                        secondarySystem: {},
                    },
                    label: {
                        [upgradeSubType]: upgradeLabel,
                    },
                    selectedComponents: ["*"],
                    parentUpgradeKey,
                },
            },
        };
    }

    if (upgradeSubType === "spaceCooling") {
        return {
            [`upgrades-${moment().format("YYYYMMDDHHmmssSS")}-${index}`]: {
                label: upgradeLabel,
                active: true,
                passed: null,
                individualSavings: null,
                noChange: false,
                order: orderOfLastComponent + 1,
                packageValues: {
                    ...selectedUpgradePackageValues,
                    upgradeType,
                    upgradeSubType,
                    cost: !isEmpty(selectedUpgradePackageValues.cost)
                        ? { [upgradeSubType]: selectedUpgradePackageValues.cost[upgradeSubType] }
                        : { [upgradeSubType]: 0 },
                    fields: {
                        ...selectedUpgradePackageValues.fields,
                        heating: {},
                        radiantHeating: {},
                        additionalOpenings: {},
                        supplSystems: {},
                    },
                    label: { [upgradeSubType]: upgradeLabel },
                    selectedComponents: ["*"],
                    parentUpgradeKey,
                },
            },
        };
    }

    if (upgradeSubType === "spaceHeating") {
        return {
            [`upgrades-${moment().format("YYYYMMDDHHmmssSS")}-${index}`]: {
                label: upgradeLabel,
                active: true,
                passed: null,
                individualSavings: null,
                noChange: false,
                order: orderOfLastComponent + 1,
                packageValues: {
                    ...selectedUpgradePackageValues,
                    upgradeType,
                    upgradeSubType,
                    cost: !isEmpty(selectedUpgradePackageValues.cost)
                        ? { [upgradeSubType]: selectedUpgradePackageValues.cost[upgradeSubType] }
                        : { [upgradeSubType]: 0 },
                    fields: {
                        ...selectedUpgradePackageValues.fields,
                        cooling: {},
                        radiantHeating: {},
                        additionalOpenings: {},
                        supplSystems: {},
                    },
                    label: { [upgradeSubType]: upgradeLabel },
                    selectedComponents: ["*"],
                    parentUpgradeKey,
                },
            },
        };
    }

    if (upgradeSubType === "secondaryHeating") {
        return {
            [`upgrades-${moment().format("YYYYMMDDHHmmssSS")}-${index}`]: {
                label: upgradeLabel,
                active: true,
                passed: null,
                individualSavings: null,
                noChange: false,
                order: orderOfLastComponent + 1,
                packageValues: {
                    ...selectedUpgradePackageValues,
                    upgradeType,
                    upgradeSubType,
                    cost: !isEmpty(selectedUpgradePackageValues.cost)
                        ? { [upgradeSubType]: selectedUpgradePackageValues.cost[upgradeSubType] }
                        : { [upgradeSubType]: 0 },
                    fields: {
                        ...selectedUpgradePackageValues.fields,
                        cooling: {},
                        radiantHeating: {},
                        additionalOpenings: {},
                        heating: {},
                    },
                    label: { [upgradeSubType]: upgradeLabel },
                    selectedComponents: ["*"],
                    parentUpgradeKey,
                },
            },
        };
    }

    if (upgradeSubType === "airTightness" && upgradeProcess === "achPercent") {
        return {
            [`upgrades-${moment().format("YYYYMMDDHHmmssSS")}-${index}`]: {
                label: label[Object.keys(label)[0]],
                active: true,
                passed: null,
                individualSavings: null,
                noChange: false,
                noEdit: true,
                order: orderOfLastComponent + 1,
                packageValues: {
                    upgradeType,
                    upgradeSubType,
                    upgradeProcess: "achPercent",
                    ...selectedUpgradePackageValues,
                    selectedComponents: ["*"],
                    parentUpgradeKey,
                },
            },
        };
    }

    return {
        [`upgrades-${moment().format("YYYYMMDDHHmmssSS")}-${index}`]: {
            label: label[Object.keys(label)[0]],
            active: true,
            passed: null,
            individualSavings: null,
            noChange: false,
            order: orderOfLastComponent + 1,
            packageValues: {
                upgradeType,
                upgradeSubType,
                ...selectedUpgradePackageValues,
                selectedComponents: ["*"],
                parentUpgradeKey,
            },
        },
    };
};

export const getUpgradesToAdd = (allUpgradesToAdd, ecmInputMap) => {
    const upgradesToAdd = [];

    // getUpgradeToAdd = (allUpgrades, selectedUpgradePackage, orderOfLastComponent = 0, index = 0)
    // const { upgradeKey, upgradeSubType, upgradeType } = selectedUpgradePackage;
    Object.entries(allUpgradesToAdd).forEach(([key, value], index) => {
        if (value.upgradeType === "baseLoads") return;

        const labelKeys = Object.keys(value.label);

        if (labelKeys.length >= 1) {
            labelKeys.forEach((labelKey) => {
                const selectedUpgradePackage = {
                    upgradeKey: key,
                    upgradeSubType: labelKey,
                    upgradeType: value.upgradeType,
                };

                const upgradeToAdd = getUpgradeToAdd(allUpgradesToAdd, selectedUpgradePackage, ecmInputMap, index);

                upgradesToAdd.push({ ...upgradeToAdd });
            });
        }
    });

    return upgradesToAdd;
};

export const canRunPrecheck = (ecmInputMap) => {
    if (isEmpty(ecmInputMap)) return false;

    let canRun = false;
    // let allUpgrades = {};

    Object.entries(ecmInputMap).forEach(([key, upgrades]) => {
        if (key === "base" || Object.keys(upgrades).length === 1) return;

        // allUpgrades = { ...allUpgrades, ...upgrades };

        // console.log(Object.entries(upgrades).filter(([upgradeKey, { active }]) => active));

        if (canRun) return;

        canRun = Object.entries(upgrades)
            .filter(([upgradeKey, { active }]) => active)
            .some(([upgradeKey, { active, individualSavings, passed, noChange }]) => {
                if (upgradeKey === "None") return false;

                return (
                    (active && individualSavings === null) || passed === null || (!passed && individualSavings === null)
                );
            });
    });

    return canRun;
};

export const canRunAnalysis = (ecmInputMap) => {
    if (isEmpty(ecmInputMap)) return false;

    let canRun = false;
    let allUpgrades = {};

    Object.entries(ecmInputMap).forEach(([key, upgrades]) => {
        if (key === "base" || Object.keys(upgrades).length === 1) return;

        allUpgrades = { ...allUpgrades, ...upgrades };
    });

    canRun =
        Object.entries(allUpgrades).filter(([upgradeKey, { active }]) => active).length === 0
            ? false
            : Object.entries(allUpgrades)
                  .filter(([upgradeKey, { active }]) => active)
                  .some(([upgradeKey, { active, individualSavings, passed, firstBatchId }]) => {
                      return active && individualSavings !== null && passed !== null && passed && !firstBatchId;
                  });

    return canRun;
};

export const getNumParametricOptionsCombinations = (ecmInputMap = {}) => {
    if (isEmpty(ecmInputMap)) {
        return {
            totalUniqueOptions: 0,
            totalCombinations: 0,
        };
    }

    // Rules for counting total unique options:
    // Always count all active options that are NOT noChange options
    // Count noChange options IF it is active and there are other active options in its category
    const totalUniqueOptions = Object.keys(ecmInputMap).reduce((sum, cat) => {
        const activeOptionsCount =
            (Object.keys(ecmInputMap[cat]).filter((ecmId) => ecmId !== "None" && ecmInputMap[cat][ecmId]?.active) || [])
                .length || 0;
        if (activeOptionsCount === 0) {
            return sum;
        }
        //Can only ever have one noChange option per category
        const { None: { active: noChangeActive = false } = {} } = ecmInputMap[cat];
        return sum + activeOptionsCount + (noChangeActive ? 1 : 0);
    }, 0);

    // the PRODUCT of all counts totalUniqueOptions
    const totalCombinations = Object.keys(ecmInputMap).reduce((product, cat) => {
        //Count the active options, and if for some reason we have zero options in a category coerce the value to 1 so we don't multiply by 0
        const currentCatCount = Math.max(
            (Object.keys(ecmInputMap[cat]).filter((ecmId) => ecmInputMap[cat][ecmId]?.active) || []).length,
            1
        );
        return product * currentCatCount;
    }, 1);

    return {
        totalUniqueOptions,
        totalCombinations,
    };
};

export const getFilterOptions = (ecmInputMap, currentFilters) => {
    // console.log("ecmInputMap", ecmInputMap, currentFilters);

    Object.keys(ecmInputMap).forEach((category) => {
        const ecmKeys = Object.keys(ecmInputMap[category]);

        if (ecmKeys.length === 1) return;

        Object.entries(ecmInputMap[category]).forEach(([key, value]) => {
            if (
                currentFilters[category].upgradesOptions.find(
                    (option) => option.value === key || (key === "None" && option.value === `${category}_None`)
                )
            )
                return;

            currentFilters[category] = {
                ...currentFilters[category],
                hasOptions: true,
            };

            if (key === "None" && value.active && value.firstBatchId) {
                currentFilters[category].upgradesOptions.push({
                    ...value,
                    value: `${category}_None`,
                    id: `${category}_None`,
                });

                currentFilters[category].selectedUpgrades.push(`${category}_None`);

                return;
            }

            if (value.active && value.firstBatchId) {
                currentFilters[category].upgradesOptions.push({ ...value, value: key, id: key });
                currentFilters[category].selectedUpgrades.push(key);
            }
        });

        currentFilters[category].upgradesOptions.sort((a, b) => {
            if (a.value.endsWith("_None") && !b.value.endsWith("_None")) {
                return -1;
            }

            if (!a.value.endsWith("_None") && b.value.endsWith("_None")) {
                return 1;
            }

            return 0;
        });
    });

    return currentFilters;
};

export const getPointsForGraph = (runResults, selectedLegend, initialRunResultsFilters) => {
    const { value: selectedLegendCategory } = selectedLegend;

    const resultsToReturn = {};
    const upgradesToShowInLegend = {};
    // const coloursInUse = [];

    const allResults = getAllResults(runResults);

    const allSelectedUpgrades = Object.values(initialRunResultsFilters).reduce(
        (acc, { selectedUpgrades }) => [...acc, ...selectedUpgrades],
        []
    );

    const allPossibleUpgrades = Object.values(initialRunResultsFilters).reduce(
        (acc, { upgradesOptions = [] }) => [...acc, ...upgradesOptions.map(({ id }) => id)],
        []
    );

    const excludedNoChangeOptions = allPossibleUpgrades.filter(
        (id) => !allSelectedUpgrades.includes(id) && id.includes("_None")
    );

    //initialRunResultsFilters contains the upgradeIds to display (in selectedUpgrades)
    //Also contains all options in upgradesOptions
    const { upgradesOptions: allLegendOptions = [] } = initialRunResultsFilters[selectedLegendCategory] || {};

    //Start by assigning colours based on the legend options, use allLegendOptions (before filters) so that we are consistent with colour selection.
    const allLegendUpgradeIds = allLegendOptions.map(({ id }) => id).sort(); //apply sort here for consistent ordering

    let colourIndex = 0;
    const colourByCategory = allLegendUpgradeIds.reduce((acc, upgradeId) => {
        const { label: upgradeLabel = "Label Error" } = allLegendOptions.find(({ id }) => id === upgradeId);
        if (upgradeId === `${selectedLegendCategory}_None`) {
            upgradesToShowInLegend["NONE"] = { label: "No Change", colourOnGraph: "#646f81" };
            return {
                ...acc,
                [upgradeId]: "#646f81",
            };
        }

        const colourToUse = COLOURS_FOR_LEGEND[colourIndex] || "#646f81";
        colourIndex++;

        upgradesToShowInLegend[upgradeId] = { label: upgradeLabel, colourOnGraph: colourToUse };
        return {
            ...acc,
            [upgradeId]: colourToUse,
        };
    }, {});

    //Now filter out results based on selections in the filters
    Object.entries(allResults).forEach(([resultKey, resultValue]) => {
        const { upgradeIds = "", listOfECMs = "", upgradesPresent = {} } = resultValue;

        // 1) Check if the upgrade is allowed by seeing if its upgradeId is present in the results data
        // Split the upgradeIds into an array of component=>id pairs
        const upgradeIdsArray = upgradeIds.split(";");

        // Extract only the IDs from the component=>id pairs
        const upgradeComponentIds = upgradeIdsArray.map((pair) => pair.split("=>")[1]);

        const hasMatchingUpgrades = upgradeComponentIds.every((id) => allSelectedUpgrades.includes(id));

        // 2) Check if we're allowing the "no change" option to pass for a category based on the filter selections.
        // An array of the no change upgradeIds that are allowed based on the filters:
        const allowedNoChangeCategories = allSelectedUpgrades.filter((id) => id.includes("_None"));

        const splitEcms = listOfECMs.split(";");

        let excludeResult = false;
        if (!isEmpty(excludedNoChangeOptions)) {
            //break early if we're dealing with a no change option that should be excluded
            excludeResult = excludedNoChangeOptions.some((id) => {
                const category = id.split("_None")[0];
                const indexOfCategory = ecmCategoryOrder.findIndex((ind) => ind === category);
                return splitEcms[indexOfCategory] === "None";
            });
        }

        const isNoChangeAllowed = allowedNoChangeCategories.every((id) => {
            const category = id.split("_None")[0];
            const indexOfCategory = ecmCategoryOrder.findIndex((ind) => ind === category);
            return splitEcms[indexOfCategory] === "None";
        });

        if (excludeResult) {
            //We're excluding a no change options, do not enter other cases
        } else if (hasMatchingUpgrades) {
            //The results contain an ecm allowed through the filter as determined by the presence of its upgradeId

            //Get the upgradeId present for the legend category
            const matchingLegendId = Object.keys(colourByCategory).find((id) => upgradeComponentIds.includes(id));

            resultsToReturn[resultKey] = {
                ...resultValue,
                resultType: selectedLegendCategory,
                colourOnGraph: colourByCategory[matchingLegendId] || "#646f81", //colorToUse,
            };
        } else if (isNoChangeAllowed) {
            //The results contain a "No Change" ecm for a categoty allowed by the filter
            resultsToReturn[resultKey] = { ...resultValue, resultType: "NONE", colourOnGraph: "#646f81" };
        }

        //Otherwise, the results are filtered out (not included in resultsToReturn)
    });

    return { resultsToReturn, upgradesToShowInLegend };
};

export const getAllResults = (runResults) => {
    const resultsToReturn = {};

    Object.entries(runResults).forEach(([_, value]) => {
        const { results } = value;

        if (results) {
            Object.entries(results).forEach(([resultKey, resultValue]) => {
                resultsToReturn[resultKey] = { ...resultValue };
            });
        }
    });

    return resultsToReturn;
};

export const getMaxX = (organizedData) => {
    const numArray = organizedData.map((d) => d.x);

    const minValue = Math.min(...numArray);
    const maxValue = Math.max(...numArray);

    return maxValue + 0.2 * Math.abs(maxValue - minValue);
};

export const getMaxY = (organizedData) => {
    const numArray = organizedData.map((d) => d.y);

    const minValue = Math.min(...numArray);
    const maxValue = Math.max(...numArray);

    return maxValue + 0.2 * Math.abs(maxValue - minValue);
};

export const getMinX = (organizedData) => {
    const numArray = organizedData.map((d) => d.x);

    const minValue = Math.min(...numArray);
    const maxValue = Math.max(...numArray);

    if (minValue > 0) return 0;

    return minValue - 0.2 * Math.abs(maxValue - minValue);
};

export const getMinY = (organizedData) => {
    const numArray = organizedData.map((d) => d.y);

    const minValue = Math.min(...numArray);
    const maxValue = Math.max(...numArray);

    if (minValue > 0) return 0;

    return minValue - 0.2 * Math.abs(maxValue - minValue);
};

/*
    const tableHeaders = [{label:"House Components", id: "houseComponents"}, {label: "Base Model", id: "baseModel"}
                            , ...pinnedRecipes];
    const tabelComponentsRowsWithCells = [[{label: ""}, {"laebl": ""}], []]
    const tableResultsRowsWithCells = [[{label: ""}, {"laebl": ""}], []]

*/
export const buildTableData = (runResults, pinnedRecipes, modelData, modelResults) => {
    const tableHeaders = [
        { label: "House Components", id: "houseComponents" },
        { label: "Base Model", id: "baseModel" },
    ];
    let tabelComponentsRowsWithCells = [];
    let tableResultsRowsWithCells = [];

    const allResults = getAllResults(runResults);
    const sortedPinnedRecipes = pinnedRecipes.sort((a, b) => a.order - b.order);

    const {
        components = {},
        codes = {},
        airTightness = {},
        heatingCooling = {},
        ventilation = {},
        domesticHotWater = {},
        generation = {},
        program = {},
        baseLoads = {},
    } = modelData;

    const SORTED_TABLE_COMPONENTS_LIST = UPGRADE_SELECTION_OPTIONS;

    // SETTING UP HEADERS
    if (sortedPinnedRecipes.length > 0) {
        sortedPinnedRecipes.forEach((recipe) => {
            tableHeaders.push(recipe);
        });
    }

    // SETTING UP tabelComponentsRowsWithCells
    // Run through sortedPinnedRecipes once

    // Loop through each component and its value
    SORTED_TABLE_COMPONENTS_LIST.forEach(({ label, value: { type, upgradeType } }, componentIndex) => {
        // Initialize the row if it hasn't been initialized yet
        if (!tabelComponentsRowsWithCells[componentIndex]) {
            tabelComponentsRowsWithCells[componentIndex] = [{ label: label }]; // Ensure the static elements are initialized
        }

        // Loop through table headers
        tableHeaders.forEach((header) => {
            const { id: headerId } = header;

            // Handle houseComponents header
            if (headerId === "houseComponents") {
                tabelComponentsRowsWithCells[componentIndex][0] = { label: label }; // Ensure only one label is added
            }

            // Handle baseModel header
            if (headerId === "baseModel") {
                if (!isEmpty(components)) {
                    let baseModelLabel = "None";

                    baseModelLabel = getUpgradeEnvelopeRowLabels({
                        components,
                        codes,
                        airtightness: airTightness,
                        rowId: type,
                        upgradePackage: {},
                    });

                    if (type === "spaceHeating" || type === "spaceCooling" || type === "ventilation") {
                        baseModelLabel = getUpgradeHvacRowLabels({
                            heatingCooling,
                            ventilation,
                            rowId: type,
                            upgradePackage: {},
                        });
                    }

                    if (type === "primaryHotWater" || type === "dwhr") {
                        baseModelLabel = getUpgradeDhwRowLabels({
                            domesticHotWater,
                            heatingCooling,
                            rowId: type,
                        });
                    }

                    if (type === "generation") {
                        baseModelLabel = getUpgradeOtherRowLabels({
                            generation,
                            program,
                            baseLoads,
                            rowId: type,
                        });
                    }

                    tabelComponentsRowsWithCells[componentIndex][1] = { label: baseModelLabel?.label || "None" };
                }
            }

            // Handle other headers (except houseComponents and baseModel)
            if (headerId !== "houseComponents" && headerId !== "baseModel") {
                const { upgradesPresent = {} } = allResults[headerId];

                // Check if an upgrade is present for the type
                if (upgradesPresent[type]) {
                    // Ensure the label is only pushed if the recipeId and type don't already exist
                    if (!tabelComponentsRowsWithCells[componentIndex].some((cell) => cell.recipeId === headerId)) {
                        tabelComponentsRowsWithCells[componentIndex].push({
                            label: upgradesPresent[type].label,
                            recipeId: headerId,
                        });
                    }
                } else {
                    // If no upgrade is present, push "-" if the recipeId doesn't already exist
                    if (!tabelComponentsRowsWithCells[componentIndex].some((cell) => cell.recipeId === headerId)) {
                        tabelComponentsRowsWithCells[componentIndex].push({ label: "None", recipeId: headerId });
                    }
                }
            }
        });
    });

    const convertValues = {
        W: 0.29307107,
        MJ: 1000,
    };

    // Loop through each component and its value
    PARAMETRIC_RESULTS_TABLE.forEach(
        ({ label, value, idForBase, convert = false, convertTo, ignore = false }, index) => {
            // Initialize the row if it hasn't been initialized yet
            if (ignore) return;

            if (!tableResultsRowsWithCells[index]) {
                tableResultsRowsWithCells[index] = [{ label: label }];
            }

            // Loop through table headers
            tableHeaders.forEach((header) => {
                const { id: headerId } = header;

                // Handle houseComponents header
                if (headerId === "houseComponents") {
                    tableResultsRowsWithCells[index][0] = { label: label }; // Ensure only one label is added
                }

                // Handle baseModel header
                if (headerId === "baseModel") {
                    let exctractedValue = "None";

                    if (idForBase !== "") {
                        exctractedValue = extractResultValues({
                            allPackageResults: { base: { modelData, resultsData: modelResults } },
                            targetPackageId: "base",
                            rowKey: idForBase,
                            fuelPrices: {},
                            upgradePackage: {},
                            packageUiSpecs: {},
                            isBaseColumn: true,
                        });

                        if (
                            idForBase === "primaryHeating" ||
                            idForBase === "primaryDHW" ||
                            idForBase === "baseConsumption" ||
                            idForBase === "ventilation"
                        ) {
                            exctractedValue =
                                getBarGraphResultsData({ resultsData: modelResults }, idForBase, "base", {})?.amount ||
                                0;
                        }

                        if (convert) {
                            exctractedValue = parseFloat(
                                parseFloat(
                                    exctractedValue.includes(",") ? exctractedValue.replace(",", "") : exctractedValue
                                ) * convertValues[convertTo]
                            ).toFixed(2);
                        }

                        if (
                            value === "heatLossReductionVSERSRef" ||
                            value === "overallEnergyPerfVSERSRef" ||
                            value === "totalUpgradeCost"
                        ) {
                            exctractedValue = "0.00";
                        }
                    }

                    tableResultsRowsWithCells[index][1] = { label: exctractedValue };
                }

                if (headerId !== "baseModel" && headerId !== "houseComponents") {
                    const result = allResults[headerId];

                    const currentRow = tableResultsRowsWithCells[index];

                    if (currentRow.some((cell) => cell.recipeId === headerId)) return;

                    tableResultsRowsWithCells[index].push({
                        label: !isNaN(parseFloat(result[value])) ? parseFloat(result[value]).toFixed(2) : result[value],
                        recipeId: headerId,
                    });
                }
            });
        }
    );

    return { tableHeaders, tabelComponentsRowsWithCells, tableResultsRowsWithCells };
};

export const isAnalysisRunCompelted = (runHistory) => {
    let isCompleted = true;

    if (isEmpty(runHistory)) return true;

    Object.entries(runHistory).forEach(([key, value]) => {
        if (isCompleted) {
            if (!value.complete) isCompleted = false;
        }
    });

    return isCompleted;
};

const getAllWallsWindowsDoors = (components, upgradeH2kType, upgradeType) => {
    let allWallsWindowsDoors = {};

    if (!components) return allWallsWindowsDoors;

    Object.entries(components).forEach(([componentKey, componentValue]) => {
        if (upgradeType === "wall" && componentKey === "wall") {
            // console.log(componentKey, upgradeType);
            allWallsWindowsDoors = { ...allWallsWindowsDoors, ...componentValue };
        }

        Object.entries(componentValue).forEach(([mainSubcomponentKey, value]) => {
            if (!value.subcomponents) return;

            Object.entries(value.subcomponents).forEach(([subcomponentKey, subcomponentValue]) => {
                if (subcomponentKey === "window" && componentKey === "ceiling" && upgradeType === "skylights")
                    allWallsWindowsDoors = { ...allWallsWindowsDoors, ...subcomponentValue };

                if (
                    upgradeH2kType === "window" &&
                    componentKey !== "ceiling" &&
                    upgradeType === "window" &&
                    (subcomponentKey === "window" || subcomponentKey === "door")
                )
                    if (subcomponentKey === "window") {
                        allWallsWindowsDoors = { ...allWallsWindowsDoors, ...subcomponentValue };

                        if (subcomponentKey === "door")
                            Object.entries(subcomponentValue).forEach(([_, doorValue]) => {
                                Object.entries(doorValue).forEach(([_, nestedDoorValue]) => {
                                    Object.entries(nestedDoorValue.subcomponents).forEach(
                                        ([nestedDoorKey, nestedDoorValue]) => {
                                            if (nestedDoorKey === "window")
                                                allWallsWindowsDoors = { ...allWallsWindowsDoors, ...nestedDoorValue };
                                        }
                                    );
                                });
                            });
                    }

                if (upgradeH2kType === "door" && subcomponentKey === "door")
                    allWallsWindowsDoors = { ...allWallsWindowsDoors, ...subcomponentValue };

                if (upgradeH2kType === "floorHeader" && subcomponentKey === "floorHeader")
                    // allWallsWindowsDoors = { ...allWallsWindowsDoors, ...subcomponentValue };
                    Object.entries(subcomponentValue).forEach(([subSubcomponentKey, subcomponentValue]) => {
                        allWallsWindowsDoors = {
                            ...allWallsWindowsDoors,
                            [subSubcomponentKey]: {
                                ...subcomponentValue,
                                pathTo: `${componentKey}.${mainSubcomponentKey}.subcomponents.${subcomponentKey}.${subSubcomponentKey}`,
                            },
                        };
                    });

                if (upgradeH2kType === "expFloor" && subcomponentKey === "expFloor")
                    // allWallsWindowsDoors = { ...allWallsWindowsDoors, ...subcomponentValue };
                    Object.entries(subcomponentValue).forEach(([subSubcomponentKey, subcomponentValue]) => {
                        allWallsWindowsDoors = {
                            ...allWallsWindowsDoors,
                            [subSubcomponentKey]: {
                                ...subcomponentValue,
                                pathTo: `${componentKey}.${mainSubcomponentKey}.subcomponents.${subcomponentKey}.${subSubcomponentKey}`,
                            },
                        };
                    });
            });
        });
    });

    return allWallsWindowsDoors;
};

export const getSelectedComponents = (upgradeType, upgradeH2kType, components) => {
    const selectedComponents = [];

    const componentsType =
        ["window", "door", "floorHeader"].includes(upgradeH2kType) || upgradeType === "window"
            ? getAllWallsWindowsDoors(components, upgradeH2kType, upgradeType)
            : components[upgradeH2kType] || {};

    if (!componentsType) return selectedComponents;

    Object.entries(componentsType).forEach(([componentKey, componentValue]) => {
        const { constructionType: { id: constructionId = 0 } = {} } = componentValue || {};

        if (upgradeH2kType === "ceiling") {
            if ([2, 3].includes(constructionId) && upgradeType === "cathedral")
                selectedComponents.push(`${upgradeH2kType}.${componentKey}`);

            if ([0, 1, 4].includes(constructionId) && upgradeType === "ceilingWithAttic")
                selectedComponents.push(`${upgradeH2kType}.${componentKey}`);
        }

        if (upgradeH2kType === "basement" || upgradeH2kType === "crawlspace")
            selectedComponents.push(`${upgradeH2kType}.${componentKey}`);

        if (upgradeH2kType === "window") selectedComponents.push(`${upgradeH2kType}.${componentKey}`);

        if (upgradeH2kType === "door") selectedComponents.push(`${upgradeH2kType}.${componentKey}`);

        if (upgradeH2kType === "wall") selectedComponents.push(`${upgradeH2kType}.${componentKey}`);

        if (upgradeH2kType === "slab") selectedComponents.push(`${upgradeH2kType}.${componentKey}`);

        if (upgradeH2kType === "expFloor") selectedComponents.push(`${upgradeH2kType}.${componentKey}`);

        if (upgradeH2kType === "floorHeader") selectedComponents.push(componentValue.pathTo);
    });

    return selectedComponents;
};
//! MOVED TO BACK END

// export const gettotalUpgradesCostOfresult = (runResult, ecmInputMap) => {
//     let totalUpgradeCost = 0;

//     const { upgradesPresent } = runResult;

//     Object.entries(upgradesPresent).forEach(([upgradeKey, upgradeValue]) => {
//         const { id: upgradeId } = upgradeValue;

//         if (ecmInputMap[upgradeKey][upgradeId]) {
//             const { packageValues: { cost = {} } = {} } = ecmInputMap[upgradeKey][upgradeId];

//             totalUpgradeCost += cost[upgradeKey]?.total || 0;
//         }
//     });

//     return totalUpgradeCost;
// };

// export const getGHGsOfResults = (runResult = {}, regionId = 0) => {
//     const houseRegion = provinceCodeMap[regionId];

//     let ghgs = {};

//     const { elecGJ = 0, ngGJ = 0, propaneGJ = 0, oilGJ = 0, woodGJ = 0, pvGJ = 0 } = runResult || {};

//     const ghgsToAdd = getNIREmissionsForParametric(elecGJ, ngGJ, propaneGJ, oilGJ, woodGJ, pvGJ, houseRegion);

//     ghgs = { ...ghgsToAdd };

//     return ghgs;
// };

// export const calculateCodeMetrics = (simulationResults) => {
//     const { generalBaseCase = {} } = simulationResults;

//     if (!generalBaseCase)
//         return {
//             overallEnergyPerfVSERSRef: 0,
//             heatLossReductionVSERSRef: 0,
//         };

//     if (!generalBaseCase.ersReference)
//         return {
//             overallEnergyPerfVSERSRef: 0,
//             heatLossReductionVSERSRef: 0,
//         };

//     const { annualEnergyConsumption: proposedAec = 0 } = getNetTotalEnergyConsumption({
//         ...generalBaseCase,
//         ersReference: {},
//     });
//     const { annualEnergyConsumption: refAec = 0 } = getNetTotalEnergyConsumption(generalBaseCase.ersReference);

//     const {
//         Annual: { HeatLoss: { _attributes: { total: proposedGrossHeatLoss } = {} } = {} } = {},
//         ersReference: { Annual: { HeatLoss: { _attributes: { total: referenceGrossHeatLoss } = {} } = {} } = {} } = {},
//     } = generalBaseCase;

//     const overallEnergyPerfVSERSRef = Number(((100 * (refAec - proposedAec)) / refAec).toFixed(2));
//     const heatLossReductionVSERSRef = Number(
//         ((100 * (referenceGrossHeatLoss - proposedGrossHeatLoss)) / referenceGrossHeatLoss).toFixed(2)
//     );

//     return {
//         overallEnergyPerfVSERSRef,
//         heatLossReductionVSERSRef,
//     };
// };

// export const extraCalculationsForResults = async (runResults, regionId, ecmInputMap, simulationResults, analysisId) => {
//     let newResults = {};

//     const runResultsKeys = Object.keys(runResults);

//     for (let i = 0; i < runResultsKeys.length; i++) {
//         const runResultKey = runResultsKeys[i];

//         const { results } = runResults[runResultKey];

//         // console.log(runResultKey, Object.keys(results).length, `Supposed to be ${runResults[runResultKey].length}`);

//         const resultsKeys = Object.keys(results);

//         for (let j = 0; j < resultsKeys.length; j++) {
//             const resultKey = resultsKeys[j];
//             const result = results[resultKey];

//             const { ghgs = null, totalUpgradeCost = null, codeMetrics = null } = result;

//             if (ghgs && totalUpgradeCost !== null && codeMetrics) continue;

//             console.log("Calculating for", runResultKey, resultKey, ghgs, totalUpgradeCost, codeMetrics);

//             const { elecGJ = 0, ngGJ = 0, propaneGJ = 0, oilGJ = 0, woodGJ = 0, pvGJ = 0 } = result;

//             const houseRegion = provinceCodeMap[regionId];

//             const ghgsToAdd = getNIREmissionsForParametric(elecGJ, ngGJ, propaneGJ, oilGJ, woodGJ, pvGJ, houseRegion);
//             const totalUpgradeCostToAdd = gettotalUpgradesCostOfresult(result, ecmInputMap);
//             const codeMetricsToAdd = calculateCodeMetrics(result, simulationResults);

//             result.ghgs = { ...ghgsToAdd };
//             result.totalUpgradeCost = totalUpgradeCostToAdd;
//             result.codeMetrics = { ...codeMetricsToAdd };

//             newResults[runResultKey] = {
//                 ...runResults[runResultKey],
//                 results: { ...results, [resultKey]: { ...result } },
//             };
//         }
//     }

//     const batch = firestore.batch();

// Object.entries(newResults).forEach(async ([key, value]) => {
//     console.log(key, value);
//     await batch.set(
//         firestore.doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`).collection("runResults").doc(key),
//         {
//             ...value,
//         },
//         { merge: true }
//     );

//     await batch
//         .commit()
//         .finally(() => console.log("success writing batch"))
//         .catch((error) => {
//             console.error("Error writing document: ", error);
//         });
// });

// await firestore
//     .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
//     .collection("runResults")
//     .get().then(res => {
// const bath = firestore.batch();
//         Object.entries(newResults).forEach(([key, value]) => {

//         });

//     })

// console.log(newResults);
// };
