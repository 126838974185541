import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isEmpty } from "lodash";

import { selectUser } from "store/users/selectors";
import { selectParametricAnalysisData } from "store/parametricAnalysis/selectors";
import { actions as userActions } from "store/users";
import { saveEcmInputMap } from "store/parametricAnalysis/thunk";

import { startParametricEcmValidation, startParametricRun } from "utils/parametric/api";
import { canRunPrecheck, canRunAnalysis } from "utils/parametric";

import parametricRunConfig from "../templates/parametricRunConfig-testSmall.json";

import ParametricAnalysisTitleStatus from "../ParametricAnalysisTitleStatus";
import ParametricAnalysisUpgradesTable from "./ParametricAnalysisUpgradesTable";
import WarningModalBeforeAnalysisRun from "./WarningModalBeforeAnalysisRun";
import Button from "components/Button";

import classes from "./styles.module.scss";

const { fetchUserCodeLib } = userActions;

const ParametricAnalysisUpgradesPage = ({
    modelDetails,
    selectedModelHasResults,
    analysisId,
    parametricDetails,
    parametricAnalysisData,
    user,
    fetchUserCodeLib,
    saveEcmInputMap,
    changeTab,
    snapParametricCredits,
    setSnapParametricCredits,
}) => {
    const [showAnalysisRunModal, setShowAnalysisRunModal] = useState(false);
    const [disablePrecheck, setDisablePrecheck] = useState(false);

    const { name: modelName } = modelDetails;
    const { model: { upgrades = {}, results: { ersReference = {} } = {} } = {}, ecmInputMap = {} } =
        parametricAnalysisData || {};

    const { lastValidationRun: { complete: isValidationRunComplete = null } = {}, runHistory = {} } =
        parametricDetails || {};

    const currentResultsSize = Object.values(runHistory).reduce((sum, curr) => sum + curr.size, 0) || 0;

    useEffect(() => {
        if (user?.uid) {
            fetchUserCodeLib(user.uid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePrecheckClick = async () => {
        //TODO: running pre-check should save the run config to the DB,
        // so the run config object doesn't necessarily need to be passed here, it can just be read in the DB
        // particularly because we want the object to be there so we can write "passed" results directly to the DB
        //
        setDisablePrecheck(true);
        if (analysisId && parametricDetails?.selectedModelId) {
            const { uid: userUid = "", email: userEmail = "" } = user || {};

            await saveEcmInputMap(analysisId);

            const results = await startParametricEcmValidation({
                analysisId,
                modelId: parametricDetails?.selectedModelId,
                // "batchID": "",
                // "checkInterval": 300,
                // "completedChunkIndexes": [],
                // "numChunks": 1,
                // "cleanUpCounter": 0,
                // "sliceLength": 500,
                // "filesRemaining": -1,
                // "user": { "uid": "", "email": "" },
                // "models": {
                //     "modelId-1": {
                //         "baseFilePassed": true,
                //         "totalFiles": 1,
                //         "randomArraySelection": []
                //     }
                // },
                // "buildType": "snap",
                // "params": {
                //     "mirrorAtticEcms": {
                //         "cathedral": false
                //     }
                // },
                parametricRunConfig: {
                    ...parametricRunConfig,
                    user: {
                        uid: userUid,
                        email: userEmail,
                    },
                    ecmInputMap,
                },
            });
        }

        setDisablePrecheck(false);
    };

    const handleParametricRunClick = async () => {
        //Implement required checks to prevent this from running prematurely

        if (analysisId && parametricDetails?.selectedModelId) {
            const { uid: userUid = "", email: userEmail = "" } = user || {};

            setShowAnalysisRunModal(false);

            const results = await startParametricRun({
                analysisId,
                modelId: parametricDetails?.selectedModelId,
                parametricRunConfig: {
                    ...parametricRunConfig,
                    user: {
                        uid: userUid,
                        email: userEmail,
                    },
                    ecmInputMap,
                },
            });

            changeTab(`/results`);
        }
    };

    const allActiveUpgradesValidated = Object.values(ecmInputMap)
        .map((upgrades) => {
            return Object.values(upgrades).every(({ passed = false, active }) => {
                return active == passed || active == false;
            });
        })
        .every((el) => el);

    return (
        <div className={classes.parametricAnalysisUpgradesPageContainer}>
            <div style={{ padding: "0 24px" }}>
                <ParametricAnalysisTitleStatus
                    modelName={modelName}
                    selectedModelHasResults={selectedModelHasResults}
                    ecmInputMap={ecmInputMap}
                    allActiveUpgradesValidated={allActiveUpgradesValidated}
                    sideContent={
                        <>
                            <Button
                                disabled={
                                    disablePrecheck ||
                                    !selectedModelHasResults ||
                                    Object.values(ecmInputMap).every(
                                        (upgradeToCheck) => Object.keys(upgradeToCheck).length === 1
                                    ) ||
                                    !canRunPrecheck(ecmInputMap) ||
                                    (!isValidationRunComplete && isValidationRunComplete !== null)
                                }
                                type="hollow"
                                onClick={handlePrecheckClick}
                            >
                                Validate Upgrades
                            </Button>
                            <Button
                                disabled={
                                    !canRunAnalysis(ecmInputMap) ||
                                    !Object.keys(runHistory).every((historyKey) => runHistory[historyKey].complete) ||
                                    !isValidationRunComplete
                                }
                                onClick={() => setShowAnalysisRunModal(true)}
                            >
                                Run Analysis
                            </Button>
                        </>
                    }
                />
            </div>
            <ParametricAnalysisUpgradesTable
                upgrades={upgrades}
                ecmInputMap={ecmInputMap}
                isValidationRunComplete={isValidationRunComplete}
                analysisId={analysisId}
            />
            <WarningModalBeforeAnalysisRun
                openDialog={showAnalysisRunModal}
                onClose={() => setShowAnalysisRunModal(false)}
                handleParametricRunClick={handleParametricRunClick}
                hasCodeMetrics={!isEmpty(ersReference)}
                ecmInputMap={ecmInputMap}
                setSnapParametricCredits={setSnapParametricCredits}
                snapParametricCredits={snapParametricCredits}
                currentResultsSize={currentResultsSize}
            />
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    parametricAnalysisData: selectParametricAnalysisData,
    user: selectUser,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserCodeLib: (uid) => dispatch(fetchUserCodeLib(uid)),
    saveEcmInputMap: (id) => dispatch(saveEcmInputMap(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParametricAnalysisUpgradesPage);
