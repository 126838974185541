import React, { useCallback } from "react";
import classes from "./style.module.scss";
import globalStyles from "components/globalStyles.module.scss";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import InputWithUnits from "components/Input/InputWithUnits";
import { required, requiredCodeRef, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import Select from "components/Input/Select";
import { useEffect } from "react";
import Add from "assets/images/icons/JSX/Add";
import Book from "assets/images/icons/JSX/Book";
import Edit from "assets/images/icons/JSX/Edit";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import Tooltip from "components/Tooltip";
import { cond, isEmpty, isEqual } from "lodash";

const wallTypeValidation = [required, ...getValidation("wallInsType")];
const lintelTypeValidation = getValidation("wallLintelInsType");

export const CodeFooter = ({ toggleDrawer, setInitCode, codeType }) => (
    <div
        className={classes.codeFooter}
        onClick={async () => {
            await setInitCode({}, codeType);
            toggleDrawer(true);
        }}
    >
        <span>Create New Code</span>
        <Add />
    </div>
);

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

const LabelActionComponent = ({ codeRef, handleEdit, codeType }) => (
    <>
        <div
            className={globalStyles.editCode}
            data-tip="Edit Code"
            data-for={codeRef}
            onClick={(event) => handleEdit(event, codeRef, codeType)}
        >
            <Edit />
            <Tooltip id={codeRef} className={globalStyles.fieldTooltip} place="left" />
        </div>
    </>
);

// Wall Ins. Type codes found in code library
export const wallTypeLibCodesMap = (libCodes, handleEditLibWallCode) =>
    Object.keys(libCodes)
        .map((codeRef) => {
            const { label = "" } = libCodes[codeRef] || {};
            const [standardCode] = codeRef.split("Wall-U");

            return {
                label: label,
                labelIcon: Book,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () =>
                    handleEditLibWallCode ? (
                        <LabelActionComponent
                            codeRef={codeRef}
                            handleEdit={handleEditLibWallCode}
                            codeType={standardCode ? "S" : "U"}
                        />
                    ) : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Wall Ins. Type codes found in model, filtered by those in library
export const wallTypeModelCodesMap = (modelCodes, libCodes, handleEditModelWallCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCodes[codeRef]) {
                return (
                    (!libCodes[codeRef] && component === "Wall") ||
                    !isEqual(modelCodes[codeRef].layers, libCodes[codeRef].layers)
                );
            }
            return !libCodes[codeRef] && component === "Wall";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("Wall-U");

            return {
                label,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () =>
                    handleEditModelWallCode ? (
                        <LabelActionComponent
                            codeRef={codeRef}
                            handleEdit={handleEditModelWallCode}
                            codeType={standardCode ? "S" : "U"}
                        />
                    ) : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Lintel Type codes found in code library
const lintelTypeLibCodesMap = (libLintelCodes, handleEditLibLintelCode) =>
    Object.keys(libLintelCodes)
        .map((codeRef) => {
            const { label = "" } = libLintelCodes[codeRef] || {};
            const [standardCode] = codeRef.split("Lintel-U");

            return {
                label: label,
                labelIcon: Book,
                labelActionComponent: standardCode
                    ? () => <LabelActionComponent codeRef={codeRef} handleEdit={handleEditLibLintelCode} />
                    : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Lintel Type codes found in model, filtered by those in library
const lintelTypeModelCodesMap = (modelCodes, libCodes, handleEditModelLintelCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCodes[codeRef]) {
                return (
                    (!libCodes[codeRef] && component === "Lintel") ||
                    !isEqual(modelCodes[codeRef].layers, libCodes[codeRef].layers)
                );
            }
            return !libCodes[codeRef] && component === "Lintel";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("Lintel-U");

            return {
                label,
                labelActionComponent: standardCode
                    ? () => <LabelActionComponent codeRef={codeRef} handleEdit={handleEditModelLintelCode} />
                    : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

export default React.memo(
    ({
        accessor,
        selectedCodeRef,
        selectedLintelCodeRef,
        change,
        modelChange = change,
        id,
        modelUnits,
        rValUnits,
        libCodes,
        libLintelCodes,
        modelCodes,
        selectedNomRValue,
        setInitCode,
        wallCodeWarning,
        isCalculatingRValue,
        updateCodeDrawer,
        disabledCodeEdit = false,
        isErsAndUSpec = false,
        canBeEditedFromParametric = true,
    }) => {
        const handleTypeChange = useCallback(
            ({ codeRef = "" }) => {
                if (codeRef === "UserSpecified") {
                    change(`${accessor}.wallInsType_nomRVal`, selectedNomRValue || 0.1);
                    change(`${accessor}.wallInsType_effRVal`, selectedNomRValue || 0.1);
                    change(`${accessor}.wallInsType_warning`, "");
                    return;
                }

                const modelMatch = modelCodes[codeRef] || {};
                if (!isEmpty(modelMatch)) {
                    change(`${accessor}.wallInsType_nomRVal`, modelMatch.nominalRValue || 0.1);
                    change(`${accessor}.wallInsType_effRVal`, modelMatch.nominalRValue || 0.1);
                    change(`${accessor}.wallInsType_warning`, modelMatch.warningType || "");
                } else {
                    const { nominalRValue = 0, warningType = "" } = libCodes[codeRef] || {};
                    change(`${accessor}.wallInsType_nomRVal`, nominalRValue || 0.1);
                    change(`${accessor}.wallInsType_effRVal`, nominalRValue || 0.1);
                    change(`${accessor}.wallInsType_warning`, warningType || "");

                    modelChange(`modelData.codes.${codeRef}`, { ...libCodes[codeRef], codeRef });
                }

                modelChange(`stashedCodes.Wall.${accessor.split(".").slice(-1)[0]}`, codeRef);
            },
            [libCodes, modelCodes, change, modelChange, accessor]
        );

        const handleLintelTypeChange = useCallback(
            ({ codeRef = "" }) => {
                if (codeRef === "UserSpecified") {
                    return;
                }

                const modelMatch = modelCodes[codeRef] || {};
                if (isEmpty(modelMatch)) {
                    modelChange(`modelData.codes.${codeRef}`, { ...libLintelCodes[codeRef], codeRef });
                }

                modelChange(`stashedCodes.Lintel.${accessor.split(".").slice(-1)[0]}`, codeRef);
            },
            [change, modelChange, accessor, modelCodes, libLintelCodes]
        );

        // useEffect(() => {
        //     if ((selectedCodeRef || "").includes("Wall-")) {
        //         modelChange(`stashedCodes.Wall.${id}`, selectedCodeRef);
        //     }
        // }, [id, selectedCodeRef]);

        // useEffect(() => {
        //     if ((selectedLintelCodeRef || "").includes("Lintel-")) {
        //         modelChange(`stashedCodes.Lintel.${id}`, selectedLintelCodeRef);
        //     }
        // }, [id, selectedLintelCodeRef]);

        const handleEditModelWallCode = useCallback(
            async (event, codeRef, codeType) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const initCodeType = codeType === "U" ? "udefStandard" : "wallInsType";
                const code = modelCodes[codeRef];
                await setInitCode(code, initCodeType);
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "Wall",
                    isEditing: true,
                    componentType: "Wall",
                    codeType: codeType,
                    fieldAccessor: `${accessor}.wallInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibWallCode = disabledCodeEdit
            ? null
            : useCallback(
                  async (event, codeRef, codeType) => {
                      event.stopPropagation(); //Don't let click event bubble up to parent
                      const initCodeType = codeType === "U" ? "udefStandard" : "wallInsType";
                      const code = {
                          ...libCodes[codeRef],
                          codeRef: codeRef,
                          isLibCode: true,
                      };
                      await setInitCode(code, initCodeType);
                      updateCodeDrawer({
                          isOpen: true,
                          codeName: "Wall",
                          isEditing: true,
                          componentType: "Wall",
                          codeType: codeType,
                          fieldAccessor: `${accessor}.wallInsType`,
                          componentId: id,
                          id: codeRef,
                          modelFormChange: modelChange,
                          currentFormChange: change,
                          lastEdited: code.lastEdited || "",
                      });
                  },
                  [libCodes, setInitCode, updateCodeDrawer, accessor, id, change, modelChange]
              );

        const handleEditModelLintelCode = useCallback(
            async (event, codeRef) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const code = modelCodes[codeRef];
                await setInitCode(code, "lintelInsType");
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "Lintel",
                    isEditing: true,
                    componentType: "Lintel",
                    codeType: "S",
                    fieldAccessor: `${accessor}.lintelInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibLintelCode = useCallback(
            async (event, codeRef) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const code = {
                    ...libLintelCodes[codeRef],
                    codeRef: codeRef,
                    isLibCode: true,
                };
                await setInitCode(code, "lintelInsType");
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "Lintel",
                    isEditing: true,
                    componentType: "Lintel",
                    codeType: "S",
                    fieldAccessor: `${accessor}.lintelInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, libLintelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        return (
            <>
                <InputRow gridTemplate="1fr 1fr 1fr">
                    <Field
                        component={Select}
                        type="number"
                        name={`${accessor}.wallInsType`}
                        options={[
                            {
                                label: "User Specified",
                                value: {
                                    codeLabel: "User Specified",
                                    codeRef: "UserSpecified",
                                },
                            },
                            ...wallTypeModelCodesMap(modelCodes, libCodes, handleEditModelWallCode),
                            ...wallTypeLibCodesMap(libCodes, handleEditLibWallCode),
                        ]}
                        label="Wall Type"
                        placeholder="Choose Wall Type"
                        searchPlaceholder="Search Wall Codes"
                        search={true}
                        footer={() => (
                            <CodeFooter
                                toggleDrawer={(value) =>
                                    updateCodeDrawer({
                                        isOpen: value,
                                        codeName: "Wall",
                                        isEditing: false,
                                        componentType: "Wall",
                                        codeType: "S",
                                        fieldAccessor: `${accessor}.wallInsType`,
                                        componentId: id,
                                        modelFormChange: modelChange,
                                        currentFormChange: change,
                                    })
                                }
                                setInitCode={setInitCode}
                                codeType="wallInsType"
                            />
                        )}
                        className={classes.wallType}
                        isLoading={isCalculatingRValue}
                        onChange={handleTypeChange}
                        validate={requiredCodeRef}
                        disabled={!canBeEditedFromParametric}
                    />
                    {selectedCodeRef === "UserSpecified" ? (
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.wallInsType_effRVal`}
                            label={"Effective R-Value"}
                            placeholder={0.0}
                            disabled={selectedCodeRef !== "UserSpecified" || !canBeEditedFromParametric}
                            validate={wallTypeValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("wallInsType")}
                            change={change}
                            units={{
                                base: getBaseUnits("wallInsType", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: rValUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.wallInsType_u`,
                            }}
                            onChange={(value) => {
                                if (selectedCodeRef === "UserSpecified") {
                                    change(`${accessor}.wallInsType_nomRVal`, value);
                                }
                            }}
                            info={"User specified values are assumed to be effective R-values."}
                            warning={isErsAndUSpec ? "ersUSpecRVal" : ""}
                        />
                    ) : (
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.wallInsType_nomRVal`}
                            label={"Nominal R-Value"}
                            placeholder={0.0}
                            disabled={true}
                            validate={wallTypeValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("wallInsType")}
                            change={change}
                            setValue={selectedNomRValue || 0.1}
                            units={{
                                base: getBaseUnits("wallInsType", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: rValUnits,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.wallInsType_u`,
                            }}
                            info={
                                "Values calculated by the Code Selector are nominal R-values. HOT2000 re-calculates R-values based on the content of the code when running a simulation."
                            }
                            warning={wallCodeWarning}
                            isLoading={isCalculatingRValue}
                        />
                    )}
                    <Field
                        component={Select}
                        type="number"
                        name={`${accessor}.lintelInsType`}
                        options={[
                            {
                                label: "N/A",
                                value: {},
                            },
                            ...lintelTypeModelCodesMap(modelCodes, libLintelCodes, handleEditModelLintelCode),
                            ...lintelTypeLibCodesMap(libLintelCodes, handleEditLibLintelCode),
                        ]}
                        label="Lintel Type"
                        placeholder="Choose Lintel Type"
                        searchPlaceholder="Search Lintel Codes"
                        search={true}
                        footer={() => (
                            <CodeFooter
                                toggleDrawer={(value) =>
                                    updateCodeDrawer({
                                        isOpen: value,
                                        codeName: "Lintel",
                                        isEditing: false,
                                        componentType: "Lintel",
                                        codeType: "S",
                                        fieldAccessor: `${accessor}.lintelInsType`,
                                        componentId: id,
                                        modelFormChange: modelChange,
                                        currentFormChange: change,
                                    })
                                }
                                setInitCode={setInitCode}
                                codeType="lintelInsType"
                            />
                        )}
                        onChange={handleLintelTypeChange}
                        className={classes.lintelType}
                        info={"If unknown, use code label 100"}
                        disabled={!canBeEditedFromParametric}
                        // validate={requiredCodeRef}
                    />
                </InputRow>
            </>
        );
    }
);
