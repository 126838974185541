import React from "react";
import classes from "../style.module.scss";
import { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import Dialog from "components/Dialog";
import Input from "components/Input";
import { isEmpty } from "lodash";

const dialogText = {
    model: {
        title: "Duplicate Model",
        label: "Model Name",
        placeholder: "Type model name...",
    },
    community: {
        title: "Duplicate Community",
        label: "Community Name",
        placeholder: "Type community name...",
    },
    analysis: {
        title: "Duplicate Parametric Analysis",
        label: "Parametric Analysis Name",
        placeholder: "Type parametric analysis name...",
    },
};

const DuplicateDialog = ({
    itemType,
    openDialog,
    newDuplicateName,
    setDuplicateName,
    onClick,
    onClose,
    isLoading,
}) => {
    const text = dialogText[itemType.toLowerCase()];

    return (
        <Dialog open={!!openDialog}>
            <CloseDialog closeActions={() => onClose()} />
            <div className={classes.dialog}>
                <h2> {text.title} </h2>
                <Input
                    label={text.label}
                    placeholder={text.placeholder}
                    disabled={isLoading}
                    input={{
                        value: newDuplicateName,
                        onChange: (value) => setDuplicateName(value),
                    }}
                />
                <div className={classes.footer}>
                    <Button type="hollow" onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button onClick={() => onClick()} type="" disabled={isLoading || isEmpty(newDuplicateName)}>
                        {isLoading ? "Duplicating..." : "Duplicate"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DuplicateDialog;