import React, { useState, useEffect, useCallback } from "react";
import classes from "./style.module.scss";
import globalStyles from "components/globalStyles.module.scss";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import InputWithUnits from "components/Input/InputWithUnits";
import { required, requiredCodeRef, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import Select from "components/Input/Select";
import Add from "assets/images/icons/JSX/Add";
import Book from "assets/images/icons/JSX/Book";
import Edit from "assets/images/icons/JSX/Edit";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import Tooltip from "components/Tooltip";
import { isEmpty, isEqual } from "lodash";

const crawlspaceWallTypeValidation = [required, ...getValidation("crawlspaceWallType")];
const lintelTypeValidation = getValidation("wallLintelInsType");

export const CodeFooter = ({ toggleDrawer, setInitCode, codeType }) => (
    <div
        className={classes.codeFooter}
        onClick={async () => {
            await setInitCode({}, codeType);
            toggleDrawer(true);
        }}
    >
        <span>Create New Code</span>
        <Add />
    </div>
);

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

const LabelActionComponent = ({ codeRef, handleEdit, codeType }) => (
    <>
        <div
            className={globalStyles.editCode}
            data-tip="Edit Code"
            data-for={codeRef}
            onClick={(event) => handleEdit(event, codeRef, codeType)}
        >
            <Edit />
            <Tooltip id={codeRef} className={globalStyles.fieldTooltip} place="left" />
        </div>
    </>
);

// CrawlspaceWall Ins. Type codes found in code library
export const crawlspaceWallTypeLibCodesMap = (libCodes, handleEditLibCrawlspaceWallCode) =>
    Object.keys(libCodes)
        .map((codeRef) => {
            const { label = "" } = libCodes[codeRef] || {};
            const [standardCode] = codeRef.split("CrawlspaceWall-U");

            return {
                label: label,
                labelIcon: Book,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () =>
                    handleEditLibCrawlspaceWallCode ? (
                        <LabelActionComponent
                            codeRef={codeRef}
                            handleEdit={handleEditLibCrawlspaceWallCode}
                            codeType={standardCode ? "S" : "U"}
                        />
                    ) : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// CrawlspaceWall Ins. Type codes found in model, filtered by those in library
export const crawlspaceWallTypeModelCodesMap = (modelCodes, libCodes, handleEditModelCrawlspaceWallCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCodes[codeRef]) {
                return (
                    (!libCodes[codeRef] && component === "CrawlspaceWall") ||
                    !isEqual(modelCodes[codeRef].layers, libCodes[codeRef].layers)
                );
            }
            return !libCodes[codeRef] && component === "CrawlspaceWall";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("CrawlspaceWall-U");

            return {
                label,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () => (
                    <LabelActionComponent
                        codeRef={codeRef}
                        handleEdit={handleEditModelCrawlspaceWallCode}
                        codeType={standardCode ? "S" : "U"}
                    />
                ),
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Lintel Type codes found in code library
const lintelTypeLibCodesMap = (libLintelCodes, handleEditLibLintelCode) =>
    Object.keys(libLintelCodes)
        .map((codeRef) => {
            const { label = "" } = libLintelCodes[codeRef] || {};
            const [standardCode] = codeRef.split("Lintel-U");

            return {
                label: label,
                labelIcon: Book,
                labelActionComponent:
                    standardCode && handleEditLibLintelCode
                        ? () => <LabelActionComponent codeRef={codeRef} handleEdit={handleEditLibLintelCode} />
                        : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Lintel Type codes found in model, filtered by those in library
const lintelTypeModelCodesMap = (modelCodes, libCodes, handleEditModelLintelCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCodes[codeRef]) {
                return (
                    (!libCodes[codeRef] && component === "Lintel") ||
                    !isEqual(modelCodes[codeRef].layers, libCodes[codeRef].layers)
                );
            }
            return !libCodes[codeRef] && component === "Lintel";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("Lintel-U");

            return {
                label,
                labelActionComponent: standardCode
                    ? () => <LabelActionComponent codeRef={codeRef} handleEdit={handleEditModelLintelCode} />
                    : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

export default React.memo(
    ({
        accessor,
        selectedCodeRef,
        selectedLintelCodeRef,
        change,
        modelChange = change,
        id,
        modelUnits,
        wallRValUnits,
        libCodes,
        libLintelCodes,
        modelCodes,
        selectedNomRValue,
        selectedEffRValue,
        compositeEffRValue,
        setInitCode,
        crawlWallCodeWarning,
        isCalculatingRValue,
        updateCodeDrawer,
        updateCompositeCalc,
        disabledCodeEdit = false,
        isUpgrade = false,
        canBeEditedFromParametric = true,
    }) => {
        const handleTypeChange = useCallback(
            ({ codeRef = "" }) => {
                change(`${accessor}.wall.crawlspaceWallInsType_composite`, {}); // TODO

                if (codeRef === "UserSpecified") {
                    change(`${accessor}.wall.crawlspaceWallInsType_warning`, "");
                    change(`${accessor}.wall.crawlspaceWallInsType_nomRVal`, selectedNomRValue || 0.1);
                    change(`${accessor}.wall.crawlspaceWallInsType_effRVal`, selectedNomRValue || 0.1);
                    change(`${accessor}.wall.intAddedInsType_composite`, {});
                    return;
                }

                const modelMatch = modelCodes[codeRef] || {};
                if (!isEmpty(modelMatch)) {
                    change(`${accessor}.wall.crawlspaceWallInsType_nomRVal`, modelMatch.nominalRValue || 0.1);
                    change(`${accessor}.wall.crawlspaceWallInsType_effRVal`, modelMatch.nominalRValue || 0.1);
                    change(`${accessor}.wall.crawlspaceWallInsType_warning`, modelMatch.warningType || "");
                } else {
                    const { nominalRValue = 0 } = libCodes[codeRef] || {};
                    change(`${accessor}.wall.crawlspaceWallInsType_nomRVal`, nominalRValue || 0.1);
                    change(`${accessor}.wall.crawlspaceWallInsType_effRVal`, nominalRValue || 0.1);
                    change(`${accessor}.wall.crawlspaceWallInsType_warning`, modelMatch.warningType || "");
                    modelChange(`modelData.codes.${codeRef}`, { ...libCodes[codeRef], codeRef });
                }
                //Redundancy
                modelChange(`stashedCodes.CrawlspaceWall.${accessor.split(".").slice(-1)[0]}`, codeRef);
            },
            [libCodes, modelCodes, change, modelChange, accessor]
        );

        const handleLintelTypeChange = useCallback(
            ({ codeRef = "" }) => {
                if (codeRef === "UserSpecified") {
                    return;
                }

                const modelMatch = modelCodes[codeRef] || {};
                if (isEmpty(modelMatch)) {
                    modelChange(`modelData.codes.${codeRef}`, { ...libLintelCodes[codeRef], codeRef });
                }

                modelChange(`stashedCodes.Lintel.${accessor.split(".").slice(-1)[0]}`, codeRef);
            },
            [change, modelChange, accessor, modelCodes, libLintelCodes]
        );

        // useEffect(() => {
        //     if ((selectedCodeRef || "").includes("CrawlspaceWall-")) {
        //         modelChange(`stashedCodes.CrawlspaceWall.${id}`, selectedCodeRef);
        //     }
        // }, [id, selectedCodeRef]);

        // useEffect(() => {
        //     if ((selectedLintelCodeRef || "").includes("Lintel-")) {
        //         modelChange(`stashedCodes.Lintel.${id}`, selectedLintelCodeRef);
        //     }
        // }, [id, selectedLintelCodeRef]);

        const handleEditModelCrawlspaceWallCode = useCallback(
            async (event, codeRef, codeType) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const initCodeType = codeType === "U" ? "udefStandard" : "crawlspaceWallInsType";
                const code = modelCodes[codeRef];
                await setInitCode(code, initCodeType);
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "Crawlspace Wall",
                    isEditing: true,
                    componentType: "CrawlspaceWall",
                    codeType: codeType,
                    fieldAccessor: `${accessor}.wall.crawlspaceWallInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibCrawlspaceWallCode = disabledCodeEdit
            ? null
            : useCallback(
                  async (event, codeRef, codeType) => {
                      event.stopPropagation(); //Don't let click event bubble up to parent
                      const initCodeType = codeType === "U" ? "udefStandard" : "crawlspaceWallInsType";
                      const code = { ...libCodes[codeRef], codeRef: codeRef, isLibCode: true };
                      await setInitCode(code, initCodeType);
                      updateCodeDrawer({
                          isOpen: true,
                          codeName: "Crawlspace Wall",
                          isEditing: true,
                          componentType: "CrawlspaceWall",
                          codeType: codeType,
                          fieldAccessor: `${accessor}.wall.crawlspaceWallInsType`,
                          componentId: id,
                          id: codeRef,
                          modelFormChange: modelChange,
                          currentFormChange: change,
                          lastEdited: code.lastEdited || "",
                      });
                  },
                  [accessor, id, libCodes, setInitCode, updateCodeDrawer, change, modelChange]
              );

        const handleEditModelLintelCode = useCallback(
            async (event, codeRef) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const code = modelCodes[codeRef];
                await setInitCode(code, "lintelInsType");
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "Lintel",
                    isEditing: true,
                    componentType: "Lintel",
                    codeType: "S",
                    fieldAccessor: `${accessor}.wall.lintelInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibLintelCode = disabledCodeEdit
            ? null
            : useCallback(
                  async (event, codeRef) => {
                      event.stopPropagation(); //Don't let click event bubble up to parent
                      const code = { ...libLintelCodes[codeRef], codeRef: codeRef, isLibCode: true };
                      await setInitCode(code, "lintelInsType");
                      updateCodeDrawer({
                          isOpen: true,
                          codeName: "Lintel",
                          isEditing: true,
                          componentType: "Lintel",
                          codeType: "S",
                          fieldAccessor: `${accessor}.wall.lintelInsType`,
                          componentId: id,
                          id: codeRef,
                          modelFormChange: modelChange,
                          currentFormChange: change,
                          lastEdited: code.lastEdited || "",
                      });
                  },
                  [accessor, id, libLintelCodes, setInitCode, updateCodeDrawer, change, modelChange]
              );

        return (
            <>
                <InputRow gridTemplate={isUpgrade ? "2fr 1fr 2fr" : "31.85% 23.25% 31.85%"}>
                    <Field
                        component={Select}
                        type="number"
                        name={`${accessor}.wall.crawlspaceWallInsType`}
                        options={[
                            {
                                label: "User Specified",
                                value: {
                                    codeLabel: "User Specified",
                                    codeRef: "UserSpecified",
                                },
                            },
                            ...crawlspaceWallTypeModelCodesMap(modelCodes, libCodes, handleEditModelCrawlspaceWallCode),
                            ...crawlspaceWallTypeLibCodesMap(libCodes, handleEditLibCrawlspaceWallCode),
                        ]}
                        label="Crawlspace Wall Insulation"
                        placeholder="Choose Insulation Type"
                        searchPlaceholder="Search Crawlspace Wall Codes"
                        search={true}
                        footer={() => (
                            <CodeFooter
                                toggleDrawer={(value) =>
                                    updateCodeDrawer({
                                        isOpen: value,
                                        codeName: "Crawlspace Wall",
                                        isEditing: false,
                                        componentType: "CrawlspaceWall",
                                        codeType: "S",
                                        fieldAccessor: `${accessor}.wall.crawlspaceWallInsType`,
                                        componentId: id,
                                        modelFormChange: modelChange,
                                        currentFormChange: change,
                                    })
                                }
                                setInitCode={setInitCode}
                                codeType="crawlspaceWallInsType"
                            />
                        )}
                        className={classes.crawlspaceWallType}
                        isLoading={isCalculatingRValue}
                        onChange={handleTypeChange}
                        validate={requiredCodeRef}
                        disabled={!canBeEditedFromParametric}
                    />
                    <div className={classes.insulationCalcWrapper}>
                        {selectedCodeRef === "UserSpecified" ? (
                            <Field
                                component={InputWithUnits}
                                type="number"
                                name={`${accessor}.wall.crawlspaceWallInsType_effRVal`}
                                label={"Effective R-Value"}
                                placeholder={0.0}
                                disabled={selectedCodeRef !== "UserSpecified" || !canBeEditedFromParametric}
                                setValue={selectedEffRValue}
                                validate={crawlspaceWallTypeValidation}
                                setTouched={true}
                                decimals={getDecimalPlaces("crawlspaceWallType")}
                                change={change}
                                units={{
                                    base: getBaseUnits("crawlspaceWallType", modelUnits),
                                    options: getUnitOptions("thermalResistance"),
                                    selected: wallRValUnits,
                                    unitType: "thermalResistance",
                                    accessor: `${accessor}.wall.crawlspaceWallInsType_u`,
                                }}
                                onChange={(value) => {
                                    if (selectedCodeRef === "UserSpecified") {
                                        change(`${accessor}.wall.crawlspaceWallInsType_nomRVal`, value || 0.1);
                                    }
                                    if (value !== compositeEffRValue) {
                                        change(`${accessor}.wall.crawlspaceWallInsType_composite`, {});
                                    }
                                }}
                                // info={"User specified values are assumed to be effective R-values."}
                                editToggle={() =>
                                    updateCompositeCalc({
                                        open: true,
                                        fieldId: "crawlspaceWallType",
                                        fieldAccessor: `${accessor}.wall.crawlspaceWallInsType`,
                                        rValUnits: wallRValUnits,
                                    })
                                }
                            />
                        ) : (
                            <Field
                                component={InputWithUnits}
                                type="number"
                                name={`${accessor}.wall.crawlspaceWallInsType_nomRVal`}
                                label={"Nominal R-Value"}
                                placeholder={0.0}
                                disabled={true}
                                validate={crawlspaceWallTypeValidation}
                                setTouched={true}
                                decimals={getDecimalPlaces("crawlspaceWallType")}
                                change={change}
                                setValue={selectedNomRValue || 0.1}
                                units={{
                                    base: getBaseUnits("crawlspaceWallType", modelUnits),
                                    options: getUnitOptions("thermalResistance"),
                                    selected: wallRValUnits,
                                    unitType: "thermalResistance",
                                    accessor: `${accessor}.wall.crawlspaceWallInsType_u`,
                                }}
                                info={
                                    "Values calculated by the Code Selector are nominal R-values. HOT2000 re-calculates R-values based on the content of the code when running a simulation."
                                }
                                warning={crawlWallCodeWarning}
                                isLoading={isCalculatingRValue}
                            />
                        )}
                    </div>
                    <Field
                        component={Select}
                        type="number"
                        name={`${accessor}.wall.lintelInsType`}
                        options={[
                            {
                                label: "N/A",
                                value: {},
                            },
                            ...lintelTypeModelCodesMap(modelCodes, libLintelCodes, handleEditModelLintelCode),
                            ...lintelTypeLibCodesMap(libLintelCodes, handleEditLibLintelCode),
                        ]}
                        label="Lintel Type"
                        placeholder="Choose Lintel Type"
                        searchPlaceholder="Search Lintel Codes"
                        search={true}
                        validate={lintelTypeValidation}
                        footer={() => (
                            <CodeFooter
                                toggleDrawer={(value) =>
                                    updateCodeDrawer({
                                        isOpen: value,
                                        codeName: "Lintel",
                                        isEditing: false,
                                        componentType: "Lintel",
                                        codeType: "S",
                                        fieldAccessor: `${accessor}.lintelInsType`,
                                        componentId: id,
                                        modelFormChange: modelChange,
                                        currentFormChange: change,
                                    })
                                }
                                setInitCode={setInitCode}
                                codeType="crawlspaceLintelInsType"
                            />
                        )}
                        onChange={handleLintelTypeChange}
                        className={classes.lintelType}
                        disabled={!canBeEditedFromParametric}
                    />
                </InputRow>
            </>
        );
    }
);
