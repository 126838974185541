import React, { useState } from "react";
import classes from "../../style.module.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import DashboardItem from "..";
import { RemoveDialog, RemoveMultipleFilesDialog } from "components/Dashboard/Actions";
// import DirectionDialog from "features/Community/CustomModels/DirectionDialog";
import { selectCommunityModelDir } from "features/Community/_ducks/selectors";
import { actions as communityActions } from "features/Community/_ducks";

const { removeCommModel, batchRemoveCommModels } = communityActions;

const CommModel = ({
    ref,
    modelId,

    tags = [], // none
    sharedWithUser = false,
    isRenoCommModel,
    isSelected,
    isDragging,
    isDropInProcess,
    multiSelectedItems,
    setMultiSelectedItems,
    batchRemoveItems,
    disableActions = false,
    details, // { userName, provTerr, currentPhase, lastEdited, targetCompletionDate }
    
    commModelDir,
    removeCommModel,
    ...props
}) => {
    const [startRemove, toggleStartRemove] = useState(false);
    const [isRemoving, toggleRemoving] = useState(false);
    const [modelsToRemove, setModelsToRemove] = useState(false);
    const [isMultiRemoving, toggleIsMultiRemoving] = useState(false);

    const [isCountDialogOpen, setIsCountDialogOpen] = useState(false);

    const handleMultipleRemove = async () => {
        let itemsBatchToRemove = [];

        modelsToRemove.forEach(async (modelId) => {
            itemsBatchToRemove.push({ 
                modelId: modelId,
                regionCode: commModelDir.models[modelId].location.regionCode
            });
        });
        await batchRemoveItems(itemsBatchToRemove);
    };

    return (
        <>
            <DashboardItem
                ref={ref}
                className={classNames({
                    [classes.selectedItem]: isSelected,
                    [classes.itemDragging]: isDragging && isSelected,
                    [classes.isDropInProcess]: isDropInProcess
                })}
                itemId={modelId}
                sharedWithUser={sharedWithUser}
                itemType={isRenoCommModel ? "CHBAModel" : "model"} // keep?
                details={details}
                tags={tags}
                actions={disableActions ? null : [
                    {
                        label: "Remove Model",
                        onClick: () => {
                            toggleStartRemove(true);
                        },
                    },
                    {
                        label: `Remove ${multiSelectedItems.length} Models`,
                        shouldShow: multiSelectedItems.length > 1,
                        onClick: () => {
                            setModelsToRemove(multiSelectedItems);
                        },
                    },
                ]}
                setIsCountDialogOpen={setIsCountDialogOpen}
                {...props}
            />  
            <RemoveDialog
                item={commModelDir.models[modelId]}
                startRemove={startRemove}
                isRemoving={isRemoving}
                onClick={async () => {
                    toggleRemoving(true);
                    const regionCode = commModelDir.models[modelId].location.regionCode;
                    await removeCommModel(modelId, regionCode);
                }}
                onClose={() => toggleStartRemove(false)}
            />
            <RemoveMultipleFilesDialog
                filesToRemove={modelsToRemove}
                startRemove={modelsToRemove.length > 1}
                isRemoving={isMultiRemoving}
                onClick={async () => {
                    toggleIsMultiRemoving(true);
                    await handleMultipleRemove();
                }}
                onClose={() => setModelsToRemove([])}
            />
            {/* <DirectionDialog
                isDialogOpen={isCountDialogOpen}
                closeDialog={() => setIsCountDialogOpen(false)}
                name={details["name"]}
                modelId={modelId}
                directions={details.countDirection}
            /> */}
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    commModelDir: selectCommunityModelDir,
});

const mapDispatchToProps = (dispatch, { history }) => ({
    removeCommModel: async (modelId, regionCode) => {
        dispatch(removeCommModel(modelId, regionCode));
    },
    batchRemoveItems: async (modelsToRemoveIds) => {
        dispatch(batchRemoveCommModels(modelsToRemoveIds));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CommModel);