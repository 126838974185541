import { DashboardAPI } from "utils/api";

export const importH2kFile = async (formData, data = {}) => {
    const { name = "", notes = "", templateID = "", templateName = "", parentFolderId = "" } = data;

    return DashboardAPI({
        method: "POST",
        url: "/createHouseModelFromFile",
        file: formData,
        headers: {
            "content-type": "multipart/form-data",
        },
    }).catch((err) => console.log(err));
};

export const importCodeLibFile = async (formData) => {
    return DashboardAPI({
        method: "POST",
        url: "/importCodeLibFromFile",
        file: formData,
        headers: {
            "content-type": "multipart/form-data",
        },
    }).catch((err) => console.log(err));
};

export const exportH2kFile = async ({
    modelId,
    returnType,
    h2kVersion,
    upgradePackage,
    appendResults,
    applyUpgradesToBase = false,
}) => {
    return DashboardAPI({
        method: "POST",
        url: "/getHouseModel",
        data: {
            id: modelId,
            returnType: returnType ? returnType : "XML",
            h2kVersion: h2kVersion ? h2kVersion : "v11-9",
            upgradePackage: upgradePackage ? upgradePackage : "",
            appendResults: appendResults ? appendResults : false,
            applyUpgradesToBase,
        },
    })
        .then((data) => data.data)
        .catch((err) => console.log(err));
};

export const exportH2kFileWithResults = async ({ modelId, userId, packages = [], applyUpgradesToBase = false }) => {
    return DashboardAPI({
        method: "POST",
        url: "/getHouseModelWithResults",
        data: {
            modelId,
            userId,
            packages,
            applyUpgradesToBase,
        },
    })
        .then((data) => data.data)
        .catch((err) => console.log(err));
};

export const validateH2kExport = async ({ modelId, h2kVersion, upgradePackage, isSimulation = false }) => {
    return DashboardAPI({
        method: "POST",
        url: "/validateH2kExport",
        data: {
            id: modelId,
            h2kVersion: h2kVersion ? h2kVersion : "v11-9",
            upgradePackage: upgradePackage ? upgradePackage : "",
            isSimulation,
        },
    })
        .then((data) => data.data)
        .catch((err) => console.log(err));
};
