import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import ClientInfo from "./";

const mapStateToProps = ({ form }, { accessor }) => {
    const selector = formValueSelector("model");
    const { id: selectedProvTerrId } = selector({ form }, `modelData.location.weather.region`) || {};
    const isSameMailingAddress = selector({ form }, `${accessor}.sameMailingInfo`) || false;
    const differentProvinceFromClimate = selector({ form }, `${accessor}.differentProvinceFromClimate`) || false;
    const isDiffMailingAddress = selector({ form }, `${accessor}.differentMailingInfo`) || false;
    const clientProvince = selector({ form }, `${accessor}.clientProvince`);
    const { major: h2kMajor, minor: h2kMinor } = selector({ form }, "modelData.h2kVersion") || {};

    console.log("clientProvince", clientProvince);

    return {
        accessor,
        selectedProvTerrId,
        isSameMailingAddress,
        isDiffMailingAddress,
        differentProvinceFromClimate,
        h2kVersion: `v${h2kMajor}-${h2kMinor}`,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ClientInfo);
