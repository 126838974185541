import React from "react";
import { connect } from "react-redux";

import {
    toggleUpgradeInParametricAnalysis,
    removeUpgradeFromParametricAnalysis,
} from "store/parametricAnalysis/actions";

import { PARAMETRIC_TABLE_HEADERS, SORTED_UPGRADES_TYPES } from "utils/parametric/constants";

import ParameticTableRow from "../ParameticTableRow";

import classes from "./styles.module.scss";
import sharedClasses from "../styles.module.scss";

const ParametricUpgradesTableBody = ({
    ecmInputMap,
    toggleUpgradeInParametricAnalysis,
    removeUpgradeFromParametricAnalysis,
    toggleUpgrade,
    setUpgradeWhereToAddCode,
    isValidationRunComplete,
    analysisId,
}) => {
    const filteredUpgrades = Object.fromEntries(
        Object.entries(ecmInputMap).filter(([_, upgradesPackages]) => Object.keys(upgradesPackages).length > 1)
    );

    return (
        <div className={classes.parametricAnalysisUpgradesTableBodyContainer}>
            {Object.values(ecmInputMap).every((upgradeToCheck) => Object.keys(upgradeToCheck).length === 1) ? (
                <div className={classes.noUpgradesContainer}>
                    <p>No upgrades have been defined.</p>

                    <p>Get started by selecting an upgrade type.</p>
                </div>
            ) : (
                SORTED_UPGRADES_TYPES.map((upgradeType) => {
                    if (!filteredUpgrades[upgradeType]) return null;

                    const upgradesPackages = filteredUpgrades[upgradeType];

                    return (
                        <>
                            <div
                                key={upgradeType}
                                className={`${sharedClasses.parametricUpgradesTableRow} 
                            ${sharedClasses.parametricUpgradesTableRowHeading}
                            ${sharedClasses.parametricUpgradesTableRowBorderTop}`}
                            >
                                <span>{PARAMETRIC_TABLE_HEADERS[upgradeType]}</span>

                                <div
                                    className={sharedClasses.parametricUpgradesTableRowAutoMarginLeft}
                                    style={{ display: "flex" }}
                                >
                                    <span>Total measures: </span>{" "}
                                    <span style={{ minWidth: "30px", marginLeft: "5px", textAlign: "right" }}>
                                        {Object.entries(upgradesPackages).length}
                                    </span>
                                </div>
                            </div>
                            {Object.entries(upgradesPackages)
                                // .sort((a, b) => a[1].order || 0 - b[1].order || 0)
                                .sort((a, b) => a[0].localeCompare(b[0]))
                                .map(([upgradePackage, upgrade], index) => (
                                    // label(pin):"4.1 ACH"
                                    // active(pin):true
                                    // passed(pin):false
                                    // individualSavings(pin):null
                                    // noChange(pin):false
                                    // order(pin):0

                                    <ParameticTableRow
                                        key={upgradePackage}
                                        upgrade={upgrade}
                                        upgradePackage={upgradePackage}
                                        upgradeType={upgradeType}
                                        toggleUpgradeInParametricAnalysis={toggleUpgradeInParametricAnalysis}
                                        removeUpgradeFromParametricAnalysis={removeUpgradeFromParametricAnalysis}
                                        toggleUpgrade={toggleUpgrade}
                                        setUpgradeWhereToAddCode={setUpgradeWhereToAddCode}
                                        isValidationRunComplete={isValidationRunComplete}
                                        analysisId={analysisId}
                                    />
                                ))}
                        </>
                    );
                })
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleUpgradeInParametricAnalysis: (sectionType, upgradeId, toggleValue) =>
        dispatch(toggleUpgradeInParametricAnalysis(sectionType, upgradeId, toggleValue)),
    removeUpgradeFromParametricAnalysis: (sectionType, upgradeId) =>
        dispatch(removeUpgradeFromParametricAnalysis(sectionType, upgradeId)),
});

export default connect(null, mapDispatchToProps)(ParametricUpgradesTableBody);
