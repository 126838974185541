import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import CostTable from "./";

const mapStateToProps = (
    { form, parametricAnalysis: { parametricAnalysis } = {} },
    { accessor, formName = "model", change, isParametricAnalysis = false }
) => {
    const componentsFromParametric = parametricAnalysis?.model?.modelData?.components || {};

    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const costItems = selector({ form }, `${accessor}.items`) || {};

    const upgradeFields = selector({ form }, "fields") || {};

    const modelUnits = isParametricAnalysis
        ? parametricAnalysis?.model?.modelData?.uiSettings?.primaryUnits
        : modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    return {
        accessor,
        formName,
        costItems,
        change,
        modelUnits,
        upgradeFields,
        componentsFromParametric,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CostTable);
