import { isEmpty } from "lodash";

export const fileShareFolderIds = ["CHBA_NZ_ADMIN_FOLDER"];

export const getOrgMemberOptions = (members, { 
    labelSuffix = () => "", 
    disabledCheck = () => false 
}) => {
    return (!isEmpty(Object.values(members))
        ? Object.values(members)
            .sort(({ name: nameA = "" }, { name: nameB = "" }) => {
                if (nameA.toLowerCase() < nameB.toLowerCase()) {
                    return -1;
                }
                if (nameA.toLowerCase() > nameB.toLowerCase()) {
                    return 1;
                }
                return 0;
            })
            .map((val) => ({
                label: `${val.name}${labelSuffix(val)}`,
                value: val.uid,
                disabled: disabledCheck(val),
            }))
        : []);
}

export const getFolderFileOptions = (files) => {
    return (!isEmpty(Object.values(files))
        ? Object.entries(files)
            .map(([key, val]) => ({
                label: `${val.name}`,
                value: key,
            }))
        : []);
}