import { createSelector } from "reselect";

const selectParametricAnalysis = (state) => state.parametricAnalysis;

export const selectParametricAnalysisData = createSelector(
    [selectParametricAnalysis],
    ({ parametricAnalysis }) => parametricAnalysis
);

export const selectParametricAnalysisToCompare = createSelector(
    [selectParametricAnalysis],
    ({ parametricAnalysisToCompare }) => parametricAnalysisToCompare
);

export const selectIsParametricAnalysisLoading = createSelector(
    [selectParametricAnalysis],
    ({ isParametricAnalysisLoading }) => isParametricAnalysisLoading
);

export const selectIsParametricAnalysisCreating = createSelector(
    [selectParametricAnalysis],
    ({ isParametricAnalysisCreating }) => isParametricAnalysisCreating
);

export const selectFetchParametricAnalysisError = createSelector(
    [selectParametricAnalysis],
    ({ fetchParametricAnalysisError }) => fetchParametricAnalysisError
);

export const selectIsSavingEcmInputMap = createSelector(
    [selectParametricAnalysis],
    ({ isSavingEcmInputMap }) => isSavingEcmInputMap
);

export const selectParametricAnalysisDetails = createSelector(
    [selectParametricAnalysis],
    ({ parametricAnalysis: { parametricDetails = {} } = {} }) => parametricDetails
);

export const selectPinnedRecipes = createSelector(
    [selectParametricAnalysis],
    ({ parametricAnalysis: { parametricDetails: { pinnedRecipes = [] } = {} } = {} }) => pinnedRecipes
);

export const selectModelResults = createSelector(
    [selectParametricAnalysis],
    ({ parametricAnalysis: { model: { results = {} } = {} } = {} }) => results
);
