import React, { useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";

import { useOutsideClickHook } from "utils/outsideClick";

import Tooltip from "components/Tooltip";
import Dropdown from "components/Dropdown";
import ModelTags from "components/ModelTags";

import classes from "../style.module.scss";

import ThreeDots from "assets/images/icons/JSX/ThreeDots";
import SharedFolderIcon from "assets/images/icons/Shared-folder.svg";
import FolderIcon from "assets/images/icons/folder.svg";

import { fileShareFolderIds } from "utils/fileSharing/index";

const Folder = ({
    folderId,
    name,
    disableDelete = false,
    sharedWithUser = false,
    lastEdited,
    startDelete,
    pathname,
    setFolderToRename,
    setFolderToMove,
    setFolderToShare,
    tags = [],
    setSelectedFolderModel,
    selectedFolderModel,
    multipleModelFolderSelect,
    isSelected,
    ref,
    isDragging,
    combineWith,
    isDropInProcess,
    selectedModelsFolders,
    setFolderModelsToDelete,
    disableMultiDelete = false,
    ...props
}) => {
    const [subMenu, toggleSubMenu] = useState(false);

    const subMenuRef = useOutsideClickHook(() => toggleSubMenu(false));

    const isSpecialFolder = fileShareFolderIds.includes(folderId);

    return (
        <li
            className={`${classes.modelFolder} ${
                selectedFolderModel === folderId || isSelected ? classes.selectedModelFolder : ""
            } ${isDragging && (isSelected || folderId === selectedFolderModel) ? classes.modelFolderDragging : ""} ${
                combineWith ? classes.modelFolderCombine : ""
            } ${isDropInProcess ? classes.isDropInProcess : ""}`}
            style={{
                padding: !isEmpty(tags) && "0.5rem 1rem",
                pointerEvents: "none",
            }}
            // onClick={(event) => onClick(event)}
            {...props}
        >
            <div className={classes.modelFolderTitle}>
                <img src={sharedWithUser ? SharedFolderIcon : FolderIcon} alt="Folder icon" /> {name}
                {tags.map((tag, i) => (
                    <ModelTags key={i} tag={tag} />
                ))}
            </div>
            <div className={classes.address}></div>
            <div className={classes.lastEdited}>{moment(lastEdited).format("MMM DD, YYYY")}</div>
            <div className={classes.actions} ref={subMenuRef}>
                {/* <div className={classes.dupe} data-tip="Duplicate Model" data-for={`actions-${modelId}`}>
                <Duplicate />
            </div> */}

                {disableDelete ? (
                    <div></div>
                ) : (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                            toggleSubMenu(!subMenu);
                        }}
                        className={classes.delete}
                        style={{ pointerEvents: "auto" }}
                    >
                        <ThreeDots />
                        <Dropdown
                            className={classes.subMenu}
                            open={subMenu}
                            options={[
                                {
                                    label: "Rename Folder",
                                    onClick: () => {
                                        setFolderToRename();
                                        toggleSubMenu(false);
                                    },
                                },
                                {
                                    label: "Move Folder",
                                    onClick: () => {
                                        toggleSubMenu(false);
                                        setFolderToMove(folderId);
                                    },
                                },
                                // {
                                //     label: "Share Folder",
                                //     onClick: () => {
                                //         setFolderToShare();
                                //         toggleSubMenu(false);
                                //     },
                                // },
                                ...(isSpecialFolder
                                    ? []
                                    : [
                                          {
                                              label: "Delete Folder",
                                              onClick: (e) => {
                                                  startDelete(e, folderId);
                                                  toggleSubMenu(false);
                                              },
                                          },
                                      ]),
                                {
                                    label: `Delete ${selectedModelsFolders.length} files`,
                                    shouldShow: selectedModelsFolders.length > 1,
                                    disabled: disableMultiDelete,
                                    onClick: () => setFolderModelsToDelete(selectedModelsFolders),
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
            <Tooltip id={`actions-${folderId}`} delay />
        </li>
    );
};

export default Folder;
