import React, { Fragment, useState } from "react";
import classes from "./style.module.scss";
import { Link } from "react-router-dom";
import { useOutsideClickHook } from "utils/outsideClick";
import GoBack from "./GoBack";
import Dropdown from "components/Dropdown";
import ThreeDots from "assets/images/icons/JSX/ThreeDots";
import nestedArrow from "assets/images/icons/nestedArrow.svg";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { DeleteDialog, MoveDialog, RenameDialog } from "../Actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserUid } from "store/users/selectors";
import { selectFolderLoading } from "store/folders/selectors";

import { deleteFolder, renameFolder, moveFolder } from "store/folders/thunk";
import {
    deleteParametricAnalysisFolder,
    moveParametricAnalysisFolder,
    renameParametricAnalysisFolder,
} from "store/parametricAnalysis/thunk";

const NestedPath = ({
    rootDir,
    rootPath,
    folders,
    dirType, // modelDir, commDir
    onDragOver,

    deleteFolder,
    renameFolder,
    moveFolder,
    folderLoading,
    uid,
    deleteParametricAnalysisFolder,
    moveParametricAnalysisFolder,
    renameParametricAnalysisFolder,
}) => {
    const history = useHistory();
    const { pathname } = useLocation();

    // Add url prefixes
    const path = pathname.split("/").filter((str) => ["", "dashboard", "communities", "parametric-analysis"].includes(str) === false) || [];

    const [startDelete, toggleStartDelete] = useState(false);
    const [isDeleting, toggleDeleting] = useState(false);

    const [startRename, toggleStartRename] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");

    const [startMove, toggleStartMove] = useState(false);

    const [isFolderOptionsOpen, setIsFolderOptionsOpen] = useState(false);

    const folderOptionsRef = useOutsideClickHook(() => setIsFolderOptionsOpen(false));

    const isLastFolder = (index) => path.length - 1 === index;
    const isNotRootDir = path.length > 0;

    const colors = {
        black: "#262e3f",
        grey: "#65676a"
    }

    return (
        <div className={classes.nestedPathsContainer}>
            {path.length > 2 && <GoBack rootDir={rootDir} rootDirPath={rootPath} path={path} folders={folders} />}
            <div className={classes.nestedPathsWrapper}>
                <div
                    className={classes.pathTextContainer}
                    onDragOver={(event) => {
                        event.preventDefault();
                        onDragOver(event, null, "folder");
                    }}
                >
                    <Link
                        to={rootPath}
                        className={classes.pathText}
                        style={{ color: isNotRootDir ? colors.grey : colors.black }}
                    >
                        {rootDir}
                    </Link>
                </div>
                {isNotRootDir &&
                    path.map((folderId, index) => (
                        <Fragment key={index}>
                            <img src={nestedArrow} alt={nestedArrow} style={{ marginTop: "6px" }} />
                            <div
                                className={classes.pathTextContainer}
                                ref={isLastFolder(index) ? folderOptionsRef : null}
                                onClick={() => isLastFolder(index) && setIsFolderOptionsOpen(!isFolderOptionsOpen)}
                                onDragOver={(event) => {
                                    event.preventDefault();
                                    onDragOver(event, folderId, "folder");
                                }}
                            >
                                <Link
                                    className={classes.pathText}
                                    to={`${rootPath}/${path
                                        .slice(0, index + 1)
                                        .join("/")
                                        .toString()}`}
                                    style={{ color: isLastFolder(index) ? colors.black : colors.grey }}
                                >
                                    {folders[folderId]?.name || ""}
                                </Link>
                                {isLastFolder(index) && (
                                    <div>
                                        <ThreeDots />
                                        <Dropdown
                                            className={classes.pathTextDropdown}
                                            open={isFolderOptionsOpen}
                                            options={[
                                                {
                                                    label: "Rename",
                                                    onClick: () => {
                                                        toggleStartRename(true);
                                                        setNewFolderName(folders[folderId]?.name);
                                                        setIsFolderOptionsOpen(false);
                                                    },
                                                },
                                                {
                                                    label: "Move Folder to",
                                                    onClick: () => {
                                                        toggleStartMove(true);
                                                        setIsFolderOptionsOpen(false);
                                                    },
                                                },
                                                {
                                                    label: "Delete",
                                                    onClick: () => {
                                                        toggleStartDelete(true);
                                                        setIsFolderOptionsOpen(false);
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                )}
                            </div>
                            <DeleteDialog
                                fileName={folders[folderId]?.name}
                                openDialog={startDelete}
                                loading={isDeleting}
                                onClick={async () => {
                                    const index = path.findIndex((el) => el === folderId);

                                    if (index !== -1) { //?
                                        if (index === 0) history.push(`${rootPath}`);

                                        if (index > 0) history.push(`${rootPath}/${path.slice(0, index).join("/").toString()}`);
                                    }

                                    toggleDeleting(true);
                                    if (dirType === "parametricDir") {
                                        await deleteParametricAnalysisFolder(folderId, uid);
                                    } else {
                                        await deleteFolder(folderId, uid, dirType);
                                    }
                                    toggleStartDelete(false);
                                    toggleDeleting(false);
                                }}
                                onClose={() => toggleStartDelete(false)}
                                isFolder
                            />
                            <RenameDialog
                                itemType="folder"
                                openDialog={startRename}
                                oldName={folders[folderId]?.name}
                                newName={newFolderName}
                                setNewName={(val) => setNewFolderName(val)}
                                onClick={async () => {
                                    // toggleRenaming(true);
                                    if (dirType === "parametricDir") {
                                        await renameParametricAnalysisFolder(folderId, uid, newFolderName);
                                    } else {
                                        await renameFolder(folderId, uid, newFolderName, dirType);
                                    }
                                    toggleStartRename(false);
                                }}
                                onClose={() => {
                                    toggleStartRename(false);
                                    setNewFolderName(folders[folderId]?.name);
                                }}
                                isLoading={folderLoading}
                            />
                            <MoveDialog
                                itemId={folderId}
                                fileName={folders[folderId]?.name}
                                rootDir={rootDir}
                                folders={folders}
                                openDialog={startMove}
                                itemLoading={folderLoading}
                                handleMove={async (moveToId) => {
                                    if (dirType === "parametricDir") {
                                        await moveParametricAnalysisFolder(folderId, uid, moveToId);
                                    } else {
                                        await moveFolder(folderId, uid, moveToId, false, dirType);
                                    }
                                    history.push(rootPath);
                                }}
                                onClose={() => toggleStartMove(false)}
                            />
                        </Fragment>
                    ))}
            </div>
        </div>
    );
};


const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    folderLoading: selectFolderLoading,
});

const mapDispatchToProps = (dispatch, { history }) => ({
    deleteFolder: async (folderId, uid, dirType = "modelDir") => dispatch(deleteFolder(folderId, uid, dirType)),
    renameFolder: async (folderId, uid, newFolderName, dirType = "modelDir") => dispatch(renameFolder(folderId, uid, newFolderName, dirType)),
    moveFolder: async (folderId, uid, moveToId, skipLoading, dirType = "modelDir") => dispatch(moveFolder(folderId, uid, moveToId, skipLoading, dirType)),

    deleteParametricAnalysisFolder: (folderId, userUid) => dispatch(deleteParametricAnalysisFolder(folderId, userUid)),
    renameParametricAnalysisFolder: (folderId, userUid, newName) =>
        dispatch(renameParametricAnalysisFolder(folderId, userUid, newName)),
    moveParametricAnalysisFolder: (folderId, userUid, moveToId) =>
        dispatch(moveParametricAnalysisFolder(folderId, userUid, moveToId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NestedPath);
