import React, { useCallback, useEffect, useRef, useState } from "react";
import classes from "../style.module.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isEmpty } from "lodash";
import DashboardTable from "components/Dashboard/Table";
import Loading from "components/Loading";
import MultiSelect from "components/Input/MultiSelect";
import FilterIcon from "assets/images/icons/Filter.svg";
import Button from "components/Button";
import Card from "components/Card";
import { getProvName } from "utils/weather";
const Models = ({
    history,
    commModelDir = {},
}) => {
    const modelRef = useRef(null);
    const scrollContainerRef = useRef(null);
    /* Loading state and cursor position for loading spinner */
    const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const [unfilteredModels, setUnFilteredModels] = useState([]);
    const [filteredModels, setFilteredModels] = useState([]);
    // Options for filters
    const [locationOpts, setLocationOpts] = useState([]);
    const [userOpts, setUserOpts] = useState([]);
    const [modelOpts, setModelOpts] = useState([]);
    // List of selected filters
    const [selectedLocs, setSelectedLocs] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);
    // Flag to determine if filters were updated
    const [newFilter, setNewFilter] = useState(false);
    // Get cursor position for loading spinner
    const onMouseMove = useCallback((e) => {
        const top = e.pageY - window.scrollY + 10;
        const left = e.pageX - window.scrollX + 10;
        setCursorPosition({ top, left });
    }, []);
    // Update loading spinner location when cursor moves
    useEffect(() => {
        const handleMouseMove = (e) => {
            onMouseMove(e);
        };
        if (!isLoading) return;
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("wheel", handleMouseMove);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("wheel", handleMouseMove);
        };
    }, [isLoading, onMouseMove]);
    // Get sorted list of commModels
    useEffect(() => {
        const { models: singleModels = {} } = commModelDir;
        const unfiltered = !isEmpty(singleModels)
            ? Object.entries(singleModels)
                .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
                .map(([key, el]) => [key, { ...el, type: "commModel" }])
            : [];
        fetchFilterOptions(singleModels);
        setUnFilteredModels(unfiltered);
        setFilteredModels(unfiltered);
    }, []);
    // Alphabetical sort function for label
    const orderByLabel = (a, b) => a.label.localeCompare(b.label);
    // Initilize filter options from current commModel data
    const fetchFilterOptions = (allModels) => {
        let locations = [];
        let users = [];
        let models = [];
        Object.entries(allModels).forEach(([key, model]) => {
            if (!locations.includes(model.provTerr)) locations.push(model.provTerr);
            if (!users.includes(model.userName)) users.push(model.userName);
            models.push({ key, ...model });
        });
        setLocationOpts(
            locations
                .map((id) => ({
                    value: id,
                    label: getProvName(id).toUpperCase(),
                }))
                .sort(orderByLabel)
        );
        setUserOpts(
            users
                .map((fullName, index) => ({
                    value: `User${index}`, // user array index
                    label: fullName, // full user name
                }))
                .sort(orderByLabel)
        );
        setModelOpts(
            models
                .map((model) => ({
                    value: model.key, // model ID
                    label: model.name, // model name
                }))
                .sort(orderByLabel)
        );
    };
    // Clear all filters
    const handleClear = () => {
        // Remove selected filters
        setSelectedLocs([]);
        setSelectedUsers([]);
        setSelectedModels([]);
        // Reset filtered models list and disable apply button
        setFilteredModels(unfilteredModels);
        setNewFilter(false);
    };
    // Apply filters
    const handleApply = () => {
        const filteredData = unfilteredModels.filter(([key, model]) => {
            const selectedUserNames = userOpts
                .filter((opt) => selectedUsers.includes(opt.value))
                .map((opt) => opt.label);
            const matchLocation = isEmpty(selectedLocs) || selectedLocs.includes(model.provTerr);
            const matchUser = isEmpty(selectedUsers) || selectedUserNames.includes(model.userName);
            const matchModel = isEmpty(selectedModels) || selectedModels.includes(key);
            return matchLocation && matchUser && matchModel;
        });
        // Update filtered models list and disable button after applying
        setFilteredModels(filteredData);
        setNewFilter(false);
    };
    // Update selected filter lists
    const handleChange = (type, value) => {
        if (type === "location") setSelectedLocs(value);
        else if (type === "user") setSelectedUsers(value);
        else if (type === "model") setSelectedModels(value);
        setNewFilter(true);
    };
    return (
        <div
            className={classes.contentContainer}
            onMouseMove={onMouseMove}
            style={{ pointerEvents: isLoading ? "none" : "auto" }}
            ref={scrollContainerRef}
        >
            <div style={{ position: "fixed", pointerEvents: "none", ...cursorPosition }}>
                {isLoading && <Loading className={classes.smallerSpinner} subClassName={classes.smallSpinner} />}
            </div>
            <div className={classes.filters}>
                <img src={FilterIcon} alt="filter" />
                <MultiSelect
                    hideLabel
                    dropDownWidth="max-content"
                    className={classes.test}
                    forcedLabel="Location"
                    options={locationOpts}
                    input={{
                        value: selectedLocs,
                        onChange: (value) => handleChange("location", value),
                    }}
                />
                <MultiSelect
                    hideLabel
                    dropDownWidth="max-content"
                    className={classes.test}
                    forcedLabel="User"
                    options={userOpts}
                    input={{
                        value: selectedUsers,
                        onChange: (value) => handleChange("user", value),
                    }}
                />
                <MultiSelect
                    hideLabel
                    dropDownWidth="max-content"
                    className={classes.test}
                    forcedLabel="Models"
                    options={modelOpts}
                    input={{
                        value: selectedModels,
                        onChange: (value) => handleChange("model", value),
                    }}
                />
                <Button small disabled={!newFilter} onClick={() => handleApply()}>
                    Apply
                </Button>
                <Button small type="white" onClick={handleClear}>
                    Clear
                </Button>
            </div>
            
            <h2 className={classes.title}>
                House Models
            </h2>
            <DashboardTable
                // folderItemRef={modelRef}
                history={history}
                dirType="renoCommModelDir"
                allFoldersItems={filteredModels}
                itemType={"commModel"}
                setIsLoading={setIsLoading}
                paginate
            />
            {isEmpty(filteredModels) && <Card>No community models match the filters you applied</Card>}
        </div>
    );
};
const mapStateToProps = createStructuredSelector({});
const mapDispatchToProps = (dispatch, { history }) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(Models);