import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import StandardCode from "./";
import ceilingCodeTemplate from "features/Model/Enclosure/Templates/ceilingCode.json";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import isEmpty from "lodash/isEmpty";
import { standardCodeLogic } from "utils/codes";

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode, getRValue } = enclosureActions;
const { updateUpgradeCodes } = upgradeActions;

const mapStateToProps = (
    {
        form,
        model: { modelId },
        user: { uid },
        enclosure: { initCodeValues: { ceilingInsType = {} } = {} } = {},
        upgradeLibrary: { currentPackage, selectedUpgrade },
    },
    {
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        toggleOpen,
        isFlat,
        isCodeLibrary = false,
        componentId,
        testProps,
    }
) => {
    // console.log(testProps);
    const selector = formValueSelector("ceilingCode");
    const { id: ceilingStructureId, value: ceilingStructureValue = 0 } =
        selector({ form }, "ceilingCode.layers.structureType") || {};
    const { value: ceilingTypeSizeValue } = selector({ form }, "ceilingCode.layers.componentTypeSize") || {};
    const { id: ceilingSpacingId, value: ceilingSpacingValue } = selector({ form }, "ceilingCode.layers.spacing") || {};
    const { value: ceilingFramingValue } = selector({ form }, "ceilingCode.layers.framing") || {};
    const { value: ceilingIns1Value } = selector({ form }, "ceilingCode.layers.insulationLayer1") || {};
    const { value: ceilingIns2Value } = selector({ form }, "ceilingCode.layers.insulationLayer2") || {};
    const { value: ceilingInteriorValue } = selector({ form }, "ceilingCode.layers.interior") || {};
    const ceilingLabel = selector({ form }, "ceilingCode.label") || "";

    const frameSpacingValue = ceilingSpacingId === null ? ceilingFramingValue : ceilingSpacingValue;

    const ceilingValue = `2${ceilingStructureValue
        .toString()
        .substr(
            -1
        )}${ceilingTypeSizeValue}${frameSpacingValue}${ceilingIns1Value}${ceilingIns2Value}${ceilingInteriorValue}${0}${0}${0}`;

    const { codeRef: editingCodeRef = null, isLibCode = false } = ceilingInsType;

    const modelSelector = formValueSelector("model");
    const modelCodes = modelSelector({ form }, "modelData.codes") || {};
    const components = modelSelector({ form }, "modelData.components") || {};

    return {
        ceilingLabel,
        fieldAccessor,
        modelFormChange,
        initialValues: {
            ceilingCode: isEmpty(ceilingInsType) ? ceilingCodeTemplate : ceilingInsType,
            addToLibrary: isLibCode,
        },
        ceilingStructureId,
        ceilingTypeSizeValue,
        isSolid: parseFloat(ceilingStructureId) === 4,
        isPanel: parseFloat(ceilingStructureId) === 5,
        isFlat,
        makeSpacingDefault: ["5", "6", "7", "8", "9", "A", "B", "C"].includes(ceilingTypeSizeValue),
        makeInsulationDefault: ["6", "7", "8", "9", "A", "B", "C"].includes(ceilingTypeSizeValue),
        ceilingValue,
        modelId,
        uid,
        editingCodeRef,
        isEditing: !!editingCodeRef,
        isLibCode,
        ceilingSpacingId,
        toggleOpen,
        isCodeLibrary,
        isUpgradeComponent: fieldAccessor.includes("fields."),
        componentId,
        modelCodes,
        components,
        currentFormChange,
        currentPackage,
        selectedUpgrade,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearForm: () => dispatch(reset("ceilingCode")),
    clearInitCode: () => dispatch(setInitCode({}, "ceilingInsType")),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        fieldAccessor,
        modelFormChange,
        editingCodeRef,
        isFlat,
        isCodeLibrary,
        componentId,
        isEditing,
        modelCodes,
        components,
        currentFormChange,
        currentPackage,
        selectedUpgrade,
        upgradeWhereToAddCode = {},
    }
) => {
    const { ceilingCode = {}, addToLibrary = false, setAsModelDefault = false, selectedComponents = [] } = form;

    const ceilCodeType = isFlat ? "CeilingFlat" : "Ceiling";

    const { ecmInputMapUpgrade, ecmInputMapUpgradeId, ecmInputMapUpgradeType } = upgradeWhereToAddCode || {};

    toggleOpen(false);

    // Run standard code logic
    standardCodeLogic({
        componentCodeType: ceilCodeType,
        componentId,
        code: ceilingCode,
        formInputName: "ceilingInsType",
        codeType: "S",
        isCodeLibrary,
        isEditing,
        editingCodeRef,
        dispatch,
        getRValue,
        modelFormChange,
        currentFormChange,
        fieldAccessor,
        components,
        modelCodes,
        addToLibrary,
        addToCodeLib,
        uid,
        deleteCode,
        updateUpgradeCodes,
        setAsModelDefault,
        selectedComponents,
        currentPackage,
        selectedUpgrade,
        ecmInputMapUpgrade,
        ecmInputMapUpgradeId,
        ecmInputMapUpgradeType,
        isEcmInputMap: !isEmpty(upgradeWhereToAddCode),
    });

    // Clear ceiling code defaults for next time
    dispatch(setInitCode({}, "ceilingInsType"));
    // Reset form
    dispatch(reset("ceilingCode"));
};

const form = reduxForm({
    form: "ceilingCode",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(StandardCode);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
