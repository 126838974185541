import { isEmpty } from "lodash";
import { DashboardAPI } from "utils/api";

export const genEnvirocentreNZReport = async (formData, data = {}) => {
    return DashboardAPI({
        method: "POST",
        url: "/genEnvirocentreNZReport",
        file: formData,
        data,
        headers: {
            "content-type": isEmpty(data) ? "multipart/form-data" : "application/json",
        },
        responseType: "text",
    }).catch((err) => console.log(err));
};
