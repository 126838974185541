import React from "react";
import classes from "../style.module.scss";
import { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import Dialog from "components/Dialog";
import Input from "components/Input";
import { isEmpty } from "lodash";

const CreateDialog = ({
    title,
    label,
    placeholder,
    openDialog,
    newName,
    setNewName,
    onClick,
    onClose,
    loading,
}) => (
    <Dialog open={openDialog}>
        <CloseDialog closeActions={() => onClose()} />
        <div className={classes.dialog}>
            <h2>{title}</h2>
            <Input
                label={label}
                placeholder={placeholder}
                disabled={loading}
                input={{
                    value: newName,
                    onChange: (value) => setNewName(value),
                }}
            />
            <div className={classes.footer}>
                <Button type="hollow" onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button onClick={onClick} type="" disabled={loading || isEmpty(newName)}>
                    {loading ? "Creating..." : "Create"}
                </Button>
            </div>
        </div>
    </Dialog>
);

export default CreateDialog;

export const CreateFolderDialog = ({
    openDialog,
    newFolderName,
    setNewFolderName,
    onClick,
    onClose,
    folderLoading,
}) => (
    <CreateDialog
        title="Create a New Folder"
        label="Folder Name"
        placeholder="Type folder name..."
        openDialog={openDialog}
        newName={newFolderName}
        setNewName={setNewFolderName}
        onClick={onClick}
        onClose={onClose}
        loading={folderLoading}
    />
);

export const CreateCommunityDialog = ({
    openDialog,
    newCommunityName,
    setNewCommunityName,
    onClick,
    onClose,
    communityLoading,
}) => (
    <CreateDialog
        title="Create a New Community"
        label="Community Name"
        placeholder="Type community name..."
        openDialog={openDialog}
        newName={newCommunityName}
        setNewName={setNewCommunityName}
        onClick={onClick}
        onClose={onClose}
        loading={communityLoading}
    />
);
