//Last updated with 2024 NIR
//NG EF in g/m3 (Part 2. Table A6.1-1)
//elec EF in g/kWh (Part 3), generation intensity
//Propane EF in g/L (Part 2. Table A6.1-4)
//Oil EF in g/L (Light Fuel Oil, residential. Part 2. Table A6.1-5)
//wood EF in g/hr operation, from EPA certified pellet stoves

import { isEmpty } from "lodash";
import { energyConversion } from "utils/constants/energyConversion";
import { getNetTotalEnergyConsumption } from "utils/results";
import { provinceCodeMap } from "utils/weather";

//woodStove EF in g/kgfuel (Part 2. Table A6.6-1)
export const emissionFactorsNIR = {
    NL: {
        elec: 18,
        ng: 1919,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    NS: {
        elec: 700,
        ng: 1919,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    NS_CLEAN: {
        elec: 233,
        ng: 1919,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    PE: {
        elec: 350,
        ng: 1919,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    NB: {
        elec: 350,
        ng: 1919,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    QC: {
        elec: 1.7,
        ng: 1926,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    ON: {
        elec: 38,
        ng: 1921,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    MB: {
        elec: 1.4,
        ng: 1915,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    SK: {
        elec: 670,
        ng: 1920,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    AB: {
        elec: 490,
        ng: 1962,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    BC: {
        elec: 15,
        ng: 1966,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    YT: {
        elec: 70,
        ng: 1966,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    NT: {
        elec: 190,
        ng: 1966,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
    NU: {
        elec: 820,
        ng: 1966,
        propane: 1515,
        oil: 2753,
        wood: 1,
        woodStove: 1539,
    },
};

//Same units as above
export const emissionFactorsPCF2004 = {
    BC: { elec: 1.32, ng: 1966.5, prop: 1548, oil: 2755 },
    AB: { elec: 181.86, ng: 1956.15, prop: 1548, oil: 2755 },
    SK: { elec: 146.6, ng: 1914.75, prop: 1548, oil: 2755 },
    MB: { elec: 0.0, ng: 1914.75, prop: 1548, oil: 2755 },
    ON: { elec: 57.9, ng: 1914.75, prop: 1548, oil: 2755 },
    QC: { elec: 0.38, ng: 1925.1, prop: 1548, oil: 2755 },
    NB: { elec: 77.88, ng: 1914.75, prop: 1548, oil: 2755 },
    NS: { elec: 161.64, ng: 1966.5, prop: 1548, oil: 2755 },
    PE: { elec: 80.42, ng: 1914.75, prop: 1548, oil: 2755 },
    NL: { elec: 11.08, ng: 1914.75, prop: 1548, oil: 2755 },
    YT: { elec: 25.0, ng: 1966.5, prop: 1548, oil: 2755 },
    NT: { elec: 6.82, ng: 1914.75, prop: 1548, oil: 2755 },
    NU: { elec: 465.16, ng: 1966.5, prop: 1548, oil: 2755 },
};

export const pcf2004SpaceHeatingRefEf = 235; // g/kWh
export const pcf2004WaterHeatingRefEf = 260; // g/kWh

export const getPcf2004RefEmissions = (results = {}, provCode = "") => {
    if (!Object.keys(emissionFactorsPCF2004).includes(provCode) || isEmpty(results)) {
        return null;
    }

    const {
        Annual: {
            Consumption: {
                Electrical: {
                    _attributes: {
                        // baseload, //GJ
                        airConditioning, //GJ
                        ventilation, //GJ
                    } = {},
                } = {},
            } = {},
            Load: {
                _attributes: {
                    auxiliaryEnergy, //MJ
                } = {},
            } = {},
            HotWaterDemand: {
                _attributes: {
                    base: baseDhwDemand, //GJ
                } = {},
            } = {},
        } = {},
    } = results || {};

    const spaceHeatingContribution = (auxiliaryEnergy / 1000) * (1 / 0.0036) * pcf2004SpaceHeatingRefEf; //[GJ] * [kWh/GJ] * [g/kWh]
    const waterHeatingContribution = baseDhwDemand * (1 / 0.0036) * pcf2004WaterHeatingRefEf; //[GJ] * [kWh/GJ] * [g/kWh]
    const nonHeatingContribution =
        (airConditioning + ventilation) * (1 / 0.0036) * emissionFactorsPCF2004[provCode].elec; //[GJ] * [kWh/GJ] * [g/kWh]

    //returns gCO2/y
    return spaceHeatingContribution + waterHeatingContribution + nonHeatingContribution;
};

export const getPcf2004ProposedEmissions = (results = {}, provCode = "") => {
    if (!Object.keys(emissionFactorsPCF2004).includes(provCode) || isEmpty(results)) {
        return null;
    }

    //All in GJ
    const { elecNoBaseloads, grossNaturalGas, grossPropane, grossOil } = getNetTotalEnergyConsumption(results);

    const elecEmissions = elecNoBaseloads * (1 / energyConversion.elecGJperkWh) * emissionFactorsPCF2004[provCode].elec; //[GJ] * [kWh/GJ] * [g/kWh]
    const ngEmissions = grossNaturalGas * (1 / energyConversion.ngGJperm3) * emissionFactorsPCF2004[provCode].ng; //[GJ] * [m3/GJ] * [g/m3]
    const propEmissions = grossPropane * (1 / energyConversion.propaneGJperL) * emissionFactorsPCF2004[provCode].prop; //[GJ] * [L/GJ] * [g/L]
    const oilEmissions = grossOil * (1 / energyConversion.oilGJperL) * emissionFactorsPCF2004[provCode].oil; //[GJ] * [L/GJ] * [g/L]

    return elecEmissions + ngEmissions + propEmissions + oilEmissions;
};

//Forest numbers from IPCC, 2000. A Special Report of the Intergovernmental Panel on Climate Change:
//Land Use, Land-Use Change, and Forestry. PDF page 12
//Vehicle numbers from https://open.canada.ca/data/en/dataset/98f1a129-f628-4ce4-b24d-6f16bf24dd64
//Medians for vehicles having between 4-6 CO2 rating
const carbonComparisons = {
    borealForest: {
        value: 0.8,
        upper: 1.2,
        lower: 0.4,
        units: "t/ha*y",
    },
    gasVehicles: {
        kgPerL: 2.3,
        gPerkm: 213,
        value: 213,
    },
    dieselVehicles: {
        kgPerL: 2.7,
        gPerkm: 268,
        value: 268,
    },
};

export const getEmissionFactorNIR = (region, fuel) => {
    if (!Object.keys(emissionFactorsNIR).includes(region)) {
        return 0;
    }

    return emissionFactorsNIR[region][fuel] || 0;
};

export const getCarbonComparisonFactor = (type) => {
    if (!Object.keys(carbonComparisons).includes(type)) {
        return 0;
    }

    return carbonComparisons[type].value || 0;
};

export const getNIREmissions = (resultsData, houseRegionId) => {
    const { grossElectricity, grossNaturalGas, grossOil, grossPropane, grossWood, pvProduction } =
        getNetTotalEnergyConsumption(resultsData);

    const houseRegion = provinceCodeMap[houseRegionId];

    const totalElecEmissionsTCO2 =
        (grossElectricity / energyConversion.elecGJperkWh) * getEmissionFactorNIR(houseRegion, "elec") * 1e-6; // EF=[g/kWh]
    const totalPvEmissionsTCO2 =
        (pvProduction / energyConversion.elecGJperkWh) * getEmissionFactorNIR(houseRegion, "elec") * 1e-6; // EF=[g/kWh]
    const totalNgEmissionsTCO2 =
        (grossNaturalGas / energyConversion.ngGJperm3) * getEmissionFactorNIR(houseRegion, "ng") * 1e-6; //EF = [g/m3]
    const totalOilEmissionsTCO2 =
        (grossOil / energyConversion.oilGJperL) * getEmissionFactorNIR(houseRegion, "oil") * 1e-6; //EF = [g/L]
    const totalPropaneEmissionsTCO2 =
        (grossPropane / energyConversion.propaneGJperL) * getEmissionFactorNIR(houseRegion, "propane") * 1e-6; //EF = [g/L]
    const totalWoodEmissionsTCO2 =
        (grossWood / energyConversion.woodPelletsGJperkg) * getEmissionFactorNIR(houseRegion, "woodStove") * 1e-6; //EF = [g/kg]

    return {
        totalEmissionsTCO2:
            totalElecEmissionsTCO2 +
            totalNgEmissionsTCO2 +
            totalOilEmissionsTCO2 +
            totalPropaneEmissionsTCO2 +
            totalWoodEmissionsTCO2 -
            totalPvEmissionsTCO2,
        totalElecEmissionsTCO2,
        totalNgEmissionsTCO2,
        totalOilEmissionsTCO2,
        totalPropaneEmissionsTCO2,
        totalWoodEmissionsTCO2,
        totalPvEmissionsTCO2,
    };
};

export const getNIREmissionsForParametric = (elecGJ, ngGJ, propaneGJ, oilGJ, woodGJ, pvGJ, houseRegion) => {
    const totalElecEmissionsTCO2 =
        (elecGJ / energyConversion.elecGJperkWh) * getEmissionFactorNIR(houseRegion, "elec") * 1e-6; // EF=[g/kWh]
    const totalPvEmissionsTCO2 =
        (pvGJ / energyConversion.elecGJperkWh) * getEmissionFactorNIR(houseRegion, "elec") * 1e-6; // EF=[g/kWh]
    const totalNgEmissionsTCO2 = (ngGJ / energyConversion.ngGJperm3) * getEmissionFactorNIR(houseRegion, "ng") * 1e-6; //EF = [g/m3]
    const totalOilEmissionsTCO2 =
        (oilGJ / energyConversion.oilGJperL) * getEmissionFactorNIR(houseRegion, "oil") * 1e-6; //EF = [g/L]
    const totalPropaneEmissionsTCO2 =
        (propaneGJ / energyConversion.propaneGJperL) * getEmissionFactorNIR(houseRegion, "propane") * 1e-6; //EF = [g/L]
    const totalWoodEmissionsTCO2 =
        (woodGJ / energyConversion.woodPelletsGJperkg) * getEmissionFactorNIR(houseRegion, "woodStove") * 1e-6; //EF = [g/kg]

    return {
        totalEmissionsTCO2:
            totalElecEmissionsTCO2 +
            totalNgEmissionsTCO2 +
            totalOilEmissionsTCO2 +
            totalPropaneEmissionsTCO2 +
            totalWoodEmissionsTCO2 -
            totalPvEmissionsTCO2,
        totalElecEmissionsTCO2,
        totalNgEmissionsTCO2,
        totalOilEmissionsTCO2,
        totalPropaneEmissionsTCO2,
        totalWoodEmissionsTCO2,
        totalPvEmissionsTCO2,
    };
};
