import React, { useEffect, useState } from "react";
import globalStyles from "components/globalStyles.module.scss";
import classes from "./style.module.scss";
import Button from "components/Button";
import Drawer from "components/Drawer";
import InputRow from "components/Input/Row";
import MultiSelect from "components/Input/MultiSelect";
import { Field } from "redux-form";
import { required } from "utils/fieldValidation";
import moment from "moment";
import { upgradeOptionsComponents, upgradeOptionsSystems, componentUpgradeContent } from "utils/upgrades";
import { isEmpty, isEqual } from "lodash";
import Input from "components/Input";
import Select from "components/Input/Select";
import Checkbox from "components/Input/Checkbox";
import CostTable from "./CostTable/container";

import { actions } from "../_ducks";

const { updateUpgradeCost } = actions;

const UpgradeDrawer = React.memo(
    ({
        isOpen = false,
        toggleOpen,
        upgradeType,
        upgradeH2kType = upgradeType,
        selectedComponents = [],
        selectedPackage,
        availableComponents = [],
        selectedUpgrade,
        handleSubmit,
        submitting,
        invalid,
        change,
        reset,
        resetState,
        modelChange,
        created,
        unavailableSystems = [],
        deleteUpgrade,
        autoGenerateLabel,
        isUpgradeLibrary = false,
        selectUpgrade,
        isNew,
        deleteUpgradeFromLib,
        associatedHeatingSystem = "",
        showCost,
        toggleShowCost,
        isParametricAnalysis,
        canBeEditedFromParametric = true,
    }) => {
        const { label: typeLabel = "", singleSelect = false } =
            upgradeOptionsComponents.find(({ value }) => value === upgradeH2kType) || {};

        const UpgradeComponent = componentUpgradeContent(upgradeType);

        const [isClicked, setIsClicked] = useState(false);

        const handleClose = () => {
            setIsClicked(false);
            toggleOpen({});

            if (isNew && isUpgradeLibrary) deleteUpgradeFromLib(selectedUpgrade);

            selectUpgrade(null);

            if (resetState) resetState();
        };

        return (
            <Drawer
                open={isOpen}
                title={`${selectedUpgrade ? "Edit" : "Add New"} ${typeLabel} Upgrade`}
                subtitle={
                    selectedUpgrade
                        ? `Created ${moment(created).format("MMMM DD, YYYY")}`
                        : `Select the type of upgrade to get started.`
                }
                close={() => {
                    !isClicked ? setIsClicked(true) : handleClose();
                }}
                noCloseButton={true}
                classes={{
                    paperClass: classes.upgradeDrawerPaper,
                }}
                width={1024}
            >
                {isClicked && (
                    <div className={classes.warningPane} onClick={() => setIsClicked(false)}>
                        <div className={classes.warning}>
                            <p>
                                Click off of the drawer twice to <strong>Discard Changes</strong>
                            </p>
                            <p style={{ fontSize: "15px" }}>Click here to continue editing</p>
                        </div>
                    </div>
                )}
                <div className={classes.contentWrapper}>
                    <div className={classes.mainContent}>
                        <InputRow>
                            <Field
                                component={Input}
                                name={`label.${upgradeType}`}
                                label="Label"
                                validate={autoGenerateLabel ? [] : [required]}
                                disabled={autoGenerateLabel}
                            />
                            <Field
                                component={Checkbox}
                                name="autoGenerateLabel"
                                label="Use automatically generated label"
                                type="checkbox"
                                // disabled={!canBeEditedFromParametric}
                            />
                            {["loan", "grant", "financialValuation"].includes(upgradeType) &&
                                !isUpgradeLibrary &&
                                !isParametricAnalysis && (
                                    <div className={classes.eligibleUpgrades}>
                                        <UpgradeComponent
                                            accessor={"fields"}
                                            change={change}
                                            modelChange={modelChange}
                                            formName="upgradeDrawer"
                                            disabledCodeEdit
                                            isUpgrade
                                            selectedPackage={selectedPackage}
                                        />
                                    </div>
                                )}
                            {/* below is checking if the components list contains upgradeType
                            looks weird because it is using the 'some' method */}
                            {upgradeType &&
                                upgradeOptionsComponents.some(({ value }) => value === upgradeType) &&
                                !singleSelect &&
                                selectedComponents &&
                                !isUpgradeLibrary &&
                                !isParametricAnalysis && (
                                    <Field
                                        component={MultiSelect}
                                        name={`selectedComponents`}
                                        label={`${typeLabel} Components`}
                                        options={availableComponents.map(
                                            ({ disabled = false, label = "", ...rest }) => ({
                                                ...rest,
                                                disabled,
                                                label: disabled
                                                    ? `${label} (Upgrade already applied in this package)`
                                                    : label,
                                            })
                                        )}
                                        validate={[required]}
                                        hasSelectAll
                                    />
                                )}
                            {!isUpgradeLibrary &&
                                !isParametricAnalysis &&
                                upgradeType &&
                                upgradeOptionsComponents.some(({ value }) => value === upgradeType) &&
                                singleSelect && (
                                    <Field
                                        component={Select}
                                        name={"selectedComponents"}
                                        label={`${typeLabel} Component`}
                                        options={
                                            availableComponents
                                                ? availableComponents.map(
                                                      ({ disabled = false, label = "", value, ...rest }) => ({
                                                          ...rest,
                                                          disabled,
                                                          value: [value],
                                                          label: disabled
                                                              ? `${label} (Upgrade already applied in this package)`
                                                              : label,
                                                      })
                                                  )
                                                : []
                                        }
                                        validate={[required]}
                                        hasSelectAll
                                    />
                                )}
                            {((upgradeType &&
                                (upgradeOptionsSystems.some(({ value }) => value === upgradeType) ||
                                    selectedComponents.length > 0) &&
                                UpgradeComponent) ||
                                isUpgradeLibrary ||
                                isParametricAnalysis) && (
                                <div className={classes.eligibleUpgrades}>
                                    <UpgradeComponent
                                        accessor={"fields"}
                                        change={change}
                                        modelChange={modelChange}
                                        formName="upgradeDrawer"
                                        disabledCodeEdit
                                        isUpgrade
                                        isParametricAnalysis={isParametricAnalysis}
                                        upgradeType={upgradeType}
                                        selectedUpgrade={selectedUpgrade}
                                        selectedPackage={selectedPackage}
                                        isUpgradeLibrary={isUpgradeLibrary}
                                        componentAccessor={
                                            !isUpgradeLibrary
                                                ? `modelData.components.${selectedComponents[0]}`
                                                : { constructionType: { id: 0 } }
                                        } // For foundation components
                                        canBeEditedFromParametric={canBeEditedFromParametric}
                                        associatedHeatingSystem={associatedHeatingSystem} //For DHW
                                    />
                                </div>
                            )}
                        </InputRow>

                        {!["loan", "grant", "financialValuation"].includes(upgradeType) && (
                            <CostTable
                                className={classes.costTable}
                                selectedComponents={selectedComponents}
                                accessor={`cost.${upgradeType}`}
                                formName="upgradeDrawer"
                                change={change}
                                modelChange={modelChange}
                                selectedPackage={selectedPackage}
                                upgradeType={upgradeType}
                                selectedUpgrade={selectedUpgrade}
                                showCost={showCost}
                                toggleShowCost={toggleShowCost}
                                isUpgradeLibrary={isUpgradeLibrary}
                                isParametricAnalysis={isParametricAnalysis}
                                upgradeH2kType={upgradeH2kType}
                            />
                        )}
                    </div>
                    <div className={classes.drawerButtonWrapper}>
                        {/* <div className={`${globalStyles.buttons}`}>
                            <Button large type="hollow" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                large
                                onClick={() => {
                                    handleSubmit();
                                }}
                                disabled={submitting || invalid}
                            >
                                {submitting
                                    ? `${selectedUpgrade && !isNew ? "Editing" : "Adding"} Upgrade...`
                                    : `${selectedUpgrade && !isNew ? "Confirm Changes" : "Add Upgrade"}`}
                            </Button>
                        </div> */}
                        {selectedUpgrade && !isParametricAnalysis && (
                            <Button
                                onClick={() => {
                                    if (isUpgradeLibrary) {
                                        deleteUpgradeFromLib(selectedUpgrade);
                                    }

                                    if (!isUpgradeLibrary) {
                                        deleteUpgrade({
                                            packageId: selectedPackage,
                                            upgradeId: selectedUpgrade,
                                        });
                                    }

                                    toggleOpen({});
                                    if (reset) reset();

                                    if (resetState) resetState();
                                }}
                                className={classes.deleteUpgradeText}
                            >
                                Remove Upgrade
                            </Button>
                        )}
                        <div className={`${globalStyles.buttons}`} style={{ marginTop: "0rem" }}>
                            <Button large type="hollow" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button large onClick={handleSubmit} disabled={submitting || invalid}>
                                {submitting
                                    ? `${selectedUpgrade && !isNew ? "Editing" : "Adding"} Upgrade...`
                                    : `${selectedUpgrade && !isNew ? "Confirm Changes" : "Add Upgrade"}`}
                            </Button>
                        </div>
                    </div>
                </div>
            </Drawer>
        );
    },
    isEqual
);

export default UpgradeDrawer;
