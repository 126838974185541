import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import queryString from "query-string";

import {
    selectUserModelDir,
    selectUserUid,
    selectUserModelDirLoading,
    selectParametricDir,
    selectIsParametricDirLoading,
} from "store/users/selectors";
import { actions as userActions } from "store/users";
import { createParametricAnalysis } from "store/parametricAnalysis/thunk";
import { selectIsParametricAnalysisCreating } from "store/parametricAnalysis/selectors";

import SelectFolder from "components/SelectModelFolder";
import { CloseDialog } from "components/Dialog";
import SectionHeading from "components/SectionHeading";
import Button from "components/Button";
import Input from "components/Input";
import InputRow from "components/Input/Row";

import classes from "./styles.module.scss";

const { fetchUserDir } = userActions;

const CreateParametricAnalysis = ({
    userModelDir,
    userUid,
    fetchUserDir,
    parametricDir,
    isUserModelDirLoading,
    isParametricDirLoading,
    createParametricAnalysis,
    isParametricAnalysisCreating,
}) => {
    const history = useHistory();
    const { search } = useLocation();

    const values = queryString.parse(search);

    const [isSelectingFolderOpen, setIsSelectingFolderOpen] = useState(false);
    const [isSelectingModelOpen, setIsSelectingModelOpen] = useState(false);

    const [newAnalysis, setNewAnalysis] = useState({
        analysisName: "",
        selectedModelId: null,
        currentFolderId: values.folderId || null,
    });

    useEffect(() => {
        if (!userUid) return;

        fetchUserDir(userUid);
    }, [userUid, fetchUserDir]);

    // console.log("userModelDir", userModelDir, isUserModelDirLoading);

    const { folders: modelDirFolders = {}, models = {} } = userModelDir;
    const { folders: parametricAnalysisFolders = {} } = parametricDir;

    return (
        <div className={classes.create}>
            <SectionHeading
                className={classes.createHeading}
                title="Create New Parametric Analysis"
                subtitle="Provide some basic information about your parametric analysis to get things started."
            />
            <CloseDialog closeActions={() => history.goBack()} className={classes.close} />
            <div className={classes.card}>
                <InputRow>
                    <Input
                        label="Analysis Name"
                        input={{
                            value: newAnalysis.analysisName,
                            onChange: (val) => setNewAnalysis({ ...newAnalysis, analysisName: val }),
                        }}
                    />
                </InputRow>
                <InputRow
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        fontSize: "0.875rem",
                    }}
                >
                    <p>
                        Selected Model -{" "}
                        <strong>
                            {newAnalysis.selectedModelId ? `${models[newAnalysis.selectedModelId].name}` : "NONE"}
                        </strong>
                    </p>
                    <p
                        style={{
                            cursor: "pointer",
                            color: "#0049c6",
                            fontWeight: 500,
                        }}
                        className={classes.folderText}
                        onClick={() => setIsSelectingModelOpen(!isSelectingModelOpen)}
                    >
                        Select Model
                    </p>
                </InputRow>
                <InputRow
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        fontSize: "0.875rem",
                    }}
                >
                    <p>
                        Current folder -{" "}
                        <strong>
                            {newAnalysis.currentFolderId
                                ? `${parametricAnalysisFolders[newAnalysis.currentFolderId].name}`
                                : "Parametric Analysis Directory"}
                        </strong>
                    </p>
                    <p
                        style={{
                            cursor: "pointer",
                            color: "#0049c6",
                            fontWeight: 500,
                        }}
                        className={classes.folderText}
                        onClick={() => setIsSelectingFolderOpen(!isSelectingFolderOpen)}
                    >
                        Select Folder
                    </p>
                </InputRow>
                <InputRow>
                    <Button
                        style={{ width: "fit-content", margin: "0 auto" }}
                        disabled={
                            !newAnalysis.selectedModelId ||
                            newAnalysis.analysisName.length < 3 ||
                            isParametricAnalysisCreating
                        }
                        onClick={async () => {
                            const id = await createParametricAnalysis(newAnalysis, userUid);

                            history.push(`/parametric-analysis/analysis/${id}`);
                        }}
                    >
                        {isParametricAnalysisCreating
                            ? "Creating Parametric Analysis..."
                            : "Create Parametric Analysis"}
                    </Button>
                </InputRow>
            </div>
            <SelectFolder
                folderToMove={isSelectingModelOpen}
                folders={modelDirFolders}
                models={models}
                close={() => setIsSelectingModelOpen(!isSelectingModelOpen)}
                isMoving={false}
                isSelectingModel={true}
                onSelect={(selectedModel) => setNewAnalysis({ ...newAnalysis, selectedModelId: selectedModel })}
            />
            <SelectFolder
                folderToMove={isSelectingFolderOpen}
                folders={parametricAnalysisFolders}
                close={() => setIsSelectingFolderOpen(!isSelectingFolderOpen)}
                isMoving={false}
                title="Parametric Analysis Directory"
                onSelect={(selectedFolder) => setNewAnalysis({ ...newAnalysis, currentFolderId: selectedFolder })}
            />
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    userModelDir: selectUserModelDir,
    userUid: selectUserUid,
    isUserModelDirLoading: selectUserModelDirLoading,
    parametricDir: selectParametricDir,
    isParametricDirLoading: selectIsParametricDirLoading,
    isParametricAnalysisCreating: selectIsParametricAnalysisCreating,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserDir: (uid) => dispatch(fetchUserDir(uid)),
    createParametricAnalysis: (analysisToCreate, userUid) =>
        dispatch(createParametricAnalysis(analysisToCreate, userUid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateParametricAnalysis);
