import types from "./types";
import { updateObject } from "utils";
import moment from "moment";

const initialState = {
    createdAt: null,
    email: null,
    language: "English",
    name: "",
    permissions: null,
    uid: null,
    units: "Metric",
    loading: false,
    modelDirLoading: false,
    commDirLoading: false,
    batchDirLoading: false,
    isParametricDirLoading: true,
    orgLoading: false,
    error: null,
    folders: [],
    modelDir: {},
    commDir: {},
    codeLib: {},
    userMeta: {},
    parametricDir: {},
    lastActivity: null,
    didUserSawNewDrawing: false,
    organization: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USER_DATA:
            return userInit(state, action);
        case types.GET_USER_DIR:
            return userDir(state, action);
        case types.GET_USER_COMM_DIR:
            return userCommDir(state, action);
        case types.GET_USER_BATCH_DIR:
            return userBatchDir(state, action);
        case types.GET_USER_ORG:
            return userOrganization(state, action);
        case types.GET_USER_DATA_START:
            return getUserStart(state, action);
        case types.GET_USER_DATA_FAIL:
            return getUserFail(state, action);
        case types.GET_USER_CODE_LIB:
            return userCodeLib(state, action);
        case types.SET_CODE_IN_LIB:
            return setCodeInLib(state, action);
        case types.REMOVE_FROM_DIR:
            return removeFromDir(state, action);
        case types.UPDATE_USER:
            return updateUser(state, action);
        case types.TOGGLE_DIR_LOADING:
            return toggleDirLoading(state, action);
        case types.TOGGLE_COMM_DIR_LOADING:
            return toggleCommDirLoading(state, action);
        case types.TOGGLE_BATCH_DIR_LOADING:
            return toggleBatchDirLoading(state, action);
        case types.TOGGLE_ORG_LOADING:
            return toggleOrganizationLoading(state, action);
        case types.UPDATE_USER_META:
            return updateUserMeta(state, action);
        case types.UPDATE_USER_PREFERENCES:
            return updateUserPreferences(state, action);
        case types.REMOVE_CODE_FROM_LIB:
            return removeCodeFromLib(state, action);
        case types.FETCH_PARAMETRIC_ANALYSIS_DIR_START:
            return updateObject(state);
        case types.FETCH_PARAMETRIC_ANALYSIS_DIR_SUCCESS:
            return fetchParametricAnalysisDirSuccess(state, action);
        case types.FETCH_PARAMETRIC_ANALYSIS_DIR_FAIL:
            return updateObject(state, {
                isParametricDirLoading: false,
                parametricDir: {},
                parametricDirError: action.payload,
            });
        case types.TOGGLE_PARAMETRIC_ANALYSIS_DIR_LOADING:
            return updateObject(state, { isParametricDirLoading: action.payload });
        default:
            return state;
    }
};

const updateUser = (state, action) => updateObject(state, action.data);

const updateUserMeta = (state, action) =>
    updateObject(state, {
        userMeta: updateObject(state.userMeta, action.data),
    });

const updateUserPreferences = (state, action) =>
    updateObject(state, {
        userPreferences: updateObject(state.userPreferences, action.data),
    });

const getUserStart = (state, action) => updateObject(state, { loading: true });

const toggleDirLoading = (state, action) => updateObject(state, { modelDirLoading: action.modelDirLoading });

const toggleCommDirLoading = (state, action) => updateObject(state, { commDirLoading: action.commDirLoading });

const toggleBatchDirLoading = (state, action) => updateObject(state, { batchDirLoading: action.batchDirLoading });

const toggleOrganizationLoading = (state, action) => updateObject(state, { orgLoading: action.orgLoading });

const getUserFail = (state, action) => updateObject(state, { loading: false, error: action.error });

const removeFromDir = (state, action) => {
    const { modelDir: { models: { [action.modelId]: _, ...rest } = {} } = {} } = state;

    return updateObject(state, {
        modelDir: {
            ...state.modelDir,
            models: {
                ...rest,
            },
        },
    });
};

const userDir = (state, action) =>
    updateObject(state, {
        modelDir: {
            folders: action.modelDir.folders,
            models: action.modelDir.models,
        },
    });

const userCommDir = (state, action) =>
    updateObject(state, {
        commDir: {
            folders: action.commDir.folders,
            communities: action.commDir.communities,
        },
    });

const userBatchDir = (state, action) =>
    updateObject(state, {
        batchDir: {
            batches: action?.batchDir?.batches || {},
        },
    });

const userOrganization = (state, action) =>
    updateObject(state, {
        organization: {
            ...state.organization,
            details: {
                ...(state?.organization?.details || {}),
                [action.orgId]: action.organizationData,
            },
        },
    });

const userCodeLib = (state, action) =>
    updateObject(state, {
        codeLib: action.codeLib,
    });

const setCodeInLib = (state, action) => {
    const { codeLib = {} } = state;
    const { [action.componentType]: componentType = {} } = codeLib;
    const { codes = {} } = componentType;

    return updateObject(state, {
        codeLib: updateObject(codeLib, {
            [action.componentType]: updateObject(componentType, {
                codes: updateObject(codes, {
                    [action.codeRef]: action.code,
                }),
            }),
        }),
    });
};

const removeCodeFromLib = (state, action) => {
    const { codeLib = {} } = state;
    const { [action.componentType]: componentType = {} } = codeLib;
    const { codes: { [action.codeRef]: codeToDelete, ...rest } = {} } = componentType;

    return updateObject(state, {
        codeLib: updateObject(codeLib, {
            [action.componentType]: updateObject(componentType, {
                codes: rest,
            }),
        }),
    });
};

const userInit = (state, action) =>
    updateObject(state, {
        createdAt: action.userData.createdAt.toDate(),
        email: action.userData.email,
        language: action.userData.language,
        name: action.userData.name,
        permissions: action.userData.permissions,
        uid: action.userData.uid,
        units: action.userData.units,
        userMeta: action.userData.userMeta,
        userPreferences: action.userData.userPreferences,
        loading: false,
        error: false,
        lastActivity: moment(action.userData.lastActivity).format(),
        userPermissions: action.userData.userPermissions,
        nonErsUser: action.userData?.nonErsUser || false,
        didUserSawNewDrawing: action.userData?.didUserSawNewDrawing || false,
        organization: action.userData?.organization ? { memberOrgIds: action.userData?.organization } : {},
        creditSummary: action.userData?.creditSummary || {},
    });

const fetchParametricAnalysisDirSuccess = (state, action) => {
    return updateObject(state, {
        parametricDir: action.payload,
        isParametricDirLoading: false,
    });
};
