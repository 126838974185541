import moment from "moment";

import { fieldValue, firestore, USERS_COLL } from "_firebase";

import {
    createFolderStart,
    createFolderSuccess,
    createFolderError,
    deleteFolderStart,
    deleteFolderSuccess,
    deleteFolderError,
    renameFolderStart,
    renameFolderSuccess,
    renameFolderError,
    moveFolderStart,
    moveFolderSuccess,
    moveFolderError,
    batchDeleteFolderStart,
    batchDeleteFolderSuccess,
    batchDeleteFolderError,
} from "./actions";

import { actions } from "store/users";

const { fetchUserDir, fetchUserCommDir } = actions;

const getUserDir = (uid) => firestore.doc(`${USERS_COLL}/${uid}`).collection("modelDir").doc("directory").get();
const getCommDir = (uid) => firestore.doc(`${USERS_COLL}/${uid}`).collection("commDir").doc("directory").get();

const getDirectoryVariables = (dirType) => {
    var fetchDir = fetchUserDir;
    var getDir = getUserDir;

    if (dirType === "commDir") {
        fetchDir = fetchUserCommDir;
        getDir = getCommDir;
    }

    return ({
        fetchDir,
        getDir
    });
}

export const createFolder = (uid, newFolderName, parentFolderId = null, dirType = "modelDir") => (dispatch) => {
    const { fetchDir } = getDirectoryVariables(dirType);

    dispatch(createFolderStart());

    // Generates firebase unique IDs
    // Source - https://www.reddit.com/r/Firebase/comments/lneeaz/need_to_create_unique_id_within_cloud_function/go0fhy1/?context=8&depth=9
    const folderId = firestore.doc(`${USERS_COLL}/${uid}`).collection(dirType).doc().id;

    return firestore
        .doc(`${USERS_COLL}/${uid}`)
        .collection(dirType)
        .doc("directory")
        .update({
            [`folders.${folderId}`]: {
                name: newFolderName,
                lastEdited: moment().format(),
                parentFolderId,
            },
        })
        .then(async () => await dispatch(fetchDir(uid)))
        .then(() => dispatch(createFolderSuccess()))
        .catch((err) => dispatch(createFolderError(err)));
};

export const deleteFolder = (folderId, uid, dirType = "modelDir") => async (dispatch) => {
    const { fetchDir, getDir } = getDirectoryVariables(dirType);

    dispatch(deleteFolderStart());

    const userDir = (await getDir(uid)).data();

    // Move subfolders to the main directory
    const folders = userDir.folders;

    // TODO: if incorrect dirType used then return to avoid creating new subfolders/items with missing properties in wrong dir
    // if (!(Object.keys(folders).includes(folderId))) {
    //     console.log(`${dirType} does not contain this folder. Cancelling action.`);
    //     return dispatch(deleteFolderError(`Folder not found in ${dirType}`));
    // }

    const foldersIds =
        Object.entries(folders)
            .filter(([key, { parentFolderId }]) => parentFolderId === folderId)
            .map(([key]) => key) || [];

    if (foldersIds.length > 0) {
        for await (const id of foldersIds) {
            firestore
                .doc(`${USERS_COLL}/${uid}`)
                .collection(dirType)
                .doc("directory")
                .update({
                    [`folders.${id}.parentFolderId`]: null,
                });
        }
    }

    // Move models to the model directory
    const models = userDir.models;

    if (models) {
        const modelsIds =
            Object.entries(models)
                .filter(([key, { parentFolderId }]) => parentFolderId === folderId)
                .map(([key]) => key) || [];

        if (modelsIds.length > 0) {
            for await (const id of modelsIds) {
                firestore
                    .doc(`${USERS_COLL}/${uid}`)
                    .collection(dirType)
                    .doc("directory")
                    .update({
                        [`models.${id}.parentFolderId`]: null,
                    });
            }
        }
    }

    // Move communities to the community directory
    const communities = userDir.communities;

    if (communities) {
        const commIds =
            Object.entries(communities)
                .filter(([key, { parentFolderId }]) => parentFolderId === folderId)
                .map(([key]) => key) || [];

        if (commIds.length > 0) {
            for await (const id of commIds) {
                firestore
                    .doc(`${USERS_COLL}/${uid}`)
                    .collection(dirType)
                    .doc("directory")
                    .update({
                        [`communities.${id}.parentFolderId`]: null,
                    });
            }
        }
    }

    // Delete folder
    return firestore
        .doc(`${USERS_COLL}/${uid}`)
        .collection(dirType)
        .doc("directory")
        .update({
            [`folders.${folderId}`]: fieldValue.delete(),
        })
        .then(() => dispatch(fetchDir(uid)))
        .then(() => dispatch(deleteFolderSuccess()))
        .catch((err) => dispatch(deleteFolderError(err)));
};

export const renameFolder = (folderId, uid, newName, dirType = "modelDir") => (dispatch) => {
    // TODO: if incorrect dirType used then return to avoid creating a new folder with missing properties in wrong dir
    const { fetchDir } = getDirectoryVariables(dirType);

    dispatch(renameFolderStart());

    return firestore
        .doc(`${USERS_COLL}/${uid}`)
        .collection(dirType)
        .doc("directory")
        .update({
            [`folders.${folderId}.name`]: newName,
        })
        .then(() => dispatch(fetchDir(uid)))
        .then(() => dispatch(renameFolderSuccess()))
        .catch((err) => dispatch(renameFolderError(err)));
};

export const moveFolder = (folderId, uid, moveToId, skipFetch = false, dirType = "modelDir") => (dispatch) => {
    // TODO: if incorrect dirType used then return to avoid creating a new folder with missing properties in wrong dir
    const { fetchDir } = getDirectoryVariables(dirType);

    dispatch(moveFolderStart());

    return firestore
        .doc(`${USERS_COLL}/${uid}`)
        .collection(dirType)
        .doc("directory")
        .update({
            [`folders.${folderId}.parentFolderId`]: moveToId,
        })
        .then(() => (!skipFetch ? dispatch(fetchDir(uid)) : {}))
        .then(() => dispatch(moveFolderSuccess()))
        .catch((err) => dispatch(moveFolderError(err)));
};

export const batchFolderRemove = (foldersToDelete, uid, dirType = "modelDir") => async (dispatch) => {
    try {
        // TODO: if incorrect dirType used then return to avoid creating a new folder with missing properties in wrong dir
        const userDirSnapshot = await firestore.doc(`${USERS_COLL}/${uid}/${dirType}/directory`).get();
        const userDir = userDirSnapshot.data();
        const folders = userDir.folders || {};
        const models = userDir.models || {};

        // Split folderIds into chunks of 10 or fewer IDs
        const chunks = [];
        for (let i = 0; i < foldersToDelete.length; i += 10) {
            chunks.push(foldersToDelete.slice(i, i + 10));
        }

        // Iterate over each chunk and perform batch delete operations
        for (const chunk of chunks) {
            // Create a batch
            const batch = firestore.batch();

            // Iterate over each folder ID in the chunk and add batch delete operations
            chunk.forEach((folderId) => {
                const folderRef = firestore.doc(`${USERS_COLL}/${uid}/${dirType}/directory`);
                batch.update(folderRef, {
                    [`folders.${folderId}`]: fieldValue.delete(),
                });

                // Find child folders of the current folder
                const childFolders = Object.entries(folders)
                    .filter(([key, { parentFolderId }]) => parentFolderId === folderId)
                    .map(([key]) => key);

                // Add batch operations to remove parentFolderId from child folders
                childFolders.forEach((childId) => {
                    batch.update(folderRef, {
                        [`folders.${childId}.parentFolderId`]: null,
                    });
                });

                const modelsInFolder = Object.entries(models)
                    .filter(([key, { parentFolderId }]) => parentFolderId === folderId)
                    .map(([key]) => key);

                // Add batch operations to remove parentFolderId from models
                modelsInFolder.forEach((modelId) => {
                    batch.update(folderRef, {
                        [`models.${modelId}.parentFolderId`]: null,
                    });
                });
            });

            // Commit the batch
            await batch.commit();
        }

        dispatch(batchDeleteFolderSuccess());
    } catch (error) {
        console.error("Error while deleting multiple folders:", error);
        dispatch(batchDeleteFolderError(error.message));
    }
};

// (For deugging) Removes any folders with missing properties in a directory (happens only if folderId is not in directory)
// Issue can be avoided by passing the correct dirType in the other functions
export const removeInvalidFolders = async (uid, dirType = "modelDir") => {
    const { getDir } = getDirectoryVariables(dirType);

    const userDir = (await getDir(uid)).data();

    const folders = userDir.folders;

    const foldersIds =
        Object.entries(folders)
            .filter(([key, { parentFolderId, name }]) => parentFolderId === undefined || !name)
            .map(([key]) => key) || [];

    if (foldersIds.length > 0) {
        console.log(`Invalid folders were detected in ${dirType} and are deleted: ${foldersIds}`);
        for await (const id of foldersIds) {
            firestore
                .doc(`${USERS_COLL}/${uid}`)
                .collection(dirType)
                .doc("directory")
                .update({
                    [`folders.${id}`]: fieldValue.delete(),
                });
        }
    }
}