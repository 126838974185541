import React, { useEffect, useRef, useState } from "react";
import classes from "./style.module.scss";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isEmpty } from "lodash";
import Dialog from "components/Dialog";
import Button from "components/Button";
import FolderIcon from "assets/images/icons/folder.svg";
import ModelIcon from "assets/images/icons/model.svg";
import closeCircle from "assets/images/icons/closeCircle.svg";
import blueArrowRight from "assets/images/icons/blueArrowRight.svg";
import goBackFolder from "assets/images/icons/goBackFolder.svg";
import Loading from "components/Loading";
import { selectUserModelDir, selectUserModelDirLoading, selectUserUid } from "store/users/selectors";
import { selectCommunityModelDir } from "features/Community/_ducks/selectors";
import { actions as userActions } from "store/users";
import { actions as communityActions } from "features/Community/_ducks";

const { fetchUserDir } = userActions;
const { addCommunityModel } = communityActions;

const AddModelDialog = ({
    addModelOpen,
    onClose,
    uid,
    fetchUserDir,
    commModelDir,
    modelDir,
    modelDirLoading,
}) => {
    const dispatch = useDispatch();

    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [preSelectedModel, setPreSelectedModel] = useState(null);
    const [preSelectedModels, setPreSelectedModels] = useState(null);

    const scrollContainerRef = useRef(null);

    useEffect(() => {
        if (uid) {
            fetchUserDir(uid, false);
        }
    }, [uid]);

    const { models = {}, folders = {} } = modelDir;

    const handleModelSelect = (key) => {
        dispatch(addCommunityModel(key));
        onClose();
    };

    const getSelectedModels = (key) => !isEmpty(models)
        ? Object.entries(models)
            .filter(([modelId, { parentFolderId }]) => parentFolderId === key && !Object.keys(commModelDir?.models).includes(modelId))
        : [];

    const handleFolderModelsSelect = (preSelectedModels) => {
        preSelectedModels.forEach(([modelId, value]) => {
            dispatch(addCommunityModel(modelId));
        });
        onClose();
    };

    const modelNames = !isEmpty(models)
        ? Object.entries(models)
            .map(([key, { name, parentFolderId }]) => {
                return {
                    key,
                    name,
                    parentFolderId,
                    type: "model",
                };
            })
            .sort((a, b) => a.name.localeCompare(b.name))
        : [];

    const folderNames = !isEmpty(folders)
        ? Object.entries(folders)
            .map(([key, { name, parentFolderId }]) => {
                return {
                    key,
                    name,
                    parentFolderId,
                    type: "folder",
                };
            })
            .sort((a, b) => a.name.localeCompare(b.name))
        : [];

    const directory = [...folderNames, ...modelNames];

    const filteredDirectory = directory
        .filter(({ parentFolderId }) => {
            return (parentFolderId == null && currentFolderId == null) || parentFolderId === currentFolderId;
        })
        .filter(({ key }) => {
            return !Object.keys(commModelDir?.models || {}).includes(key);
        });

    // this appears when pre-selecting a model
    const preSelectedModelLabel =
        preSelectedModel != null
            ? modelNames.reduce((acc, { key, name }) => {
                if (key === preSelectedModel) {
                    return name;
                } else {
                    return acc;
                }
            }, "")
            : "";

    const selectedFolderLabel =
        selectedFolder != null && !isEmpty(preSelectedModels)
            ? folderNames.reduce((acc, { key, name }) => {
                if (key === selectedFolder) {
                    return `All models in ${name}`;
                } else {
                    return acc;
                }
            }, "")
            : "";

    return (
        <div ref={scrollContainerRef}>
            <Dialog onClose={onClose} open={addModelOpen} classes={{ paperClass: classes.dialogContainer }}>
                <div className={classes.headerContainer}>
                    <div className={classes.headerTextContainer}>
                        {currentFolderId && (
                            <img
                                className={classes.goBackImg}
                                src={goBackFolder}
                                alt="go back"
                                onClick={() => {
                                    setCurrentFolderId(folders[currentFolderId].parentFolderId);
                                    setSelectedFolder("");
                                }}
                            />
                        )}
                        <span className={classes.headerText}>
                            {" "}
                            {currentFolderId ? `${folders[currentFolderId].name}` : "Select a house model"}
                        </span>
                    </div>
                    <img
                        src={closeCircle}
                        alt="close circle"
                        onClick={() => {
                            setPreSelectedModel(null);
                            setPreSelectedModels(null);
                            setCurrentFolderId(null);
                            setSelectedFolder("");
                            onClose();
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={classes.foldersContainer}>
                    {modelDirLoading ? (
                        <div className={classes.loading}>
                            <Loading message="loading" />
                        </div>
                    ) : isEmpty(filteredDirectory) ? (
                        <div className={classes.noFoldersText}>
                            This folder is either empty or its models have already been added to this community.
                        </div>
                    ) : (
                        filteredDirectory.map(({ key, name, type }) => (
                            <div
                                key={key}
                                className={`
                                ${classes.folderContainer} 
                                ${selectedFolder === key && preSelectedModel !== key && classes.selectedFolderContainer}
                                ${preSelectedModel === key && classes.selectedFolderContainer}
                            `}
                                onClick={
                                    type === "folder"
                                        ? () => {
                                            if (selectedFolder === key) {
                                                // Open when double clicked
                                                setCurrentFolderId(key);
                                                setSelectedFolder("");
                                                setPreSelectedModels(null);
                                            } else {
                                                // Highlight folder
                                                setSelectedFolder(key);
                                                setPreSelectedModels(getSelectedModels(key));
                                                setPreSelectedModel(null);
                                            }
                                        }
                                        : () => {
                                            if (preSelectedModel === key) {
                                                // Select when double clicked 
                                                setPreSelectedModel(null);
                                                handleModelSelect(key);
                                            } else {
                                                // Highlight model
                                                setPreSelectedModel(key);
                                                setSelectedFolder(null);
                                                setPreSelectedModels(null);
                                            }
                                        }
                                }
                            >
                                <div className={classes.itemLabel}>
                                    {type === "folder" ? (
                                        <img src={FolderIcon} alt="Folder icon" />
                                    ) : (
                                        <img src={ModelIcon} alt="Model icon" />
                                    )}
                                    <div className={classes.labelRowText}>
                                        <p>{name}</p>
                                        {key === preSelectedModel && (
                                            <p className={classes.selectedModelText}>{`(Selected)`}</p>
                                        )}
                                        {key === selectedFolder && !isEmpty(preSelectedModels) && (
                                            <p className={classes.selectedModelText}>{`(${preSelectedModels.length} Selected)`}</p>
                                        )}
                                    </div>
                                </div>
                                {type === "folder" && (
                                    <img
                                        className={`${selectedFolder !== key && classes.folderImg}`}
                                        src={blueArrowRight}
                                        alt="blue arrow right"
                                    />
                                )}
                            </div>
                        ))
                    )}
                </div>
                <div className={classes.moveToContainer}>
                    <div className={classes.moveToText}>
                        <p>{preSelectedModelLabel || selectedFolderLabel}</p>
                    </div>
                    {selectedFolder ? (
                        <Button
                            onClick={() => {
                                handleFolderModelsSelect(preSelectedModels);
                                setSelectedFolder(null);
                                setPreSelectedModels(null);
                                setCurrentFolderId(null);
                            }}
                            disabled={isEmpty(preSelectedModels)}
                        >
                            Select Models
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                handleModelSelect(preSelectedModel);
                                setPreSelectedModel(null);
                                setCurrentFolderId(null);
                            }}
                            disabled={preSelectedModel === null}
                        >
                            Select Model
                        </Button>
                    )}
                </div>
            </Dialog>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    commModelDir: selectCommunityModelDir,
    modelDir: selectUserModelDir,
    modelDirLoading: selectUserModelDirLoading,
});

const mapDispatchToProps = (dispatch, { history }) => ({
    fetchUserDir: (uid, skipLoading) => dispatch(fetchUserDir(uid, skipLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddModelDialog);
