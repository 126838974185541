import { DashboardAPI } from "utils/api";

// ****************************************************
// LEGACY
// ****************************************************

export const getAllCbatCsvFiles = async (data) => {
    const { buildType = "cbat", ...rest } = data;

    const url = {
        cbat: "/getAllCbatCsvFiles",
        lret: "/getAllRetrofitCsvFiles",
        lnco: "/getAllNewConstCsvFiles",
    }[buildType];

    return DashboardAPI({
        method: "POST",
        url,
        data: rest,
    }).catch((err) => {
        return { status: 500, err, data: {} };
    });
};

export const getCbatBatchSize = async (data) => {
    const { batchId } = data;
    return DashboardAPI({
        method: "POST",
        url: "/getCbatBatchSize",
        data: {
            batchId,
        },
    }).catch((err) => {
        return { status: 500, err, data: {} };
    });
};

// const ecmInputMap = {
//     ["Opt-FloorHeaderIntIns"]: {
//         "NBC_RSI2.97_int": true,
//     },
//     ["Opt-AboveGradeWall"]: {
//         "NC_R-16(eff)_2x6-16inOC_R19-batt_poly_vb": false,
//         "NC_R-22(eff)_2x6-16inOC_R22-batt+1inXPS_poly_vb": false,
//         "NC_R-26(eff)_2x6-16inOC_R19-batt+2inXPS_poly_vb": false,
//         "NC_R-30(eff)_2x6-16inOC_R24-batt+3inMineralWool_poly_vb": false,
//         "NC_R-40(eff)_2x6-16inOC_R24-batt+4.5inXPS_poly_vb": true,
//     },
//     ["Opt-AtticCeilings"]: {
//         "CeilR40-Opt-AtticCeilings": false,
//         "CeilR50-Opt-AtticCeilings": false,
//         "CeilR60-Opt-AtticCeilings": false,
//         "CeilR70-Opt-AtticCeilings": true,
//         "CeilR80-Opt-AtticCeilings": false,
//     },
//     ["Opt-FlatCeilings"]: {
//         "CeilR40-Opt-FlatCeilings": true,
//     },
//     ["Opt-CathCeilings"]: {
//         "CeilR40-Opt-CathCeilings": true,
//     },
//     ["Opt-FoundationSlabBelowGrade"]: {
//         "uninsulated-Opt-FoundationSlabBelowGrade": true,
//         xps2inEffR10: false,
//         xps4inEffR20: false,
//     },
//     ["Opt-FoundationWallIntIns"]: {
//         WoodFrameEffR15: true,
//     },
//     ["Opt-FoundationWallExtIns"]: {
//         "uninsulated-Opt-FoundationWallExtIns": true,
//         "xps1.5inEffR7.5": true,
//         "xps2.5inEffR12.5": false,
//     },
//     ["Opt-FoundationSlabOnGrade"]: {
//         "NBC_936_2.84RSI": true,
//     },
//     ["Opt-ExposedFloor"]: {
//         "NBC_936_5.02RSI": true,
//     },
//     ["Opt-Windows"]: {
//         "NC-3g-LG-u1.08": true,
//         "NC-2g-MG-u1.65": true,
//         "NC-2g-LG-u1.65": false,
//         "NC-3g-MG-u1.08": false,
//     },
//     ["Opt-ACH"]: {
//         "New-Const-air_seal_to_3.50_ach": true,
//         "New-Const-air_seal_to_2.50_ach": false,
//         "New-Const-air_seal_to_1.50_ach": false,
//         "New-Const-air_seal_to_0.60_ach": false,
//         "New-Const-air_seal_to_1.00_ach": true,
//     },
//     ["Opt-DHWSystem"]: {
//         "gas_storagetank_w/powervent_ef0.67": false,
//         GasInstantaneous: false,
//         HPHotWater: true,
//         "elec_storage_ef0.89": true,
//     },
//     ["Opt-DWHR"]: {
//         "None;lpDWHR_00_X": false,
//         "DWHR-eff-55": true,
//     },
//     ["Opt-VentSystem"]: {
//         HRV_sre_67: true,
//     },
//     ["Opt-Heating-Cooling"]: {
//         "elec-baseboard+AC": true,
//         "gas-furnace-ecm+AC": true,
//         CCASHP: false,
//         ASHP: true,
//     },
//     ["Opt-WindowDistribution"]: {
//         "None;id-16_X": false,
//     },
// };

export const startBatchRun = async ({
    batchSize,
    modelId,
    cbatToken,
    ecmInputMap,
    buildType = "cbat",
    params = {},
}) => {
    return DashboardAPI({
        method: "POST",
        url: "/startBatchRun",
        data: {
            sliceLength: 500,
            baseFileID: modelId,
            maxRuns: batchSize,
            cbatToken,
            ecmInputMap,
            buildType,
            params,
        },
    }).catch((err) => {
        return { status: 500, err, data: {} };
    });
};

// ****************************************************
// MAIN
// ****************************************************

export const startParametricEcmValidation = async (data) => {
    return DashboardAPI({
        method: "POST",
        url: "/startParametricEcmValidation",
        data: {
            ...data,
        },
    }).catch((err) => {
        return { status: 500, err, data: {} };
    });
};

export const startParametricRun = async (data) => {
    return DashboardAPI({
        method: "POST",
        url: "/startParametricRun",
        data: {
            ...data,
        },
    }).catch((err) => {
        return { status: 500, err, data: {} };
    });
};
