import React, { useState, useRef } from "react";
import classes from "./style.module.scss";
import { DropDown } from "components/Input/Select";
import Add from "assets/images/icons/JSX/Add";
import { useOutsideClickHook } from "utils/outsideClick";
import { getVentilationTemplate } from "utils/mechanicals";
import { required, maxLength } from "utils/fieldValidation";
import moment from "moment";

const charLim32 = maxLength(32);

const options = [
    {
        value: "bathroom",
        label: "Bathroom",
    },
    {
        value: "rangeHood",
        label: "Range Hood",
    },
    {
        value: "utility",
        label: "Utility",
    },
];

export default ({ accessor, hrvExists, dryerExists, isSupplemental, change, canBeEditedFromParametric }) => {
    const [open, toggleOpen] = useState(false);

    const ref = useOutsideClickHook(() => toggleOpen(false));

    const handleAddSystem = ({ sysType, isSupplemental }) => {
        //suppl defaults | all (not HRV): 72 min | HRV: continuous
        let template = getVentilationTemplate(sysType, isSupplemental);

        if (sysType === "hrv") {
            template = isSupplemental ? { ...template, operationSchedule: { id: 0, value: 1440 } } : template;
            change(`${accessor}.${isSupplemental ? "supplementalSystems" : "wholeHouseSystems"}.hrv`, template);
        } else if (sysType === "dryer" && isSupplemental) {
            change(`${accessor}.supplementalSystems.dryer`, template);
        } else {
            template = isSupplemental ? { ...template, operationSchedule: { id: 2, value: 72 } } : template;
            change(
                `${accessor}.${
                    isSupplemental ? "supplementalSystems" : "wholeHouseSystems"
                }.ventilators.ventilator${moment().format("YYYYMMDDHHmmssSS")}`,
                template
            );
        }
    };

    return (
        <div className={classes.addComponent} ref={ref}>
            <div
                className={`${classes.addText} ${open && classes.open}`}
                onClick={() => (canBeEditedFromParametric ? toggleOpen(!open) : {})}
            >
                {isSupplemental ? `Add Supplemental System` : `Add Whole-house System`}
                <Add />
            </div>
            <DropDown
                className={classes.dropDown}
                options={[
                    !hrvExists && { value: "hrv", label: "HRV/ERV" },
                    isSupplemental && !dryerExists && { value: "dryer", label: "Dryer" },
                    ...options,
                ].filter((value) => Object.keys(value).length !== 0)}
                open={open}
                toggleOpen={toggleOpen}
                onChange={(value) => {
                    handleAddSystem({ sysType: value, isSupplemental });
                }}
                disabled={!canBeEditedFromParametric}
            />
        </div>
    );
};
