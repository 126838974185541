import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import HeatingSystems from "./";
import { isEmpty } from "lodash";

const mapStateToProps = ({ form }, { accessor, formName = "model", canBeEditedFromParametric }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const basementComponents = modelSelector({ form }, `modelData.components.basement`) || {};

    const currentPrimarySystem = Object.keys(selector({ form }, `${accessor}.heating.system`) || {})[0];
    const primarySystemType = selector({ form }, `${accessor}.heating.selected.type`);
    const eStarManufacturerValue = selector({ form }, `${accessor}.heating.selected.eStar.manufacturer`);
    const eStarModelId = selector({ form }, `${accessor}.heating.selected.eStar.modelId`);
    const modelUnits = selector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    const radiantSystem = selector({ form }, `${accessor}.radiantHeating`) || {};
    const additionalOpenings = selector({ form }, `${accessor}.additionalOpenings`) || {};
    const multipleSystems = selector({ form }, `${accessor}.multipleSystems.systems`) || {};
    const supplSystems = selector({ form }, `${accessor}.supplSystems.systems`) || {};

    let noBasementComponents = false;
    if (isEmpty(basementComponents)) {
        noBasementComponents = true;
    } else {
        noBasementComponents = Object.keys(basementComponents)
            .reduce((acc, key) => [...acc, isEmpty(basementComponents[key])], [])
            .every((el) => el);
    }

    return {
        accessor,
        currentPrimarySystem,
        primarySystemType,
        eStarManufacturerValue,
        eStarModelId,
        modelUnits,
        radiantSystem,
        additionalOpenings,
        multipleSystems,
        supplSystems,
        noBasementComponents,
        formName,
        canBeEditedFromParametric,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HeatingSystems);
