import React, { useState } from "react";
import classes from "../style.module.scss";
import HouseIcon from "assets/images/icons/JSX/House";
import CommunitiesIcon from "assets/images/icons/JSX/CommunitiesIcon";
import Button from "components/Button";
import SectionHeading from "components/SectionHeading";
import CodeLibIcon from "assets/images/icons/JSX/CodeLib";
import { useHistory } from "react-router";
import { CreateCommunityDialog } from "..";

export const CreateFirstModel = ({ currentFolderId }) => {
    const history = useHistory();

    return (
        <div className={classes.dashboardEmpty}>
            <div className={classes.singleBox}>
                <div></div>
                <div className={classes.box}>
                    <HouseIcon />
                    <h3>Create your first House Model in this folder</h3>
                    <Button
                        onClick={() =>
                            history.push(`/create${currentFolderId ? `?folderId=${currentFolderId}` : ""}`)
                        }
                    >
                        Create New Model
                    </Button>
                </div>
                <div></div>
            </div>
        </div>
    );
}

export const CreateFirstCommunity = ({ currentFolderId, communityLoading, createCommunity, uid }) => {
    const [toCreateComm, setToCreateComm] = useState(false);
    const [communityName, setCommunityName] = useState("");

    return (
        <div className={classes.dashboardEmpty}>
            <div className={classes.singleBox}>
                <div></div>
                <div className={classes.box}>
                    <CommunitiesIcon />
                    <h3>Create your first Community in this folder</h3>
                    <Button
                        onClick={() => setToCreateComm(true)}
                    >
                        Create New Community
                    </Button>
                </div>
                <div></div>
            </div>
            <CreateCommunityDialog
                openDialog={toCreateComm}
                newCommunityName={communityName}
                setNewCommunityName={(newVal) => setCommunityName(newVal)}
                onClick={async () => {
                    await createCommunity(uid, communityName, currentFolderId);
                }}
                onClose={() => setToCreateComm(false)}
                communityLoading={communityLoading}
            />
        </div>
    );
}

export const CreateVeryFirstModel = () => {
    const history = useHistory();

    return (
        <div className={classes.dashboardEmpty}>
            {/* Empty directory */}
            <SectionHeading
                title="Looks like you are a new user!"
                subtitle="Get started by uploading your code library or diving straight in and creating your first house model."
                className={classes.emptyHeader}
            />
            <div className={classes.boxes}>
                <div className={classes.box}>
                    <CodeLibIcon />
                    <h3>Upload your Code Library</h3>
                    <p>
                        Long-time HOT2000 user? Upload your code library to bring Volta SNAP up to speed with
                        all your favourite component assemblies.
                    </p>
                    <Button onClick={() => history.push("/code-library")}>Upload Your Code Library</Button>
                </div>
                <div className={classes.box}>
                    <HouseIcon />
                    {/* in this folder */}
                    <h3>Create your first House Model </h3>
                    <p>
                        Get started on your first model! Have your building’s construction PDFs ready to upload
                        and get ready to start drawing!
                    </p>
                    <Button onClick={() => history.push("/create")}>Create New Model</Button>
                </div>
            </div>
        </div>
    );
}