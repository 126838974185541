import isEmpty from "lodash/isEmpty";
import cleanComponents from "utils/cleanComponents";
import { getObjValAtPath } from "utils/fields";

const codeRefMap = {
    Wall: {
        path: ["wallInsType.codeRef"],
        componentType: ["wall"],
    },
    BasementWall: {
        path: ["wall.intAddedInsType.codeRef"],
        componentType: ["basement"],
    },
    Ceiling: {
        path: ["ceilingInsType.codeRef"],
        componentType: ["ceiling"],
    },
    CeilingFlat: {
        path: ["ceilingInsType.codeRef"],
        componentType: ["ceiling"],
    },
    CrawlspaceWall: {
        path: ["wall.crawlspaceWallInsType.codeRef", "wall.ponyWall.ponyWallInsType.codeRef"],
        componentType: ["crawlspace", "basement"],
    },
    Floor: {
        path: ["expFloorInsType.codeRef"],
        componentType: ["expFloor"],
    },
    FloorsAbove: {
        path: ["floor.floorsAboveInsType.codeRef", "floor.floorsAboveInsType.codeRef"],
        componentType: ["basement", "crawlspace"],
    },
    FloorsAdded: {
        path: ["floor.slabInsType.codeRef", "floor.slabInsType.codeRef", "floor.slabInsType.codeRef"],
        componentType: ["basement", "crawlspace", "slab"],
    },
    Lintel: {
        path: ["lintelInsType.codeRef", "wall.lintelInsType.codeRef", "wall.lintelInsType.codeRef"],
        componentType: ["wall", "basement", "crawlspace"],
    },
};

// export const getMatchCodeRefs = (components, newCodeRef, editingCodeRef) => {
//     const { path = '', componentType = '' } = codeRefMap[newCodeRef.split('-')[0]];

//     if (isEmpty(components[componentType]) || isEmpty(editingCodeRef)) {
//         return [];
//     }

//     return Object.keys(components[componentType]).reduce((cache, compKey) => {
//         const foundCodeRef = path.split('.').reduce((acc, curr) => acc[curr], components[componentType][compKey]);
//         if (foundCodeRef === editingCodeRef) {
//             return [...cache, `modelData.components.${componentType}.${compKey}.${path.split('.codeRef')[0]}`];
//         } else {
//             return cache;
//         }
//     }, []);
// };

export const getMatchCodeRefs = (components, newCodeRef, editingCodeRef) => {
    if (newCodeRef.includes("FloorHeader") || editingCodeRef.includes("FloorHeader")) {
        return getMatchFloorHeaderCodeRefs(components, editingCodeRef);
    }

    const { path: pathArray = [], componentType = [] } = codeRefMap[newCodeRef.split("-")[0]];

    const accessorList = pathArray.reduce((acc, path, ind) => {
        if (isEmpty(components[componentType[ind]]) || isEmpty(editingCodeRef)) {
            return acc;
        }

        return [
            ...acc,
            ...Object.keys(components[componentType[ind]]).reduce((cache, compKey) => {
                const foundCodeRef = path
                    .split(".")
                    .reduce((acc, curr) => acc[curr], components[componentType[ind]][compKey]);
                if (foundCodeRef === editingCodeRef) {
                    return [
                        ...cache,
                        `modelData.components.${componentType[ind]}.${compKey}.${path.split(".codeRef")[0]}`,
                    ];
                } else {
                    return cache;
                }
            }, []),
        ];
    }, []);

    return accessorList;
};

export const getMatchFloorHeaderCodeRefs = (components, editingCodeRef) => {
    const { wall = {}, basement = {}, crawlspace = {} } = components;

    let accessorsToUpdate = [];
    if (!isEmpty(wall)) {
        Object.entries(wall).forEach(([wallKey, wallObj]) => {
            const { subcomponents: { floorHeader = {} } = {} } = wallObj;

            if (!isEmpty(floorHeader)) {
                Object.keys(floorHeader).forEach((floorHeaderKey) => {
                    const { floorHeaderInsType: { codeRef: floorHeaderCodeRef = "" } = {} } =
                        floorHeader[floorHeaderKey];

                    if (floorHeaderCodeRef === editingCodeRef) {
                        accessorsToUpdate = [
                            ...accessorsToUpdate,
                            `modelData.components.wall.${wallKey}.subcomponents.floorHeader.${floorHeaderKey}.floorHeaderInsType`,
                        ];
                    }
                });
            }
        });
    }

    if (!isEmpty(basement)) {
        Object.entries(basement).forEach(([basementKey, basementObj]) => {
            const { subcomponents: { floorHeader = {} } = {} } = basementObj;

            if (!isEmpty(floorHeader)) {
                Object.keys(floorHeader).forEach((floorHeaderKey) => {
                    const { floorHeaderInsType: { codeRef: floorHeaderCodeRef = "" } = {} } =
                        floorHeader[floorHeaderKey];

                    if (floorHeaderCodeRef === editingCodeRef) {
                        accessorsToUpdate = [
                            ...accessorsToUpdate,
                            `modelData.components.basement.${basementKey}.subcomponents.floorHeader.${floorHeaderKey}.floorHeaderInsType`,
                        ];
                    }
                });
            }
        });
    }

    if (!isEmpty(crawlspace)) {
        Object.entries(crawlspace).forEach(([crawlspaceKey, crawlspaceObj]) => {
            const { subcomponents: { floorHeader = {} } = {} } = crawlspaceObj;

            if (!isEmpty(floorHeader)) {
                Object.keys(floorHeader).forEach((floorHeaderKey) => {
                    const { floorHeaderInsType: { codeRef: floorHeaderCodeRef = "" } = {} } =
                        floorHeader[floorHeaderKey];

                    if (floorHeaderCodeRef === editingCodeRef) {
                        accessorsToUpdate = [
                            ...accessorsToUpdate,
                            `modelData.components.crawlspace.${crawlspaceKey}.subcomponents.floorHeader.${floorHeaderKey}.floorHeaderInsType`,
                        ];
                    }
                });
            }
        });
    }

    return accessorsToUpdate;
};

export const getAllComponents = (componentType, components, currentComponent = "") =>
    Object.keys(components)
        .reduce((cache, key) => {
            // get id and location
            const cleanedComps = cleanComponents(components);
            const parentComp = cleanedComps[key] || {};

            let componentArray = Object.keys(parentComp).map((componentId) => {
                const { label = "", constructionType: { id: constructionTypeId } = {} } = parentComp[componentId];
                const value = `${key}.${componentId}`;

                const isCurrentComponent = currentComponent !== "" && currentComponent.includes(value);

                return {
                    label,
                    value,
                    isFlat: [2, 3].includes(constructionTypeId),
                    disabled: isCurrentComponent,
                };
            });

            if (componentType === "ceiling-flat") {
                componentArray = componentArray.filter(({ isFlat }) => isFlat);
            } else if (componentType === "ceiling") {
                componentArray = componentArray.filter(({ isFlat }) => !isFlat);
            }

            return [...cache, ...componentArray];
        }, [])
        .filter(({ value = "" }) => value.includes(componentType.split("-")[0])); //splitting handles "ceiling-flat"

//Only call this with 'component' === 'window' 'door' or 'floorHeader'
export const getAllSubcomponents = (componentType, components, currentComponent) =>
    Object.keys(components)
        .reduce((cache, key) => {
            const cleanedComps = cleanComponents(components);
            //this returns a flat array with value (accessor) and label of subcomponents

            const parentComp = cleanedComps[key] || {};
            const subcomponents = Object.keys(parentComp).reduce((compCache, parentKey) => {
                const { subcomponents: { [componentType]: subComps = {}, door: doorObj = {} } = {} } =
                    parentComp[parentKey];
                return [
                    ...compCache,
                    ...Object.keys(subComps).reduce((subCache, subCompKey) => {
                        return [
                            ...subCache,
                            {
                                value: `${key}.${parentKey}.subcomponents.${componentType}.${subCompKey}`,
                                label: subComps[subCompKey].label,
                            },
                        ];
                    }, []),
                    ...(componentType === "window"
                        ? getWindowsOnDoors(doorObj, `${key}.${parentKey}.subcomponents`)
                        : []),
                ];
            }, []);

            const subComponentArray = subcomponents.map((subcomp) => {
                const { label = "", value = "" } = subcomp;

                const isCurrentComponent = currentComponent !== "" && currentComponent.includes(value);

                return {
                    label,
                    value,
                    disabled: isCurrentComponent,
                };
            });

            return [...cache, ...subComponentArray];
        }, [])
        .filter(({ value = "" }) => value.includes(componentType));

const getWindowsOnDoors = (doorsObj, currentPath) => {
    return Object.keys(doorsObj).reduce((cache, doorKey) => {
        const { subcomponents: { window = {} } = {} } = doorsObj[doorKey];
        return [
            ...cache,
            ...Object.keys(window).reduce((subCache, subCompKey) => {
                return [
                    ...subCache,
                    {
                        value: `${currentPath}.door.${doorKey}.subcomponents.window.${subCompKey}`,
                        label: window[subCompKey].label,
                    },
                ];
            }, []),
        ];
    }, []);
};

export const getCompAreaForCostCalc = (selectedComponents = [], components = {}, compType = "") => {
    //Where selectedComponents is an array like ["ceiling.ceiling001", "ceiling.ceiling002"]
    //components is the object at modelData.components
    //compType aligns with "upgradeType"

    if (isEmpty(selectedComponents) || isEmpty(components) || compType === "") return 0;

    return selectedComponents
        .map((compAccessor) => {
            if (["basementSlab", "slab"].includes(compType)) {
                const { measurements: { isRectangular, length = 0, width = 0, area = 0 } = {} } =
                    getObjValAtPath(components, `${compAccessor}.floor`) || {};

                return isRectangular ? length * width : area;
            } else if (["basementWall", "crawlspace"].includes(compType)) {
                const { measurements: { isRectangular, length = 0, width = 0, perimeter = 0 } = {} } =
                    getObjValAtPath(components, `${compAccessor}.floor`) || {};
                const { measurements: { height = 0 } = {} } = getObjValAtPath(components, `${compAccessor}.wall`) || {};

                return isRectangular ? height * 2 * (length + width) : height * perimeter;
            } else if (["window", "skylights"].includes(compType)) {
                const { numIdentical = 1, measurements: { width, height } = {} } =
                    getObjValAtPath(components, compAccessor) || {};

                // return (numIdentical * (width * height)) / 1000000; //mm2 to m2
                //returning window count
                return 1 * numIdentical;
            } else if (compType === "door") {
                return 1; //count
            }

            const { measurements: { area } = {} } = getObjValAtPath(components, compAccessor) || {};

            return area;
        })
        .reduce((sum, curr) => sum + curr, 0);
};
