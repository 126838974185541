import types from "./types";
import { firestore, storage, fieldValue, storageRef, MODEL_COLL, USERS_COLL, MODEL_ERRORS_COLL } from "_firebase";
import { exportH2kFile } from "utils/h2kinout/api";
import { convertPdfToImage } from "utils/drawing/api";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import { imageDataSnapshotUpdate } from "../DrawingNew/_ducks/actions";
import { updateImageGallerySnapshot } from "store/imageGallery/actions";
import { actions as userActions } from "store/users";
// import { actions as resultsActions } from "../Review/Results/_ducks"
import moment from "moment";
import { duplicateModelData } from "utils/create/api";
import { tagModelFile, removeTagFromModelFile } from "utils/model/api";

import {
    removeModelFromSharedWithDirs,
    renameModelSharedWithDirs,
    shareModelWithOrgMembers,
    shareFolderModelsWithOrgMembers,
    flagModelWithErrorForReview,
} from "utils/fileSharing/api";

import { updateChbaCommunityWithModel, populateChbaCommunity } from "utils/communities/api";
import { fetchDrawingData } from "../DrawingNew/_ducks/thunk";

const { getUpgrades, updateUpgradeSnapshot } = upgradeActions;
const { updateUserData, fetchUserDir } = userActions;
// const {getLinkedResults} = resultsActions

//********************* //
//                       /
//  Firebase Calls       /
//                       /
//********************* */

// use this function to retreive pre-retrofit modeldata
const getModel = (modelId) => async (dispatch) => {
    if (!modelId) return null;

    const metaData = await firestore.doc(`${MODEL_COLL}/${modelId}`).get();
    const data = await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("modelData").doc("data").get();
    const checklist = await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("modelData").doc("checklist").get();
    const files = await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("modelFiles").get();

    const takeoffDoc = await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("modelTakeoff").doc("data").get();
    const { modelTakeoff = {}, dataEntryMode = "Hot2000" } = takeoffDoc.exists ? takeoffDoc.data() : {};

    // linkedBaseline data is not present in DB. Need to set it upon saving model
    const linkedBaseline = await firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("linkedBaseline")
        .doc("data")
        .get();
    const linkedBaselineData = linkedBaseline.exists ? linkedBaseline.data() : {};

    // console.log('linkedBaseline from getModel',linkedBaselineData)

    await dispatch(getUpgrades({ modelId }));

    // console.log('metaData',metaData.data())
    // console.log('data',data.data())

    return {
        ...metaData.data(),
        modelData: data.data(),
        modelTakeoff: modelTakeoff,
        dataEntryMode: dataEntryMode,
        checklist: checklist.data(),
        modelFiles: files.docs.map((doc) => doc.data()),
        linkedBaseline: linkedBaselineData,
    };
};

const checkModelUpdates = (modelId) => (dispatch, getState) => {
    if (!modelId) return null;

    dispatch({ type: types.UPDATE_MODEL_SNAPSHOT_START });

    let payload = getState().model.lastSnapshot;

    const updateStatus = {
        mainDoc: false,
        modelData: false,
        checklist: false,
        takeoffDoc: false,
        upgradePackage: false,
        drawingData: false,
        imageGallery: false,
    };

    const checkAllUpdates = () => {
        if (Object.values(updateStatus).every((val) => val === true)) {
            dispatch({ type: types.UPDATE_MODEL_SNAPSHOT_SUCCESS });
        }
    };

    const unsubscribeMainDoc = firestore.doc(`${MODEL_COLL}/${modelId}`).onSnapshot((snapshot) => {
        payload = {
            ...payload,
            ...snapshot.data(),
        };

        dispatch({
            type: types.UPDATE_MODEL_SNAPSHOT,
            payload,
        });

        updateStatus.mainDoc = true;
        checkAllUpdates();
    });

    const unsubscribeModelData = firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("modelData")
        .doc("data")
        .onSnapshot((snapshot) => {
            payload = {
                ...payload,
                modelData: snapshot.data(),
            };

            dispatch({
                type: types.UPDATE_MODEL_SNAPSHOT,
                payload,
            });

            updateStatus.modelData = true;
            checkAllUpdates();
        });

    const unsubscribeChecklist = firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("modelData")
        .doc("checklist")
        .onSnapshot((snapshot) => {
            payload = {
                ...payload,
                checklist: snapshot.data(),
            };

            dispatch({
                type: types.UPDATE_MODEL_SNAPSHOT,
                payload,
            });

            updateStatus.checklist = true;
            checkAllUpdates();
        });

    const unsubscribeTakeoffDoc = firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("modelTakeoff")
        .doc("data")
        .onSnapshot((snapshot) => {
            const { modelTakeoff = {}, dataEntryMode = "Hot2000" } = snapshot.exists ? snapshot.data() : {};

            payload = {
                ...payload,
                modelTakeoff,
                dataEntryMode,
            };

            dispatch({
                type: types.UPDATE_MODEL_SNAPSHOT,
                payload,
            });

            updateStatus.takeoffDoc = true;
            checkAllUpdates();
        });

    const unsubscribeUpgradePackage = firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("modelData")
        .doc("upgrades")
        .onSnapshot((snapshot) => {
            const packages = snapshot.data();

            dispatch(updateUpgradeSnapshot(packages));

            updateStatus.upgradePackage = true;
            checkAllUpdates();
        });

    const unsubscribeDrawingData = firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("drawingData")
        .doc("data")
        .onSnapshot((snapshot) => {
            let { imageData = {} } = snapshot.data()?.imageData || {};

            dispatch(imageDataSnapshotUpdate(imageData));

            updateStatus.drawingData = true;
            checkAllUpdates();
        });

    const unsubscribeImageGallery = firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("imageGallery")
        .onSnapshot((snapshot) => {
            let imageData = snapshot.docs.map((doc) => doc.data());

            dispatch(updateImageGallerySnapshot(imageData));

            updateStatus.imageGallery = true;
            checkAllUpdates();
        });

    // Cleanup function to unsubscribe from all listeners
    return () => {
        unsubscribeMainDoc();
        unsubscribeModelData();
        unsubscribeChecklist();
        unsubscribeTakeoffDoc();
        unsubscribeUpgradePackage();
        unsubscribeDrawingData();
        unsubscribeImageGallery();
    };
};

const getModelInfo = (modelId) => async (dispatch) => {
    if (!modelId) return null;

    const metaData = await firestore.doc(`${MODEL_COLL}/${modelId}`).get();
    const data = await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("modelData").doc("data").get();

    return {
        ...metaData.data(),
        modelData: data.data(),
    };
};

// blank values are handled in the reducer
const clearModelInfo = () => async (dispatch) => {
    return dispatch(setModelInfo());
};

const getLinkedResultsStatus = (hasResults) => async (dispatch) => {
    return dispatch(setMissingResults(hasResults));
};
const clearMissingResultsWarning = () => async (dispatch) => {
    return dispatch(setMissingResults({}));
};

const setMissingResults = (hasResults) => ({
    type: types.SET_MISSING_RESULTS,
    hasResults,
});

const getModelFiles = (modelId) => {
    if (!modelId) return null;
    return firestore.doc(`${MODEL_COLL}/${modelId}`).collection("modelFiles").get();
};

const addHouseModel = async ({ modelData, drawingData, modelTakeoff, ...rest }) => {
    const { id } = await firestore.collection(`${MODEL_COLL}`).add({ modelDetails: rest });
    await firestore.doc(`${MODEL_COLL}/${id}`).collection("modelData").doc("data").set(modelData);
    await firestore
        .doc(`${MODEL_COLL}/${id}`)
        .collection("modelTakeoff")
        .doc("data")
        .set({ modelTakeoff: {}, dataEntryMode: "Hot2000" });
    return id;
};

const deleteHouseModel = (modelId) => {
    return firestore.collection(MODEL_COLL).doc(modelId).delete();
};

const prepareHouseModelDelete = async (modelId, uid) => {
    const modelDetails = await firestore.doc(`${MODEL_COLL}/${modelId}`).get();
    const { modelDetails: { sharedWith = [] } = {} } = modelDetails.data() || {};

    if (sharedWith.length > 1) {
        await removeModelFromSharedWithDirs({
            modelId,
            sharedWith,
            uid,
        }).catch((err) => console.log("Error removing file from other directories", err));
    }

    return firestore.doc(`${MODEL_COLL}/${modelId}`).collection("recoveryDetails").doc("data").set(modelDetails.data());
};

const updateHouseInFirebase = async ({ model, modelId, uid }) => {
    const { modelData, modelDetails = {}, modelTakeoff, dataEntryMode, linkedBaseline } = model;

    const {
        general: {
            client: { clientStreetAddress },
        },
    } = modelData;
    const { name, lastEdited = {}, sharedWith = [] } = modelDetails;
    const { datetime = "" } = lastEdited;

    await firestore.doc(`${MODEL_COLL}/${modelId}`).update({
        "modelDetails.lastEdited": lastEdited,
        "modelDetails.name": name,
    });

    await firestore.doc(`${MODEL_COLL}/${modelId}`).collection("modelData").doc("data").update(modelData);

    await firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("modelTakeoff")
        .doc("data")
        .set({ modelTakeoff, dataEntryMode }, { merge: true });

    await firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("linkedBaseline")
        .doc("data")
        .set(linkedBaseline, { merge: true });

    //TODO: AIDAN check that when updateHouseInFirebase is run it doesn't mess up folder stuff
    await firestore
        .doc(`${USERS_COLL}/${uid}`)
        .collection("modelDir")
        .doc("directory")
        .set(
            {
                models: {
                    [modelId]: {
                        name,
                        complete: true,
                        lastEdited: datetime,
                        address: clientStreetAddress || "",
                    },
                },
            },
            { merge: true }
        );

    if (sharedWith?.length > 1) {
        renameModelSharedWithDirs({
            modelId,
            sharedWith,
            uid,
            newName: name,
            lastEdited: datetime,
            address: clientStreetAddress || "",
        }).catch((err) => console.log("error renaming models in sharedWith dirs", err));
    }

    return updateUserData(uid, { lastActivity: moment().utc().format() });
};

// [`models.${modelId}`]: {
//     name,
//     complete: true,
//     lastEdited: datetime,
// },

const updateModelFieldInFirebase = ({ modelId, accessor, value }) => {
    return firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("modelData")
        .doc("data")
        .update({
            [accessor]: value,
        });
};

const deleteModelFieldInFirebase = ({ modelId, accessor }) => {
    return firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("modelData")
        .doc("data")
        .update({
            [accessor]: fieldValue.delete(),
        })
        .catch((err) => console.log("err"));
};

const updateModelCodes = ({ code, codeRef, modelId }) => {
    return firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("modelData")
        .doc("data")
        .update({
            [`codes.${codeRef}`]: code,
        });
};

//TODO: hook this up properly
const saveAndExportH2k = async ({ modelId, exportType = "XML" }) => {
    if (!modelId) {
        return { error: "No model was specified" };
    }

    //1. Save model prior to export

    const returnPackage = await exportH2kFile(modelId, exportType);

    if (returnPackage) {
        //After some cleaning, h2kData is the xml string to download
        let h2kData = returnPackage.split("\r")[3];
        if (h2kData.charAt(0) === "\r" || h2kData.charAt(0) === "\n") {
            h2kData = h2kData.substring(1);
        }

        //Error list is parsed into an array that indicates any potential issues with the exported file
        let errorList = returnPackage.split("\r")[7];
        if (errorList.charAt(0) === "\r" || errorList.charAt(0) === "\n") {
            errorList = JSON.parse(errorList.substring(1));
        }

        return { h2kData, errorList };
    } else {
        return { error: "Sorry, no data was returned" };
    }
};

// https://firebase.google.com/docs/firestore/manage-data/add-data#update_fields_in_nested_objects
const addModelToUser = ({ modelId, uid, parentFolderId, name, lastEdited, complete = false }) => {
    return firestore
        .doc(`${USERS_COLL}/${uid}`)
        .collection("modelDir")
        .doc("directory")
        .update({
            [`models.${modelId}`]: {
                name,
                complete,
                lastEdited,
                parentFolderId,
            },
        });
};

const removeModelFromUser = ({ modelId, uid }) => {
    return firestore
        .doc(`${USERS_COLL}/${uid}`)
        .collection("modelDir")
        .doc("directory")
        .update({
            [`models.${modelId}`]: fieldValue.delete(),
        });
};

const addComponentToFirebase = ({ modelId, componentId, type, parentPath, componentData }) => {
    return firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("modelData")
        .doc("data")
        .update({
            [`components${parentPath}.${type}.${componentId}`]: componentData,
        });
};

const deleteComponentFromFirebase = ({ modelId, type, parentPath, componentId }) => {
    return firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("modelData")
        .doc("data")
        .update({
            [`components${parentPath}.${type}.${componentId}`]: fieldValue.delete(),
        });
};

const submitModelError = async ({ modelId = "", description = "" }) => {
    if (!modelId) {
        return {};
    }

    const batch = firestore.batch();
    const modelDetails = await firestore.doc(`${MODEL_COLL}/${modelId}`).get();
    const modelData = await firestore.doc(`${MODEL_COLL}/${modelId}/modelData/data`).get();

    batch.set(firestore.doc(`${MODEL_ERRORS_COLL}/${modelId}`), {
        modelDetails: modelDetails.data().modelDetails,
        errorDetails: {
            submittedAt: moment().format(),
            description,
            isErrorPending: true,
        },
    });
    batch.set(firestore.doc(`${MODEL_ERRORS_COLL}/${modelId}/modelData/data`), modelData.data());
    batch.set(firestore.doc(`${MODEL_COLL}/${modelId}`), {
        modelDetails: {
            ...modelDetails.data().modelDetails,
            isErrorPending: true,
        },
    });

    return batch.commit();
};

const updateChecklistItemInFirebase = (modelId, checklistId, updates) => {
    return firestore
        .doc(`${MODEL_COLL}/${modelId}`)
        .collection("modelData")
        .doc("checklist")
        .set({ list: { [checklistId]: updates } }, { merge: true });
};

//********************* */
//
//  Actions
//
//********************* */

const modelLoading = () => ({
    type: types.MODEL_LOADING,
});

const modelLoadingEnd = () => ({
    type: types.MODEL_LOADING_END,
});

// set the selectedModelInfo to a variable that can be accessed by <SelectModel>
const setModelInfo = (modelId, model) => ({
    type: types.SET_MODEL_INFO,
    modelId,
    model,
});

const setModelData = (modelId, model, isSaving = false) => ({
    type: types.SET_MODEL_DATA,
    modelId,
    model,
    isSaving,
});

export const setModelError = (error) => ({
    type: types.SET_MODEL_ERROR,
    error,
});

export const setMissingCodes = (missingCodes) => ({
    type: types.SET_MISSING_CODES,
    missingCodes,
});

const clearModelData = () => ({
    type: types.CLEAR_MODEL_DATA,
});

const setModelCode = (code, codeRef) => ({
    type: types.SET_MODEL_CODE,
    code,
    codeRef,
});

const setComponent = (componentId, isDuplicate) => ({
    type: types.SET_MODEL_COMPONENT,
    componentId,
    isDuplicate,
});

export const setFiles = (files) => ({
    type: types.SET_MODEL_FILES,
    files,
});

const setExportErrors = (errors, location, proceedFunc) => ({
    type: types.SET_EXPORT_ERROR,
    errors,
    location,
    proceedFunc,
});

const clearNewComponents = (id) => ({
    type: types.CLEAR_NEW_COMPONENTS,
    id,
});

export const setFileUploadStatus = (statusUpdates) => ({
    type: types.SET_FILE_UPLOAD_STATUS,
    statusUpdates,
});

const updateModalDetails = (modelDetails) => ({
    type: types.UPDATE_MODEL_DETAILS,
    modelDetails,
});

const toggleContentOpen = () => ({
    type: types.TOGGLE_CONTENT_OPEN,
});

//Action gets type

const changeDataentryMode = (mode) => ({
    type: types.CHANGE_DATAENTRY_MODE,
    mode,
});

const addTakeoffComp = (comp, compType) => ({
    type: types.ADD_TAKEOFF_COMP,
    comp,
    compType,
});

const removeTakeoffComp = (foundationType) => ({
    type: types.REMOVE_TAKEOFF_COMP,
    foundationType,
});

const setTakeoffErrors = (data) => ({
    type: types.SET_TAKEOFF_ERRORS,
    data,
});

const changeTakeoffFoundation = (comp) => ({
    type: types.CHANGE_TAKEOFF_FOUNDATION,
    comp,
});

const updateTakeoffComponent = (data) => ({
    type: types.UPDATE_TAKEOFF_COMPONENT,
    data,
});

const addTakeoffSubcomp = (parent, subcomponent) => ({
    type: types.ADD_TAKEOFF_SUBCOMP,
    parent,
    subcomponent,
});
const editTakeoffSubcomp = (parent, id, value, unit) => ({
    type: types.EDIT_TAKEOFF_SUBCOMP,
    parent,
    id,
    value,
    unit,
});
const removeTakeoffSubcomp = (parent, id) => ({
    type: types.REMOVE_TAKEOFF_SUBCOMPONENT,
    parent,
    id,
});
const duplicateTakeoffSubcomp = (parent, id) => ({
    type: types.DUPLICATE_TAKEOFF_SUBCOMPONENT,
    parent,
    id,
});

const addToffTableComp = (parent, component) => ({
    type: types.ADD_TOFF_TABLE_COMP,
    parent,
    component,
});

const addToffTableMultiRow = (parent, component, overwrite) => ({
    type: types.ADD_TOFF_TABLE_MULTI_ROW,
    parent,
    component,
    overwrite,
});

const editToffTableComp = (parent, id, value, unit) => ({
    type: types.EDIT_TOFF_TABLE_COMP,
    parent,
    id,
    value,
    unit,
});

const removeToffTableComp = (parent, id) => ({
    type: types.REMOVE_TOFF_TABLE_COMP,
    parent,
    id,
});

const translateComponent = (componentType, componentSubtype, formModelData) => ({
    type: types.TRANSLATE_COMPONENT,
    componentType,
    componentSubtype,
    formModelData,
});

const rotateHome = (windows, newHomeDirection, formModelData) => ({
    type: types.ROTATE_WINDOWS,
    payload: { windows, newHomeDirection, formModelData },
});

const fetchModelInfo = (modelId) => (dispatch) => {
    return dispatch(getModelInfo(modelId)).then((data) => dispatch(setModelInfo(modelId, data)));
};

// pre-retrofit modelData
const fetchModelData = (modelId) => (dispatch) => {
    return dispatch(getModel(modelId))
        .then((data) => dispatch(setModelData(modelId, data)))
        .catch(() => dispatch(setModelError("Model does not exist")));
};

export const fetchModelFiles = (modelId) => (dispatch) => {
    return getModelFiles(modelId)
        .then(({ docs }) => {
            const files = docs.map((doc) => doc.data());
            return dispatch(setFiles(files));
        })
        .catch(() => dispatch(setModelError("There was a problem getting model files")));
};

const createModel = (model, uid, parentFolderId) => async (dispatch) => {
    dispatch(modelLoading());

    const modelId = await addHouseModel(model);

    if (!modelId) {
        dispatch(setModelError("Model could not be created successfully"));
    }

    return addModelToUser({
        modelId,
        uid,
        parentFolderId,
        name: model.name,
        lastEdited: model.createdAt,
        complete: true,
    })
        .then(() => modelId)
        .catch((err) => dispatch(setModelError(err)));
};

const deleteModel =
    ({ modelId, uid }) =>
    async (dispatch) => {
        await prepareHouseModelDelete(modelId, uid);

        return Promise.all([deleteHouseModel(modelId), removeModelFromUser({ modelId, uid })]);
    };

const removeModelFromDashboard =
    ({ modelId, uid }) =>
    async (dispatch) => {
        return removeModelFromUser({ modelId, uid });
    };

const saveField = (modelId, accessor, value) => async (dispatch) => {
    return updateModelFieldInFirebase({ modelId, accessor, value }).then(() => "");
};

const deleteField = (modelId, accessor) => async (dispatch) => {
    return deleteModelFieldInFirebase({ modelId, accessor }).then(() => "");
};

const updateHouseModel = (model, modelId, uid) => async (dispatch) => {
    return updateHouseInFirebase({
        model,
        modelId,
        uid,
    })
        .then(async () => {
            await dispatch(fetchModelData(modelId));
        })
        .catch((err) => {
            console.log("error saving model", err);
            return dispatch(setModelError(err));
        });
};

const addMissingCodeWarning = (missingCodes) => async (dispatch) => {
    return dispatch(setMissingCodes(missingCodes));
};

const clearMissingCodeWarning = () => async (dispatch) => {
    return dispatch(setMissingCodes({}));
};

const addModelCode =
    ({ code, codeRef, modelId }) =>
    async (dispatch) => {
        return updateModelCodes({ code, codeRef, modelId })
            .then(() => dispatch(setModelCode(code, codeRef)))
            .catch((err) => dispatch(setModelError(err)));
        //TODO: set separate code error
    };

const addComponent =
    ({ modelId, type, componentId, parentPath, componentData, isDuplicate = false }) =>
    async (dispatch) => {
        await addComponentToFirebase({
            modelId,
            type,
            componentData,
            parentPath,
            componentId,
        })
            .then(() => dispatch(setComponent(componentId, isDuplicate)))
            .then(() => dispatch(getModel(modelId)))
            .then((data) => dispatch(setModelData(modelId, data)))
            .catch((err) => dispatch(setModelError(err)));
    };

const deleteComponent =
    ({ modelId, type, parentPath, componentId }) =>
    (dispatch) => {
        return deleteComponentFromFirebase({
            modelId,
            type,
            parentPath,
            componentId,
        })
            .then(() => dispatch(getModel(modelId)))
            .then((data) => dispatch(setModelData(modelId, data)))
            .catch((err) => dispatch(setModelError(err)));
    };

// const uploadToStorage = ({ modelId, uid, file, fileName }) => {
//     return storageRef.child(`${uid}/${modelId}/${fileName}/${fileName}.pdf`).put(file);
// };

const uploadDrawing =
    ({ modelId, uid, file, fileName }) =>
    (dispatch) => {
        const pdfPath = `${uid}/${modelId}/${fileName}/${fileName}.pdf`;

        const uploadTask = storageRef.child(pdfPath).put(file);

        dispatch(
            setFileUploadStatus({
                isUploading: true,
            })
        );

        return uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                dispatch(setFileUploadStatus({ progress }));
                switch (snapshot.state) {
                    case storage.TaskState.PAUSED: // or 'paused'
                        break;
                    case storage.TaskState.RUNNING: // or 'running'
                        break;
                    default:
                        console.log("default");
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                dispatch(
                    setFileUploadStatus({
                        error: error,
                    })
                );
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    dispatch(
                        setFileUploadStatus({
                            isGenerating: true,
                            isUploading: false,
                            uploadSuccess: true,
                        })
                    );
                });

                convertPdfToImage({ pdfLink: pdfPath })
                    .then(async (data) => {
                        await dispatch(fetchModelFiles(modelId));
                        dispatch(
                            setFileUploadStatus({
                                isGenerating: false,
                                isUploading: false,
                                success: true,
                            })
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(
                            setFileUploadStatus({
                                isGenerating: false,
                                isUploading: false,
                                success: false,
                            })
                        );
                    });
            }
        );
    };

const updateChecklistItem = (id, updates) => (dispatch, getState) => {
    const { model: { modelId = "" } = {} } = getState();

    updateChecklistItemInFirebase(modelId, id, updates);

    return dispatch({
        type: types.UPDATE_CHECKLIST,
        id,
        updates,
    });
};

const moveModel =
    (modelId, uid, folderId, skipFetch = false) =>
    (dispatch) => {
        dispatch({ type: types.MOVE_MODEL_START });

        return firestore
            .doc(`${USERS_COLL}/${uid}`)
            .collection("modelDir")
            .doc("directory")
            .update({
                [`models.${modelId}.parentFolderId`]: folderId,
            })
            .then(() => (!skipFetch ? dispatch(fetchUserDir(uid)) : {}))
            .then(() => dispatch({ type: types.MOVE_MODEL_SUCCESS }))
            .catch((err) => dispatch({ type: types.MOVE_MODEL_ERROR, payload: err }));
    };

const duplicateModel = (modelId, uid, folderId, duplicateName) => (dispatch) => {
    dispatch({ type: types.DUPLICATE_MODEL_START });

    return duplicateModelData({ modelId, uid, folderId, duplicateName })
        .then((data) => dispatch(fetchUserDir(uid)))
        .then(() => dispatch({ type: types.DUPLICATE_MODEL_SUCCESS }))
        .catch((err) => dispatch({ type: types.DUPLICATE_MODEL_ERROR, payload: err }));
};

const addTagToModelFile = (modelId, uid, tag, taggingType) => (dispatch) => {
    //tag is the actual tag that gets applied to the model.
    //taggingType defines any special behaviour that needs to go along with the tagging (i.e. for CHBA purposes)
    //taggingType Options: "CHBA_NZ": Handles all CHBA NZ tagging behaviour (assumed the same between NZ and NZ RENO)
    dispatch({ type: types.ADD_MODEL_TAG_START, tag });

    return tagModelFile({ modelId, uid, tag, taggingType })
        .then((data) => dispatch({ type: types.ADD_MODEL_TAG_SUCCESS }))
        .catch((err) => dispatch({ type: types.ADD_MODEL_TAG_ERROR, payload: err }));
};

const removeTagFromModel = (modelId, uid, tag, taggingType) => (dispatch) => {
    //tag is the actual tag that gets applied to the model.
    //taggingType defines any special behaviour that needs to go along with the tagging (i.e. for CHBA purposes)
    //taggingType Options: "CHBA_NZ": Handles all CHBA NZ tagging behaviour (assumed the same between NZ and NZ RENO)
    dispatch({ type: types.REMOVE_MODEL_TAG_START, tag });

    return removeTagFromModelFile({ modelId, uid, tag, taggingType })
        .then((data) => dispatch({ type: types.REMOVE_MODEL_TAG_SUCCESS }))
        .catch((err) => dispatch({ type: types.REMOVE_MODEL_TAG_ERROR, payload: err }));
};

const shareModelFile = (modelId, uid, orgId, userList, permissionsType) => async (dispatch) => {
    dispatch({ type: types.SHARE_FILE_ORG_START });

    return shareModelWithOrgMembers({ modelId, uid, userList, orgId, permissionsType })
        .then((data) => {
            dispatch({ type: types.SHARE_FILE_ORG_SUCCESS, payload: { userList } });
        })
        .finally(async () => {
            dispatch({ type: types.TOGGLE_SHARE_FILE_ORG_LOADING });
        })
        .catch((err) => dispatch({ type: types.SHARE_FILE_ORG_ERROR, payload: err }));
};

const shareFolderOfModels =
    (selectedFileList, uid, primaryMemberOrgId, selectedUserList, folderName) => async (dispatch) => {
        dispatch({ type: types.SHARE_FILE_ORG_START });

        return shareFolderModelsWithOrgMembers({
            selectedModelIds: selectedFileList,
            uid,
            userList: selectedUserList,
            orgId: primaryMemberOrgId,
            sharedFolderName: folderName,
        })
            .then((data) => {
                dispatch({ type: types.SHARE_FILE_ORG_SUCCESS, payload: { userList: selectedUserList } });
            })
            .finally(async () => {
                dispatch({ type: types.TOGGLE_SHARE_FILE_ORG_LOADING });
            })
            .catch((err) => dispatch({ type: types.SHARE_FILE_ORG_ERROR, payload: err }));
    };

const flagModelError = (modelId, uid, userMessage) => (dispatch) => {
    dispatch({ type: types.FLAG_MODEL_ERROR_START });

    return flagModelWithErrorForReview({ modelId, uid, userMessage })
        .then((data) => dispatch({ type: types.FLAG_MODEL_ERROR_SUCCESS }))
        .catch((err) => dispatch({ type: types.FLAG_MODEL_ERROR_ERROR, payload: err }));
};

const appendToChbaAdminCommunity = (modelId, uid) => async (dispatch) => {
    dispatch({ type: types.ADD_MODEL_CHBA_COMMUNITY, modelId });

    return updateChbaCommunityWithModel({ modelId, uid })
        .then((data) => dispatch({ type: types.ADD_MODEL_CHBA_COMMUNITY_SUCCESS }))
        .catch((err) => dispatch({ type: types.ADD_MODEL_CHBA_COMMUNITY_ERROR, payload: err }));
};

const countBuildingSurfaceArea = (componentType) => ({
    type: types.COUNT_BUILDING_SURFACE_AREA,
    componentType,
});

export const updateSpecifications = (specificationType, value, formModelData) => ({
    type: types.UPDATE_SPECIFICATIONS,
    payload: { specificationType, value, formModelData },
});

export const setSaveTimeout = (timeoutFunc) => ({
    type: types.SET_SAVE_TIMEOUT,
    payload: timeoutFunc,
});

const batchDeleteModelsStart = () => ({
    type: types.BATCH_DELETE_MODELS_START,
});

const batchDeleteModelsSuccess = () => ({
    type: types.BATCH_DELETE_MODELS_SUCCESS,
});

const batchDeleteModelsError = (err) => ({
    type: types.BATCH_DELETE_MODELS_ERROR,
    payload: err,
});

const batchDeleteModels = (modelsToDeleteIds, uid) => async (dispatch) => {
    try {
        dispatch(batchDeleteModelsStart());

        const chunks = [];
        for (let i = 0; i < modelsToDeleteIds.length; i += 10) {
            chunks.push(modelsToDeleteIds.slice(i, i + 10));
        }

        // Iterate over each chunk and perform batch delete operations
        for (const chunk of chunks) {
            // Create a batch
            const batch = firestore.batch();

            // Delete each model and remove it from the user's model directory
            chunk.forEach((modelId) => {
                // Delete model document
                const modelRef = firestore.doc(`${MODEL_COLL}/${modelId}`);
                batch.delete(modelRef);

                // Remove model from user's model directory
                const userDirRef = firestore.doc(`${USERS_COLL}/${uid}/modelDir/directory`);
                batch.update(userDirRef, {
                    [`models.${modelId}`]: fieldValue.delete(),
                });
            });

            // Commit the batch
            await batch.commit();
        }

        dispatch(batchDeleteModelsSuccess());
    } catch (error) {
        console.error("Error deleting multiple models:", error);
        dispatch(batchDeleteModelsError(error.message));
    }
};

export default {
    fetchModelData,
    clearModelInfo,
    getLinkedResultsStatus,
    clearMissingResultsWarning,
    createModel,
    modelLoading,
    modelLoadingEnd,
    clearModelData,
    updateHouseModel,
    addMissingCodeWarning,
    clearMissingCodeWarning,
    addModelCode,
    saveField,
    saveAndExportH2k,
    addComponent,
    deleteComponent,
    uploadDrawing,
    fetchModelFiles,
    clearNewComponents,
    setExportErrors,
    submitModelError,
    setFileUploadStatus,
    deleteField,
    getModel,
    deleteModel,
    removeModelFromDashboard,
    updateChecklistItem,
    updateModalDetails,
    toggleContentOpen,
    moveModel,
    duplicateModel,
    addTagToModelFile,
    removeTagFromModel,
    shareModelFile,
    shareFolderOfModels,
    flagModelError,
    changeDataentryMode,
    addTakeoffComp,
    removeTakeoffComp,
    changeTakeoffFoundation,
    setTakeoffErrors,
    translateComponent,
    countBuildingSurfaceArea,
    updateTakeoffComponent,
    addTakeoffSubcomp,
    editTakeoffSubcomp,
    removeTakeoffSubcomp,
    duplicateTakeoffSubcomp,
    addToffTableMultiRow,
    addToffTableComp,
    editToffTableComp,
    removeToffTableComp,
    rotateHome,
    getModelInfo,
    fetchModelInfo,
    batchDeleteModels,
    appendToChbaAdminCommunity,
    checkModelUpdates,
};
