import React from "react";

import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";

import classes from "./styles.module.scss";

const ModelSummaryInputRow = ({ label, value, inputWithUnits = false, units }) => {
    return (
        <div className={classes.modelSummaryInputRow}>
            <span className={classes.inputLabel}>{label}</span>
            {inputWithUnits ? (
                <InputWithUnits
                    style={{ maxWidth: "286px" }}
                    units={units}
                    input={{ value, onChange: () => {} }}
                    disabled
                />
            ) : (
                <Input input={{ value, onChange: () => {} }} disabled style={{ maxWidth: "286px" }} />
            )}
        </div>
    );
};

export default ModelSummaryInputRow;
