import { getLabel } from "utils/dcfExport/details";
import { getProvCode } from "utils/weather";

const h2kVersion = "v11-12";

// Capitalize the first letter of each word in a phrase
function capitalizeFirstLetter(string) {
    const words = string.split(" ");
    var result = "";
    for (let w of words) {
        result += w.charAt(0).toUpperCase() + w.toLowerCase().slice(1) + " ";
    }
    return result.trimEnd();
}

// Table body data
export const fetchColumnData = (itemDetails) => (details) => {
    const data = {};
    Object.keys(itemDetails).forEach((key) => {
        if (Object.keys(details).includes(key)) {
            // If "lastEdited" attribute is an object, display the "datetime" attribute
            if (key === "lastEdited" && typeof (details[key] === "object")) data[key] = details["lastEdited"];
            // If "provTerr" attribute present, display code based on id stored
            else if (key === "provTerr") data[key] = getProvCode(details[key]);
            // If attribute present, display stored value
            else data[key] = details[key];
        }
        // If "size" attribute missing but "totalModels" exists, display totalModels as size
        else if (key === "size" && Object.keys(details).includes("totalModels")) {
            data[key] = details["totalModels"];
        }
        // If "weatherRegion" attribute missing from object, display "Location Name (Code)"
        else if (key === "weatherRegion") {
            const modellocation = details["location"];
            const label = getLabel("weatherLocation", modellocation?.locationId, modellocation?.regionId, h2kVersion);
            data[key] = `${capitalizeFirstLetter(label)} (${modellocation?.regionCode})`;
        }
        // If "region" attribute missing from object, display default "CAN"
        else if (key === "region") data[key] = "CAN";
        // If attribute missing from object, display nothing
        else data[key] = "";
    });
    return data;
};
