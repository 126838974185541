import React from "react";

import Dialog, { CloseDialog } from "components/Dialog";
import Button from "components/Button";

import classes from "./styles.module.scss";
import { getNumParametricOptionsCombinations } from "utils/parametric";

const WarningModalBeforeAnalysisRun = ({
    openDialog,
    onClose,
    handleParametricRunClick,
    hasCodeMetrics,
    ecmInputMap,
    snapParametricCredits,
    setSnapParametricCredits,
    currentResultsSize,
}) => {
    const { totalCombinations } = getNumParametricOptionsCombinations(ecmInputMap);

    const newCombinationCount = Math.max(0, totalCombinations - currentResultsSize);

    const creditsRemaining = snapParametricCredits - newCombinationCount;

    return (
        <Dialog open={openDialog}>
            <CloseDialog closeActions={onClose} />
            <div className={classes.dialog}>
                {/* TODO Change text */}
                <h3>Are you sure you want to begin the analysis?</h3>
                <p>
                    {
                        "Once you have run an analysis you will not be able to re-sync your base model or delete any upgrades used to produce results."
                    }
                </p>
                <p>
                    {`Proceeding with the analysis will run `}
                    <strong>{newCombinationCount} new</strong>{" "}
                    {` combinations of upgrades, using the same number of credits.`}
                </p>
                <p>
                    {`The total result set will be comprised of `}
                    <strong>{totalCombinations}</strong> {` different combinations of upgrades.`}
                </p>

                <p>
                    <strong>Code Metrics:</strong>{" "}
                    {hasCodeMetrics
                        ? "Enabled (ERS mode applied to base model)"
                        : "Not available (ERS mode is not applied to base model)"}{" "}
                </p>

                {creditsRemaining < 0 && (
                    <div className={classes.insufficientCreditsText}>
                        <p>
                            {`Your account has insufficient credits (`}
                            <strong>{snapParametricCredits}</strong>/<strong>{newCombinationCount}</strong>
                            {`) to run an analysis of this size.`}
                        </p>
                    </div>
                )}

                <div className={classes.footer}>
                    <Button type="red" onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setSnapParametricCredits(parseInt(creditsRemaining));
                            handleParametricRunClick();
                        }}
                        type="hollow"
                        disabled={creditsRemaining < 0}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default WarningModalBeforeAnalysisRun;
