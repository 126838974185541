import React, { useEffect, useState } from "react";

import DashboardItem from "..";

import classNames from "classnames";
import classes from "../../style.module.scss";

import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectUserUid } from "store/users/selectors";
import { DeleteDialog, DuplicateDialog, MoveDialog } from "components/Dashboard/Actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import {
    deleteParametricAnalysis,
    moveParametricAnalysis,
    duplicateParametricAnalysis,
} from "store/parametricAnalysis/thunk";
import { selectIsParametricAnalysisLoading } from "store/parametricAnalysis/selectors";

const Analysis = ({
    uid,
    ref,
    analysisId,
    tags = [],
    sharedWithUser = false,
    isSelected,
    isDragging,
    isDropInProcess,
    multiSelectedItems,
    setFolderItemsToDelete,
    disableActions = false,
    details, // { userName, provTerr, currentPhase, lastEdited, targetCompletionDate }

    folders,
    rootDir,
    deleteParametricAnalysis,
    duplicateParametricAnalysis,
    moveParametricAnalysis,
    isAnalysisLoading,
    ...props
}) => {
    const { name } = details;

    const { pathname } = useLocation();
    const path =
        pathname.split("/").filter((str) => ["", "dashboard", "parametric-analysis"].includes(str) === false) || [];
    const currentFolderId = path[path.length - 1] || null;

    const [startDelete, toggleStartDelete] = useState(false);
    const [isDeleting, toggleDeleting] = useState(false);

    const [startDuplicate, toggleStartDuplicate] = useState(false);
    const [isDuplicating, toggleDuplicating] = useState(false);
    const [duplicateName, setDuplicateName] = useState("");

    const [startMove, toggleStartMove] = useState(false);
    const [isMoving, toggleMoving] = useState(false);

    useEffect(() => {
        // Close dialog when action completed
        if (!isAnalysisLoading) {
            if (startDelete) toggleStartDelete(false);
            if (startDuplicate) toggleStartDuplicate(false);
            if (startMove) toggleStartMove(false);
        }
    }, [isAnalysisLoading]);

    return (
        <React.Fragment>
            <DashboardItem
                ref={ref}
                className={classNames({
                    [classes.selectedItem]: isSelected,
                    [classes.itemDragging]: isDragging && isSelected,
                    [classes.isDropInProcess]: isDropInProcess,
                })}
                itemId={analysisId}
                name={name}
                sharedWithUser={sharedWithUser}
                itemType={"analysis"}
                details={details}
                tags={tags}
                actions={
                    disableActions
                        ? null
                        : [
                              {
                                  label: "Move Analysis",
                                  onClick: () => {
                                      toggleStartMove(true);
                                  },
                              },
                              {
                                  label: "Duplicate Analysis",
                                  onClick: () => {
                                      toggleStartDuplicate(true);
                                      setDuplicateName(`${name} (duplicate)`);
                                  },
                              },
                              {
                                  label: "Delete Analysis",
                                  onClick: () => {
                                      toggleStartDelete(true);
                                  },
                              },
                              {
                                  label: `Delete ${multiSelectedItems.length} files`,
                                  shouldShow: multiSelectedItems.length > 1,
                                  onClick: () => {
                                      setFolderItemsToDelete(multiSelectedItems);
                                  },
                              },
                          ]
                }
                {...props}
            />
            <DeleteDialog
                fileName={name}
                openDialog={startDelete}
                onClick={async () => {
                    toggleDeleting(true);
                    await deleteParametricAnalysis(analysisId, uid);
                }}
                onClose={() => toggleStartDelete(false)}
                loading={isDeleting}
            />
            <DuplicateDialog
                itemType={"analysis"}
                openDialog={startDuplicate}
                newDuplicateName={duplicateName}
                setDuplicateName={(newVal) => setDuplicateName(newVal)}
                onClick={async () => {
                    toggleDuplicating(true);
                    await duplicateParametricAnalysis(analysisId, uid, currentFolderId, duplicateName);
                    toggleDuplicating(false);
                }}
                onClose={() => toggleStartDuplicate(false)}
                isLoading={isDuplicating}
            />
            <MoveDialog
                itemId={analysisId}
                fileName={name}
                rootDir={rootDir}
                folders={folders}
                openDialog={startMove}
                itemLoading={isMoving}
                handleMove={async (moveToId) => {
                    toggleMoving(true);
                    await moveParametricAnalysis(analysisId, uid, moveToId);
                    toggleMoving(false);
                }}
                onClose={() => toggleStartMove(false)}
            />
        </React.Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    isAnalysisLoading: selectIsParametricAnalysisLoading,
});

const mapDispatchToProps = (dispatch, { history }) => ({
    deleteParametricAnalysis: (analysisId, userUid) => dispatch(deleteParametricAnalysis(analysisId, userUid)),
    moveParametricAnalysis: (analysisId, userUid, moveToId) =>
        dispatch(moveParametricAnalysis(analysisId, userUid, moveToId)),
    duplicateParametricAnalysis: (analysisId, userUid, moveToId, duplicateName) =>
        dispatch(duplicateParametricAnalysis(analysisId, userUid, moveToId, duplicateName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Analysis);
