import moment from "moment";

import { USERS_COLL, PARAMETRIC_ANALYSIS_COLL, MODEL_COLL, firestore, fieldValue } from "_firebase";

import { checkForResults } from "utils/parametric";
import { DEFAULT_ECM_INPUT_MAP } from "utils/parametric/constants";

import {
    createParametricAnalysisStart,
    createParametricAnalysisSuccess,
    createParametricAnalysisError,
    createParametricAnalysisFolderError,
    createParametricAnalysisFolderStart,
    createParametricAnalysisFolderSuccess,
    deleteParametricAnalysisFolderError,
    deleteParametricAnalysisFolderSuccess,
    deleteParametricAnalysisFolderStart,
    deleteParametricAnalysisError,
    deleteParametricAnalysisSuccess,
    deleteParametricAnalysisStart,
    moveParametricAnalysisStart,
    moveParametricAnalysisSuccess,
    moveParametricAnalysisError,
    dublicateParametricAnalysisError,
    dublicateParametricAnalysisStart,
    dublicateParametricAnalysisSuccess,
    moveParametricAnalysisFolderStart,
    moveParametricAnalysisFolderSuccess,
    moveParametricAnalysisFolderError,
    renameParametricAnalysisFolderError,
    renameParametricAnalysisFolderStart,
    renameParametricAnalysisFolderSuccess,
    batchDeleteParametricAnalysisFoldersError,
    batchDeleteParametricAnalysisFoldersStart,
    batchDeleteParametricAnalysisFoldersSuccess,
    batchDeleteParametricAnalysisError,
    batchDeleteParametricAnalysisStart,
    batchDeleteParametricAnalysisSuccess,
    fetchParametricAnalysisError,
    fetchParametricAnalysisStart,
    fetchParametricAnalysisSuccess,
    syncParametricAnalysisError,
    syncParametricAnalysisStart,
    syncParametricAnalysisSuccess,
    saveEcmInputMapError,
    saveEcmInputMapStart,
    saveEcmInputMapSuccess,
    updateEcmInputMap,
    updateValidationPreCheckRunStatus,
    updateRunResults,
    pinUnpinRecipesError,
    pinUnpinRecipesStart,
    pinUnpinRecipesSuccess,
    updatePriceStateParametricStart,
    updatePriceStateParametricSuccess,
    updatePriceStateParametricError,
} from "./actions";

import { actions as userActions } from "store/users";

const { fetchParametricDir, getUserParametricDir } = userActions;

// *************************************************
// PARAMETRIC ACTIONS
// *************************************************

export const createParametricAnalysis = (analysisToCreate, userUid) => async (dispatch) => {
    try {
        dispatch(createParametricAnalysisStart());

        const { analysisName, selectedModelId, currentFolderId } = analysisToCreate;

        const modelDetailsSnapshot = await firestore.doc(`${MODEL_COLL}/${selectedModelId}`).get();
        const modelDataSnapshot = await firestore
            .doc(`${MODEL_COLL}/${selectedModelId}`)
            .collection("modelData")
            .doc("data")
            .get();
        const upgradesSnapshot = await firestore
            .doc(`${MODEL_COLL}/${selectedModelId}`)
            .collection("modelData")
            .doc("upgrades")
            .get();

        const model = {
            modelDetails: modelDetailsSnapshot.data() || {},
            modelData: modelDataSnapshot.data() || {},
            upgrades: upgradesSnapshot.data() || {},
        };

        const { hasResults = false, results: { base: { resultsData = {} } = {} } = {} } =
            (await checkForResults(model, selectedModelId)) || false;

        const allowedKeys = ["generalBaseCase", "socBaseCase", "ersReference"];
        const filteredResults = Object.keys(resultsData).reduce((acc, curr) => {
            if (allowedKeys.includes(curr)) {
                //Don't save hourly data
                const { hourlyElectricalLoadkWh = [], ...rest } = resultsData[curr];
                return { ...acc, [curr]: rest };
            }

            return acc;
        }, {});

        const { id } = await firestore.collection(PARAMETRIC_ANALYSIS_COLL).add({
            parametricDetails: {
                name: analysisName,
                selectedModelId,
                createdAt: new Date(),
                lastEdited: new Date(),
                lastModelSync: moment().format(),
                selectedModelHasResults: hasResults,
                sharedWith: [userUid],
                lastValidationRun: { complete: null },
            },
        });

        await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${id}`)
            .collection("modelData")
            .doc("data")
            .set(model.modelData);

        await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${id}`)
            .collection("modelData")
            .doc("results")
            .set(filteredResults);

        await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${id}`)
            .collection("modelData")
            .doc("modelDetails")
            .set(model.modelDetails);

        await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${id}`)
            .collection("modelData")
            .doc("upgrades")
            .set(model.upgrades);

        await firestore
            .doc(`${USERS_COLL}/${userUid}`)
            .collection("parametricDir")
            .doc("directory")
            .set(
                {
                    analyses: {
                        [`${id}`]: {
                            name: analysisName,
                            lastEdited: moment().format(),
                            parentFolderId: currentFolderId,
                        },
                    },
                },
                { merge: true }
            );

        await dispatch(fetchParametricDir(userUid, true));

        dispatch(createParametricAnalysisSuccess());

        return id;
    } catch (error) {
        console.log("error", error);
        dispatch(createParametricAnalysisError(error));
    }
};

export const fetchParametricAnalysis = (analysisId) => async (dispatch) => {
    try {
        dispatch(fetchParametricAnalysisStart());

        const analysisSnapshot = await firestore.doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`).get();

        if (!analysisSnapshot.exists) {
            throw new Error(JSON.stringify({ type: "not-found", message: "Analysis not found" }));
        }

        const analysisData = analysisSnapshot.data();

        const modelDataSnapshot = await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("modelData")
            .doc("data")
            .get();

        const modelDetailsSnapshot = await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("modelData")
            .doc("modelDetails")
            .get();

        const upgradesSnapshot = await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("modelData")
            .doc("upgrades")
            .get();

        const resultsSnapshot = await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("modelData")
            .doc("results")
            .get();

        const ecmInputMapSnapshot = await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("config")
            .doc("ecmInputMap")
            .get();

        const modelData = modelDataSnapshot.data();
        const modelDetails = modelDetailsSnapshot.data();
        const upgrades = upgradesSnapshot.data();
        const results = resultsSnapshot.data();

        const analysis = {
            ...analysisData,
            model: { modelData, modelDetails: { ...modelDetails.modelDetails }, upgrades, results },
            ecmInputMap: ecmInputMapSnapshot.exists ? ecmInputMapSnapshot.data() : { ...DEFAULT_ECM_INPUT_MAP },
        };

        dispatch(fetchParametricAnalysisSuccess(analysis));
    } catch (error) {
        dispatch(fetchParametricAnalysisError(JSON.parse(error.message)));
    }
};

export const syncParametricAnalysis = (analysisId, selectedModelId) => async (dispatch, getState) => {
    try {
        dispatch(syncParametricAnalysisStart());

        const {
            parametricAnalysis: { parametricAnalysis: { ecmInputMap = {} } = {} },
        } = getState();

        const modelDetailsSnapshotFromModel = await firestore.doc(`${MODEL_COLL}/${selectedModelId}`).get();
        const modelDataSnapshotFromModel = await firestore
            .doc(`${MODEL_COLL}/${selectedModelId}`)
            .collection("modelData")
            .doc("data")
            .get();
        const upgradesSnapshotFromModel = await firestore
            .doc(`${MODEL_COLL}/${selectedModelId}`)
            .collection("modelData")
            .doc("upgrades")
            .get();

        const model = {
            modelDetails: modelDetailsSnapshotFromModel.data(),
            modelData: modelDataSnapshotFromModel.data(),
            upgrades: upgradesSnapshotFromModel.data(),
        };

        const { hasResults = false, results: { base: { resultsData = {} } = {} } = {} } =
            (await checkForResults(model, selectedModelId)) || false;

        const allowedKeys = ["generalBaseCase", "socBaseCase", "ersReference"];
        const filteredResults = Object.keys(resultsData).reduce((acc, curr) => {
            if (allowedKeys.includes(curr)) {
                //Don't save hourly data
                // eslint-disable-next-line no-unused-vars
                const { hourlyElectricalLoadkWh = [], ...rest } = resultsData[curr];
                return { ...acc, [curr]: rest };
            }

            return acc;
        }, {});
        // update analysis in parametric analysis collection
        await firestore.doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`).set(
            {
                parametricDetails: {
                    lastEdited: new Date(),
                    lastModelSync: moment().format(),
                    selectedModelHasResults: hasResults,
                },
            },
            { merge: true }
        );

        // update model data in modelData collection
        await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("modelData")
            .doc("data")
            .set(model.modelData);

        await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("modelData")
            .doc("results")
            .set(filteredResults);

        // update model details in modelData collection
        await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("modelData")
            .doc("modelDetails")
            .set(model.modelDetails);

        // update upgrades in modelData collection
        await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("modelData")
            .doc("upgrades")
            .set(model.upgrades);

        const analysisSnapshot = await firestore.doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`).get();
        const analysisData = analysisSnapshot.data();

        const modelDataSnapshot = await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("modelData")
            .doc("data")
            .get();

        const modelDetailsSnapshot = await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("modelData")
            .doc("modelDetails")
            .get();

        const upgradesSnapshot = await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("modelData")
            .doc("upgrades")
            .get();

        const resultsSnapshot = await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("modelData")
            .doc("results")
            .get();

        const modelData = modelDataSnapshot.data();
        const modelDetails = modelDetailsSnapshot.data();
        const upgrades = upgradesSnapshot.data();
        const results = resultsSnapshot.data();

        const analysis = {
            ...analysisData,
            ecmInputMap,
            model: { modelData, modelDetails: { ...modelDetails.modelDetails }, upgrades, results },
        };

        dispatch(syncParametricAnalysisSuccess({ ...analysis }));
    } catch (error) {
        console.log("error", error);
        dispatch(syncParametricAnalysisError(error.message));
    }
};

export const deleteParametricAnalysis = (analysisId, userUid) => async (dispatch) => {
    try {
        dispatch(deleteParametricAnalysisStart());

        await firestore.doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`).delete();

        await firestore
            .doc(`${USERS_COLL}/${userUid}`)
            .collection("parametricDir")
            .doc("directory")
            .update({
                [`analyses.${analysisId}`]: fieldValue.delete(),
            });

        await dispatch(fetchParametricDir(userUid, true));

        dispatch(deleteParametricAnalysisSuccess());

        return;
    } catch (error) {
        console.log("error", error);
        dispatch(deleteParametricAnalysisError(error));
    }
};

export const moveParametricAnalysis = (analysisId, userUid, moveToId) => async (dispatch) => {
    try {
        dispatch(moveParametricAnalysisStart());

        await firestore
            .doc(`${USERS_COLL}/${userUid}`)
            .collection("parametricDir")
            .doc("directory")
            .update({
                [`analyses.${analysisId}.parentFolderId`]: moveToId,
            });

        await dispatch(fetchParametricDir(userUid, true));

        dispatch(moveParametricAnalysisSuccess());
    } catch (error) {
        console.log("error", error);
        dispatch(moveParametricAnalysisError(error));
    }
};

// itemToDuplicate, uid, currentFolderId, duplicateName
export const duplicateParametricAnalysis =
    (analysisId, userUid, currentFolderId, duplicateName) => async (dispatch) => {
        try {
            dispatch(dublicateParametricAnalysisStart());

            const analysisSnapshot = await firestore.doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`).get();

            const analysisData = analysisSnapshot.data();

            const modelDataSnapshot = await firestore
                .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
                .collection("modelData")
                .doc("data")
                .get();
            const modelDetailsSnapshot = await firestore
                .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
                .collection("modelData")
                .doc("modelDetails")
                .get();
            const upgradesSnapshot = await firestore
                .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
                .collection("modelData")
                .doc("upgrades")
                .get();

            const modelData = modelDataSnapshot.data();
            const modelDetails = modelDetailsSnapshot.data();
            const upgrades = upgradesSnapshot.data();

            const { id } = await firestore.collection(PARAMETRIC_ANALYSIS_COLL).add({
                parametricDetails: {
                    name: duplicateName,
                    selectedModelId: analysisData.parametricDetails.selectedModelId,
                    createdAt: new Date(),
                    lastEdited: new Date(),
                    lastModelSync: moment().format(),
                    sharedWith: [userUid],
                },
            });

            await firestore.doc(`${PARAMETRIC_ANALYSIS_COLL}/${id}`).collection("modelData").doc("data").set(modelData);

            await firestore
                .doc(`${PARAMETRIC_ANALYSIS_COLL}/${id}`)
                .collection("modelData")
                .doc("modelDetails")
                .set(modelDetails);

            await firestore
                .doc(`${PARAMETRIC_ANALYSIS_COLL}/${id}`)
                .collection("modelData")
                .doc("upgrades")
                .set(upgrades);

            await firestore
                .doc(`${USERS_COLL}/${userUid}`)
                .collection("parametricDir")
                .doc("directory")
                .update({
                    [`analyses.${id}`]: {
                        name: duplicateName,
                        lastEdited: moment().format(),
                        parentFolderId: currentFolderId,
                    },
                });

            await dispatch(fetchParametricDir(userUid, true));

            dispatch(dublicateParametricAnalysisSuccess());
        } catch (error) {
            console.log("error", error);
            dispatch(dublicateParametricAnalysisError(error));
        }
    };

export const batchDeleteParametricAnalysis = (analysesIds, userUid) => async (dispatch) => {
    try {
        dispatch(batchDeleteParametricAnalysisStart());

        for await (const id of analysesIds) {
            await dispatch(deleteParametricAnalysis(id, userUid));
        }

        dispatch(batchDeleteParametricAnalysisSuccess());
    } catch (error) {
        console.log("error", error);
        dispatch(batchDeleteParametricAnalysisError(error));
    }
};

export const saveEcmInputMap = (analysisId) => async (dispatch, getState) => {
    try {
        dispatch(saveEcmInputMapStart());

        const { parametricAnalysis: { parametricAnalysis: { ecmInputMap } = {} } = {} } = getState();

        // console.log("ecmInputMap", ecmInputMap);

        await firestore
            .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
            .collection("config")
            .doc("ecmInputMap")
            .set(ecmInputMap);

        dispatch(saveEcmInputMapSuccess());
    } catch (error) {
        console.log("error", error);
        dispatch(saveEcmInputMapError(error));
    }
};

export const pinUnpinRecipes = (analysisId, recipeId) => (dispatch, getState) => {
    try {
        dispatch(pinUnpinRecipesStart());

        const { parametricAnalysis: { parametricDetails: { pinnedRecipes = [] } } = {} } =
            getState().parametricAnalysis;

        let updatedPinnedRecipes = [...pinnedRecipes];

        const allIds = updatedPinnedRecipes.map(({ id }) => id);

        const isAdding = !allIds.includes(recipeId);

        if (isAdding) {
            const highestOrder = updatedPinnedRecipes.reduce((acc, { order }) => (order > acc ? order : acc), 0);

            updatedPinnedRecipes = [
                ...updatedPinnedRecipes,
                {
                    id: recipeId,
                    label: `Pinned ${updatedPinnedRecipes.length + 1}`,
                    order: highestOrder + 1,
                },
            ];
        }

        if (!isAdding) {
            updatedPinnedRecipes = updatedPinnedRecipes.filter(({ id }) => id !== recipeId);
        }

        // Updating order to  be sequential
        updatedPinnedRecipes = updatedPinnedRecipes
            .sort((a, b) => a.order - b.order)
            .map((recipe, index) => ({
                ...recipe,
                order: index,
            }));

        firestore.doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`).set(
            {
                parametricDetails: {
                    pinnedRecipes: updatedPinnedRecipes,
                },
            },
            { merge: true }
        );

        dispatch(pinUnpinRecipesSuccess(updatedPinnedRecipes));
    } catch (error) {
        console.log("error", error);
        dispatch(pinUnpinRecipesError(error));
    }
};

export const updatePriceStateParametric = (analysisId) => async (dispatch) => {
    try {
        dispatch(updatePriceStateParametricStart());

        await firestore.doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`).set(
            {
                parametricDetails: {
                    totalCost: {
                        wasTotalCostChanged: true,
                    },
                },
            },
            { merge: true }
        );

        dispatch(updatePriceStateParametricSuccess());
    } catch (error) {
        dispatch(updatePriceStateParametricError(error));
    }
};

export const watchParametricUpdates = (analysisId) => (dispatch) => {
    const unsubscribe = firestore
        .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
        .collection("config")
        .doc("ecmInputMap")
        .onSnapshot((doc) => {
            dispatch(updateEcmInputMap(doc.data()));
        });

    const unsubscribeDetails = firestore.doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`).onSnapshot((doc) => {
        const {
            parametricDetails: { lastValidationRun = {}, runHistory = {}, totalCost = {} },
        } = doc.data();

        dispatch(updateValidationPreCheckRunStatus({ lastValidationRun, runHistory, totalCost }));
    });

    const unsubscribeRunResults = firestore
        .doc(`${PARAMETRIC_ANALYSIS_COLL}/${analysisId}`)
        .collection("runResults")
        .onSnapshot(
            (snapshot) => {
                let runResults = {};

                snapshot.docs.forEach((doc) => {
                    runResults = { ...runResults, [doc.id]: doc.data() };
                });

                dispatch(updateRunResults(runResults));
            },
            (error) => {
                console.log("error", error);
            }
        );

    return () => {
        unsubscribe();
        unsubscribeDetails();
        unsubscribeRunResults();
    };
};

// *************************************************
// FOLDERS ACTIONS
// *************************************************

export const createParametricAnalysisFolder = (folderName, userUid, currentFolderId) => async (dispatch) => {
    try {
        dispatch(createParametricAnalysisFolderStart());

        const folderId = firestore.doc(`${USERS_COLL}/${userUid}`).collection("parametricDir").doc().id;

        await firestore
            .doc(`${USERS_COLL}/${userUid}`)
            .collection("parametricDir")
            .doc("directory")
            .set(
                {
                    folders: {
                        [`${folderId}`]: {
                            name: folderName,
                            lastEdited: moment().format(),
                            parentFolderId: currentFolderId,
                        },
                    },
                },
                { merge: true }
            );

        await dispatch(fetchParametricDir(userUid, true));

        dispatch(createParametricAnalysisFolderSuccess());
    } catch (error) {
        console.log("error", error);
        dispatch(createParametricAnalysisFolderError(error));
    }
};

export const deleteParametricAnalysisFolder = (folderId, userUid) => async (dispatch) => {
    try {
        dispatch(deleteParametricAnalysisFolderStart());

        const analysesDir = (await getUserParametricDir(userUid)).data();

        const folders = analysesDir.folders;
        const analyses = analysesDir.analyses;

        const foldersIds =
            Object.entries(folders)
                .filter(([key, { parentFolderId }]) => parentFolderId === folderId)
                .map(([key]) => key) || [];
        const analysesIds =
            Object.entries(analyses)
                .filter(([key, { parentFolderId }]) => parentFolderId === folderId)
                .map(([key]) => key) || [];

        if (foldersIds.length > 0) {
            for await (const id of foldersIds) {
                await firestore
                    .doc(`${USERS_COLL}/${userUid}`)
                    .collection("parametricDir")
                    .doc("directory")
                    .update({
                        [`folders.${id}.parentFolderId`]: null,
                    });
            }
        }

        if (analysesIds.length > 0) {
            for await (const id of analysesIds) {
                await firestore
                    .doc(`${USERS_COLL}/${userUid}`)
                    .collection("parametricDir")
                    .doc("directory")
                    .update({
                        [`analyses.${id}.parentFolderId`]: null,
                    });
            }
        }

        await firestore
            .doc(`${USERS_COLL}/${userUid}`)
            .collection("parametricDir")
            .doc("directory")
            .update({
                [`folders.${folderId}`]: fieldValue.delete(),
            });

        await dispatch(fetchParametricDir(userUid, true));

        dispatch(deleteParametricAnalysisFolderSuccess());
    } catch (error) {
        console.log("error", error);
        dispatch(deleteParametricAnalysisFolderError(error));
    }
};

export const moveParametricAnalysisFolder = (folderId, userUid, moveToId) => async (dispatch) => {
    try {
        dispatch(moveParametricAnalysisFolderStart());

        await firestore
            .doc(`${USERS_COLL}/${userUid}`)
            .collection("parametricDir")
            .doc("directory")
            .update({
                [`folders.${folderId}.parentFolderId`]: moveToId,
            });

        await dispatch(fetchParametricDir(userUid, true));

        dispatch(moveParametricAnalysisFolderSuccess());
    } catch (error) {
        console.log("error", error);
        dispatch(moveParametricAnalysisFolderError(error));
    }
};

export const renameParametricAnalysisFolder = (folderId, userUid, newName) => async (dispatch) => {
    try {
        dispatch(renameParametricAnalysisFolderStart());

        await firestore
            .doc(`${USERS_COLL}/${userUid}`)
            .collection("parametricDir")
            .doc("directory")
            .update({
                [`folders.${folderId}.name`]: newName,
            });

        await dispatch(fetchParametricDir(userUid, true));

        dispatch(renameParametricAnalysisFolderSuccess());
    } catch (error) {
        console.log("error", error);
        dispatch(renameParametricAnalysisFolderError(error));
    }
};

export const batchDeleteParametricAnalysisFolders = (foldersIds, userUid) => async (dispatch) => {
    try {
        dispatch(batchDeleteParametricAnalysisFoldersStart());

        for await (const id of foldersIds) {
            await dispatch(deleteParametricAnalysisFolder(id, userUid));
        }

        dispatch(batchDeleteParametricAnalysisFoldersSuccess());
    } catch (error) {
        console.log("error", error);
        dispatch(batchDeleteParametricAnalysisFoldersError(error));
    }
};
