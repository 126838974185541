import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Construction from "./";
import { actions } from "features/Model/Enclosure/_ducks";
import { isEmpty, isEqual } from "lodash";

const { setInitCode, updateCodeDrawer } = actions;

const mapStateToProps = (
    {
        form,
        user: { codeLib: { Wall: { codes = {} } = {}, Lintel: { codes: lintelCodes = {} } = {} } = {} },
        enclosure: { calculatingRValue = {} } = {},
        upgradeLibrary: { currentPackage = {} },
        parametricAnalysis: { parametricAnalysis } = {},
    },
    { accessor, formName = "model", id, selectedUpgrade, canBeEditedFromParametric }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const ecmInputMap = parametricAnalysis?.ecmInputMap || {};

    const allEcmInputMapUpgradesArr = ecmInputMap
        ? Object.keys(ecmInputMap)
              .map((key) =>
                  Object.entries(ecmInputMap[key])
                      .filter(([k, _]) => k !== "None")
                      .map(([k, v]) => ({ [k]: v }))
              )
              .flat()
        : {};

    const allEcmInputMapUpgrades = allEcmInputMapUpgradesArr.reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const modelCodes =
        allEcmInputMapUpgrades[selectedUpgrade]?.packageValues?.codes ||
        currentPackage?.upgrades?.[selectedUpgrade]?.codes ||
        modelSelector({ form }, "modelData.codes") ||
        {};
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const programMode = modelSelector({ form }, `modelData.program.class`) || "";

    const { codeRef: selectedCodeRef } = selector({ form }, `${accessor}.wallInsType`) || {};
    const selectedNomRValue = selector({ form }, `${accessor}.wallInsType_nomRVal`) || 0;
    const wallCodeWarning = selector({ form }, `${accessor}.wallInsType_warning`) || "";
    const { codeRef: selectedLintelCodeRef } = selector({ form }, `${accessor}.lintelInsType`) || {};
    const rValUnits = selector({ form }, `${accessor}.wallInsType_u`);
    const { [id]: { wallInsType: isCalculatingRValue = false } = {} } = calculatingRValue;

    return {
        id,
        accessor,
        libCodes: codes,
        libLintelCodes: lintelCodes,
        modelCodes,
        selectedCodeRef,
        selectedLintelCodeRef,
        modelUnits,
        rValUnits,
        selectedNomRValue,
        wallCodeWarning,
        isCalculatingRValue,
        isErsAndUSpec:
            selectedCodeRef === "UserSpecified" && (programMode.includes("ERS") || programMode.includes("ONrh")),
        canBeEditedFromParametric,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({
    setInitCode: async (code, type) => {
        return dispatch(setInitCode(code, type));
    },
    updateCodeDrawer: (updates) => dispatch(updateCodeDrawer({ updates })),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Construction);
