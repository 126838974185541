import React from "react";
import classes from "./style.module.scss";
import { isEmpty } from "lodash";

const ParagraphInput = ({
    value,
    onChange,
    formTitle,
    placeholder = "",
    disabled = false,
    className,
    checkboxValue,
}) => {
    const needsText = checkboxValue === "n/a" && isEmpty(value);
    return (
        <div className={classes.textInputContainer}>
            {formTitle && <label>{formTitle}</label>}
            <textarea
                placeholder={placeholder || "Text..."}
                value={value}
                onChange={onChange}
                className={`${classes.input} ${needsText && classes.needsText} `}
                disabled={disabled}
            />
        </div>
    );
};

export default ParagraphInput;
