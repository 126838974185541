import React, { useEffect, useState, useRef, useCallback } from "react";
import classes from "../style.module.scss";
import moment from "moment";
import { useHistory, useLocation, Redirect } from "react-router";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserCommDir, selectUserCommDirLoading, selectUserError, selectUserUid } from "store/users/selectors";
import { selectCommunityLoading } from "features/Community/_ducks/selectors";
import { actions as userActions } from "store/users";
import { actions as communityActions } from "features/Community/_ducks";
import Loading from "components/Loading";
import DashboardTable from "components/Dashboard/Table";
import { CHBA_RENO_COMM_ID } from "utils/communities/api";
import { CreateFirstCommunity } from "components/Dashboard/Actions";
import DashboardLayout from "../";
// import { getRegion } from "utils/communities";

// const { fetchCommunityData, clearCommunityData, startCommunityLoading, createCommunity } = communityActions;
const { fetchCommunityData, clearCommunityData, createCommunity } = communityActions;
const { fetchUserCommDir } = userActions;

const CommunitiesDashboard = ({
    uid,
    error,
    fetchUserCommDir,
    commDir,
    commDirLoading,
    communityLoading,
    createCommunity
}) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const path = pathname.split("/").filter((str) => ["", "dashboard", "communities"].includes(str) === false) || [];
    const currentFolderId = path[path.length - 1] || null;

    /* Loading state and cursor position for loading spinner */
    const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
    const [isLoading, setIsLoading] = useState(true);

    /* Fetch user's community directory data */
    useEffect(() => {
        if (uid) {
            fetchUserCommDir(uid, false);
        }
    }, [uid]);

    const [allFolders, setAllFolders] = useState([]);
    const [allCommunities, setAllCommunities] = useState([]);
    const [filteredFoldersCommunities, setFilteredFoldersCommunities] = useState([]);

    /* Update loading state once directory has loaded */
    useEffect(() => {
        if (!isEmpty(commDir) && !communityLoading) {
            setIsLoading(false);
        }
    }, [commDir]);

    useEffect(() => {
        const { communities: singleCommunities = {}, folders = {} } = commDir;

        var folderCounts = {};
        const folderRegionLists = {};
        Object.entries({ ...singleCommunities, ...folders })
            .forEach(([key, { parentFolderId, region }]) => {
                if (parentFolderId) {
                    if (parentFolderId in folderCounts)
                        folderCounts[parentFolderId] += 1;
                    else
                        folderCounts[parentFolderId] = 1;
                }
                if (parentFolderId && region) {
                    if (parentFolderId in folderRegionLists)
                        folderRegionLists[parentFolderId].push(region);
                    else
                        folderRegionLists[parentFolderId] = [region];
                }
            });

            // TODO
        // const getFolderRegion = (subRegions = []) => {
        //     var provinceList = [];
        //     subRegions.forEach((label) => provinceList.push(...label.split(", ")));
        //     return !provinceList.includes("CAN") ? getRegion(provinceList) : "CAN";
        // };

        const filteredFolders = [];

        // const filteredFolders = !isEmpty(folders)
        //     ? Object.entries(folders)
        //         .filter(([key, { parentFolderId }]) => parentFolderId === currentFolderId)
        //         .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
        //         .map(([key, el]) => [key, { 
        //             ...el, 
        //             size: folderCounts[key] || 0,
        //             region: "CAN", // TODO
        //             // region: getFolderRegion(folderRegionLists[key]),
        //             type: "folder"
        //         }])
        //     : [];

        const filteredCommunities = !isEmpty(singleCommunities)
            ? Object.entries(singleCommunities)
                .filter(([key, value]) => key === CHBA_RENO_COMM_ID) // Display only CHBA for now
                // .filter(([key, { parentFolderId }]) =>
                //     parentFolderId ? parentFolderId === currentFolderId : currentFolderId ? false : true
                // )
                // .sort(([aKey, aValues], [bKey, bValues]) => {
                //     if (aKey === CHBA_RENO_COMM_ID)
                //         return -1;
                //     else if (bKey === CHBA_RENO_COMM_ID)
                //         return 1;
                //     return aValues.name.localeCompare(bValues.name);
                // })
                .map(([key, el]) => [key, { ...el, type: "community" }])
            : [];

        setAllFolders(folders);
        setAllCommunities(singleCommunities);
        setFilteredFoldersCommunities([...filteredFolders, ...filteredCommunities]);
    }, [commDir, currentFolderId]);

    if (!isLoading && path.length > 0 && !allFolders[path[0]]) {
        return <Redirect to="/404-page-not-found" />;
    }

    return (
        <DashboardLayout 
            isLoading={isLoading} 
            cursorPosition={cursorPosition} 
            setCursorPosition={setCursorPosition}
            folders={allFolders}
            dirLoading={commDirLoading}
        >
            {/* Loading background */}
            {commDirLoading && <Loading className={classes.loading} message="Loading Communities" />}

            {/* Display table header and communities or folders in current folder */}
            {!commDirLoading && (
                <div className={classes.dashboardBody}>
                    {error && <p>{error}</p>}

                    <DashboardTable
                        history={history}
                        hasNestedPaths
                        rootDir={"Communities"}
                        rootPath={`/communities/dashboard`}
                        folders={allFolders}
                        draggable
                        allowMultiSelect
                        setCursorPosition={setCursorPosition}
                        fetchDir={fetchUserCommDir}
                        dirType={"commDir"}
                        allFoldersItems={filteredFoldersCommunities}
                        itemType={"community"}
                        setIsLoading={setIsLoading}
                    />
                </div>
            )}
            
            {/* Display for empty folders or empty commDirs */}
            {!commDirLoading && isEmpty(filteredFoldersCommunities) && (
                <CreateFirstCommunity
                    currentFolderId={currentFolderId}
                    communityLoading={communityLoading}
                    createCommunity={createCommunity}
                    uid={uid}
                />
            )}
        </DashboardLayout>
    );
};

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    error: selectUserError,
    commDir: selectUserCommDir,
    commDirLoading: selectUserCommDirLoading,
    communityLoading: selectCommunityLoading,
});

const mapDispatchToProps = (dispatch, { history, uid }) => ({
    fetchUserCommDir: (uid, skipLoading) => dispatch(fetchUserCommDir(uid, skipLoading)),

    createCommunity: async (uid, newCommunityName, parentFolderId = null) => {
        const communityId =
            (await dispatch(
                createCommunity(
                    {
                        name: newCommunityName,
                        totalModels: 0,
                        parentFolderId,
                        createdAt: moment().format(),
                        sharedWith: [uid],
                        regions: [],
                        weatherLocation: {},
                    },
                    uid,
                    parentFolderId
                )
            ).then((id) => id));

        await Promise.all([
            dispatch(clearCommunityData()),
            // TODO
            // dispatch(startCommunityLoading()),
            dispatch(fetchCommunityData(communityId)),
        ]).then(() => history.push(`/communities/${communityId}`));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunitiesDashboard);
