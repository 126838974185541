import React, { useEffect, useRef, useState } from "react";
import classes from "./style.module.scss";
import classNames from "classnames";
import ReactDOM from "react-dom";
import { useLocation, useHistory } from "react-router";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Table, TableRow, TablePagination, TableFooter } from "@material-ui/core";
import { useOutsideClickHook } from "utils/outsideClick";
import { CHBA_RENO_COMM_ID } from "utils/communities/api";
import { fetchColumnData } from "./utils";
import moment from "moment";
import AddIcon from "assets/images/icons/JSX/Add";
import NestedPath from "../NestedPath";
import ComponentListImage from "components/Draggable/DraggableImage/ComponentListImage";
import Dropdown from "components/Dropdown";
import {
    CreateCommunityDialog,
    DeleteMultipleFilesDialog,
    AddModelDialog,
    CreateFolderDialog,
} from "components/Dashboard/Actions";
import { Community, Folder, Model, CommModel, Analysis } from "./Item";
import { selectUserUid } from "store/users/selectors";
import { selectCommunityLoading } from "features/Community/_ducks/selectors";
import { selectFolderLoading } from "store/folders/selectors";
import { batchFolderRemove, createFolder, moveFolder } from "store/folders/thunk";
import {
    createParametricAnalysisFolder,
    moveParametricAnalysis,
    moveParametricAnalysisFolder,
    batchDeleteParametricAnalysis,
    batchDeleteParametricAnalysisFolders,
} from "store/parametricAnalysis/thunk";
import { actions as modelActions } from "features/Model/_ducks";
import { actions as communityActions } from "features/Community/_ducks";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as resultsActions } from "features/Model/Review/Results/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import { fetchDrawingData } from "features/Model/DrawingNew/_ducks/thunk";
import { clearDrawingData } from "features/Model/DrawingNew/_ducks/actions";

// const { createCommunity, clearCommunityData, startCommunityLoading, fetchCommunityData, moveCommunity, batchDeleteComms } = communityActions;
const { createCommunity, clearCommunityData, fetchCommunityData, moveCommunity, batchDeleteComms } = communityActions;
const { clearModelData, batchDeleteModels, moveModel, fetchModelData, modelLoading, setExportErrors } = modelActions;
const { clearEnclosureState } = enclosureActions;
const { resetResults } = resultsActions;
const { clearUpgradeData } = upgradeActions;

// key must match the property name in the model/folder/community object
// define "actions" to display three dots icon beside each item
const columnHeadings = {
    modelDir: {
        name: "Name",
        lastEdited: "Last Modified",
        actions: "Create",
    },
    commDir: {
        name: "Name",
        size: "Total size",
        region: "Region",
        lastEdited: "Last Modified",
        // actions: "New",
    },
    renoCommModelDir: {
        name: "Name",
        userName: "User",
        provTerr: "Province",
        currentPhase: "Current Phase",
        lastEdited: "Last Modified",
        targetCompletionDate: "Target Completion",
    },
    commModelDir: {
        name: "Name",
        weatherRegion: "Region/Location", // update key
        // duplicates: "Duplicates",
        countDirection: "Count & Direction",
        status: "Status",
        lastEdited: "Last Modified",
        actions: "Add",
    },
    parametricDir: {
        name: "Name",
        lastEdited: "Last Modified",
        actions: "New",
    },
};

// export const DashboardTable = ({
//     uid,
//     type,
// };

const DashboardTable = ({
    allowMultiSelect = false,
    // if hasNestedPaths
    hasNestedPaths = false,
    folders = [],
    rootDir = "Root Directory",
    rootPath = "",
    // if draggable
    draggable = false,
    setCursorPosition,
    fetchDir,
    // if paginated
    paginate = false,
    defaultRowsPerPage = 10,
    rowsPerPageOptions = [10, 25, 50, 100],
    // mandatory props
    history,
    dirType,
    allFoldersItems = [],
    itemType,
    setIsLoading,
    // mapping state and dispatch to props
    uid,
    folderLoading,
    communityLoading,
    batchFolderRemove,
    batchDeleteModels,
    batchDeleteComms,
    createFolder,
    createCommunity,
    createParametricAnalysisFolder,
    moveFolder,
    moveModel,
    moveCommunity,
    selectModel,
    selectCommunity,

    selectAnalysis,
    moveParametricAnalysis,
    moveParametricAnalysisFolder,
    batchDeleteParametricAnalysis,
    batchDeleteParametricAnalysisFolders,
}) => {
    const { pathname } = useLocation();

    const path =
        pathname
            .split("/")
            .filter(
                (str) => str !== "" && str !== "dashboard" && str !== "communities" && str !== "parametric-analysis"
            ) || [];
    const currentFolderId = path[path.length - 1] || null;

    const { actions: actionsLabel, ...itemDetails } = columnHeadings[dirType];

    const isRenoComm = dirType === "renoCommModelDir";
    const isCommModelDir = ["commModelDir", "renoCommModelDir"].includes(dirType);
    const getColumnData = fetchColumnData(itemDetails);

    /* For creating new dashboard items */
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [toCreateFolder, setToCreateFolder] = useState(false);
    const [folderName, setFolderName] = useState("");
    const [toCreateComm, setToCreateComm] = useState(false);
    const [communityName, setCommunityName] = useState("");
    const [toAddModel, setToAddModel] = useState(false);
    const [isFolderLoading, setIsFolderLoading] = useState(false);

    const createRef = useOutsideClickHook(() => setIsCreateOpen(false));

    let createNewOptions = [
        dirType === "modelDir"
            ? {
                  label: "New Model",
                  onClick: () => {
                      history.push(`/create${currentFolderId ? `?folderId=${currentFolderId}` : ""}`);
                      setIsCreateOpen(false);
                  },
              }
            : dirType === "parametricDir"
            ? {
                  label: "New Parametric Analysis",
                  onClick: () => {
                      history.push(
                          `/parametric-analysis/create${currentFolderId ? `?folderId=${currentFolderId}` : ""}`
                      );
                  },
              }
            : dirType === "commDir"
            ? {
                  label: "New Community",
                  onClick: () => {
                      setToCreateComm(true);
                      setIsCreateOpen(false);
                  },
              }
            : dirType === "commModelDir"
            ? {
                  label: "Add Model(s)",
                  onClick: () => {
                      setToAddModel(true);
                      setIsCreateOpen(false);
                  },
              }
            : {},
    ];

    if (path.length <= 6 && ["modelDir", "commDir", "parametricDir"].includes(dirType)) {
        createNewOptions = [
            ...createNewOptions,
            {
                label: "New Folder",
                onClick: () => {
                    setToCreateFolder(true);
                    setIsCreateOpen(false);
                },
            },
        ];
    }

    /* Pagination */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [paginatedRows, setPaginatedRows] = useState([]);

    // Reset page when new filters are applied and items change
    useEffect(() => {
        setPage(0);
    }, [allFoldersItems]);

    useEffect(() => {
        const currentPageData =
            paginate && rowsPerPage > 0
                ? allFoldersItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : allFoldersItems;
        setPaginatedRows(currentPageData);
    }, [rowsPerPage, page, allFoldersItems]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const paginatedRows =
    //     paginate && rowsPerPage > 0
    //         ? allFoldersItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //         : allFoldersItems;

    /* Deleting multiple items from dashboard */
    const [folderItemsToDelete, setFolderItemsToDelete] = useState([]);
    const [isMultiDeleting, toggleIsMultiDeleting] = useState(false);

    const handleMultipleDelete = async () => {
        let foldersBatchDelete = [];
        let itemsBatchToDelete = [];

        folderItemsToDelete.forEach(async (folderModel) => {
            const [itemId, { type: itemType }] = allFoldersItems.find(([key, {}]) => key === folderModel);

            if (itemType === "folder") {
                foldersBatchDelete.push(itemId);
            } else {
                itemsBatchToDelete.push(itemId);
            }
        });

        if (dirType === "modelDir") {
            await batchFolderRemove(foldersBatchDelete, uid, dirType);
            await batchDeleteModels(itemsBatchToDelete, uid);
        } else if (dirType === "commDir") {
            await batchFolderRemove(foldersBatchDelete, uid, dirType);
            await batchDeleteComms(itemsBatchToDelete, uid);
        } else if (dirType === "parametricDir") {
            await batchDeleteParametricAnalysisFolders(foldersBatchDelete, uid);
            await batchDeleteParametricAnalysis(itemsBatchToDelete, uid);
        }
        await fetchDir(uid, false);
    };

    /* Clicking outside of the table */
    const folderItemRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (folderItemRef.current && !folderItemRef.current.contains(event.target)) {
                setSelectedItem("");
                setMultiSelectedItems([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    /* Selecting a Folder, Model, or Community */
    const [selectedItem, setSelectedItem] = useState("");
    const [multiSelectedItems, setMultiSelectedItems] = useState([]);

    const isSelected = (id) => {
        return multiSelectedItems.includes(id) || id === selectedItem;
    };

    const onClick = async (event, type, key, index) => {
        event.preventDefault();

        if (allowMultiSelect) {
            // Pressed shift, multi select items from selected to clicked
            if (event.shiftKey && !event.ctrlKey && !event.metaKey) {
                multipleItemSelect(event, index);
                return;
            }

            // Pressed ctrl and clicked element is not in multiselected items (stuff selected)
            if (
                (event.ctrlKey || event.metaKey) &&
                multiSelectedItems &&
                !multiSelectedItems.includes(key) &&
                selectedItem
            ) {
                setMultiSelectedItems([...multiSelectedItems, key]);
                return;
            }

            // Pressed ctrl and clicked element is already in multiselected items (stuff selected) deselect item
            if (
                (event.ctrlKey || event.metaKey) &&
                multiSelectedItems &&
                multiSelectedItems.includes(key) &&
                selectedItem
            ) {
                const newFoldersAndItems = multiSelectedItems.filter((id) => id !== key);
                // Update selectedItem if it's being removed from multiSelectedItems
                if (selectedItem === key) {
                    setSelectedItem(newFoldersAndItems[0]);
                }
                setMultiSelectedItems(newFoldersAndItems);
                return;
            }
        }

        // Select item when first clicked and deselect any other items
        if (!selectedItem || selectedItem !== key) {
            setSelectedItem(key);
            setMultiSelectedItems([key]);
            return;
        }

        // Open selected folder
        if (selectedItem && selectedItem === key && type === "folder") {
            history.push(`${pathname === "/" ? "/dashboard" : pathname}/${key}`);
            setSelectedItem("");
            return;
        }

        // Open selected item when clicked if no other items are selected, otherwise deselect the rest
        if (selectedItem && selectedItem === key && type === itemType) {
            if (multiSelectedItems.length === 1) {
                setIsLoading(true);
                if (type === "model" || type === "commModel") {
                    await selectModel(key);
                }
                if (type === "community") {
                    await selectCommunity(key);
                }
                if (type === "analysis") {
                    await selectAnalysis(key);
                }
            } else {
                setSelectedItem(key);
                setMultiSelectedItems([key]);
            }
            return;
        }
    };

    const multipleItemSelect = (event, index) => {
        event.preventDefault();

        if (event.shiftKey && multiSelectedItems.length === 0) {
            setMultiSelectedItems([allFoldersItems[index][0]]);
        }

        if (event.shiftKey && multiSelectedItems.length > 0) {
            const firstIndex = allFoldersItems.findIndex(([key, el]) => key == multiSelectedItems[0]);
            const startIndex =
                multiSelectedItems.length > 1 && firstIndex >= index
                    ? allFoldersItems.findIndex(([key, el]) => key == multiSelectedItems[multiSelectedItems.length - 1])
                    : firstIndex;
            const endIndex = multiSelectedItems.includes(allFoldersItems[index][0]) ? index : index;

            const selectedItems =
                endIndex < startIndex
                    ? allFoldersItems.slice(endIndex, startIndex + 1)
                    : allFoldersItems.slice(startIndex, endIndex + 1);

            setMultiSelectedItems(selectedItems.map(([key, _]) => key));
        }
    };

    /* Drag and drop Folders, Models, and Communities */
    const [isDragging, setIsDragging] = useState(false);
    const [dropTo, setDropTo] = useState("");
    const [isDropInProcess, setIsDropInProcess] = useState(false);

    const onDragEnd = async (event) => {
        event.preventDefault();

        setCursorPosition({ top: event.clientY, left: event.clientX - 250 });

        setIsDragging(false);

        if (dropTo !== "") {
            setIsLoading(true);
            setIsDropInProcess(true);
        } else return;

        if (selectedItem && multiSelectedItems.length <= 1) {
            const itemToMove = allFoldersItems.find(([key, el]) => key === selectedItem);

            const { type } = itemToMove[1];

            if (type === "model") await moveModel(itemToMove[0], uid, dropTo, true);

            if (type === "community") await moveCommunity(itemToMove[0], uid, dropTo, true);

            if (type === "folder") {
                if (dirType === "parametricDir") await moveParametricAnalysisFolder(itemToMove[0], uid, dropTo, true);
                else await moveFolder(itemToMove[0], uid, dropTo, true, dirType);
            }

            if (type === "analysis") await moveParametricAnalysis(itemToMove[0], uid, dropTo, true);
        }

        if (!multiSelectedItems.includes(dropTo) && multiSelectedItems.length > 1 && dropTo !== "") {
            await Promise.all(
                multiSelectedItems.map((itemId) => {
                    const itemToMove = allFoldersItems.find(([key, el]) => key === itemId);

                    const { type } = itemToMove[1];

                    if (type === "model") return moveModel(itemToMove[0], uid, dropTo, true);

                    if (type === "community") return moveCommunity(itemToMove[0], uid, dropTo, true);

                    if (type === "folder") return moveFolder(itemToMove[0], uid, dropTo, true, dirType);
                })
            );
        }

        await fetchDir(uid, true);

        setIsLoading(false);
        setIsDropInProcess(false);
        setDropTo("");
        setSelectedItem("");
        if (multiSelectedItems.length > 1) setMultiSelectedItems([]);
    };

    const onDragStart = (event, draggableId, name, type) => {
        event.dataTransfer.effectAllowed = "move";

        var selectedItems = multiSelectedItems;

        // Reselect items if dragging starts on an unselected item
        if (!multiSelectedItems.includes(draggableId)) {
            setSelectedItem(draggableId);
            setMultiSelectedItems([draggableId]);
            selectedItems = [draggableId];
        }

        const image = <ComponentListImage componentLabel={name} componentType={type} multipleSelect={selectedItems} />;

        let ghost = document.createElement("div");

        ghost.style.transform = "translate(-10000px, -10000px)";
        ghost.style.position = "absolute";
        document.body.appendChild(ghost);
        event.dataTransfer.setDragImage(ghost, 0, 0);

        ReactDOM.render(image, ghost);

        setIsDragging(true);
    };

    const onDragUpdate = (event, dropToId, type) => {
        event.preventDefault();

        if (dropToId === dropTo) return;

        if (type === "folder" && !isSelected(dropToId)) {
            setDropTo(dropToId);
        }

        if (type === itemType && dropTo !== "") {
            setDropTo("");
        }
    };

    return (
        <React.Fragment>
            {hasNestedPaths && (
                <NestedPath
                    rootDir={rootDir}
                    rootPath={rootPath}
                    folders={folders}
                    dirType={dirType}
                    onDragOver={onDragUpdate}
                />
            )}
            {/* TABLE HEADER */}
            <div
                className={classNames(classes.tableHead, {
                    [classes.renoComm]: isRenoComm,
                    [classes[`col${Object.keys(columnHeadings[dirType]).length}`]]: !isRenoComm,
                })}
            >
                {Object.entries(itemDetails).map(([key, el]) => (
                    <div key={key}> {el} </div>
                ))}
                {actionsLabel && (
                    <div className={classes.createNew} ref={createRef}>
                        <span onClick={() => setIsCreateOpen(!isCreateOpen)}>
                            {actionsLabel} <AddIcon />
                        </span>
                        <Dropdown className={classes.createDropdown} open={isCreateOpen} options={createNewOptions} />
                        {["modelDir", "commDir", "parametricDir"].includes(dirType) && (
                            <CreateFolderDialog
                                openDialog={toCreateFolder}
                                newFolderName={folderName}
                                setNewFolderName={(newVal) => setFolderName(newVal)}
                                onClick={async () => {
                                    if (!folderName) return;

                                    setIsFolderLoading(true);

                                    if (dirType === "parametricDir") {
                                        await createParametricAnalysisFolder(folderName, uid, currentFolderId);
                                    } else {
                                        await createFolder(uid, folderName, currentFolderId, dirType);
                                    }

                                    setToCreateFolder(false);
                                    setFolderName("");
                                    setIsFolderLoading(false);
                                }}
                                onClose={() => setToCreateFolder(false)}
                                folderLoading={folderLoading || isFolderLoading}
                            />
                        )}
                        {dirType === "commDir" && (
                            <CreateCommunityDialog
                                openDialog={toCreateComm}
                                newCommunityName={communityName}
                                setNewCommunityName={(newVal) => setCommunityName(newVal)}
                                onClick={async () => {
                                    await createCommunity(uid, communityName, currentFolderId);
                                }}
                                onClose={() => setToCreateComm(false)}
                                communityLoading={communityLoading}
                            />
                        )}
                        {dirType === "commModelDir" && (
                            <AddModelDialog addModelOpen={toAddModel} onClose={() => setToAddModel(false)} />
                        )}
                        <DeleteMultipleFilesDialog
                            numFilesToDelete={folderItemsToDelete.length}
                            loading={isMultiDeleting}
                            onClick={async () => {
                                toggleIsMultiDeleting(true);
                                await handleMultipleDelete();
                                //doesn't close when finished
                                toggleIsMultiDeleting(false);
                                setFolderItemsToDelete([]);
                            }}
                            onClose={() => setFolderItemsToDelete([])}
                        />
                    </div>
                )}
            </div>
            {/* TABLE BODY */}
            <ul
                className={classNames(classes.tableBody, {
                    [classes.commModelDir]: isCommModelDir,
                    [classes.renoComm]: isRenoComm,
                    [classes[`col${Object.keys(columnHeadings[dirType]).length}`]]: !isRenoComm,
                })}
                style={{ userSelect: "none" }}
                ref={folderItemRef}
            >
                {paginatedRows.map(
                    ([key, { sharedWithUser, type, disableDelete = false, tags, ...details }], index) => {
                        return (
                            <div
                                key={key}
                                className={classes.draggableItem}
                                draggable={draggable}
                                onDragStart={(event) => onDragStart(event, key, details.name, type)}
                                onDragOver={(event) => {
                                    // Prevent moving for CHBA Reno Community
                                    if (!multiSelectedItems.includes(CHBA_RENO_COMM_ID)) {
                                        event.stopPropagation();
                                        onDragUpdate(event, key, type);
                                    }
                                }}
                                onDragEnd={(event) => onDragEnd(event)}
                                onDragLeave={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setDropTo("");
                                }}
                                onClick={(event) => onClick(event, type, key, index)}
                            >
                                {type === "folder" && (
                                    <Folder
                                        key={key}
                                        folderId={key}
                                        rootDir={rootDir}
                                        folders={folders}
                                        dirType={dirType}
                                        sharedWithUser={sharedWithUser}
                                        // pathname={pathname === "/" ? "/dashboard" : pathname}
                                        tags={tags}
                                        isSelected={isSelected(key)}
                                        isDragging={isDragging}
                                        combineWith={dropTo === key && !isSelected(key)}
                                        isDropInProcess={isDropInProcess && isSelected(key)}
                                        setFolderItemsToDelete={setFolderItemsToDelete}
                                        multiSelectedItems={multiSelectedItems}
                                        disableActions={disableDelete || !actionsLabel}
                                        details={getColumnData(details)}
                                    />
                                )}
                                {type === "community" && (
                                    <Community
                                        key={key}
                                        communityId={key}
                                        rootDir={rootDir}
                                        folders={folders}
                                        // tags={tags}
                                        sharedWithUser={sharedWithUser}
                                        isSelected={isSelected(key)}
                                        isDragging={isDragging}
                                        isDropInProcess={isDropInProcess && isSelected(key)}
                                        setFolderItemsToDelete={setFolderItemsToDelete}
                                        multiSelectedItems={multiSelectedItems}
                                        disableActions={sharedWithUser || !actionsLabel}
                                        details={getColumnData(details)}
                                    />
                                )}
                                {type === "model" && (
                                    <Model
                                        key={key}
                                        modelId={key}
                                        tags={tags}
                                        rootDir={rootDir}
                                        folders={folders}
                                        sharedWithUser={sharedWithUser}
                                        isSelected={isSelected(key)}
                                        isDragging={isDragging}
                                        isDropInProcess={isDropInProcess && isSelected(key)}
                                        multiSelectedItems={multiSelectedItems}
                                        setFolderItemsToDelete={setFolderItemsToDelete}
                                        disableActions={sharedWithUser || !actionsLabel}
                                        details={getColumnData(details)}
                                    />
                                )}
                                {type === "commModel" && (
                                    <CommModel
                                        key={key}
                                        modelId={key}
                                        sharedWithUser={sharedWithUser}
                                        isRenoCommModel={isRenoComm}
                                        isSelected={isSelected(key)}
                                        multiSelectedItems={multiSelectedItems}
                                        setMultiSelectedItems={setMultiSelectedItems}
                                        disableActions={sharedWithUser || !actionsLabel}
                                        details={getColumnData(details)}
                                    />
                                )}
                                {type === "analysis" && (
                                    <Analysis
                                        key={key}
                                        analysisId={key}
                                        tags={tags}
                                        rootDir={rootDir}
                                        folders={folders}
                                        sharedWithUser={sharedWithUser}
                                        isSelected={isSelected(key)}
                                        isDragging={isDragging}
                                        isDropInProcess={isDropInProcess && isSelected(key)}
                                        multiSelectedItems={multiSelectedItems}
                                        setFolderItemsToDelete={setFolderItemsToDelete}
                                        disableActions={sharedWithUser || !actionsLabel}
                                        details={getColumnData(details)}
                                    />
                                )}
                            </div>
                        );
                    }
                )}
            </ul>
            {paginate && allFoldersItems.length > rowsPerPage && (
                <Table>
                    <TableFooter style={{ backgroundColor: "white" }}>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                count={allFoldersItems.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    folderLoading: selectFolderLoading,
    communityLoading: selectCommunityLoading,
});

const mapDispatchToProps = (dispatch, { history }) => ({
    batchFolderRemove: async (foldersToDelete, uid, dirType = "modelDir") =>
        dispatch(batchFolderRemove(foldersToDelete, uid, dirType)),
    batchDeleteModels: async (modelsToDeleteIds, uid) => dispatch(batchDeleteModels(modelsToDeleteIds, uid)),
    batchDeleteComms: async (commsToDeleteIds, uid) => dispatch(batchDeleteComms(commsToDeleteIds, uid)),

    createFolder: async (uid, newFolderName, parentFolderId, dirType = "modelDir") => {
        return dispatch(createFolder(uid, newFolderName, parentFolderId, dirType));
    },
    createCommunity: async (uid, newCommunityName, parentFolderId = null) => {
        const communityId = await dispatch(
            createCommunity(
                {
                    name: newCommunityName,
                    totalModels: 0,
                    parentFolderId,
                    createdAt: moment().format(),
                    sharedWith: [uid],
                    regions: [],
                    weatherLocation: {},
                },
                uid,
                parentFolderId
            )
        ).then((id) => id);

        await Promise.all([
            dispatch(clearCommunityData()),
            // dispatch(startCommunityLoading()),
            dispatch(fetchCommunityData(communityId)),
        ]).then(() => history.push(`/communities/${communityId}`));
    },
    createParametricAnalysisFolder: (folderName, uid, currentFolderId) =>
        dispatch(createParametricAnalysisFolder(folderName, uid, currentFolderId)),

    moveFolder: async (folderId, uid, moveToId, skipLoading, dirType = "modelDir") => {
        return dispatch(moveFolder(folderId, uid, moveToId, skipLoading, dirType));
    },
    moveCommunity: async (communityId, uid, folderId, skipFetch = false) => {
        return dispatch(moveCommunity(communityId, uid, folderId, skipFetch));
    },
    moveModel: async (modelId, uid, folderId, skipLoading = false) => {
        return dispatch(moveModel(modelId, uid, folderId, skipLoading));
    },
    selectCommunity: async (communityId) => {
        await Promise.all([
            dispatch(clearCommunityData()),
            // dispatch(startCommunityLoading()),
            dispatch(fetchCommunityData(communityId)),
        ]).then(() => history.push(`/communities/${communityId}`));
    },
    selectModel: async (modelId) => {
        await Promise.all([
            dispatch(clearModelData()),
            dispatch(clearDrawingData()),
            dispatch(resetResults()),
            dispatch(clearUpgradeData()),
            dispatch(clearEnclosureState()),
            dispatch(setExportErrors([])),
            dispatch(modelLoading()),
            dispatch(fetchModelData(modelId)),
            dispatch(fetchDrawingData(modelId)),
        ]).then(() => history.push(`/models/${modelId}`));
    },
    selectAnalysis: async (analysisId) => {
        history.push(`/parametric-analysis/analysis/${analysisId}`);
    },

    moveParametricAnalysis: (analysisId, userUid, moveToId) =>
        dispatch(moveParametricAnalysis(analysisId, userUid, moveToId)),
    moveParametricAnalysisFolder: (folderId, userUid, moveToId) =>
        dispatch(moveParametricAnalysisFolder(folderId, userUid, moveToId)),

    batchDeleteParametricAnalysis: (analysisIds, userUid) =>
        dispatch(batchDeleteParametricAnalysis(analysisIds, userUid)),
    batchDeleteParametricAnalysisFolders: (folderIds, userUid) =>
        dispatch(batchDeleteParametricAnalysisFolders(folderIds, userUid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTable);
