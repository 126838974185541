import React from "react";

import { getOptions } from "utils/fields";
import { getF280WeatherOpts } from "utils/weather";

import ModelSummaryLayout from "./components/ModelSummaryLayout";
import ModelSummaryInputRow from "./components/ModelSummaryInputRow";

import classes from "./styles.module.scss";

const ersTypes = {
    "": "General",
    "ca.nrcan.gc.OEE.ERS.ErsProgram": "EnerGuide Rating System",
    "ca.nrcan.gc.OEE.ERS2020NBC.ErsProgram": "EnerGuide Rating System 2020 NBC",
    "ca.nrcan.gc.OEE.ONrh.OnProgram": "Ontario Reference House",
};

const ModelSummary = ({ modelData = {} }) => {
    const {
        specifications: {
            buildingType: { id: buildingTypeId = 0 } = {},
            buildingSubType: { id: buildingSubTypeId = 0 } = {},
            facingDirection: { id: facingDirectionId = 0 } = {},
            yearBuilt: { value: yearBuiltVal = 0 } = {},
            numStoreys: { id: numStoreysId = 0 } = {},
        } = {},
        h2kVersion: { major: majorVersion = 0, minor: minorVersion = 0 } = {},
        location: {
            weather: {
                location: { id: weatherLocationId = 0 } = {},
                region: { id: regionId = 0 } = {},
                heatingDegreeDay = 0,
                f280: { dhdbt = 0, dcdbt = 0, location: { id: locationId = 0 } = {} } = {},
            } = {},
        } = {},
        dimensions: {
            aboveGradeIntFloorArea: { total: aboveGradeIntFloorAreaTotal = 0 } = {},
            belowGradeIntFloorArea: { total: belowGradeIntFloorAreaTotal = 0 } = {},
            volume: { total: volumeTotal = 0 } = {},
        } = {},
        program: { class: programmClass = "" } = {},
    } = modelData;

    return (
        <div className={classes.modelSummaryContainer}>
            <ModelSummaryLayout title="Model Summary">
                <ModelSummaryInputRow
                    label={"Building-Type"}
                    value={
                        getOptions({
                            fieldKey: "buildingType",
                        }).filter(({ value }) => value.id === buildingTypeId)[0]?.label || "N/A"
                    }
                />
                <ModelSummaryInputRow
                    label={"Building Subtype"}
                    value={
                        getOptions({
                            fieldKey: "buildingSubType",
                            indKey: buildingTypeId,
                        }).filter(({ value }) => value.id === buildingSubTypeId)[0]?.label || "N/A"
                    }
                />
                <ModelSummaryInputRow
                    label={"Facing Direction"}
                    value={
                        getOptions({
                            fieldKey: "facingDirection",
                        }).filter(({ value }) => value.id === facingDirectionId)[0]?.label || "N/A"
                    }
                />
                <ModelSummaryInputRow label={"Year Built"} value={yearBuiltVal || "N/A"} />
                <ModelSummaryInputRow label={"H2K Version"} value={`V${majorVersion}.${minorVersion}` || "N/A"} />
                <ModelSummaryInputRow label={"Program type"} value={ersTypes[programmClass] || "N/A"} />
            </ModelSummaryLayout>
            <ModelSummaryLayout title="Weather and Location Information">
                <ModelSummaryInputRow
                    label={"Location"}
                    value={
                        getOptions({ fieldKey: "weatherRegion", version: `v${majorVersion}-${minorVersion}` }).filter(
                            ({ value }) => value.id === regionId
                        )[0].label
                    }
                />
                <ModelSummaryInputRow
                    label={"Location"}
                    value={
                        getOptions({
                            fieldKey: "weatherLocation",
                            indKey: regionId,
                            version: `v${majorVersion}-${minorVersion}`,
                        }).filter(({ value }) => value.id === weatherLocationId)[0].label
                    }
                />
                <ModelSummaryInputRow label={"Heating Degree Days"} value={heatingDegreeDay} />
                <ModelSummaryInputRow
                    label={"CSA F280-12 Design Conditions:"}
                    value={getF280WeatherOpts(regionId).filter(({ value }) => value.id === locationId)[0].label}
                />
                <ModelSummaryInputRow label={"Heating Design Temperature:"} value={`${dhdbt}°C`} />
                <ModelSummaryInputRow label={"Cooling Design Temperature:"} value={`${dcdbt}°C`} />
            </ModelSummaryLayout>
            <ModelSummaryLayout title="Building Dimensions">
                <ModelSummaryInputRow
                    inputWithUnits
                    label={"Above Grade Internal Floor Area"}
                    value={aboveGradeIntFloorAreaTotal}
                />
                <ModelSummaryInputRow
                    inputWithUnits
                    label={"Below Grade Internal Floor Area"}
                    value={belowGradeIntFloorAreaTotal}
                />
                <ModelSummaryInputRow inputWithUnits label={"Volume"} value={volumeTotal} />
                <ModelSummaryInputRow
                    label={"Volume"}
                    value={
                        getOptions({ fieldKey: "numStoreys" }).filter(({ value }) => value.id === numStoreysId)[0].label
                    }
                />
            </ModelSummaryLayout>
        </div>
    );
};

export default ModelSummary;
