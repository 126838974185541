import React, { useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";

import { addUpgradeToParametricAnalysis, removeUpgradeFromParametricAnalysis } from "store/parametricAnalysis/actions";

import { UPGRADE_SELECTION_OPTIONS } from "utils/parametric/constants";
import {
    getUpgradeSelectionOptions,
    getAllUpgradesPackages,
    getUpgradeToAdd,
    getPrebuiltEcmUpgradeOptions,
} from "utils/parametric";
import { getBlankComponentUpgradeFields, envelopeRowHeadings, getBlankUpgradeTemplates } from "utils/upgrades";

import Select from "components/Input/Select";
import Button from "components/Button";
import AddUpgradeFromLibraryDialog from "../AddUpgradeFromLibraryDialog";

import { ReactComponent as ParametricLibIcon } from "assets/images/icons/ParametricUpgradesLib.svg";
import Add from "assets/images/icons/JSX/Add";

import classes from "./styles.module.scss";

const UpgradesSelection = ({
    selectedUpgradeType,
    setSelectedUpgradeType,
    selectedUpgradePackage,
    setSelectedUpgradePackage,
    upgrades,
    addUpgradeToParametricAnalysis,
    ecmInputMap,
    toggleUpgrade,
    removeUpgradeFromParametricAnalysis,
    setUpgradeWhereToAddCode,
}) => {
    const upgradesOptions = getUpgradeSelectionOptions(upgrades, selectedUpgradeType);
    const prebuiltOptions = getPrebuiltEcmUpgradeOptions(selectedUpgradeType?.type || "");

    const allUpgrades = getAllUpgradesPackages(upgrades);

    const [isAddingFromLibrary, setIsAddingFromLibrary] = useState(false);

    const envelopeRowHeadingsIds = envelopeRowHeadings.map(({ id }) => id);

    return (
        <>
            <div className={classes.upgradesSelectionContainer}>
                <Select
                    className={classes.upgradeType}
                    options={UPGRADE_SELECTION_OPTIONS}
                    input={{
                        value: selectedUpgradeType,
                        onChange: (value) => {
                            setSelectedUpgradeType(value);
                            setSelectedUpgradePackage("");
                        },
                    }}
                    placeholder="Select Upgrade Type"
                />
                <Select
                    disabled={isEmpty(selectedUpgradeType)}
                    options={[...upgradesOptions, ...prebuiltOptions]}
                    input={{
                        value: selectedUpgradePackage,
                        onChange: (value) => setSelectedUpgradePackage(value),
                    }}
                    placeholder="Select Upgrade Package"
                    search={true}
                    searchPlaceholder="Search for upgrade package"
                    footer={() => (
                        <div
                            className={classes.selectFooter}
                            onClick={() => {
                                const newUpgradeId = `upgrades-${moment().format("YYYYMMDDHHmmssSS")}-${0}`;

                                setUpgradeWhereToAddCode({
                                    ecmInputMapUpgradeType: selectedUpgradeType.type,
                                    ecmInputMapUpgradeId: newUpgradeId,
                                    ecmInputMapUpgrade: {},
                                });

                                let initUpgradeData = {};

                                if (envelopeRowHeadingsIds.includes(selectedUpgradeType.type)) {
                                    initUpgradeData = getBlankComponentUpgradeFields(selectedUpgradeType.type);
                                } else {
                                    initUpgradeData = {
                                        fields: getBlankUpgradeTemplates(selectedUpgradeType.type),
                                    };
                                }

                                toggleUpgrade({
                                    isOpen: true,
                                    formName: "upgrades",
                                    selectedPackage: null,
                                    selectedUpgrade: newUpgradeId,
                                    upgradeType: selectedUpgradeType.type,
                                    selectedUpgradeType: selectedUpgradeType.type,
                                    selectedUpgradeH2kType: selectedUpgradeType.upgradeType,
                                    selectedUpgradeTypeLabel: "",
                                    selectedUpgradeLabel: "",
                                    initUpgradeData,
                                    withSelectComponents: false,
                                    accessor: "fields",
                                    isUpgradeLibrary: false,
                                    isParametricAnalysis: true,
                                    isNew: true,
                                    resetState: () => {
                                        setSelectedUpgradeType({});
                                        setSelectedUpgradePackage("");
                                        setUpgradeWhereToAddCode({});
                                        removeUpgradeFromParametricAnalysis(selectedUpgradeType.type, newUpgradeId);
                                    },
                                });
                            }}
                        >
                            <span>Add new upgrade</span>
                            <Add />
                        </div>
                    )}
                />
                <Button
                    style={{ maxHeight: "36px" }}
                    disabled={isEmpty(selectedUpgradeType) || !selectedUpgradePackage}
                    onClick={() => {
                        const upgradeToAdd = getUpgradeToAdd(allUpgrades, selectedUpgradePackage, ecmInputMap);

                        addUpgradeToParametricAnalysis(selectedUpgradeType.type, upgradeToAdd);

                        // setSelectedUpgradeType({});
                        setSelectedUpgradePackage("");
                    }}
                >
                    Add
                </Button>
                <Button
                    style={{ maxHeight: "36px", maxWidth: "253px", width: "100%" }}
                    type={"lightGrey"}
                    disabled={!selectedUpgradeType}
                    icon={ParametricLibIcon}
                    onClick={() => setIsAddingFromLibrary(true)}
                >
                    Add from upgrades library
                </Button>
            </div>
            <AddUpgradeFromLibraryDialog
                isOpen={isAddingFromLibrary}
                setIsAddingFromLibrary={setIsAddingFromLibrary}
                addUpgradeToParametricAnalysis={addUpgradeToParametricAnalysis}
            />
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    addUpgradeToParametricAnalysis: (sectionType, upgrade) =>
        dispatch(addUpgradeToParametricAnalysis(sectionType, upgrade)),
    removeUpgradeFromParametricAnalysis: (sectionType, upgradeId) =>
        dispatch(removeUpgradeFromParametricAnalysis(sectionType, upgradeId)),
});

export default connect(null, mapDispatchToProps)(UpgradesSelection);
