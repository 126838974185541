import React, { useState } from "react";
import classes from "../../style.module.scss";
import classNames from "classnames";
import DashboardItem from "..";
import { selectUserUid } from "store/users/selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { DeleteDialog, DuplicateDialog, MoveDialog } from "components/Dashboard/Actions";

import { actions as userActions } from "store/users";
import { actions as modelActions } from "features/Model/_ducks";
import { useLocation } from "react-router";
import { selectModelLoading } from "features/Model/_ducks/selectors";

const { deleteModel, moveModel, duplicateModel } = modelActions;
const { removeFromDir } = userActions;

const Model = ({
    uid,
    ref,
    modelId,
    tags = [],
    setModelToMove,
    sharedWithUser = false,
    isSelected,
    isDragging,
    isDropInProcess,
    multiSelectedItems,
    setFolderItemsToDelete,
    disableActions = false,
    details, // { name, lastEdited }

    folders,
    rootDir,
    selectModel,
    deleteModel,
    duplicateModel,
    moveModel,
    modelLoading,
    ...props
}) => {
    const { name } = details;

    const { pathname } = useLocation();
    const path = pathname.split("/").filter((str) => ["", "dashboard", "communities"].includes(str) === false) || [];
    const currentFolderId = path[path.length - 1] || null;

    const [startDelete, toggleStartDelete] = useState(false);
    const [isDeleting, toggleDeleting] = useState(false);

    const [startDuplicate, toggleStartDuplicate] = useState(false);
    const [isDuplicating, toggleDuplicating] = useState(false);
    const [duplicateName, setDuplicateName] = useState("");

    const [startMove, toggleStartMove] = useState(false);

    return (
        <React.Fragment>
            <DashboardItem
                ref={ref}
                className={classNames({
                    [classes.selectedItem]: isSelected,
                    [classes.itemDragging]: isDragging && isSelected,
                    [classes.isDropInProcess]: isDropInProcess,
                })}
                itemId={modelId}
                sharedWithUser={sharedWithUser}
                itemType={"model"}
                details={details}
                tags={tags}
                actions={
                    disableActions
                        ? null
                        : [
                              {
                                  label: "Duplicate Model",
                                  onClick: () => {
                                      toggleStartDuplicate(true);
                                      setDuplicateName(`${name} (1)`);
                                  },
                              },
                              {
                                  label: "Move Model",
                                  onClick: () => {
                                      toggleStartMove(true);
                                  },
                              },
                              {
                                  label: "Delete Model",
                                  onClick: () => {
                                      toggleStartDelete(true);
                                  },
                              },
                              {
                                  label: `Delete ${multiSelectedItems.length} files`,
                                  shouldShow: multiSelectedItems.length > 1,
                                  onClick: () => {
                                      setFolderItemsToDelete(multiSelectedItems);
                                  },
                              },
                          ]
                }
                {...props}
            />
            <DeleteDialog
                fileName={name}
                openDialog={startDelete}
                loading={isDeleting}
                onClick={async () => {
                    toggleDeleting(true);
                    await deleteModel({ modelId, uid });
                }}
                onClose={() => toggleStartDelete(false)}
            />
            <DuplicateDialog
                itemType={"model"}
                openDialog={startDuplicate}
                newDuplicateName={duplicateName}
                setDuplicateName={(newVal) => setDuplicateName(newVal)}
                onClick={async () => {
                    toggleDuplicating(true);
                    await duplicateModel(modelId, uid, currentFolderId, duplicateName);
                }}
                onClose={() => toggleStartDuplicate(false)}
                isLoading={isDuplicating}
            />
            <MoveDialog
                itemId={modelId}
                fileName={name}
                rootDir={rootDir}
                folders={folders}
                openDialog={startMove}
                itemLoading={modelLoading}
                handleMove={async (moveToId) => {
                    await moveModel(modelId, uid, moveToId);
                }}
                onClose={() => toggleStartMove(false)}
            />
        </React.Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    modelLoading: selectModelLoading,
});

const mapDispatchToProps = (dispatch, { history }) => ({
    deleteModel: async ({ modelId, uid }) => {
        await dispatch(deleteModel({ modelId, uid }));
        return dispatch(removeFromDir(modelId));
    },
    duplicateModel: async (modelId, uid, folderId, duplicateName) => {
        return dispatch(duplicateModel(modelId, uid, folderId, duplicateName));
    },
    moveModel: async (modelId, uid, folderId, skipLoading = false) => {
        return dispatch(moveModel(modelId, uid, folderId, skipLoading));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Model);
