import React from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getOptions, getBaseUnits, getUnitOptions } from "utils/fields";
import { getValidation, getDecimalPlaces } from "utils/fieldValidation";
import isEmpty from "lodash/isEmpty";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import InputWithUnits from "components/Input/InputWithUnits";
import Accordion from "components/Accordion";
import Checkbox from "components/Input/Checkbox";
import Select from "components/Input/Select";
import AddSystem from "./AddSystem/container";
import Ventilator from "./Ventilator/container";
import Dryer from "./Dryer/container";
import HRV from "./HRV/container";
import EmptyState from "components/EmptyState";
import { NBCMinVentIndex, OBCMinVentIndex } from "utils/buildingCode";
import NonH2kParam from "assets/images/icons/JSX/NonH2kParam";

const reqUseValidation = getValidation("ventRequirementsUse");
const reqAchValidation = getValidation("ventRequirementsACH");
const reqSupplyValidation = getValidation("ventRequirementsSupply");
const reqExhaustValidation = getValidation("ventRequirementsExhaust");

const roomsLivingValidation = getValidation("numLivingRooms");
const roomsBathValidation = getValidation("numBathrooms");
const roomsBedValidation = getValidation("numBedrooms");
const roomsUtilityValidation = getValidation("numUtility");
const roomsOtherValidation = getValidation("numOtherRooms");
const roomsVentRateValidation = getValidation("ventRoomsVentilationRate");
const roomsDepressLimitValidation = getValidation("ventDepressurizationLimit");

const airDistTypeValidation = getValidation("ventAirDistributionType");
const airDistFanPowerValidation = getValidation("ventAirDistributionFanPower");
const operationScheduleValidation = getValidation("ventOperationSchedule");
const tempCtrlLowerValidation = getValidation("ventTempControlledLower");
const tempCtrlUpperValidation = getValidation("ventTempControlledUpper");

const getAirFlowLabel = (airFlowMetric) => {
    return `${airFlowMetric.toFixed(1)} L/s (${(2.11888 * airFlowMetric).toFixed(1)} cfm)`;
};

const F280VentilationText = ({
    hasWholeHouseSystem = false,
    pvcFromWholeHouseSystems = false,
    wholeHouseRate = 0,
    bedroomMinVentRate = 0,
}) => {
    if (!hasWholeHouseSystem) {
        return (
            <p>
                No whole-house system is specified. CSA F280-12 calculations will not consider mechanical ventilation
                heat loss and gain.
            </p>
        );
    }

    if (hasWholeHouseSystem && !pvcFromWholeHouseSystems) {
        return (
            <p>
                A whole-house system is specified. CSA F280-12 calculations will consider the minimum ventilation rate
                reported in the Enclosure tab ({bedroomMinVentRate.toFixed(2)} L/s) for the purposes of calculating
                mechanical ventilation losses and gains. This is the <strong>recommended</strong> approach.
            </p>
        );
    }

    if (hasWholeHouseSystem && pvcFromWholeHouseSystems) {
        return (
            <>
                <p>
                    A whole-house system is specified, and you have opted to override the minimum ventilation rate from
                    your selected building code. CSA F280-12 calculations will consider the specified flow rate (
                    {wholeHouseRate.toFixed(2)} L/s) for mechanical heat loss and gain calculations.
                </p>
                <p style={{ marginTop: "0.5rem" }}>
                    This override is <strong>not recommended</strong>, and should only be used when accompanied by
                    ventilation system design.
                </p>
            </>
        );
    }
};

export default ({
    accessor,
    reqUseId,
    achValue,
    reqSupplyValue,
    reqExhaustValue,
    reqSupplyUnits,
    reqExhaustUnits,
    depressId,
    depressValue,
    depressUnits,
    airDistTypeId,
    airDistFanPowerId,
    airDistFanPowerValue,
    airDistFanPowerUnits,
    operationScheduleId,
    operationScheduleValue,
    operationScheduleUnits,
    tempCtrlLowerUnits,
    tempCtrlUpperUnits,
    wholeHouseHrv,
    wholeHouseVentilators,
    supplementalHrv,
    supplementalVentilators,
    supplementalDryer,
    totalWholeHouseSupply,
    totalSupplementalSupply,
    totalWholeHouseExhaust,
    totalSupplementalExhaust,
    bedroomsEntered,
    buildingCodeEntered,
    modelUnits,
    pvcFromWholeHouseSystems,
    hasWholeHouseSystem,
    wholeHouseRate,
    bedroomMinVentRate,
    change,
    isUpgrade = false,
    formName,
    atticDuctInsUnits,
    unconditionedCrawlspaceDuctInsUnits,
    unconditionedBasementDuctInsUnits,
    canBeEditedFromParametric = true,
}) => {
    const handleBedroomChange = () => {
        if (buildingCodeEntered === "NBC") {
            change("modelData.ventilation.requirements.minBedroomVentilation", NBCMinVentIndex[bedroomsEntered]);
        }
        if (buildingCodeEntered === "OBC") {
            change("modelData.ventilation.requirements.minBedroomVentilation", OBCMinVentIndex[bedroomsEntered]);
        }

        if (bedroomsEntered > 5) {
            change(
                "modelData.ventilation.requirements.minBedroomVentilation",
                buildingCodeEntered === "NBC" ? NBCMinVentIndex[5] : OBCMinVentIndex[5]
            );
        }
    };

    return (
        <div className={!isUpgrade && sharedClasses.section}>
            {!isUpgrade && (
                <>
                    <h4 style={{ marginBottom: "1.25rem" }}>Ventilation Requirements</h4>
                    <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                        <Field
                            component={Select}
                            name={`${accessor}.requirements.use`}
                            options={getOptions({ fieldKey: "ventRequirementsUse" })}
                            label="Type"
                            placeholder="Choose Requirements Type"
                            validate={reqUseValidation}
                            info={
                                'Please note that the "Devices over 75 L/s" field is not stored in the .h2k file, and therefore cannot be modified here.'
                            }
                            disabled={!canBeEditedFromParametric}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.requirements.ach`}
                            label="Air Change Rate"
                            placeholder={0.0}
                            disabled={reqUseId !== 1 || !canBeEditedFromParametric}
                            validate={reqAchValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("ventRequirementsACH")}
                            change={change}
                            setValue={reqUseId === 1 ? 0 : achValue}
                            units={{
                                base: getBaseUnits("ventRequirementsACH", modelUnits),
                                options: getUnitOptions("airtightness"),
                                selected: "ACH50",
                                unitType: "airtightness",
                                accessor: `${accessor}.requirements.ach_u`,
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.requirements.supply`}
                            label="Supply Flow"
                            placeholder={0.0}
                            disabled={reqUseId !== 2 || !canBeEditedFromParametric}
                            validate={reqSupplyValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("ventRequirementsSupply")}
                            change={change}
                            setValue={reqUseId === 0 ? 50 : reqUseId === 2 ? reqSupplyValue : 0}
                            units={{
                                base: getBaseUnits("ventRequirementsSupply", modelUnits),
                                options: getUnitOptions("airFlow"),
                                selected: reqSupplyUnits,
                                unitType: "airFlow",
                                accessor: `${accessor}.requirements.supply_u`,
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.requirements.exhaust`}
                            label="Exhaust Flow"
                            placeholder={0.0}
                            disabled={reqUseId !== 2 || !canBeEditedFromParametric}
                            validate={reqExhaustValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("ventRequirementsExhaust")}
                            change={change}
                            setValue={reqUseId === 0 ? 50 : reqUseId === 2 ? reqExhaustValue : 0}
                            units={{
                                base: getBaseUnits("ventRequirementsExhaust", modelUnits),
                                options: getUnitOptions("airFlow"),
                                selected: reqExhaustUnits,
                                unitType: "airFlow",
                                accessor: `${accessor}.requirements.exhaust_u`,
                            }}
                        />
                    </InputRow>
                    <h4 style={{ marginBottom: "1.25rem", marginTop: "1.75rem" }}>Room Inputs</h4>
                    <InputRow gridTemplate="1fr 1fr 1fr 1fr 1fr" style={{ alignItems: "flex-end" }}>
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.rooms.living`}
                            label="Kitchen, Living, Dining Rooms"
                            placeholder={0.0}
                            disabled={false || !canBeEditedFromParametric}
                            validate={roomsLivingValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("numLivingRooms")}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.rooms.bedrooms`}
                            label="Bedrooms"
                            placeholder={0.0}
                            disabled={false || !canBeEditedFromParametric}
                            validate={roomsBedValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("numBedrooms")}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.rooms.bathrooms`}
                            label="Bathrooms"
                            placeholder={0.0}
                            disabled={false || !canBeEditedFromParametric}
                            validate={roomsBathValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("numBathrooms")}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.rooms.utility`}
                            label="Utility Rooms"
                            placeholder={0.0}
                            disabled={false || !canBeEditedFromParametric}
                            validate={roomsUtilityValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("numUtility")}
                        />
                        <Field
                            component={Input}
                            type="number"
                            name={`${accessor}.rooms.otherHabitable`}
                            label="Other Habitable Rooms"
                            placeholder={0.0}
                            disabled={false || !canBeEditedFromParametric}
                            validate={roomsOtherValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("numOtherRooms")}
                        />
                    </InputRow>
                    <InputRow gridTemplate="38.64% 38.64% 18.19%" style={{ alignItems: "flex-end" }}>
                        <Field
                            component={Select}
                            name={`${accessor}.rooms.ventilationRate`}
                            options={getOptions({ fieldKey: "ventRoomsVentilationRate" })}
                            label="Ventilation Rate for Other Basement Areas"
                            placeholder="Choose Ventilation Rate"
                            validate={roomsVentRateValidation}
                            disabled={!canBeEditedFromParametric}
                        />
                        <Field
                            component={Select}
                            name={`${accessor}.rooms.depressurizationLimit`}
                            options={[
                                ...getOptions({ fieldKey: "ventDepressurizationLimit" }),
                                {
                                    label: "User specified",
                                    value: {
                                        id: 3,
                                        value: depressValue,
                                    },
                                },
                            ]}
                            label="Vented Combustion Appliances Depressurization Limit"
                            placeholder="Choose Depressurization Limit"
                            validate={roomsDepressLimitValidation}
                            disabled={!canBeEditedFromParametric}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.rooms.depressurizationLimit.value`}
                            label="Value"
                            placeholder={0.0}
                            disabled={depressId !== 3 || !canBeEditedFromParametric}
                            validate={roomsDepressLimitValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("ventDepressurizationLimit")}
                            change={change}
                            setValue={depressValue}
                            units={{
                                base: getBaseUnits("ventDepressurizationLimit", modelUnits),
                                options: getUnitOptions("pressure"),
                                selected: depressUnits,
                                unitType: "pressure",
                                accessor: `${accessor}.rooms.depressurizationLimit_u`,
                            }}
                        />
                    </InputRow>
                </>
            )}
            <h4 style={{ marginBottom: "1.25rem", marginTop: "1.75rem" }}>
                Whole-house Ventilation System Description
            </h4>
            <InputRow gridTemplate={isUpgrade ? "2fr 2fr 1fr" : "1fr 1fr 1fr 1fr"}>
                <Field
                    component={Select}
                    name={`${accessor}.wholeHouse.airDistributionType`}
                    options={getOptions({ fieldKey: "ventAirDistributionType" })}
                    label="Air Distribution/Circulation Type"
                    placeholder="Choose Air Distribution Type"
                    validate={airDistTypeValidation}
                    info={
                        "Use this drop down to specify the ductwork configuration. In the case of a continuously operating fan depending on passive outlets for circulation, select Dedicated low volume ductwork."
                    }
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={Select}
                    name={`${accessor}.wholeHouse.airDistributionFanPower`}
                    options={[
                        {
                            label: "User specified",
                            value: {
                                id: 0,
                                value: airDistFanPowerValue,
                            },
                        },
                        ...getOptions({ fieldKey: "ventAirDistributionFanPower" }),
                    ]}
                    label="Air Distribution Fan Power"
                    placeholder="Choose Input Type"
                    validate={airDistFanPowerValidation}
                    disabled={airDistTypeId !== 2 || !canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.wholeHouse.airDistributionFanPower.value`}
                    label="Value"
                    placeholder={0.0}
                    disabled={airDistTypeId !== 2 || airDistFanPowerId !== 0 || !canBeEditedFromParametric}
                    validate={airDistFanPowerValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces("ventAirDistributionFanPower")}
                    change={change}
                    setValue={airDistFanPowerValue}
                    units={{
                        base: getBaseUnits("ventAirDistributionFanPower", modelUnits),
                        options: getUnitOptions("power"),
                        selected: airDistFanPowerUnits,
                        unitType: "power",
                        accessor: `${accessor}.wholeHouse.airDistributionFanPower_u`,
                    }}
                />
            </InputRow>
            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.wholeHouse.operationSchedule`}
                    options={[
                        ...getOptions({ fieldKey: "ventOperationSchedule" }),
                        {
                            label: "User specified",
                            value: {
                                id: 5,
                                value: operationScheduleValue,
                            },
                        },
                    ]}
                    label="Operation Schedule"
                    placeholder="Choose Operation Schedule"
                    validate={operationScheduleValidation}
                    info={"Leave at the default value (480 min/day)."}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={InputWithUnits}
                    type="number"
                    name={`${accessor}.wholeHouse.operationSchedule.value`}
                    label="Daily Operation"
                    placeholder={0.0}
                    disabled={operationScheduleId !== 5 || !canBeEditedFromParametric}
                    validate={operationScheduleValidation}
                    setTouched={true}
                    decimals={getDecimalPlaces("ventOperationSchedule")}
                    change={change}
                    setValue={operationScheduleValue}
                    units={{
                        base: getBaseUnits("ventOperationSchedule", modelUnits),
                        options: getUnitOptions("time"),
                        selected: operationScheduleUnits,
                        unitType: "time",
                        accessor: `${accessor}.wholeHouse.operationSchedule_u`,
                    }}
                />
                {operationScheduleId === 6 && (
                    <>
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.wholeHouse.tempControlLower`}
                            label="Lower Temperature Limit"
                            placeholder={0.0}
                            disabled={operationScheduleId !== 6 || !canBeEditedFromParametric}
                            validate={tempCtrlLowerValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("ventTempControlledLower")}
                            change={change}
                            units={{
                                base: getBaseUnits("ventTempControlledLower", modelUnits),
                                options: getUnitOptions("temperature"),
                                selected: tempCtrlLowerUnits,
                                unitType: "temperature",
                                accessor: `${accessor}.wholeHouse.tempControlLower_u`,
                            }}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.wholeHouse.tempControlUpper`}
                            label="Upper Temperature Limit"
                            placeholder={0.0}
                            disabled={operationScheduleId !== 6 || !canBeEditedFromParametric}
                            validate={tempCtrlUpperValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("ventTempControlledUpper")}
                            change={change}
                            units={{
                                base: getBaseUnits("ventTempControlledUpper", modelUnits),
                                options: getUnitOptions("temperature"),
                                selected: tempCtrlUpperUnits,
                                unitType: "temperature",
                                accessor: `${accessor}.wholeHouse.tempControlUpper_u`,
                            }}
                        />
                    </>
                )}
            </InputRow>
            <Accordion
                className={sharedClasses.accordion}
                heading={
                    <div className={sharedClasses.accordionIconHeading}>
                        Ductwork Specifications <NonH2kParam />
                    </div>
                }
                large
            >
                <div>
                    <h5 className={classes.f280Text}>
                        Use these fields for CSA F280-12 calculations to indicate if ducts serving the building pass
                        through any of the unconditioned spaces listed below, and indicate duct insulation values where
                        appropriate.
                    </h5>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={Checkbox}
                            name={`modelData.ventilation.f280DuctLoss.atticCrawlspace.enabled`}
                            label="Attic or open crawl space"
                            setValue={true}
                            large
                            className={classes.checkbox}
                            type="checkbox"
                            disabled={!canBeEditedFromParametric}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={"modelData.ventilation.f280DuctLoss.atticCrawlspace.areaPerc"}
                            label="Approximate floor area served"
                            placeholder={0.0}
                            setTouched={true}
                            decimals={getDecimalPlaces("fanPumpLowPower")}
                            change={change}
                            units={{
                                base: getBaseUnits("generalFuelTaxRebate", modelUnits),
                                options: ["%"],
                                selected: "%",
                                unitType: "fraction",
                                accessor: "modelData.ventilation.f280DuctLoss.atticCrawlspace.areaPerc_u",
                            }}
                            disabled={!canBeEditedFromParametric}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`modelData.ventilation.f280DuctLoss.atticCrawlspace.effRVal`}
                            label="Duct insulation"
                            placeholder={0.0}
                            setTouched={true}
                            decimals={getDecimalPlaces("doorType")}
                            change={change}
                            units={{
                                base: getBaseUnits("doorType", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: atticDuctInsUnits,
                                unitType: "thermalResistance",
                                accessor: `modelData.ventilation.f280DuctLoss.atticCrawlspace.effRVal_u`,
                            }}
                            disabled={!canBeEditedFromParametric}
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={Checkbox}
                            name={`modelData.ventilation.f280DuctLoss.unconditionedCrawlspace.enabled`}
                            label="Enclosed, unconditioned crawl space"
                            setValue={true}
                            large
                            className={classes.checkbox}
                            type="checkbox"
                            disabled={!canBeEditedFromParametric}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={"modelData.ventilation.f280DuctLoss.unconditionedCrawlspace.areaPerc"}
                            label="Approximate floor area served"
                            placeholder={0.0}
                            setTouched={true}
                            decimals={getDecimalPlaces("fanPumpLowPower")}
                            change={change}
                            units={{
                                base: getBaseUnits("generalFuelTaxRebate", modelUnits),
                                options: ["%"],
                                selected: "%",
                                unitType: "fraction",
                                accessor: "modelData.ventilation.f280DuctLoss.unconditionedCrawlspace.areaPerc_u",
                            }}
                            disabled={!canBeEditedFromParametric}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`modelData.ventilation.f280DuctLoss.unconditionedCrawlspace.effRVal`}
                            label="Duct insulation"
                            placeholder={0.0}
                            setTouched={true}
                            decimals={getDecimalPlaces("doorType")}
                            change={change}
                            units={{
                                base: getBaseUnits("doorType", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: unconditionedCrawlspaceDuctInsUnits,
                                unitType: "thermalResistance",
                                accessor: `modelData.ventilation.f280DuctLoss.unconditionedCrawlspace.effRVal_u`,
                            }}
                            disabled={!canBeEditedFromParametric}
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={Checkbox}
                            name={`modelData.ventilation.f280DuctLoss.unconditionedBasement.enabled`}
                            label="Unconditioned basement"
                            setValue={true}
                            large
                            className={classes.checkbox}
                            type="checkbox"
                            disabled={!canBeEditedFromParametric}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={"modelData.ventilation.f280DuctLoss.unconditionedBasement.areaPerc"}
                            label="Approximate floor area served"
                            placeholder={0.0}
                            setTouched={true}
                            decimals={getDecimalPlaces("fanPumpLowPower")}
                            change={change}
                            units={{
                                base: getBaseUnits("generalFuelTaxRebate", modelUnits),
                                options: ["%"],
                                selected: "%",
                                unitType: "fraction",
                                accessor: "modelData.ventilation.f280DuctLoss.unconditionedBasement.areaPerc_u",
                            }}
                            disabled={!canBeEditedFromParametric}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`modelData.ventilation.f280DuctLoss.unconditionedBasement.effRVal`}
                            label="Duct insulation"
                            placeholder={0.0}
                            setTouched={true}
                            decimals={getDecimalPlaces("doorType")}
                            change={change}
                            units={{
                                base: getBaseUnits("doorType", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: unconditionedBasementDuctInsUnits,
                                unitType: "thermalResistance",
                                accessor: `modelData.ventilation.f280DuctLoss.unconditionedBasement.effRVal_u`,
                            }}
                            disabled={!canBeEditedFromParametric}
                        />
                    </InputRow>
                    <InputRow gridTemplate="1fr 1fr 1fr">
                        <Field
                            component={Checkbox}
                            name={`modelData.ventilation.f280DuctLoss.slabOnGradePerimeterIns.enabled`}
                            label="Slab-on-grade with perimeter insulation"
                            setValue={true}
                            large
                            className={classes.checkbox}
                            type="checkbox"
                            disabled={!canBeEditedFromParametric}
                        />
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={"modelData.ventilation.f280DuctLoss.slabOnGradePerimeterIns.areaPerc"}
                            label="Approximate floor area served"
                            placeholder={0.0}
                            setTouched={true}
                            decimals={getDecimalPlaces("fanPumpLowPower")}
                            change={change}
                            units={{
                                base: getBaseUnits("generalFuelTaxRebate", modelUnits),
                                options: ["%"],
                                selected: "%",
                                unitType: "fraction",
                                accessor: "modelData.ventilation.f280DuctLoss.slabOnGradePerimeterIns.areaPerc_u",
                            }}
                            disabled={!canBeEditedFromParametric}
                        />
                    </InputRow>
                </div>
            </Accordion>
            <div className={classes.title} style={{ marginTop: "1.5rem" }}>
                <div>
                    <h4>Whole-house Systems</h4>
                    <p>
                        <strong>Total Supply</strong>: {getAirFlowLabel(totalWholeHouseSupply)}{" "}
                        <span className={classes.divider}></span> <strong>Total Exhaust</strong>:{" "}
                        {getAirFlowLabel(totalWholeHouseExhaust)}
                    </p>
                </div>
                <AddSystem
                    accessor={accessor}
                    hrvExists={!isEmpty(wholeHouseHrv) || !isEmpty(supplementalHrv)}
                    isSupplemental={false}
                    dryerExists={true}
                    change={change}
                    canBeEditedFromParametric={canBeEditedFromParametric}
                />
            </div>
            {isEmpty(wholeHouseHrv) && isEmpty(wholeHouseVentilators) && (
                <EmptyState title="No whole-house ventilation systems to display" style={{}} />
            )}
            {!isEmpty(wholeHouseHrv) && (
                <HRV
                    accessor={`${accessor}.wholeHouseSystems.hrv`}
                    change={change}
                    isSupplemental={false}
                    formName={formName}
                    canBeEditedFromParametric={canBeEditedFromParametric}
                />
            )}
            {!isEmpty(wholeHouseVentilators) &&
                Object.keys(wholeHouseVentilators)
                    .sort((a, b) => {
                        if (a < b) {
                            return -1;
                        }
                        if (a > b) {
                            return 1;
                        }
                        return 0;
                    })
                    .map((id) => (
                        <Ventilator
                            accessor={`${accessor}.wholeHouseSystems.ventilators.${id}`}
                            change={change}
                            key={id}
                            isSupplemental={false}
                            formName={formName}
                        />
                    ))}
            <div>
                <InputRow style={{ marginTop: "1rem" }} gridTemplate="1fr 1fr">
                    <Field
                        component={Checkbox}
                        name={`${accessor}.pvcFromWholeHouseSystems`}
                        label="Use whole-house ventilation rate as PVC for CSA F280-12"
                        large
                        className={classes.bottomCheckBox}
                        type="checkbox"
                        nonH2kParam="f280Default"
                        disabled={!canBeEditedFromParametric}
                    />
                </InputRow>
                <div className={classes.f280MechVentText}>
                    <F280VentilationText
                        hasWholeHouseSystem={hasWholeHouseSystem}
                        pvcFromWholeHouseSystems={pvcFromWholeHouseSystems}
                        wholeHouseRate={wholeHouseRate}
                        bedroomMinVentRate={bedroomMinVentRate}
                    />
                </div>
            </div>
            <div className={classes.title} style={{ marginTop: "1.25rem" }}>
                <div>
                    <h4>Supplemental Systems</h4>
                    <p>
                        <strong>Total Supply</strong>: {getAirFlowLabel(totalSupplementalSupply)}{" "}
                        <span className={classes.divider}></span> <strong>Total Exhaust</strong>:{" "}
                        {getAirFlowLabel(totalSupplementalExhaust)}
                    </p>
                </div>
                <AddSystem
                    accessor={accessor}
                    hrvExists={!isEmpty(wholeHouseHrv) || !isEmpty(supplementalHrv)}
                    isSupplemental={true}
                    dryerExists={!isEmpty(supplementalDryer)}
                    change={change}
                    formName={formName}
                    canBeEditedFromParametric={canBeEditedFromParametric}
                />
            </div>
            {!isEmpty(supplementalDryer) && (
                <Dryer
                    accessor={`${accessor}.supplementalSystems.dryer`}
                    change={change}
                    isSupplemental={true}
                    formName={formName}
                />
            )}
            {!isEmpty(supplementalHrv) && (
                <HRV
                    accessor={`${accessor}.supplementalSystems.hrv`}
                    change={change}
                    isSupplemental={true}
                    formName={formName}
                />
            )}
            {!isEmpty(supplementalVentilators) &&
                Object.keys(supplementalVentilators)
                    .sort((a, b) => {
                        if (a < b) {
                            return -1;
                        }
                        if (a > b) {
                            return 1;
                        }
                        return 0;
                    })
                    .map((id) => (
                        <Ventilator
                            accessor={`${accessor}.supplementalSystems.ventilators.${id}`}
                            change={change}
                            key={id}
                            isSupplemental={true}
                            formName={formName}
                        />
                    ))}
            {isEmpty(supplementalDryer) && isEmpty(supplementalHrv) && isEmpty(supplementalVentilators) && (
                <EmptyState title="No supplemental ventilation systems to display" style={{}} />
            )}
        </div>
    );
};
