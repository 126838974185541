import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import isEmpty from "lodash/isEmpty";
import HotWater from "./";

const mapStateToProps = (
    { form, model: { contentOpen = false } = {} },
    {
        accessor,
        formName = "model",
        associatedHeatingSystem = "",
        canBeEditedFromParametric,
        isParametricAnalysis = false,
        upgradeType,
    }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const isPrimaryPresent = Object.keys(selector({ form }, `${accessor}.primarySystem`) || {}).length > 3;
    const isSecondaryPresent = Object.keys(selector({ form }, `${accessor}.secondarySystem`) || {}).length > 3;

    const { id: buildingTypeId } = modelSelector({ form }, `modelData.specifications.buildingType`) || {};
    const isMURB = buildingTypeId === 2; //Whole building MURB

    const isMURBEmpty = isEmpty(selector({ form }, `${accessor}.murb`)) || {};

    const activeSystem = selector({ form }, `${accessor}.activeSystem`) || "primary";

    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    let heatingSysType = Object.keys(modelSelector({ form }, `modelData.heatingCooling.heating.system`) || {})[0];

    if (formName === "upgradeDrawer") {
        heatingSysType = associatedHeatingSystem || heatingSysType;
    }

    const basementComponents = modelSelector({ form }, `modelData.components.basement`) || {};

    let noBasementComponents = false;
    if (isEmpty(basementComponents)) {
        noBasementComponents = true;
    } else {
        noBasementComponents = Object.keys(basementComponents)
            .reduce((acc, key) => [...acc, isEmpty(basementComponents[key])], [])
            .every((el) => el);
    }

    const { minor: h2kMinor } = selector({ form }, "modelData.h2kVersion") || {};

    return {
        accessor,
        isPrimaryPresent,
        isSecondaryPresent,
        isMURB,
        isMURBEmpty,
        activeSystem,
        heatingSysType,
        noBasementComponents,
        modelUnits,
        contentOpen,
        h2kMinor,
        canBeEditedFromParametric,
        isPrimaryDhwParametric: isParametricAnalysis && upgradeType === "primaryHotWater",
        isDwhrParametric: isParametricAnalysis && upgradeType === "dwhr",
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HotWater);
