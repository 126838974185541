import React, { useState } from "react";
import classes from "./style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import classNames from "classnames";
import { Field } from "redux-form";
import { getValidation } from "utils/fieldValidation";
import { getDhwTemplate, getEStarManufacturerOptions, getEStarModelOptions, getEStarOptions } from "utils/mechanicals";
import InputRow from "components/Input/Row";
import Input from "components/Input";
import Select from "components/Input/Select";
import Button from "components/Button";
import Accordion from "components/Accordion";
import DhwSystem from "./DhwSystem/container";
import AddBox from "components/AddBox";
import Drawer from "components/Drawer";

const dwhrMURBNumLowEffValidation = getValidation("dwhrMURBNumLowEff");
const dwhrMURBNumHighEffValidation = getValidation("dwhrMURBNumHighEff");
const dhwMURBEStarInstCondensingValidation = getValidation("dhwMURBEStarInstCondensing");
const dhwMURBEStarInstValidation = getValidation("dhwMURBEStarInst");
const dhwMURBCondensingValidation = getValidation("dhwMURBCondensing");
const dhwMURBInstValidation = getValidation("dhwMURBInst");
const dhwMURBHeatPumpValidation = getValidation("dhwMURBHeatPump");
const eStarManufacturerValidation = getValidation("dhwEStarManufacturer");
const eStarModelValidation = getValidation("dhwEStarModel");

export default ({
    accessor,
    isPrimaryPresent,
    isSecondaryPresent,
    isMURB,
    // eStarManufacturerValue,
    // eStarModelId,
    favId,
    activeSystem,
    heatingSysType,
    noBasementComponents,
    change,
    formName,
    isUpgrade = false,
    contentOpen,
    h2kMinor,
    canBeEditedFromParametric = true,
    isDwhrParametric = false,
    isPrimaryDhwParametric = false,
}) => {
    const [open, toggleOpen] = useState(false);
    const [eStarManufacturerValue, setEStarManufacturerValue] = useState("");
    const [eStarModelId, setEStarModelId] = useState("");

    const createButtonLabel = () => {
        if (favId) {
            return "Favourite";
        } else if (eStarManufacturerValue && eStarModelId) {
            return "Energy Star";
        } else {
            return "Standard";
        }
    };

    const createHeaderLabel = () => {
        if (activeSystem === "secondary") {
            return "Secondary";
        }
        return "Primary";
    };

    const handleDrawerOpen = (sysType) => {
        change(`${accessor}.activeSystem`, sysType);
        toggleOpen(true);
    };

    const handleCreateDhw = ({ sysType, manufacturer, modelId, favId, noBasementComponents }) => {
        if (favId) {
            //Favourite System
            return;
        } else if (manufacturer && modelId) {
            //EStar
            const { primarySystem: eStarTemplate = {} } = getEStarOptions("dhw")[modelId] || {};
            const { energyFactor: { id, value, inputCapacity = 0 } = {} } = eStarTemplate;
            change(`${accessor}.${sysType}System`, {
                ...eStarTemplate,
                tankLocation: {
                    id: noBasementComponents ? 0 : 1,
                },
                energyFactor: { id, value },
            });
            if (inputCapacity > 0) {
                change(`${accessor}.${sysType}System.standby.inputCapacity`, inputCapacity);
            }

            return;
        } else if ((sysType === "primary" && !isPrimaryPresent) || (sysType === "secondary" && !isSecondaryPresent)) {
            change(`${accessor}.${sysType}System`, {
                ...getDhwTemplate("dhw"),
                tankLocation: {
                    id: noBasementComponents ? 0 : 1,
                },
            });
            return;
        }
    };

    const handleDeleteDhw = (sysType) => {
        if ((sysType === "primary" && isPrimaryPresent) || (sysType === "secondary" && isSecondaryPresent)) {
            change(`${accessor}.${sysType}System`, {});

            if (sysType === "secondary") {
                change(`${accessor}.primarySystem.fractionOfTank`, "NaN");
            }
        }
    };

    const isCombo = ["combo", "p9Combo"].includes(heatingSysType);

    return (
        <>
            <Drawer
                open={open}
                close={() => toggleOpen(false)}
                preTitle="New System"
                title={`Create ${createHeaderLabel()} Domestic Hot Water System`}
                subtitle="Leave the selections below blank to define a system from scratch."
                topBarColor="#0eb0a9"
            >
                <div>
                    {/* <InputRow>
                        <Select
                            name="favouriteDhwSystem"
                            options={[
                                {
                                    value:null,
                                    label:"None"
                                }
                            ]}
                            label="Favourite DHW System Type"
                            placeholder="Coming soon!"
                            disabled={true}
                        />
                    </InputRow> */}
                    <InputRow>
                        <Select
                            name="eStarManufacturer"
                            options={[
                                {
                                    value: false,
                                    label: "None",
                                },
                                ...getEStarManufacturerOptions("dhw"),
                            ]}
                            label="Energy Star Manufacturer"
                            placeholder="Choose Manufacturer"
                            validate={eStarManufacturerValidation}
                            disabled={false || !canBeEditedFromParametric}
                            info={"Select a manufacturer of an Energy Star heating system"}
                            search={true}
                            input={{
                                value: eStarManufacturerValue,
                                onChange: (value) => setEStarManufacturerValue(value),
                            }}
                        />
                    </InputRow>
                    <InputRow>
                        <Select
                            name="eStarModelId"
                            options={[
                                {
                                    value: false,
                                    label: "None",
                                },
                                ...getEStarModelOptions({ systemType: "dhw", manufacturer: eStarManufacturerValue }),
                            ]}
                            label="Energy Star Model"
                            placeholder="Choose Model"
                            validate={eStarModelValidation}
                            disabled={!eStarManufacturerValue || !canBeEditedFromParametric}
                            info={"Select an Energy Star certified model"}
                            search={true}
                            input={{
                                value: eStarModelId,
                                onChange: (value) => setEStarModelId(value),
                            }}
                        />
                    </InputRow>
                    <div className={classes.buttons}>
                        <Button
                            large
                            type="hollow"
                            onClick={() => {
                                toggleOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleCreateDhw({
                                    sysType: activeSystem,
                                    manufacturer: eStarManufacturerValue,
                                    modelId: eStarModelId,
                                    noBasementComponents: noBasementComponents,
                                });
                                toggleOpen(false);
                            }}
                            disabled={false || !canBeEditedFromParametric}
                        >
                            {`Create ${createButtonLabel()} System`}
                        </Button>
                    </div>
                </div>
            </Drawer>
            {isCombo && !isUpgrade && (
                <div className={sharedClasses.section}>
                    <div className={classes.heading}>
                        <div className={classes.title}>
                            <h4>
                                Hot water is controlled by a combo heating/DHW system, and can be edited in the Heating
                                System tab
                            </h4>
                        </div>
                    </div>
                </div>
            )}
            {!isCombo && (
                <>
                    {isPrimaryPresent ? (
                        <DhwSystem
                            accessor={`${accessor}.primarySystem`}
                            change={change}
                            isPrimary={true}
                            handleDelete={() => handleDeleteDhw("primary")}
                            hasSecondarySystem={isSecondaryPresent}
                            isCombo={isCombo}
                            noBasementComponents={noBasementComponents}
                            formName={formName}
                            isUpgrade={isUpgrade}
                            isDwhrParametric={isDwhrParametric}
                            isPrimaryDhwParametric={isPrimaryDhwParametric}
                        />
                    ) : (
                        <div className={!isUpgrade && classes.addBoxWrapper}>
                            <AddBox onClick={() => handleDrawerOpen("primary")} title="Add Primary System" icon="" />
                        </div>
                    )}
                    {isSecondaryPresent ? (
                        <DhwSystem
                            accessor={`${accessor}.secondarySystem`}
                            change={change}
                            isPrimary={false}
                            handleDelete={() => handleDeleteDhw("secondary")}
                            noBasementComponents={noBasementComponents}
                            formName={formName}
                            isUpgrade={isUpgrade}
                        />
                    ) : !isDwhrParametric && !isPrimaryDhwParametric ? (
                        <div className={classes.addBoxWrapper} style={{ padding: isUpgrade ? 0 : "0 1.875rem" }}>
                            <AddBox
                                onClick={() => handleDrawerOpen("secondary")}
                                title="Add Secondary System"
                                icon=""
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
            {isMURB && (
                <div
                    // className={sharedClasses.section}
                    className={classNames(sharedClasses.section, {
                        [classes.upgradeMurbSpecsContainer]: isUpgrade,
                    })}
                >
                    <Accordion
                        className={sharedClasses.accordion}
                        heading={<span className={sharedClasses.heading}>MURB Specifications</span>}
                        large
                        disabled={!isMURB}
                        forceOpen={contentOpen && isMURB}
                    >
                        <h4>Number of Hot Water Systems in Building</h4>
                        <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                            <Field
                                component={Input}
                                name={`${accessor}.murb.murbNumHotWaterSystems.eStarInstantaneousCondensing`}
                                type="number"
                                label="Energy Star Instantaneous (Cond.)"
                                placeholder={0.0}
                                validate={dhwMURBEStarInstCondensingValidation}
                                disabled={!canBeEditedFromParametric}
                            />
                            <Field
                                component={Input}
                                name={`${accessor}.murb.murbNumHotWaterSystems.eStarInstantaneous`}
                                type="number"
                                label="Energy Star Instantaneous"
                                placeholder={0.0}
                                validate={dhwMURBEStarInstValidation}
                                disabled={!canBeEditedFromParametric}
                            />
                            <Field
                                component={Input}
                                name={`${accessor}.murb.murbNumHotWaterSystems.condensing`}
                                type="number"
                                label="Condensing"
                                placeholder={0.0}
                                validate={dhwMURBCondensingValidation}
                                disabled={!canBeEditedFromParametric}
                            />
                            <Field
                                component={Input}
                                name={`${accessor}.murb.murbNumHotWaterSystems.instantaneous`}
                                type="number"
                                label="Instantaneous"
                                placeholder={0.0}
                                validate={dhwMURBInstValidation}
                                disabled={!canBeEditedFromParametric}
                            />
                        </InputRow>
                        {h2kMinor >= 11 && (
                            <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                                <Field
                                    component={Input}
                                    name={`${accessor}.murb.murbNumHotWaterSystems.heatPumpWaterHeater`}
                                    type="number"
                                    label="Heat Pump Water Heaters"
                                    placeholder={0.0}
                                    validate={dhwMURBInstValidation}
                                    disabled={!canBeEditedFromParametric}
                                />
                            </InputRow>
                        )}
                        <h4>Number of Drain Water Heat Recovery Systems in Building</h4>
                        <InputRow gridTemplate="1fr 1fr 1fr 1fr">
                            <Field
                                component={Input}
                                name={`${accessor}.murb.murbNumDwhrSystems.lowEfficiency`}
                                type="number"
                                label="Low Efficiency (<42%)"
                                placeholder={0.0}
                                validate={dwhrMURBNumLowEffValidation}
                                disabled={!canBeEditedFromParametric}
                            />
                            <Field
                                component={Input}
                                name={`${accessor}.murb.murbNumDwhrSystems.highEfficiency`}
                                type="number"
                                label="High Efficiency (>42%)"
                                placeholder={0.0}
                                validate={dwhrMURBNumHighEffValidation}
                                disabled={!canBeEditedFromParametric}
                            />
                        </InputRow>
                    </Accordion>
                </div>
            )}
        </>
    );
};
