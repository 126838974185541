import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import isEmpty from "lodash/isEmpty";
import Ventilation from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model", canBeEditedFromParametric }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const { id: reqUseId } = selector({ form }, `${accessor}.requirements.use`) || {};
    const achValue = selector({ form }, `${accessor}.requirements.ach`);
    const reqSupplyValue = selector({ form }, `${accessor}.requirements.supply`);
    const reqExhaustValue = selector({ form }, `${accessor}.requirements.exhaust`);
    const { id: depressId, value: depressValue } = selector({ form }, `${accessor}.rooms.depressurizationLimit`) || {};
    const { id: airDistTypeId } = selector({ form }, `${accessor}.wholeHouse.airDistributionType`) || {};
    const { id: airDistFanPowerId, value: airDistFanPowerValue } =
        selector({ form }, `${accessor}.wholeHouse.airDistributionFanPower`) || {};
    const { id: operationScheduleId, value: operationScheduleValue } =
        selector({ form }, `${accessor}.wholeHouse.operationSchedule`) || {};

    const reqSupplyUnits = selector({ form }, `${accessor}.requirements.supply_u`);
    const reqExhaustUnits = selector({ form }, `${accessor}.requirements.exhaust_u`);
    const depressUnits = selector({ form }, `${accessor}.rooms.depressurizationLimit_u`);
    const airDistFanPowerUnits = selector({ form }, `${accessor}.wholeHouse.airDistributionFanPower_u`);
    const operationScheduleUnits = selector({ form }, `${accessor}.wholeHouse.operationSchedule_u`);
    const tempCtrlLowerUnits = selector({ form }, `${accessor}.wholeHouse.tempControlLower_u`);
    const tempCtrlUpperUnits = selector({ form }, `${accessor}.wholeHouse.tempControlUpper_u`);

    const wholeHouseHrv = selector({ form }, `${accessor}.wholeHouseSystems.hrv`) || {};
    const wholeHouseVentilators = selector({ form }, `${accessor}.wholeHouseSystems.ventilators`) || {};
    const supplementalHrv = selector({ form }, `${accessor}.supplementalSystems.hrv`) || {};
    const supplementalVentilators = selector({ form }, `${accessor}.supplementalSystems.ventilators`) || {};
    const supplementalDryer = selector({ form }, `${accessor}.supplementalSystems.dryer`) || {};

    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};

    let totalWholeHouseSupply = 0;
    totalWholeHouseSupply += wholeHouseHrv.supplyFlowrate || 0;
    totalWholeHouseSupply += Object.values(wholeHouseVentilators).reduce(
        (acc, curr) => acc + (curr.supplyFlowrate || 0),
        0
    );
    let totalSupplementalSupply = 0;
    totalSupplementalSupply += supplementalHrv.supplyFlowrate || 0;
    totalSupplementalSupply += supplementalDryer.supplyFlowrate || 0;
    totalSupplementalSupply += Object.values(supplementalVentilators).reduce(
        (acc, curr) => acc + (curr.supplyFlowrate || 0),
        0
    );

    let totalWholeHouseExhaust = 0;
    totalWholeHouseExhaust += wholeHouseHrv.exhaustFlowrate || 0;
    totalWholeHouseExhaust += Object.values(wholeHouseVentilators).reduce(
        (acc, curr) => acc + (curr.exhaustFlowrate || 0),
        0
    );
    let totalSupplementalExhaust = 0;
    totalSupplementalExhaust += supplementalHrv.exhaustFlowrate || 0;
    totalSupplementalExhaust += supplementalDryer.exhaustFlowrate || 0;
    totalSupplementalExhaust += Object.values(supplementalVentilators).reduce(
        (acc, curr) => acc + (curr.exhaustFlowrate || 0),
        0
    );

    const bedroomsEntered = selector({ form }, "modelData.ventilation.rooms.bedrooms");
    const buildingCodeEntered = selector({ form }, "modelData.specifications.buildingCode.type");

    const pvcFromWholeHouseSystems = selector({ form }, `${accessor}.pvcFromWholeHouseSystems`) || false;
    const hasWholeHouseSystem = totalWholeHouseExhaust + totalWholeHouseSupply > 0;

    const bedroomMinVentRate = selector({ form }, `modelData.ventilation.requirements.minBedroomVentilation`);

    const atticDuctInsUnits = selector({ form }, `modelData.ventilation.f280DuctLoss.atticCrawlspace.effRVal_u`);
    const unconditionedCrawlspaceDuctInsUnits = selector(
        { form },
        `modelData.ventilation.f280DuctLoss.unconditionedCrawlspace.effRVal_u`
    );
    const unconditionedBasementDuctInsUnits = selector(
        { form },
        `modelData.ventilation.f280DuctLoss.unconditionedBasement.effRVal_u`
    );

    return {
        accessor,
        reqUseId,
        achValue,
        reqSupplyValue,
        reqExhaustValue,
        reqSupplyUnits,
        reqExhaustUnits,
        depressId,
        depressValue,
        depressUnits,
        airDistTypeId,
        airDistFanPowerId,
        airDistFanPowerValue,
        airDistFanPowerUnits,
        operationScheduleId,
        operationScheduleValue,
        operationScheduleUnits,
        tempCtrlLowerUnits,
        tempCtrlUpperUnits,
        wholeHouseHrv,
        wholeHouseVentilators,
        supplementalHrv,
        supplementalVentilators,
        supplementalDryer,
        totalWholeHouseSupply,
        totalSupplementalSupply,
        totalWholeHouseExhaust,
        totalSupplementalExhaust,
        bedroomsEntered,
        buildingCodeEntered,
        modelUnits,
        canBeEditedFromParametric,
        pvcFromWholeHouseSystems,
        hasWholeHouseSystem,
        bedroomMinVentRate,
        wholeHouseRate: Math.max(totalWholeHouseExhaust, totalWholeHouseSupply),
        atticDuctInsUnits,
        unconditionedCrawlspaceDuctInsUnits,
        unconditionedBasementDuctInsUnits,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Ventilation);
