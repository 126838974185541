import React, { useEffect } from "react";
import classes from "../style.module.scss";
import Dialog from "components/Dialog";
import { CloseDialog } from "components/Dialog";
import Button from "components/Button";

const DeleteDialog = ({ 
    fileName,
    openDialog,
    onClick,
    onClose,
    loading,
    isFolder = false
}) => (
    <Dialog open={!!openDialog}>
        <CloseDialog closeActions={() => onClose()} />
        <div className={classes.dialog}>
            <h3>
                Are you sure you want to delete <strong>{fileName}</strong> permanently?
            </h3>
            {isFolder && (
                <h4>
                    Deleting a folder will move all of its nested models and sub-folders to the root level Model
                    Directory.
                </h4>
            )}

            <div className={classes.footer}>
                <Button type="hollow" onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button onClick={() => onClick()} type="red" disabled={loading}>
                    {loading ? "Deleting..." : "Delete"}
                </Button>
            </div>
        </div>
    </Dialog>
);

export default DeleteDialog;

export const DeleteMultipleFilesDialog = ({ 
    numFilesToDelete, 
    onClick, 
    onClose,
    loading,
}) => {
    return (
        <Dialog open={numFilesToDelete > 1}>
            <CloseDialog closeActions={() => onClose()} />
            <div className={classes.dialog}>
                <h3>
                    Are you sure you want to delete <strong>{numFilesToDelete} files</strong>?
                </h3>

                <h4>
                    Deleting a folder will move all of its nested models and sub-folders to the root level
                    Directory.
                </h4>

                <div className={classes.footer}>
                    <Button type="hollow" onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button onClick={() => {onClick(); onClose();}} type="red" disabled={loading}>
                        {loading ? "Deleting..." : "Delete"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
