import React, { useState } from "react";
import classes from "../../style.module.scss";
import classNames from "classnames";
import { fileShareFolderIds } from "utils/fileSharing/index";
import DashboardItem from "..";
import { deleteFolder, renameFolder, moveFolder } from "store/folders/thunk";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { DeleteDialog, MoveDialog, RenameDialog } from "components/Dashboard/Actions";
import { selectUserUid } from "store/users/selectors";
import { selectFolderLoading } from "store/folders/selectors";

import {
    deleteParametricAnalysisFolder,
    moveParametricAnalysisFolder,
    renameParametricAnalysisFolder,
} from "store/parametricAnalysis/thunk";


const Folder = ({
    uid,
    ref,
    folderId,
    dirType, // modelDir, commDir
    disableActions = false,
    sharedWithUser = false,
    // pathname,
    tags = [],
    // setSelectedFolderModel={setSelectedFolderModel}
    // selectedFolderModel={selectedFolderModel}
    isSelected,
    isDragging,
    combineWith,
    isDropInProcess,
    setFolderItemsToDelete,
    multiSelectedItems,
    details, // { totalModels, region, lastEdited }

    rootDir,
    folders,
    deleteFolder,
    renameFolder,
    moveFolder,
    folderLoading,

    deleteParametricAnalysisFolder,
    moveParametricAnalysisFolder,
    renameParametricAnalysisFolder,

    ...props
}) => {
    const { name } = details;
    const history = useHistory();
    const { pathname } = useLocation();
    const path = pathname.split("/").filter((str) => ["", "dashboard", "communities"].includes(str) === false) || [];

    const rootPath = dirType === "commDir" ? '/communities/dashboard' : '/dashboard'; //?

    const isSpecialFolder = fileShareFolderIds.includes(folderId);

    const [startDelete, toggleStartDelete] = useState(false);
    const [isDeleting, toggleDeleting] = useState(false);

    const [startRename, toggleStartRename] = useState(false);
    const [newFolderName, setNewFolderName] = useState(name);

    const [startMove, toggleStartMove] = useState(false);

    return (
        <React.Fragment>
            <DashboardItem
                ref={ref}
                className={classNames({
                    [classes.selectedItem]: isSelected,
                    [classes.itemDragging]: isDragging && isSelected,
                    [classes.isDropInProcess]: isDropInProcess,
                    [classes.itemCombine]: combineWith
                })}
                itemId={folderId}
                sharedWithUser={sharedWithUser}
                itemType={"folder"}
                details={details}
                tags={tags}
                actions={disableActions ? null :
                    [
                        {
                            label: "Rename Folder",
                            onClick: () => {
                                toggleStartRename(true);
                            },
                        },
                        {
                            label: "Move Folder",
                            onClick: () => {
                                toggleStartMove(true);
                            },
                        },
                        ...(isSpecialFolder
                            ? []
                            : [
                                {
                                    label: "Delete Folder",
                                    onClick: () => {
                                        toggleStartDelete(true);
                                    },
                                },
                            ]),
                        {
                            label: `Delete ${multiSelectedItems.length} files`,
                            shouldShow: multiSelectedItems.length > 1,
                            onClick: () => setFolderItemsToDelete(multiSelectedItems),
                        },
                    ]}
                {...props}
            />
            <DeleteDialog
                fileName={name}
                openDialog={startDelete}
                loading={isDeleting}
                onClick={async () => {
                    const index = path.findIndex((el) => el === folderId);

                    if (index !== -1) { //?
                        if (index === 0) history.push(`${rootPath}`);

                        if (index > 0) history.push(`${rootPath}/${path.slice(0, index).join("/").toString()}`);
                    }

                    toggleDeleting(true);
                    if (dirType === "parametricDir") {
                        await deleteParametricAnalysisFolder(folderId, uid);
                    } else {
                        await deleteFolder(folderId, uid, dirType);
                    }
                }}
                onClose={() => toggleStartDelete(false)}
                isFolder
            />
            <RenameDialog
                itemType={"folder"}
                openDialog={startRename}
                oldName={name}
                newName={newFolderName}
                setNewName={(val) => setNewFolderName(val)}
                onClick={async () => {
                    // toggleRenaming(true);
                    if (dirType === "parametricDir") {
                        await renameParametricAnalysisFolder(folderId, uid, newFolderName);
                    } else {
                        await renameFolder(folderId, uid, newFolderName, dirType);
                    }
                    toggleStartRename(false);
                }}
                onClose={() => {
                    toggleStartRename(false);
                    setNewFolderName(name);
                }}
                isLoading={folderLoading}
            />
            <MoveDialog
                itemId={folderId}
                fileName={name}
                rootDir={rootDir}
                folders={folders}
                openDialog={startMove}
                itemLoading={folderLoading}
                handleMove={async (moveToId) => {
                    if (dirType === "parametricDir") {
                        await moveParametricAnalysisFolder(folderId, uid, moveToId);
                    } else {
                        await moveFolder(folderId, uid, moveToId, false, dirType);
                    }
                }}
                onClose={() => toggleStartMove(false)}
            />
        </React.Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    uid: selectUserUid,
    folderLoading: selectFolderLoading,
});

const mapDispatchToProps = (dispatch, { history }) => ({
    deleteFolder: async (folderId, uid, dirType = "modelDir") => dispatch(deleteFolder(folderId, uid, dirType)),
    renameFolder: async (folderId, uid, newFolderName, dirType = "modelDir") => dispatch(renameFolder(folderId, uid, newFolderName, dirType)),
    moveFolder: async (folderId, uid, moveToId, skipLoading, dirType = "modelDir") => dispatch(moveFolder(folderId, uid, moveToId, skipLoading, dirType)),

    deleteParametricAnalysisFolder: (folderId, userUid) => dispatch(deleteParametricAnalysisFolder(folderId, userUid)),
    renameParametricAnalysisFolder: (folderId, userUid, newName) =>
        dispatch(renameParametricAnalysisFolder(folderId, userUid, newName)),
    moveParametricAnalysisFolder: (folderId, userUid, moveToId) =>
        dispatch(moveParametricAnalysisFolder(folderId, userUid, moveToId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Folder);
