import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getUpgradeEnvelopeRowLabels,
    getUpgradeHvacRowLabels,
    getUpgradeDhwRowLabels,
    getUpgradeOtherRowLabels,
    envelopeRowHeadings,
    hvacRowHeadings,
    dhwRowHeadings,
    otherRowHeadings,
    financingRowHeadings,
    // grantRebateHeadings,
    getSubComponentOptions,
    getComponentOptions,
    stripWindowSkylights,
    stripCeilings,
    getInitComponentUpgradeFields,
    getInitAirtightUpgradeFields,
    // getUpgradeGrantRowLabels,
    getUpgradeFinancingRowsLabels,
} from "utils/upgrades";
import { selectUpgradePackagesSortedArray, selectUpgradePackages, selectBaseUiDetails } from "../_ducks/selectors";
import Table from "components/Table";
import classes from "./UpgradesTable.module.scss";
import UpgradeHead from "./UpgradeHead/UpgradeHead";
import UpgradeHeadBase from "./UpgradeHead/UpgradeHeadBase";
import classNames from "classnames";
import Add from "assets/images/icons/JSX/Add";
import Edit from "assets/images/icons/JSX/Edit";
import Tooltip from "components/Tooltip";
import UpgradeTableSimulations from "./UpgradeTableSimulations/UpgradeTableSimulations";
import { modelFormSelector } from "store/form/selectors";
import RowToggle from "./RowToggle/RowToggle";
import CostInput from "./CostInput/CostInput";
import { actions } from "../_ducks";
import SelectModel from "./SelectModel";
import { selectModel } from "features/Model/_ducks/selectors";
import { useLocation } from "react-router";
import { fetchUpgradePackage } from "store/upgradeLibrary/thunk";

const { updateUpgradeCost } = actions;

const buildRow = ({ id, value, content, hideRows, hiddenRows = {}, columns = {}, className, modelChange }) => {
    return {
        id,
        house: {
            value,
            content: (
                <>
                    <strong>{content}</strong>{" "}
                    <RowToggle
                        className={classNames(classes.rowToggle, {
                            [classes.hide]: !hiddenRows[id],
                        })}
                        rowId={id}
                        modelChange={modelChange}
                    />
                </>
            ),
            className,
            // verticalAlign: "top",
        },
        rowClassName: hideRows && hiddenRows[id] ? classes.hiddenRow : "",
        ...columns,
    };
};

const baseDefault = {
    value: "base",
    content: "Base Value",
    upgradeSet: true,
    className: `${classes.regularCell} ${classes.baseCell}`,
};

const emptyDividerCell = {
    value: "",
    className: classes.dividerCell,
};

export const getEmptyDividerCells = (upgrades, showCost) => {
    return {
        base: emptyDividerCell,
        ...upgrades.reduce(
            (obj, { id }) => ({
                ...obj,
                [id]: emptyDividerCell,
                ...(showCost ? { [`${id}-cost`]: emptyDividerCell } : {}),
            }),
            {}
        ),
    };
};

const buildUpgradeCell = ({ empty, componentsRemaining, toggleUpgrade, upgrades = [], showCost }) => {
    return {
        content: upgrades.map(({ displayLabel, upgradeId, cost, onChangeCost, upgradeType, hasCost }, ind) => {
            return (
                <span key={`upgrade-${upgradeId}`} className={classes.upgradeCellText}>
                    <span
                        className={classNames(classes.upgradeCellTextLabel, {
                            [classes.rightAlign]: false, //upgradeType === "totalCapitalCost" && showCost,
                        })}
                    >
                        {displayLabel}
                    </span>{" "}
                    {!empty && upgradeType !== "totalCapitalCost" && (
                        <span
                            data-for={`upgrade-table-tooltip-${upgradeId}`}
                            data-tip="Edit Upgrade"
                            data-place="left"
                            onClick={() => toggleUpgrade(upgradeId)}
                            className={classes.edit}
                        >
                            <Edit />
                        </span>
                    )}
                    {(empty || componentsRemaining) && (
                        <span
                            data-for={`upgrade-table-tooltip-${upgradeId}`}
                            data-tip="Add Upgrade"
                            data-place="left"
                            onClick={() => toggleUpgrade()}
                            className={classes.edit}
                        >
                            <Add />
                        </span>
                    )}
                    {showCost && upgradeType !== "totalCapitalCost" && (
                        <span className={classes.upgradeCellCostText}>
                            {empty ? (
                                "-"
                            ) : (
                                <>
                                    <span>$</span> {/* add hasCost as prop here*/}
                                    <CostInput
                                        hasCost={true}
                                        setValue={cost}
                                        value={cost ?? 0}
                                        onChange={onChangeCost}
                                    />
                                </>
                            )}
                        </span>
                    )}
                    <Tooltip id={`upgrade-table-tooltip-${upgradeId}`} />
                </span>
            );
        }),
        className: classNames(`${classes.regularCell} ${classes.upgradeCell}`, {
            [classes.fadeCell]: empty,
            [classes.cellWithCost]: showCost,
        }),
    };
};

const UpgradesTable = React.forwardRef(
    (
        {
            toggleUpgradeDrawer,
            togglePackageEdit,
            duplicateUpgrade,
            toggleToDelete,
            showCost,
            handleSimulate,
            modelChange,
            uid,
            codeLib,
            modelDir,
            simulateDisabled,
        },
        ref
    ) => {
        const dispatch = useDispatch();
        const [scrolled, setScrolled] = useState(false);

        const [selectFolderOpen, toggleSelectFolder] = useState(false);
        const [acceptLinkedBaseline, setAcceptLinkedBaseline] = useState(false);

        const components = useSelector(modelFormSelector)("modelData.components");
        const airtightness = useSelector(modelFormSelector)("modelData.airTightness");
        const codes = useSelector(modelFormSelector)("modelData.codes");
        const heatingCooling = useSelector(modelFormSelector)("modelData.heatingCooling");
        const domesticHotWater = useSelector(modelFormSelector)("modelData.domesticHotWater");
        const ventilation = useSelector(modelFormSelector)("modelData.ventilation");
        const generation = useSelector(modelFormSelector)("modelData.generation");
        const program = useSelector(modelFormSelector)("modelData.program");
        const baseLoads = useSelector(modelFormSelector)("modelData.baseLoads");
        const dimensions = useSelector(modelFormSelector)("modelData.dimensions");
        const { file: { targetLabel: { id: chbaLabelId } = {} } = {} } =
            useSelector(modelFormSelector)("modelData.chbaNz") || {};
        const isChbaFile = [0, 1, 2, 3].includes(chbaLabelId);

        const model = useSelector(selectModel);
        const linkedBaseline = model.linkedBaseline;

        const { pathname } = useLocation();
        const path = pathname.split("/").filter((str) => str !== "" && str !== "upgrades") || [];
        const currentModelId = path[path.length - 1] || null;

        const linkedBaselineModelData = linkedBaseline?.data?.modelData || {
            components,
            airTightness: airtightness,
            codes,
            heatingCooling,
            domesticHotWater,
            ventilation,
            generation,
            program,
            baseLoads,
            dimensions,
        };

        const originalData = {
            components,
            airTightness: airtightness,
            codes,
            heatingCooling,
            domesticHotWater,
            ventilation,
            generation,
            program,
            baseLoads,
            dimensions,
        };

        const [baseModelData, setBaseModelData] = useState(originalData);

        // only set to linkedData if the model is suitable (has results)
        useEffect(() => {
            if (acceptLinkedBaseline === true) {
                setBaseModelData(linkedBaselineModelData);
            } else {
                // whenever returning to the existing home, results are replaced by the original set
                setBaseModelData(originalData);
            }
        }, [acceptLinkedBaseline]);

        const { models = {}, folders = {} } = modelDir;

        const upgradePackages = useSelector(selectUpgradePackagesSortedArray);
        const baseUi = useSelector(selectBaseUiDetails);

        const { name: baseName = "" } = baseUi || {};

        const { upgrades: { hideRows = false, hiddenRows = {} } = {} } =
            useSelector(modelFormSelector)("modelData.uiSettings");

        // const rowFilter = ({ id }) => !hideRows || !hiddenRows[id];
        const rowFilter = ({ id }) => true;

        // const handleUpgradeCostChange = (props) => dispatch(updateUpgradeCost(props)); //Removed with transition to detailed costs
        const handleUpgradeCostChange = () => {};

        useEffect(() => {
            const tableWrapper = ref.current;
            const initiateScroll = () => {
                if (tableWrapper.scrollLeft > 0) {
                    setScrolled(true);
                } else {
                    setScrolled(false);
                }
            };

            tableWrapper.addEventListener("scroll", initiateScroll);

            return () => {
                tableWrapper.removeEventListener("scroll", initiateScroll);
            };
        }, [ref]);

        const linkedBaseModelName = linkedBaseline?.data?.modelDetails?.name || "";
        const baseHeaderName = baseName || linkedBaseModelName || "Existing Home";

        return (
            <div
                ref={ref}
                className={classNames(classes.upgradesTableWrapper, {
                    [classes.scrolled]: scrolled,
                })}
            >
                <Table
                    className={classes.upgradesTable}
                    headClassName={classes.upgradesTableHead}
                    headCells={[
                        {
                            id: "house",
                            label: "House Components",
                            align: "left",
                            className: `${classes.headCell} ${classes.freezeCell}`,
                            width: "290",
                        },
                        {
                            id: "base",
                            label: (
                                <UpgradeHeadBase
                                    name={baseHeaderName}
                                    packageId="base"
                                    togglePackageEdit={() => {
                                        togglePackageEdit({
                                            isOpen: true,
                                            selectedPackage: "base",
                                        });
                                    }}
                                    toggleSelectFolder={toggleSelectFolder}
                                    modelDir={modelDir}
                                    uid={uid}
                                    currentModelId={currentModelId}
                                    acceptLinkedBaseline={acceptLinkedBaseline}
                                    setAcceptLinkedBaseline={setAcceptLinkedBaseline}
                                />
                            ),
                            align: "left",
                            className: `${classes.headCell} ${classes.upgradeCell} ${classes.baseCell}`,
                            width: "268",
                        },
                        ...upgradePackages.reduce((arr, { id, name }) => {
                            return [
                                ...arr,
                                {
                                    id,
                                    label: (
                                        <UpgradeHead
                                            name={name}
                                            packageId={id}
                                            packageCount={upgradePackages.length}
                                            packageIds={upgradePackages.map(({ id }) => id)}
                                            togglePackageEdit={() => {
                                                togglePackageEdit({
                                                    isOpen: true,
                                                    selectedPackage: id,
                                                });
                                            }}
                                            duplicateUpgrade={duplicateUpgrade}
                                            deletePackage={() => {
                                                toggleToDelete(id);
                                            }}
                                            showCost={showCost}
                                            uid={uid}
                                            codes={{ ...codes, ...codeLib }}
                                            fetchLabels={(uid, orgId) => dispatch(fetchUpgradePackage(uid, orgId))}
                                        />
                                    ),
                                    align: "left",
                                    className: `${classes.headCell} ${classes.upgradeCell}`,
                                    width: showCost ? "380" : "268",
                                },
                            ];
                        }, []),
                    ]}
                    rows={[
                        {
                            house: {
                                id: "envelope",
                                value: "envelope",
                                content: (
                                    <div>
                                        <strong>ENVELOPE</strong>
                                    </div>
                                ),
                                className: `${classes.dividerCell} ${classes.freezeCell}`,
                            },
                            ...getEmptyDividerCells(upgradePackages, showCost),
                        },
                        ...envelopeRowHeadings.filter(rowFilter).map((obj) =>
                            buildRow({
                                ...obj,
                                modelChange,
                                hideRows,
                                hiddenRows,
                                columns: {
                                    // this is where pre-retrofit data will be applied
                                    base: {
                                        ...baseDefault,
                                        content: getUpgradeEnvelopeRowLabels({
                                            components: baseModelData.components,
                                            codes: baseModelData.codes,
                                            airtightness: baseModelData["airTightness"],
                                            rowId: obj.id,
                                        })?.label,
                                        className: `${classes.regularCell} ${classes.baseCell}`,
                                        // verticalAlign: "top",
                                    },
                                    ...upgradePackages.reduce((packagesObj, upgradePackage) => {
                                        const { id, upgrades = {} } = upgradePackage;
                                        const { label, upgradeIds } = getUpgradeEnvelopeRowLabels({
                                            components,
                                            codes,
                                            airtightness,
                                            rowId: obj.id,
                                            upgradePackage: {
                                                [id]: upgradePackage,
                                            },
                                        });

                                        let availableComponents = ["window", "door", "floorHeader"].includes(
                                            obj.upgradeType
                                        )
                                            ? getSubComponentOptions(
                                                  obj.upgradeType,
                                                  components,
                                                  upgrades,
                                                  "" // We're checking for an empty ID here
                                              )
                                            : getComponentOptions(obj.upgradeType, components, upgrades, "");

                                        if (obj.id === "window" || obj.id === "skylights") {
                                            availableComponents = stripWindowSkylights(availableComponents, obj.id);
                                        } else if (obj.id === "ceilingWithAttic" || obj.id === "cathedral") {
                                            availableComponents = stripCeilings(
                                                availableComponents,
                                                components,
                                                obj.id
                                            );
                                        }

                                        const componentsRemaining = availableComponents.some(
                                            ({ disabled }) => !disabled
                                        );

                                        //Include airtightness
                                        let initUpgradeData = {};
                                        if (componentsRemaining && availableComponents.length > 0) {
                                            initUpgradeData = getInitComponentUpgradeFields(
                                                components,
                                                availableComponents[0].value,
                                                obj.upgradeType
                                            );
                                        } else if (obj.upgradeType === "airTightness") {
                                            initUpgradeData = getInitAirtightUpgradeFields(airtightness, dimensions);
                                        }

                                        const toggleUpgrade = (upgradeId) =>
                                            toggleUpgradeDrawer({
                                                isOpen: true,
                                                formName: "upgrades",
                                                selectedPackage: id,
                                                selectedUpgrade: upgradeId,
                                                selectedUpgradeType: obj.id,
                                                selectedUpgradeH2kType: obj.upgradeType,
                                                selectedUpgradeTypeLabel: obj.content,
                                                selectedUpgradeLabel: label,
                                                initUpgradeData,
                                                isNew: !upgradeId,
                                            });

                                        const upgradesObj =
                                            upgradeIds.length === 0
                                                ? buildUpgradeCell({
                                                      empty: true,
                                                      toggleUpgrade,
                                                      upgrades: [
                                                          {
                                                              displayLabel: label,
                                                          },
                                                      ],
                                                      showCost,
                                                  })
                                                : buildUpgradeCell({
                                                      empty: false,
                                                      toggleUpgrade,
                                                      componentsRemaining,
                                                      upgrades: upgradeIds.map((upgradeId) => {
                                                          return {
                                                              displayLabel:
                                                                  upgrades[upgradeId]?.label?.[obj.id] || label,
                                                              upgradeType: obj.id,
                                                              upgradeId,
                                                              //   hasCost,
                                                              //   pulling cost from the cost table
                                                              cost: upgrades[upgradeId]?.cost?.[obj.id]?.total,
                                                              onChangeCost: (cost) =>
                                                                  handleUpgradeCostChange({
                                                                      packageId: id,
                                                                      upgradeId,
                                                                      upgradeType: obj.id,
                                                                      cost,
                                                                  }),
                                                          };
                                                      }),
                                                      showCost,
                                                  });

                                        return {
                                            ...packagesObj,
                                            [id]: upgradesObj,
                                        };
                                    }, {}),
                                },
                                className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell}`,
                            })
                        ),
                        {
                            house: {
                                id: "hvac",
                                value: "hvac",
                                content: <strong>HVAC</strong>,
                                className: `${classes.dividerCell} ${classes.freezeCell}`,
                            },
                            ...getEmptyDividerCells(upgradePackages, showCost),
                        },
                        ...hvacRowHeadings.filter(rowFilter).map((obj) =>
                            buildRow({
                                ...obj,
                                modelChange,
                                hideRows,
                                hiddenRows,
                                columns: {
                                    base: {
                                        ...baseDefault,
                                        content: getUpgradeHvacRowLabels({
                                            heatingCooling: baseModelData.heatingCooling,
                                            ventilation: baseModelData.ventilation,
                                            rowId: obj.id,
                                        })?.label,
                                        className: `${classes.regularCell} ${classes.baseCell}`,
                                        // verticalAlign: "top",
                                    },
                                    ...upgradePackages.reduce((packagesObj, upgradePackage) => {
                                        const { id, upgrades = {}, upgradeSettings = {} } = upgradePackage;
                                        const { label, upgradeIds } = getUpgradeHvacRowLabels({
                                            heatingCooling,
                                            ventilation,
                                            rowId: obj.id,
                                            upgradePackage: {
                                                [id]: upgradePackage,
                                            },
                                        });

                                        const initUpgradeData = {
                                            ventilation: {
                                                fields: ventilation,
                                            },
                                            spaceHeating: {
                                                fields: heatingCooling,
                                            },
                                            secondaryHeating: {
                                                fields: heatingCooling,
                                            },
                                            spaceCooling: {
                                                fields: heatingCooling,
                                            },
                                        }[obj.id];

                                        const toggleUpgrade = (upgradeId) =>
                                            toggleUpgradeDrawer({
                                                isOpen: true,
                                                formName: "upgrades",
                                                selectedPackage: id,
                                                selectedUpgrade: upgradeId,
                                                selectedUpgradeType: obj.id,
                                                selectedUpgradeH2kType: obj.upgradeType,
                                                selectedUpgradeTypeLabel: obj.content,
                                                selectedUpgradeLabel: label,
                                                initUpgradeData,
                                                isNew: !upgradeId,
                                            });

                                        const upgradesObj =
                                            upgradeIds.length === 0
                                                ? buildUpgradeCell({
                                                      empty: true,
                                                      toggleUpgrade,
                                                      upgrades: [
                                                          {
                                                              displayLabel: label,
                                                          },
                                                      ],
                                                      showCost,
                                                  })
                                                : buildUpgradeCell({
                                                      empty: false,
                                                      toggleUpgrade,
                                                      componentsRemaining: false,
                                                      upgrades: upgradeIds.map((upgradeId) => {
                                                          return {
                                                              displayLabel:
                                                                  upgrades[upgradeId]?.label?.[obj.id] || label,
                                                              upgradeType: obj.id,
                                                              upgradeId,
                                                              cost: upgrades[upgradeId]?.cost?.[obj.id]?.total,
                                                              onChangeCost: (cost) =>
                                                                  handleUpgradeCostChange({
                                                                      packageId: id,
                                                                      upgradeId,
                                                                      upgradeType: obj.id,
                                                                      cost,
                                                                  }),
                                                          };
                                                      }),
                                                      showCost,
                                                  });

                                        return {
                                            ...packagesObj,
                                            [id]: upgradesObj,
                                        };
                                    }, {}),
                                },
                                className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell}`,
                            })
                        ),
                        {
                            house: {
                                id: "dhw",
                                value: "dhw",
                                content: <strong>DHW</strong>,
                                className: `${classes.dividerCell} ${classes.freezeCell}`,
                            },
                            ...getEmptyDividerCells(upgradePackages, showCost),
                        },
                        ...dhwRowHeadings.filter(rowFilter).map((obj) =>
                            buildRow({
                                ...obj,
                                modelChange,
                                hideRows,
                                hiddenRows,
                                columns: {
                                    base: {
                                        ...baseDefault,
                                        content: getUpgradeDhwRowLabels({
                                            domesticHotWater: baseModelData.domesticHotWater,
                                            heatingCooling: baseModelData.heatingCooling,
                                            rowId: obj.id,
                                        })?.label,
                                        className: `${classes.regularCell} ${classes.baseCell}`,
                                        // verticalAlign: "top",
                                    },
                                    ...upgradePackages.reduce((packagesObj, upgradePackage) => {
                                        const { id, upgrades = {}, upgradeSettings = {} } = upgradePackage;
                                        const { label, upgradeIds } = getUpgradeDhwRowLabels({
                                            domesticHotWater,
                                            heatingCooling,
                                            rowId: obj.id,
                                            upgradePackage: {
                                                [id]: upgradePackage,
                                            },
                                        });

                                        //Must get the heating system type so we know if we're dealing with a combo
                                        const upHeatingCooling =
                                            Object.values(upgradePackage?.upgrades || {}).find(
                                                (val) => val.upgradeType === "heatingCooling"
                                            ) || {};

                                        const { fields: { heating: { system: heatingSystem = {} } = {} } = {} } =
                                            upHeatingCooling || {};

                                        const initUpgradeData = {
                                            primaryHotWater: {
                                                fields: domesticHotWater,
                                            },
                                            dwhr: {
                                                fields: domesticHotWater,
                                            },
                                        }[obj.id];

                                        const toggleUpgrade = (upgradeId) =>
                                            toggleUpgradeDrawer({
                                                isOpen: true,
                                                formName: "upgrades",
                                                selectedPackage: id,
                                                selectedUpgrade: upgradeId,
                                                selectedUpgradeType: obj.id,
                                                selectedUpgradeH2kType: obj.upgradeType,
                                                selectedUpgradeTypeLabel: obj.content,
                                                selectedUpgradeLabel: label,
                                                initUpgradeData,
                                                isNew: !upgradeId,
                                                associatedHeatingSystem: Object.keys(heatingSystem)[0] || "",
                                            });

                                        const upgradesObj =
                                            upgradeIds.length === 0
                                                ? buildUpgradeCell({
                                                      empty: true,
                                                      toggleUpgrade,
                                                      upgrades: [
                                                          {
                                                              displayLabel: label,
                                                          },
                                                      ],
                                                      showCost,
                                                  })
                                                : buildUpgradeCell({
                                                      empty: false,
                                                      toggleUpgrade,
                                                      componentsRemaining: false,
                                                      upgrades: upgradeIds.map((upgradeId) => {
                                                          return {
                                                              displayLabel:
                                                                  upgrades[upgradeId]?.label?.[obj.id] || label,
                                                              upgradeType: obj.id,
                                                              upgradeId,
                                                              cost: upgrades[upgradeId]?.cost?.[obj.id]?.total,
                                                              onChangeCost: (cost) =>
                                                                  handleUpgradeCostChange({
                                                                      packageId: id,
                                                                      upgradeId,
                                                                      upgradeType: obj.id,
                                                                      cost,
                                                                  }),
                                                          };
                                                      }),
                                                      showCost,
                                                  });

                                        return {
                                            ...packagesObj,
                                            [id]: upgradesObj,
                                        };
                                    }, {}),
                                },
                                className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell}`,
                            })
                        ),
                        {
                            house: {
                                id: "other",
                                value: "other",
                                content: <strong>OTHER</strong>,
                                className: `${classes.dividerCell} ${classes.freezeCell}`,
                            },
                            ...getEmptyDividerCells(upgradePackages, showCost),
                        },
                        ...otherRowHeadings.filter(rowFilter).map((obj) =>
                            buildRow({
                                ...obj,
                                modelChange,
                                hideRows,
                                hiddenRows,
                                columns: {
                                    base: {
                                        ...baseDefault,
                                        content: getUpgradeOtherRowLabels({
                                            generation: baseModelData.generation,
                                            program: baseModelData.program,
                                            baseLoads: baseModelData.baseLoads,
                                            rowId: obj.id,
                                        })?.label,
                                        className: `${classes.regularCell} ${classes.baseCell}`,
                                        // verticalAlign: "top",
                                    },
                                    ...upgradePackages.reduce((packagesObj, upgradePackage) => {
                                        const { id, upgrades = {}, upgradeSettings = {} } = upgradePackage;
                                        const { label, upgradeIds } = getUpgradeOtherRowLabels({
                                            generation,
                                            program,
                                            baseLoads,
                                            rowId: obj.id,
                                            upgradePackage: {
                                                [id]: upgradePackage,
                                            },
                                        });

                                        const initUpgradeData = {
                                            generation: {
                                                fields: generation,
                                            },
                                            baseLoads: {
                                                fields: baseLoads,
                                            },
                                        }[obj.upgradeType];

                                        const toggleUpgrade = (upgradeId) =>
                                            toggleUpgradeDrawer({
                                                isOpen: true,
                                                formName: "upgrades",
                                                selectedPackage: id,
                                                selectedUpgrade: upgradeId,
                                                selectedUpgradeType: obj.id,
                                                selectedUpgradeH2kType: obj.upgradeType,
                                                selectedUpgradeTypeLabel: obj.content,
                                                selectedUpgradeLabel: label,
                                                initUpgradeData,
                                                isNew: !upgradeId,
                                            });

                                        const upgradesObj =
                                            upgradeIds.length === 0
                                                ? buildUpgradeCell({
                                                      empty: true,
                                                      toggleUpgrade,
                                                      upgrades: [
                                                          {
                                                              displayLabel: label,
                                                          },
                                                      ],
                                                      showCost,
                                                  })
                                                : buildUpgradeCell({
                                                      empty: false,
                                                      toggleUpgrade,
                                                      componentsRemaining: false,
                                                      upgrades: upgradeIds.map((upgradeId) => {
                                                          return {
                                                              displayLabel:
                                                                  upgrades[upgradeId]?.label?.[obj.id] || label,
                                                              upgradeType: obj.id,
                                                              upgradeId,
                                                              cost: upgrades[upgradeId]?.cost?.[obj.id]?.total,
                                                              onChangeCost: (cost) =>
                                                                  handleUpgradeCostChange({
                                                                      packageId: id,
                                                                      upgradeId,
                                                                      upgradeType: obj.id,
                                                                      cost,
                                                                  }),
                                                          };
                                                      }),
                                                      showCost,
                                                  });

                                        return {
                                            ...packagesObj,
                                            [id]: upgradesObj,
                                        };
                                    }, {}),
                                },
                                className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell}`,
                            })
                        ),
                        {
                            house: {
                                id: "financing",
                                value: "financing",
                                content: <strong>PROJECT FINANCIALS</strong>,
                                className: `${classes.dividerCell} ${classes.freezeCell}`,
                            },
                            ...getEmptyDividerCells(upgradePackages, showCost),
                        },
                        ...financingRowHeadings.filter(rowFilter).map((obj) =>
                            buildRow({
                                ...obj,
                                modelChange,
                                hideRows,
                                hiddenRows,
                                columns: {
                                    base: {
                                        ...baseDefault,
                                        content: getUpgradeFinancingRowsLabels({
                                            rowId: obj.id,
                                        })?.label,
                                        className: `${classes.regularCell} ${classes.baseCell}`,
                                        // verticalAlign: "top",
                                    },
                                    ...upgradePackages.reduce((packagesObj, upgradePackage) => {
                                        const { id, upgrades = {}, upgradeSettings = {} } = upgradePackage;
                                        const { label, upgradeIds } = getUpgradeFinancingRowsLabels({
                                            rowId: obj.id,
                                            upgradePackage: {
                                                [id]: upgradePackage,
                                            },
                                        });

                                        const toggleUpgrade = (upgradeId) =>
                                            toggleUpgradeDrawer({
                                                isOpen: true,
                                                selectedPackage: id,
                                                selectedUpgrade: upgradeId,
                                                selectedUpgradeType: obj.id,
                                                selectedUpgradeH2kType: obj.upgradeType,
                                                selectedUpgradeTypeLabel: obj.content,
                                                selectedUpgradeLabel: label,
                                            });

                                        const upgradesObj =
                                            upgradeIds.length === 0
                                                ? buildUpgradeCell({
                                                      empty: true,
                                                      toggleUpgrade,
                                                      upgrades: [
                                                          {
                                                              displayLabel: label,
                                                          },
                                                      ],
                                                      showCost,
                                                  })
                                                : buildUpgradeCell({
                                                      empty: false,
                                                      toggleUpgrade,
                                                      componentsRemaining: false,
                                                      upgrades: upgradeIds.map((upgradeId) => {
                                                          return {
                                                              displayLabel:
                                                                  upgrades[upgradeId]?.label?.[obj.id] || label,
                                                              upgradeType: obj.id,
                                                              upgradeId,
                                                              cost: upgrades[upgradeId]?.cost?.[obj.id]?.total,
                                                              onChangeCost: (cost) =>
                                                                  handleUpgradeCostChange({
                                                                      packageId: id,
                                                                      upgradeId,
                                                                      upgradeType: obj.id,
                                                                      cost,
                                                                  }),
                                                          };
                                                      }),
                                                      showCost,
                                                  });

                                        return {
                                            ...packagesObj,
                                            [id]: upgradesObj,
                                        };
                                    }, {}),
                                },
                                className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell}`,
                            })
                        ),
                        ...UpgradeTableSimulations({
                            upgradePackages,
                            baseUi,
                            handleSimulate,
                            modelChange,
                            hiddenRows,
                            hideRows,
                            rowFilter,
                            acceptLinkedBaseline,
                            simulateDisabled,
                            isChbaFile,
                        }),
                    ]}
                />
                <SelectModel
                    folderToMove={selectFolderOpen}
                    models={models}
                    folders={folders}
                    close={() => toggleSelectFolder(false)}
                    isMoving={false}
                    acceptLinkedBaseline={acceptLinkedBaseline}
                    setAcceptLinkedBaseline={setAcceptLinkedBaseline}
                />
            </div>
        );
    }
);

export default UpgradesTable;
