import React, { Suspense } from "react";
import { PrivateRoute } from "utils/routes";
import { Route, Switch } from "react-router-dom";
// import { mixpanel } from "components/Mixpanel";

import Loading from "components/Loading";
import Dashboard from "features/Dashboard/container";
import Login from "features/Auth/Login/container";
import Register from "features/Auth/Register/container";
import Create from "features/CreateModel";
import Model from "features/Model/container";
import CodeLibrary from "features/CodeLibrary/container";
import UpgradeLibrary from "features/UpgradeLibrary";
import ReportGenerator from "features/ReportGenerator/container";
import LegacyParametricAnalysis from "features/LegacyParametricAnalysis/container";
import CommunitiesDashboard from "features/Dashboards/Communities";
import Community from "features/Community";
import QuickStart from "features/Resources/QuickStart";
import FAQs from "features/Resources/FAQs";
import ContactUs from "features/Resources/ContactUs";
import Roadmap from "features/Resources/Roadmap";
import Updates from "features/Resources/Updates";
import Preferences from "features/User/Preferences/container";
import Account from "features/User/Account/container";
import PageNotFound from "features/Navigation/PageNotFound";
import ParametricAnalysis from "features/ParametricAnalysis";

export default () => {
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                <PrivateRoute path="/dashboard" exact component={Dashboard} />
                <Route path="/login" exact component={Login} />
                <Route path="/register" exact component={Register} />
                <PrivateRoute path="/404-page-not-found" component={PageNotFound} />
                <PrivateRoute path="/updates" exact component={Updates} />
                <PrivateRoute path="/code-library" component={CodeLibrary} />
                <PrivateRoute path="/upgrade-library" component={UpgradeLibrary} />
                <PrivateRoute path="/generate-report" component={ReportGenerator} />
                <PrivateRoute path="/legacy-parametric-analysis" component={LegacyParametricAnalysis} />
                <PrivateRoute path="/parametric-analysis" component={ParametricAnalysis} />
                <PrivateRoute path="/communities/dashboard" component={CommunitiesDashboard} />
                <PrivateRoute path="/communities/:communityId" component={Community} />
                <PrivateRoute path="/resources/quickstart" exact component={QuickStart} />
                <PrivateRoute path="/resources/faqs" exact component={FAQs} />
                <PrivateRoute path="/resources/contact" exact component={ContactUs} />
                <PrivateRoute path="/resources/roadmap" exact component={Roadmap} />
                <PrivateRoute path="/user/preferences" exact component={Preferences} />
                <PrivateRoute path="/user/account" exact component={Account} />
                <PrivateRoute path="/create" component={Create} />
                <PrivateRoute path="/models/:modelId" component={Model} />
                <PrivateRoute path="/communities/" component={CommunitiesDashboard} />
                <PrivateRoute path="/" component={Dashboard} />
            </Switch>
        </Suspense>
    );
};
