import React, { useState } from "react";
import { isEmpty, set } from "lodash";

import Dialog from "components/Dialog";
import Button from "components/Button";

import {
    headerContainer,
    headerText,
    dialogContainer,
    foldersContainer,
    noFoldersText,
    folderContainer,
    folderImg,
    selectedFolderContainer,
    headerTextContainer,
    goBackImg,
    moveToContainer,
    moveToText,
    selectedModelContainer,
} from "./style.module.scss";

import closeCircle from "assets/images/icons/closeCircle.svg";
import blueArrowRight from "assets/images/icons/blueArrowRight.svg";
import goBackFolder from "assets/images/icons/goBackFolder.svg";
import ModelIcon from "assets/images/icons/model.svg";
import FolderIcon from "assets/images/icons/folder.svg";

const SelectModelFolder = ({
    rootDir = "Root Directory",
    rootDirPath = "/dashboard",
    isOpen,
    folderToMove,
    modelToMove,
    close,
    folders,
    models,
    // models,
    moveFolder,
    moveModel,
    folderLoading,
    itemLoading,
    history,
    isMoving = true,
    onSelect = () => {},
    isSelectingModel,
}) => {
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState("");
    const [selectedModelId, setSelectedModelId] = useState(null);

    const filteredFolders = !isEmpty(folders)
        ? Object.entries(folders).filter(([key, { parentFolderId }]) => {
              return parentFolderId === currentFolderId && key !== folderToMove;
          })
        : [];

    const filteredModels = !isEmpty(models)
        ? Object.entries(models).filter(([key, { parentFolderId }]) => {
              return parentFolderId === currentFolderId && key !== modelToMove;
          })
        : [];

    return (
        <Dialog open={!!folderToMove || !!modelToMove || isOpen} classes={{ paperClass: dialogContainer }}>
            <div className={headerContainer}>
                <div className={headerTextContainer}>
                    {currentFolderId && (
                        <img
                            className={goBackImg}
                            src={goBackFolder}
                            alt="go back"
                            onClick={() => {
                                setCurrentFolderId(folders[currentFolderId].parentFolderId);
                                setSelectedFolder("");
                            }}
                        />
                    )}
                    <span className={headerText}>{currentFolderId ? `${folders[currentFolderId].name}` : rootDir}</span>
                </div>
                <img
                    src={closeCircle}
                    alt="close circle"
                    onClick={() => {
                        close();
                        setCurrentFolderId(null);
                        setSelectedFolder("");
                    }}
                    style={{ cursor: "pointer" }}
                />
            </div>
            <div className={foldersContainer}>
                {isEmpty(filteredFolders) && isEmpty(filteredModels) ? (
                    <div>
                        <span className={noFoldersText}>You haven't created any folders yet.</span>
                    </div>
                ) : (
                    <>
                        {!isEmpty(filteredFolders) &&
                            filteredFolders
                                .sort(([aKey, aValues], [bKey, bValues]) => aValues.name?.localeCompare(bValues.name))
                                .map(([key, { name }]) => (
                                    <div
                                        key={key}
                                        className={`${folderContainer} ${
                                            selectedFolder === key && selectedFolderContainer
                                        }`}
                                        onClick={() => {
                                            if (isSelectingModel) {
                                                setSelectedModelId(null);
                                                setCurrentFolderId(key);

                                                return;
                                            }

                                            if (selectedFolder === key) {
                                                setSelectedFolder("");
                                                setCurrentFolderId(key);
                                                setSelectedModelId(null);
                                            } else {
                                                setSelectedFolder(key);
                                                setSelectedModelId(null);
                                            }
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignmodels: "center",
                                                gap: "10px",
                                            }}
                                        >
                                            <img
                                                // className={}
                                                src={FolderIcon}
                                                alt="folder icon"
                                            />
                                            {name}
                                        </div>
                                        <img
                                            className={selectedFolder !== key && folderImg}
                                            src={blueArrowRight}
                                            alt="blue arrow right"
                                        />
                                    </div>
                                ))}
                        {!isEmpty(filteredModels) &&
                            isSelectingModel &&
                            filteredModels
                                .sort(([aKey, aValues], [bKey, bValues]) => aValues.name?.localeCompare(bValues.name))
                                .map(([key, { name }]) => (
                                    <div
                                        key={key}
                                        className={`${folderContainer} ${
                                            selectedModelId === key && selectedModelContainer
                                        }`}
                                        onClick={() => {
                                            setSelectedFolder("");
                                            setSelectedModelId(key);
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignmodels: "center",
                                                gap: "10px",
                                            }}
                                        >
                                            <img
                                                // className={}
                                                src={ModelIcon}
                                                alt="model icon"
                                            />
                                            {name}
                                        </div>
                                        {/* <img
                                            className={selectedFolder !== key && folderImg}
                                            src={blueArrowRight}
                                            alt="blue arrow right"
                                        /> */}
                                    </div>
                                ))}
                    </>
                )}
            </div>
            <div className={moveToContainer}>
                {isMoving ? (
                    <>
                        <span className={moveToText}>
                            Moving{" "}
                            {folderToMove ? (
                                <strong style={{ color: "#0049c6" }}>{folders[folderToMove].name}</strong>
                            ) : modelToMove ? (
                                <strong style={{ color: "#0049c6" }}>{models[modelToMove].name}</strong>
                            ) : (
                                ""
                            )}{" "}
                            to{" "}
                            {selectedFolder || currentFolderId ? (
                                <strong>{folders[selectedFolder || currentFolderId].name}</strong>
                            ) : (
                                <strong>{rootDir}</strong>
                            )}
                        </span>
                        <Button
                            onClick={async () => {
                                if (folderToMove) {
                                    await moveFolder(selectedFolder || currentFolderId);
                                    close();
                                    setCurrentFolderId(null);
                                    setSelectedFolder("");
                                    history.push(rootDirPath);
                                }

                                if (modelToMove) {
                                    await moveModel(selectedFolder || currentFolderId);
                                    close();
                                    setCurrentFolderId(null);
                                    setSelectedFolder("");
                                }
                            }}
                            disabled={isEmpty(folders)} //disabled in the case that we're at the root and have no folders at all
                        >
                            {folderLoading || itemLoading ? "Moving..." : "Move"}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={() => close()} type="hollow">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                onSelect(isSelectingModel ? selectedModelId : selectedFolder || currentFolderId);
                                close();
                            }}
                        >
                            {isSelectingModel ? "Select This Model" : "Select This Folder"}
                        </Button>
                    </>
                )}
            </div>
        </Dialog>
    );
};

export default SelectModelFolder;
