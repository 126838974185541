import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import _ from "lodash";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { modelFormSelector } from "store/form/selectors";

import { saveBase64File } from "utils/reportGen";

import actions from "features/ReportGenerator/_ducks/actions";

import { selectBaseUiDetails, selectUpgradePackagesSortedArray } from "../_ducks/selectors";
import { selectPackageResults } from "features/Model/Review/Results/_ducks/selectors";
import { selectUserPermissions, selectUserUid } from "store/users/selectors";
import { selectModelData } from "features/Model/_ducks/selectors";

import DrawerComponent from "components/Drawer";
import Select from "components/Input/Select";
import Input from "components/Input";
import { default as MultiSelect } from "components/Input/MultiSelect";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import InputWithUnits from "components/Input/InputWithUnits";

import UpgradesTablePDF from "./UpgradesTable/UpgradesTablePDF";

import DownloadIcon from "assets/images/icons/JSX/Dowload";

import { exportPdfButton } from "../style.module.scss";
import { pdfExportContainer, pdfBtnsContainer } from "./styles.module.scss";
import {
    simulationRowHeadings,
    simulationF280PDFHeadings,
    simulationFuelHeadings,
    simulationCostHeadings,
    simulationHeatlossHeadings,
} from "utils/upgrades";

const { generatePdf } = actions;

const PACKAGE_TYPES = {
    1: {
        label: "Easy",
        type: "easy",
    },
    2: {
        label: "Deep",
        type: "deep",
    },
    3: {
        label: "Net Zero",
        type: "net_zero",
    },
};

const ExportPDFDrawer = ({
    isOpen,
    close,
    upgradePackages,
    packageResults,
    userPermissions,
    generatePdf,
    uid,
    modelData,
}) => {
    const { ENVIROCENTRE_REPORT = false } = userPermissions || {};

    const [selectedFormat, setSelectedFormat] = useState("ledger");
    const [selectedUpgradesPackages, setSelectedUpgradesPackages] = useState(["existing_home"]);
    const [isOutSync, setIsOutSync] = useState(false);
    const [pdfHeaderText, setPdfHeaderText] = useState("Upgrade Pathways Report");
    const [reportType, setReportType] = useState("default"); // default or envirocentre
    const [netzero, setNetzero] = useState(0);
    const [isGenerating, setIsGenerating] = useState(false);

    useEffect(() => {
        for (let i = 0; i < upgradePackages.length; i++) {
            const pkg = upgradePackages[i];

            const { id: upgradeId, lastEdited } = pkg;
            const { metadata: { completedAt } = {} } = packageResults[upgradeId] || {};

            setIsOutSync(lastEdited && moment(lastEdited).isAfter(moment(completedAt)));
        }
    }, [upgradePackages, isOutSync, setIsOutSync, packageResults]);

    const components = useSelector(modelFormSelector)("modelData.components");
    const airtightness = useSelector(modelFormSelector)("modelData.airTightness");
    const codes = useSelector(modelFormSelector)("modelData.codes");
    const heatingCooling = useSelector(modelFormSelector)("modelData.heatingCooling");
    const domesticHotWater = useSelector(modelFormSelector)("modelData.domesticHotWater");
    const ventilation = useSelector(modelFormSelector)("modelData.ventilation");
    const generation = useSelector(modelFormSelector)("modelData.generation");
    const program = useSelector(modelFormSelector)("modelData.program");
    const baseLoads = useSelector(modelFormSelector)("modelData.baseLoads");
    const dimensions = useSelector(modelFormSelector)("modelData.dimensions");
    const fuelPrices = useSelector(modelFormSelector)("modelData.location.fuelPrices") || {};
    const client = useSelector(modelFormSelector)("modelData.general.client");
    const modelName = useSelector(modelFormSelector)("modelDetails.name");

    const { upgrades = {} } = useSelector(modelFormSelector)("modelData.uiSettings");

    const baseUi = useSelector(selectBaseUiDetails);

    const multiSelectOptions = [
        {
            value: "existing_home",
            label: "Existing Home",
            disabled: true,
        },
        ...upgradePackages.map((pkg, ind) => ({
            value: pkg.id,
            label:
                reportType === "default"
                    ? pkg.name
                    : `${pkg.name}${
                          PACKAGE_TYPES[selectedUpgradesPackages.indexOf(pkg.id)]
                              ? ` (${PACKAGE_TYPES[selectedUpgradesPackages.indexOf(pkg.id)].label})`
                              : ""
                      }`,
        })),
    ];

    const resultsRowIds = [
        ...simulationRowHeadings,
        ...simulationF280PDFHeadings,
        ...simulationFuelHeadings,
        ...simulationCostHeadings,
        ...simulationHeatlossHeadings,
    ].map((el) => el.id);

    //Set up warning for hidden rows
    //Need at least 9 results rows to be hidden in the results section for ledger, 20 to be hidden for 2-pages
    const { hiddenRows = {} } = upgrades || {};
    const hiddenResultsRows = Object.keys(hiddenRows)
        .filter((key) => hiddenRows[key])
        .filter((key) => resultsRowIds.includes(key));

    const ledgerOverflow = hiddenResultsRows.length < 9;
    const landscapeOverflow = hiddenResultsRows.length < 20;
    const overflowWarning =
        ledgerOverflow && selectedFormat === "ledger"
            ? `Hide ${9 - hiddenResultsRows.length} more row${
                  hiddenResultsRows.length === 8 ? "" : "s"
              } in the results section to prevent overflow onto a second page.`
            : landscapeOverflow && selectedFormat === "landscape"
            ? `Hide ${20 - hiddenResultsRows.length} more row${
                  hiddenResultsRows.length === 19 ? "" : "s"
              } in the results section to prevent overflow onto a third page.`
            : "";

    const generate = async () => {
        setIsGenerating(true);

        const easyUpgrades = upgradePackages.find((pkg) => pkg.id === selectedUpgradesPackages[1]) || {};
        const { resultsData: easyUpgradesResults = {} } = packageResults[selectedUpgradesPackages[1]] || {};
        const deepUpgrades = upgradePackages.find((pkg) => pkg.id === selectedUpgradesPackages[2]) || {};
        const { resultsData: deepUpgradesResults = {} } = packageResults[selectedUpgradesPackages[2]] || {};
        const netZeroUpgrades = upgradePackages.find((pkg) => pkg.id === selectedUpgradesPackages[3]) || {};
        const { resultsData: netZeroUpgradesResults = {} } = packageResults[selectedUpgradesPackages[3]] || {};

        const allH2kData = {
            easy: {
                houseData: { ...modelData },
                upgradePackage: { [selectedUpgradesPackages[1]]: { ...easyUpgrades } },
                h2kResults: { ...easyUpgradesResults },
            },
            deep: {
                houseData: { ...modelData },
                upgradePackage: { [selectedUpgradesPackages[1]]: { ...deepUpgrades } },
                h2kResults: { ...deepUpgradesResults },
            },
            netzero: {
                houseData: { ...modelData },
                upgradePackage: { [selectedUpgradesPackages[1]]: { ...netZeroUpgrades } },
                h2kResults: { ...netZeroUpgradesResults },
            },
        };

        const dataToSend = {
            uid,
            nzBlgEnvValues: { netzero: isNaN(netzero) ? 0 : netzero },
            isFromNewReportGenerator: true,
            allH2kData,
        };

        const pdfBytes = await generatePdf(null, dataToSend);

        saveBase64File(`${modelName}_envirocentre_report`, pdfBytes);

        setIsGenerating(false);
    };

    return (
        <DrawerComponent open={isOpen} close={close} title="Export PDF">
            <div className={pdfExportContainer}>
                {ENVIROCENTRE_REPORT && (
                    <Select
                        label={"Select report type"}
                        options={[
                            { value: "default", label: "Upgrades PDF" },
                            { value: "envirocentre", label: "EnviroCentre Report" },
                        ]}
                        input={{
                            value: reportType,
                            onChange: (value) => setReportType(value),
                        }}
                    />
                )}
                {reportType === "envirocentre" && (
                    <>
                        <MultiSelect
                            label={"Upgrade packages (select 3 packages in order: Easy, Deep, Net Zero)"}
                            options={multiSelectOptions}
                            input={{
                                value: selectedUpgradesPackages,
                                onChange: (value) => setSelectedUpgradesPackages(value),
                            }}
                        />
                        <InputWithUnits
                            label={"NetZero Building Envelope Thermal Performance Improvement (%)"}
                            input={{ value: netzero, onChange: (val) => setNetzero(val) }}
                            units={{
                                base: "%",
                                options: "%",
                                selected: "%",
                                unitType: "fraction",
                                accessor: `netZeroImprovement`,
                            }}
                        />
                    </>
                )}
                {reportType === "default" && (
                    <>
                        <Select
                            label={"Select page format"}
                            options={[
                                { value: "ledger", label: 'Ledger (11x17")' },
                                {
                                    value: "landscape",
                                    label: 'Landscape (2 pages @ 8.5x11")',
                                },
                            ]}
                            input={{
                                value: selectedFormat,
                                onChange: (value) => setSelectedFormat(value),
                            }}
                        />
                        <MultiSelect
                            label={"Select upgrade packages (Max existing home + 4 packages)"}
                            options={multiSelectOptions}
                            input={{
                                value: selectedUpgradesPackages,
                                onChange: (value) => setSelectedUpgradesPackages(value),
                            }}
                        />
                        <Input
                            label={"Report Title"}
                            input={{
                                value: pdfHeaderText,
                                onChange: (value) => setPdfHeaderText(value),
                            }}
                        />
                    </>
                )}
                {overflowWarning && reportType === "default" && (
                    <div>
                        <h5>{overflowWarning}</h5>
                    </div>
                )}
                <div className={pdfBtnsContainer}>
                    <Button type="hollow" onClick={close} style={{ width: "144px" }} disabled={isGenerating}>
                        Cancel
                    </Button>
                    <div
                        data-html
                        data-for="exportBtnTip"
                        data-tip={
                            reportType === "default"
                                ? _.isEmpty(packageResults)
                                    ? "No simulation results are present"
                                    : isOutSync
                                    ? "Simulation results out of sync with upgrades"
                                    : selectedUpgradesPackages.length < 2
                                    ? "Please select at least one upgrade package"
                                    : selectedUpgradesPackages.length > 5
                                    ? "A maximum of four (4) upgrade packages may be exported in one PDF"
                                    : ""
                                : selectedUpgradesPackages.length < 4
                                ? "Please select 3 upgrade packages"
                                : ""
                        }
                        style={{
                            width: "fit-content",
                        }}
                    >
                        {(reportType === "default" &&
                            (selectedUpgradesPackages.length === 1 ||
                                multiSelectOptions.length === 1 ||
                                selectedUpgradesPackages.length > 5 ||
                                pdfHeaderText === "" ||
                                isOutSync)) ||
                        reportType === "envirocentre" ? (
                            <Button
                                icon={
                                    reportType === "envirocentre"
                                        ? selectedUpgradesPackages.length === 4
                                            ? DownloadIcon.bind(this, {
                                                  colour: "#ffffff",
                                              })
                                            : DownloadIcon
                                        : DownloadIcon
                                }
                                className={exportPdfButton}
                                type={
                                    reportType === "envirocentre"
                                        ? selectedUpgradesPackages.length === 4
                                            ? "default"
                                            : "lightGrey"
                                        : "lightGrey"
                                }
                                style={{ width: "fit-content" }}
                                disabled={
                                    reportType === "envirocentre"
                                        ? selectedUpgradesPackages.length < 4 || isGenerating
                                        : true
                                }
                                onClick={() => {
                                    if (reportType === "envirocentre") {
                                        generate();
                                    }
                                }}
                            >
                                {reportType === "default"
                                    ? "Export PDF"
                                    : isGenerating
                                    ? "Generating..."
                                    : "Generate Report"}
                            </Button>
                        ) : (
                            reportType === "default" && (
                                <PDFDownloadLink
                                    document={
                                        <UpgradesTablePDF
                                            pdfHeaderText={pdfHeaderText}
                                            baseUi={baseUi}
                                            components={components}
                                            airtightness={airtightness}
                                            codes={codes}
                                            heatingCooling={heatingCooling}
                                            domesticHotWater={domesticHotWater}
                                            ventilation={ventilation}
                                            generation={generation}
                                            program={program}
                                            baseLoads={baseLoads}
                                            dimensions={dimensions}
                                            fuelPrices={fuelPrices}
                                            selectedUpgradesPackages={selectedUpgradesPackages}
                                            upgradePackages={upgradePackages}
                                            clientData={client}
                                            upgrades={upgrades}
                                            packageResults={packageResults}
                                            pageFormat={selectedFormat}
                                        />
                                    }
                                    fileName={`${modelName}_Pathways Report.pdf`}
                                >
                                    <Button
                                        icon={DownloadIcon.bind(this, {
                                            colour: "#ffffff",
                                        })}
                                        className={exportPdfButton}
                                        type={"default"}
                                        onClick={close}
                                        style={{ width: "fit-content" }}
                                        disabled={multiSelectOptions.length === 1 || isOutSync}
                                    >
                                        Export PDF
                                    </Button>
                                </PDFDownloadLink>
                            )
                        )}
                    </div>
                </div>
            </div>
            <Tooltip id={"exportBtnTip"} />
        </DrawerComponent>
    );
};

const mapStateToProps = createStructuredSelector({
    upgradePackages: selectUpgradePackagesSortedArray,
    packageResults: selectPackageResults,
    userPermissions: selectUserPermissions,
    uid: selectUserUid,
    modelData: selectModelData,
});

const mapDispatchToProps = (dispatch) => ({
    generatePdf: (formData, dataToSend) => dispatch(generatePdf(formData, dataToSend)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportPDFDrawer);
