import React from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Button";

import CommunitiesIcon from "assets/images/icons/JSX/CommunitiesIcon";

import classes from "./styles.module.scss";

const EmptyDashboard = ({ emptyText = "", currentFolderId = "" }) => {
    const history = useHistory();

    return (
        <div className={classes.emptyDashboardContainer}>
            <div className={classes.emptyDashboardWrapper}>
                <div className={classes.emptyDashboardTextWrapper}>
                    <CommunitiesIcon />
                    <h3 className={classes.emptyDashboardText}>{emptyText}</h3>
                </div>
                <Button
                    type="default"
                    onClick={() =>
                        history.push(
                            `/parametric-analysis/create${currentFolderId ? `?folderId=${currentFolderId}` : ""}`
                        )
                    }
                    style={{ marginTop: "1.5rem" }}
                >
                    Create Parametric Analysis
                </Button>
            </div>
        </div>
    );
};

export default EmptyDashboard;
