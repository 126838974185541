import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import CoolingHeatPumps from "./";

const mapStateToProps = ({ form }, { accessor, formName = "model", canBeEditedFromParametric }) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const { major: h2kMajor, minor: h2kMinor } = modelSelector({ form }, "modelData.h2kVersion") || {};

    const currentPrimarySystem = Object.keys(selector({ form }, `${accessor}.cooling.system`) || {})[0];
    const primarySystemType = selector({ form }, `${accessor}.cooling.selected.type`);
    const eStarManufacturerValue = selector({ form }, `${accessor}.cooling.selected.eStar.manufacturer`);
    const eStarModelId = selector({ form }, `${accessor}.cooling.selected.eStar.modelId`);

    const currentHeatingSystem = Object.keys(selector({ form }, `${accessor}.heating.system`) || {})[0];

    return {
        accessor,
        currentPrimarySystem,
        primarySystemType,
        eStarManufacturerValue,
        eStarModelId,
        modelUnits,
        h2kMinor,
        currentHeatingSystem,
        canBeEditedFromParametric,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CoolingHeatPumps);
