export const prebuiltEcms = {
    ceilingWithAttic: {
        ["upgradePre-2024120600000001-0"]: {
            label: "R40 Eff.",
            packageValues: {
                label: { ceilingWithAttic: "R40 Eff." },
                upgradeType: "ceiling",
                upgradeSubType: "ceilingWithAttic",
                upgradeProcess: "codeReplace",
                fields: {
                    ceilingInsType: {
                        codeRef: "Ceiling-S-2231490000",
                        codeLabel: "2231490000",
                    },
                    ceilingInsType_nomRVal: 7.04,
                    ceilingInsType_effRVal: 7.04,
                },
                codes: {
                    "Ceiling-S-2231490000": {
                        componentType: "Ceiling",
                        codeRef: "Ceiling-S-2231490000",
                        codeType: "Standard",
                        label: "2231490000",
                        value: "2231490000",
                        description: "Attic eff. R40",
                        nominalRValue: 7.04,
                        layers: {
                            structureType: {
                                id: 0,
                                value: "2",
                            },
                            componentTypeSize: {
                                id: 45,
                                value: "3",
                            },
                            spacing: {
                                id: 4,
                                value: "1",
                            },
                            framing: {
                                id: null,
                            },
                            insulationLayer1: {
                                id: 7,
                                value: "4",
                            },
                            insulationLayer2: {
                                id: 26,
                                value: "9",
                            },
                            interior: {
                                id: 0,
                                value: "0",
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000001-1"]: {
            label: "R50 Eff.",
            packageValues: {
                label: { ceilingWithAttic: "R50 Eff." },
                upgradeType: "ceiling",
                upgradeSubType: "ceilingWithAttic",
                upgradeProcess: "codeReplace",
                fields: {
                    ceilingInsType: {
                        codeRef: "Ceiling-S-2221a90000",
                        codeLabel: "Ceiling-S-2221a90000",
                    },
                    ceilingInsType_nomRVal: 8.81,
                    ceilingInsType_effRVal: 8.81,
                },
                codes: {
                    "Ceiling-S-2221a90000": {
                        componentType: "Ceiling",
                        codeRef: "Ceiling-S-2221a90000",
                        codeType: "Standard",
                        label: "2221a90000",
                        value: "2221a90000",
                        description: "Attic eff. R50",
                        nominalRValue: 9.86,
                        layers: {
                            structureType: {
                                id: 0,
                                value: "2",
                            },
                            componentTypeSize: {
                                id: 44,
                                value: "2",
                            },
                            spacing: {
                                id: 4,
                                value: "1",
                            },
                            framing: {
                                id: null,
                            },
                            insulationLayer1: {
                                id: 9,
                                value: "a",
                            },
                            insulationLayer2: {
                                id: 26,
                                value: "9",
                            },
                            interior: {
                                id: 0,
                                value: "0",
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000001-2"]: {
            label: "R60 Eff.",
            packageValues: {
                label: { ceilingWithAttic: "R60 Eff." },
                upgradeType: "ceiling",
                upgradeSubType: "ceilingWithAttic",
                upgradeProcess: "codeReplace",
                fields: {
                    ceilingInsType: {
                        codeRef: "Ceiling-S-2231c90000",
                        codeLabel: "2231c90000",
                    },
                    ceilingInsType_nomRVal: 10.57,
                    ceilingInsType_effRVal: 10.57,
                },
                codes: {
                    "Ceiling-S-2231c90000": {
                        componentType: "Ceiling",
                        codeRef: "Ceiling-S-2231c90000",
                        codeType: "Standard",
                        label: "2231c90000",
                        value: "2231c90000",
                        description: "Attic eff. R60",
                        nominalRValue: 10.57,
                        layers: {
                            structureType: {
                                id: 0,
                                value: "2",
                            },
                            componentTypeSize: {
                                id: 45,
                                value: "3",
                            },
                            spacing: {
                                id: 4,
                                value: "1",
                            },
                            framing: {
                                id: null,
                            },
                            insulationLayer1: {
                                id: 12,
                                value: "c",
                            },
                            insulationLayer2: {
                                id: 26,
                                value: "9",
                            },
                            interior: {
                                id: 0,
                                value: "0",
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000001-3"]: {
            label: "R70 Eff.",
            packageValues: {
                label: { ceilingWithAttic: "R70 Eff." },
                upgradeType: "ceiling",
                upgradeSubType: "ceilingWithAttic",
                upgradeProcess: "codeReplace",
                fields: {
                    ceilingInsType: {
                        codeRef: "Ceiling-S-2243J90000",
                        codeLabel: "2243J90000",
                    },
                    ceilingInsType_nomRVal: 12.33,
                    ceilingInsType_effRVal: 12.33,
                },
                codes: {
                    "Ceiling-S-2243J90000": {
                        componentType: "Ceiling",
                        codeRef: "Ceiling-S-2243J90000",
                        codeType: "Standard",
                        label: "2243J90000",
                        value: "2243J90000",
                        description: "Attic eff. R70",
                        nominalRValue: 12.33,
                        layers: {
                            structureType: {
                                id: 0,
                                value: "2",
                            },
                            componentTypeSize: {
                                id: 46,
                                value: "4",
                            },
                            spacing: {
                                id: 6,
                                value: "3",
                            },
                            framing: {
                                id: null,
                            },
                            insulationLayer1: {
                                id: 13,
                                value: "J",
                            },
                            insulationLayer2: {
                                id: 26,
                                value: "9",
                            },
                            interior: {
                                id: 0,
                                value: "0",
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000001-4"]: {
            label: "R80 Eff.",
            packageValues: {
                label: { ceilingWithAttic: "R80 Eff." },
                upgradeType: "ceiling",
                upgradeSubType: "ceilingWithAttic",
                upgradeProcess: "UserSpecified",
                fields: {
                    ceilingInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    ceilingInsType_nomRVal: 14.09,
                    ceilingInsType_effRVal: 14.09,
                },
            },
        },
    },
    cathedral: {},
    wall: {
        ["upgradePre-2024120600000003-0"]: {
            label: "R17 Eff.",
            packageValues: {
                label: { wall: "R17 Eff." },
                upgradeType: "wall",
                upgradeSubType: "wall",
                upgradeProcess: "UserSpecified",
                fields: {
                    wallInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    wallInsType_nomRVal: parseFloat((17 / 5.678).toFixed(2)),
                    wallInsType_effRVal: parseFloat((17 / 5.678).toFixed(2)),
                },
                floorHeaderAddedRSI: 0,
            },
        },
        ["upgradePre-2024120600000003-1"]: {
            label: "R21 Eff.",
            packageValues: {
                label: { wall: "R21 Eff." },
                upgradeType: "wall",
                upgradeSubType: "wall",
                upgradeProcess: "UserSpecified",
                fields: {
                    wallInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    wallInsType_nomRVal: parseFloat((21 / 5.678).toFixed(2)),
                    wallInsType_effRVal: parseFloat((21 / 5.678).toFixed(2)),
                },
                floorHeaderAddedRSI: 0,
            },
        },
        ["upgradePre-2024120600000003-2"]: {
            label: "R23.5 Eff.",
            packageValues: {
                label: { wall: "R23.5 Eff." },
                upgradeType: "wall",
                upgradeSubType: "wall",
                upgradeProcess: "UserSpecified",
                fields: {
                    wallInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    wallInsType_nomRVal: parseFloat((23.5 / 5.678).toFixed(2)),
                    wallInsType_effRVal: parseFloat((23.5 / 5.678).toFixed(2)),
                },
                floorHeaderAddedRSI: 0,
            },
        },
        ["upgradePre-2024120600000003-3"]: {
            label: "R26 Eff.",
            packageValues: {
                label: { wall: "R26 Eff." },
                upgradeType: "wall",
                upgradeSubType: "wall",
                upgradeProcess: "UserSpecified",
                fields: {
                    wallInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    wallInsType_nomRVal: parseFloat((26 / 5.678).toFixed(2)),
                    wallInsType_effRVal: parseFloat((26 / 5.678).toFixed(2)),
                },
                floorHeaderAddedRSI: 0,
            },
        },
        ["upgradePre-2024120600000003-4"]: {
            label: "R30 Eff.",
            packageValues: {
                label: { wall: "R30 Eff." },
                upgradeType: "wall",
                upgradeSubType: "wall",
                upgradeProcess: "UserSpecified",
                fields: {
                    wallInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    wallInsType_nomRVal: parseFloat((30 / 5.678).toFixed(2)),
                    wallInsType_effRVal: parseFloat((30 / 5.678).toFixed(2)),
                },
                floorHeaderAddedRSI: 0,
            },
        },
        ["upgradePre-2024120600000003-5"]: {
            label: "R40 Eff.",
            packageValues: {
                label: { wall: "R40 Eff." },
                upgradeType: "wall",
                upgradeSubType: "wall",
                upgradeProcess: "UserSpecified",
                fields: {
                    wallInsType: { codeRef: "UserSpecified", codeLabel: "User Specified" },
                    wallInsType_nomRVal: parseFloat((40 / 5.678).toFixed(2)),
                    wallInsType_effRVal: parseFloat((40 / 5.678).toFixed(2)),
                },
                floorHeaderAddedRSI: 0,
            },
        },
    },
    expFloor: {},
    floorHeader: {},
    basementWall: {
        ["upgradePre-2024120600000006-0"]: {
            label: "R15 Eff.",
            packageValues: {
                label: { basementWall: "R15 Eff." },
                upgradeType: "basement",
                upgradeSubType: "basementWall",
                upgradeProcess: "UserSpecified",
                fields: {
                    wall: {
                        extAddedInsType: { id: 10, value: 0 },
                        extAddedInsType_composite: {},
                        intAddedInsType: {
                            codeRef: "UserSpecified",
                            codeLabel: "User Specified",
                        },
                        intAddedInsType_effRVal: parseFloat((15 / 5.678).toFixed(2)),
                        intAddedInsType_nomRVal: parseFloat((15 / 5.678).toFixed(2)),
                        intAddedInsType_composite: {
                            section0: {
                                section: 1,
                                percentage: 100,
                                rsi: parseFloat((15 / 5.678).toFixed(2)),
                                nominalRsi: parseFloat((15 / 5.678).toFixed(2)),
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000006-1"]: {
            label: "R18.5 Eff.",
            packageValues: {
                label: { basementWall: "R18.5 Eff." },
                upgradeType: "basement",
                upgradeSubType: "basementWall",
                upgradeProcess: "UserSpecified",
                fields: {
                    wall: {
                        extAddedInsType: { id: 10, value: 0 },
                        extAddedInsType_composite: {},
                        intAddedInsType: {
                            codeRef: "UserSpecified",
                            codeLabel: "User Specified",
                        },
                        intAddedInsType_effRVal: parseFloat((18.5 / 5.678).toFixed(2)),
                        intAddedInsType_nomRVal: parseFloat((18.5 / 5.678).toFixed(2)),
                        intAddedInsType_composite: {
                            section0: {
                                section: 1,
                                percentage: 100,
                                rsi: parseFloat((18.5 / 5.678).toFixed(2)),
                                nominalRsi: parseFloat((18.5 / 5.678).toFixed(2)),
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000006-2"]: {
            label: "R21 Eff.",
            packageValues: {
                label: { basementWall: "R21 Eff." },
                upgradeType: "basement",
                upgradeSubType: "basementWall",
                upgradeProcess: "UserSpecified",
                fields: {
                    wall: {
                        extAddedInsType: { id: 10, value: 0 },
                        extAddedInsType_composite: {},
                        intAddedInsType: {
                            codeRef: "UserSpecified",
                            codeLabel: "User Specified",
                        },
                        intAddedInsType_effRVal: parseFloat((21 / 5.678).toFixed(2)),
                        intAddedInsType_nomRVal: parseFloat((21 / 5.678).toFixed(2)),
                        intAddedInsType_composite: {
                            section0: {
                                section: 1,
                                percentage: 100,
                                rsi: parseFloat((21 / 5.678).toFixed(2)),
                                nominalRsi: parseFloat((21 / 5.678).toFixed(2)),
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000006-3"]: {
            label: "R28 Eff.",
            packageValues: {
                label: { basementWall: "R28 Eff." },
                upgradeType: "basement",
                upgradeSubType: "basementWall",
                upgradeProcess: "UserSpecified",
                fields: {
                    wall: {
                        extAddedInsType: { id: 10, value: 0 },
                        extAddedInsType_composite: {},
                        intAddedInsType: {
                            codeRef: "UserSpecified",
                            codeLabel: "User Specified",
                        },
                        intAddedInsType_effRVal: parseFloat((28 / 5.678).toFixed(2)),
                        intAddedInsType_nomRVal: parseFloat((28 / 5.678).toFixed(2)),
                        intAddedInsType_composite: {
                            section0: {
                                section: 1,
                                percentage: 100,
                                rsi: parseFloat((28 / 5.678).toFixed(2)),
                                nominalRsi: parseFloat((28 / 5.678).toFixed(2)),
                            },
                        },
                    },
                },
            },
        },
    },
    basementSlab: {
        ["upgradePre-2024120600000007-0"]: {
            label: "R6 Eff.",
            packageValues: {
                label: { basementSlab: "R6 Eff." },
                upgradeType: "basement",
                upgradeSubType: "basementSlab",
                upgradeProcess: "UserSpecified",
                fields: {
                    floor: {
                        slabInsType: {
                            codeRef: "UserSpecified",
                            codeLabel: "User Specified",
                        },
                        slabInsType_nomRVal: parseFloat((6 / 5.678).toFixed(2)),
                        slabInsType_effRVal: parseFloat((6 / 5.678).toFixed(2)),
                        floorsAboveInsType: {
                            codeRef: "FloorsAbove-S-4231000660",
                            codeLabel: "4231000660",
                        },
                        floorsAboveInsType_nomRVal: 0.7,
                        floorsAboveInsType_effRVal: 0.7,
                    },
                },
                codes: {
                    "FloorsAbove-S-4231000660": {
                        codeRef: "FloorsAbove-S-4231000660",
                        label: "4231000660",
                        value: "4231000660",
                        description: "4231000660",
                        nominalRValue: 0.7,
                        layers: {
                            structureType: {
                                id: 0,
                                value: "2",
                            },
                            componentTypeSize: {
                                id: 45,
                                value: "3",
                            },
                            spacing: {
                                id: 4,
                                value: "1",
                            },
                            framing: {
                                id: null,
                                value: null,
                            },
                            insulationLayer1: {
                                id: 0,
                                value: "0",
                            },
                            insulationLayer2: {
                                id: 0,
                                value: "0",
                            },
                            interior: {
                                id: 0,
                                value: "0",
                            },
                            sheathing: {
                                id: 6,
                                value: "6",
                            },
                            exterior: {
                                id: 6,
                                value: "6",
                            },
                            dropFraming: {
                                id: 0,
                                value: "0",
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000007-1"]: {
            label: "R10 Eff.",
            packageValues: {
                label: { basementSlab: "R10 Eff." },
                upgradeType: "basement",
                upgradeSubType: "basementSlab",
                upgradeProcess: "UserSpecified",
                fields: {
                    floor: {
                        slabInsType: {
                            codeRef: "UserSpecified",
                            codeLabel: "User Specified",
                        },
                        slabInsType_nomRVal: 1.76,
                        slabInsType_effRVal: 1.76,
                        floorsAboveInsType: {
                            codeRef: "FloorsAbove-S-4231000660",
                            codeLabel: "4231000660",
                        },
                        floorsAboveInsType_nomRVal: 0.7,
                        floorsAboveInsType_effRVal: 0.7,
                    },
                },
                codes: {
                    "FloorsAbove-S-4231000660": {
                        codeRef: "FloorsAbove-S-4231000660",
                        label: "4231000660",
                        value: "4231000660",
                        description: "4231000660",
                        nominalRValue: 0.7,
                        layers: {
                            structureType: {
                                id: 0,
                                value: "2",
                            },
                            componentTypeSize: {
                                id: 45,
                                value: "3",
                            },
                            spacing: {
                                id: 4,
                                value: "1",
                            },
                            framing: {
                                id: null,
                                value: null,
                            },
                            insulationLayer1: {
                                id: 0,
                                value: "0",
                            },
                            insulationLayer2: {
                                id: 0,
                                value: "0",
                            },
                            interior: {
                                id: 0,
                                value: "0",
                            },
                            sheathing: {
                                id: 6,
                                value: "6",
                            },
                            exterior: {
                                id: 6,
                                value: "6",
                            },
                            dropFraming: {
                                id: 0,
                                value: "0",
                            },
                        },
                    },
                },
            },
        },
    },
    crawlspace: {},
    slab: {},
    window: {
        ["upgradePre-2024120600000010-0"]: {
            label: "Double U-1.6 SHGC-0.3",
            packageValues: {
                label: { window: "Double U-1.6 SHGC-0.3" },
                upgradeType: "window",
                upgradeSubType: "window",
                upgradeProcess: "codeReplace",
                fields: {
                    windowType: {
                        codeRef: "Window-U-2023083112000005",
                        codeLabel: "Double U-1.6 SHGC-0.3",
                    },
                    windowType_nomRVal: 0.625,
                    windowType_effRVal: 0.625,
                    shgc: 0.3,
                    er: 34,
                },
                codes: {
                    "Window-U-2023083112000005": {
                        componentType: "Window",
                        codeRef: "Window-U-2023083112000005",
                        codeType: "UserDefined",
                        label: "Double U-1.6 SHGC-0.3",
                        value: "",
                        description: "U-1.6 SHGC-0.3",
                        nominalRValue: 0.625,
                        er: 34,
                        shgc: 0.3,
                        layers: {
                            window: {
                                window2023083112000005: {
                                    fillGas: { id: 1 },
                                    frameHeight: 0,
                                    glazingType: { id: 3 },
                                    layer: 1,
                                    lowECoating: { id: 1 },
                                    overallThermalResistance: { id: 1, value: 1.6 },
                                    shgc: 0.3,
                                    windowStyle: { id: 2 },
                                },
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000010-1"]: {
            label: "Double U-1.6 SHGC-0.2",
            packageValues: {
                label: { window: "Double U-1.6 SHGC-0.2" },
                upgradeType: "window",
                upgradeSubType: "window",
                upgradeProcess: "codeReplace",
                fields: {
                    windowType: {
                        codeRef: "Window-U-2023083112000006",
                        codeLabel: "Double U-1.6 SHGC-0.2",
                    },
                    windowType_nomRVal: 0.625,
                    windowType_effRVal: 0.625,
                    shgc: 0.2,
                    er: 34,
                },
                codes: {
                    "Window-U-2023083112000006": {
                        componentType: "Window",
                        codeRef: "Window-U-2023083112000006",
                        codeType: "UserDefined",
                        label: "Double U-1.6 SHGC-0.2",
                        value: "",
                        description: "Double U-1.6 SHGC-0.2",
                        nominalRValue: 0.625,
                        er: 34,
                        shgc: 0.2,
                        layers: {
                            window: {
                                window2023083112000006: {
                                    fillGas: { id: 1 },
                                    frameHeight: 0,
                                    glazingType: { id: 3 },
                                    layer: 1,
                                    lowECoating: { id: 1 },
                                    overallThermalResistance: { id: 1, value: 1.6 },
                                    shgc: 0.2,
                                    windowStyle: { id: 2 },
                                },
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000010-2"]: {
            label: "Double U-1.2 SHGC-0.3",
            packageValues: {
                label: { window: "Double U-1.2 SHGC-0.3" },
                upgradeType: "window",
                upgradeSubType: "window",
                upgradeProcess: "codeReplace",
                fields: {
                    windowType: {
                        codeRef: "Window-U-2024052212000005",
                        codeLabel: "Double U-1.2 SHGC-0.3",
                    },
                    windowType_nomRVal: 0.833,
                    windowType_effRVal: 0.833,
                    shgc: 0.3,
                    er: 34,
                },
                codes: {
                    "Window-U-2024052212000005": {
                        componentType: "Window",
                        codeRef: "Window-U-2024052212000005",
                        codeType: "UserDefined",
                        label: "Double U-1.2 SHGC-0.3",
                        value: "",
                        description: "Double U-1.2 SHGC-0.3",
                        nominalRValue: 0.833,
                        er: 34,
                        shgc: 0.3,
                        layers: {
                            window: {
                                window2024052212000005: {
                                    fillGas: { id: 1 },
                                    frameHeight: 0,
                                    glazingType: { id: 3 },
                                    layer: 1,
                                    lowECoating: { id: 1 },
                                    overallThermalResistance: { id: 1, value: 1.2 },
                                    shgc: 0.3,
                                    windowStyle: { id: 2 },
                                },
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000010-3"]: {
            label: "Double U-1.2 SHGC-0.2",
            packageValues: {
                label: { window: "Double U-1.2 SHGC-0.2" },
                upgradeType: "window",
                upgradeSubType: "window",
                upgradeProcess: "codeReplace",
                fields: {
                    windowType: {
                        codeRef: "Window-U-2024052212000006",
                        codeLabel: "Double U-1.2 SHGC-0.2",
                    },
                    windowType_nomRVal: 0.833,
                    windowType_effRVal: 0.833,
                    shgc: 0.2,
                    er: 34,
                },
                codes: {
                    "Window-U-2024052212000006": {
                        componentType: "Window",
                        codeRef: "Window-U-2024052212000006",
                        codeType: "UserDefined",
                        label: "Double U-1.2 SHGC-0.2",
                        value: "",
                        description: "Double U-1.2 SHGC-0.2",
                        nominalRValue: 0.833,
                        er: 34,
                        shgc: 0.2,
                        layers: {
                            window: {
                                window2024052212000006: {
                                    fillGas: { id: 1 },
                                    frameHeight: 0,
                                    glazingType: { id: 3 },
                                    layer: 1,
                                    lowECoating: { id: 1 },
                                    overallThermalResistance: { id: 1, value: 1.2 },
                                    shgc: 0.2,
                                    windowStyle: { id: 2 },
                                },
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000010-4"]: {
            label: "Triple U-1.05 SHGC-0.3",
            packageValues: {
                label: { window: "Triple U-1.05 SHGC-0.3" },
                upgradeType: "window",
                upgradeSubType: "window",
                upgradeProcess: "codeReplace",
                fields: {
                    windowType: {
                        codeRef: "Window-U-2023083112000002",
                        codeLabel: "Triple U-1.05 SHGC-0.3",
                    },
                    windowType_nomRVal: 0.95,
                    windowType_effRVal: 0.95,
                    shgc: 0.3,
                    er: 34,
                },
                codes: {
                    "Window-U-2023083112000002": {
                        componentType: "Window",
                        codeRef: "Window-U-2023083112000002",
                        codeType: "UserDefined",
                        label: "Triple U-1.05 SHGC-0.3",
                        value: "",
                        description: "Triple U-1.05 SHGC-0.3",
                        nominalRValue: 0.95,
                        er: 34,
                        shgc: 0.3,
                        layers: {
                            window: {
                                window2023083112000002: {
                                    fillGas: { id: 1 },
                                    frameHeight: 0,
                                    glazingType: { id: 3 },
                                    layer: 1,
                                    lowECoating: { id: 1 },
                                    overallThermalResistance: { id: 1, value: 1.05 },
                                    shgc: 0.3,
                                    windowStyle: { id: 2 },
                                },
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000010-5"]: {
            label: "Triple U-1.05 SHGC-0.2",
            packageValues: {
                label: { window: "Triple U-1.05 SHGC-0.2" },
                upgradeType: "window",
                upgradeSubType: "window",
                upgradeProcess: "codeReplace",
                fields: {
                    windowType: {
                        codeRef: "Window-U-2023083112000001",
                        codeLabel: "Triple U-1.05 SHGC-0.2",
                    },
                    windowType_nomRVal: 0.95,
                    windowType_effRVal: 0.95,
                    shgc: 0.2,
                    er: 34,
                },
                codes: {
                    "Window-U-2023083112000001": {
                        componentType: "Window",
                        codeRef: "Window-U-2023083112000001",
                        codeType: "UserDefined",
                        label: "Triple U-1.05 SHGC-0.2",
                        value: "",
                        description: "Triple U-1.05 SHGC-0.2",
                        nominalRValue: 0.95,
                        er: 34,
                        shgc: 0.2,
                        layers: {
                            window: {
                                window2023083112000001: {
                                    fillGas: { id: 1 },
                                    frameHeight: 0,
                                    glazingType: { id: 3 },
                                    layer: 1,
                                    lowECoating: { id: 1 },
                                    overallThermalResistance: { id: 1, value: 1.05 },
                                    shgc: 0.2,
                                    windowStyle: { id: 2 },
                                },
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000010-6"]: {
            label: "Triple U-0.80 SHGC-0.3",
            packageValues: {
                label: { window: "Triple U-0.80 SHGC-0.3" },
                upgradeType: "window",
                upgradeSubType: "window",
                upgradeProcess: "codeReplace",
                fields: {
                    windowType: {
                        codeRef: "Window-U-2023083112000004",
                        codeLabel: "Triple U-0.80 SHGC-0.3",
                    },
                    windowType_nomRVal: 1.25,
                    windowType_effRVal: 1.25,
                    shgc: 0.3,
                    er: 34,
                },
                codes: {
                    "Window-U-2023083112000004": {
                        componentType: "Window",
                        codeRef: "Window-U-2023083112000004",
                        codeType: "UserDefined",
                        label: "Triple U-0.80 SHGC-0.3",
                        value: "",
                        description: "Triple U-0.80 SHGC-0.3",
                        nominalRValue: 1.25,
                        er: 34,
                        shgc: 0.3,
                        layers: {
                            window: {
                                window2023083112000004: {
                                    fillGas: { id: 1 },
                                    frameHeight: 0,
                                    glazingType: { id: 3 },
                                    layer: 1,
                                    lowECoating: { id: 1 },
                                    overallThermalResistance: { id: 1, value: 0.8 },
                                    shgc: 0.3,
                                    windowStyle: { id: 2 },
                                },
                            },
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000010-7"]: {
            label: "Triple U-0.80 SHGC-0.2",
            packageValues: {
                label: { window: "Triple U-0.80 SHGC-0.2" },
                upgradeType: "window",
                upgradeSubType: "window",
                upgradeProcess: "codeReplace",
                fields: {
                    windowType: {
                        codeRef: "Window-U-2023083112000003",
                        codeLabel: "Triple U-0.80 SHGC-0.2",
                    },
                    windowType_nomRVal: 1.25,
                    windowType_effRVal: 1.25,
                    shgc: 0.2,
                    er: 34,
                },
                codes: {
                    "Window-U-2023083112000003": {
                        componentType: "Window",
                        codeRef: "Window-U-2023083112000003",
                        codeType: "UserDefined",
                        label: "Triple U-0.80 SHGC-0.2",
                        value: "",
                        description: "Triple U-0.80 SHGC-0.2",
                        nominalRValue: 1.25,
                        er: 34,
                        shgc: 0.2,
                        layers: {
                            window: {
                                window2023083112000003: {
                                    fillGas: { id: 1 },
                                    frameHeight: 0,
                                    glazingType: { id: 3 },
                                    layer: 1,
                                    lowECoating: { id: 1 },
                                    overallThermalResistance: { id: 1, value: 0.8 },
                                    shgc: 0.2,
                                    windowStyle: { id: 2 },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    skylights: {},
    door: {},
    airTightness: {
        ["upgradePre-2024120600000013-0"]: {
            label: "25% Reduction",
            noEdit: true,
            packageValues: {
                label: { window: "25% Reduction" },
                upgradeType: "airTightness",
                upgradeSubType: "airTightness",
                upgradeProcess: "achPercent",
                fields: {
                    testType: {
                        id: 0,
                    },
                    blowerTest: {
                        airChangeRate50Pa: 0.25, //As the % reduction
                        eqLeakageArea: 0.25,
                        isCgsbTest: false,
                        isEqLeakageAreaCalculated: true,
                        guarded: false,
                        pressure: {
                            id: null,
                        },
                    },
                    buildingSiteTerrain: {
                        id: 6,
                    },
                    localShieldingWalls: {
                        id: 2,
                    },
                    localShieldingFlue: {
                        id: 1,
                    },
                    exhaustDevicesTest: {
                        id: 0,
                        result: null,
                    },
                    commonSurfaces: {
                        floors: 0,
                        walls: 0,
                        ceilings: 0,
                        total: 0,
                    },
                    other: {
                        weatherStationTerrain: {
                            id: 2,
                        },
                        anemometerHeight: 10,
                        leakageFractions: {
                            useDefaults: true,
                            ceilings: 0,
                            walls: 0,
                            floors: 0,
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000013-1"]: {
            label: "50% Reduction",
            noEdit: true,
            packageValues: {
                label: { window: "50% Reduction" },
                upgradeType: "airTightness",
                upgradeSubType: "airTightness",
                upgradeProcess: "achPercent",
                fields: {
                    testType: {
                        id: 0,
                    },
                    blowerTest: {
                        airChangeRate50Pa: 0.5, //As the % reduction
                        eqLeakageArea: 0.5,
                        isCgsbTest: false,
                        isEqLeakageAreaCalculated: true,
                        guarded: false,
                        pressure: {
                            id: null,
                        },
                    },
                    buildingSiteTerrain: {
                        id: 6,
                    },
                    localShieldingWalls: {
                        id: 2,
                    },
                    localShieldingFlue: {
                        id: 1,
                    },
                    exhaustDevicesTest: {
                        id: 0,
                        result: null,
                    },
                    commonSurfaces: {
                        floors: 0,
                        walls: 0,
                        ceilings: 0,
                        total: 0,
                    },
                    other: {
                        weatherStationTerrain: {
                            id: 2,
                        },
                        anemometerHeight: 10,
                        leakageFractions: {
                            useDefaults: true,
                            ceilings: 0,
                            walls: 0,
                            floors: 0,
                        },
                    },
                },
            },
        },
        ["upgradePre-2024120600000013-2"]: {
            label: "75% Reduction",
            noEdit: true,
            packageValues: {
                label: { window: "75% Reduction" },
                upgradeType: "airTightness",
                upgradeSubType: "airTightness",
                upgradeProcess: "achPercent",
                fields: {
                    testType: {
                        id: 0,
                    },
                    blowerTest: {
                        airChangeRate50Pa: 0.75, //As the % reduction
                        eqLeakageArea: 0.75,
                        isCgsbTest: false,
                        isEqLeakageAreaCalculated: true,
                        guarded: false,
                        pressure: {
                            id: null,
                        },
                    },
                    buildingSiteTerrain: {
                        id: 6,
                    },
                    localShieldingWalls: {
                        id: 2,
                    },
                    localShieldingFlue: {
                        id: 1,
                    },
                    exhaustDevicesTest: {
                        id: 0,
                        result: null,
                    },
                    commonSurfaces: {
                        floors: 0,
                        walls: 0,
                        ceilings: 0,
                        total: 0,
                    },
                    other: {
                        weatherStationTerrain: {
                            id: 2,
                        },
                        anemometerHeight: 10,
                        leakageFractions: {
                            useDefaults: true,
                            ceilings: 0,
                            walls: 0,
                            floors: 0,
                        },
                    },
                },
            },
        },
    },
    ventilation: {},
    spaceHeating: {},
    secondaryHeating: {},
    spaceCooling: {},
    primaryHotWater: {},
    dwhr: {},
    generation: {},
    baseLoads: {},
};
