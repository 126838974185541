import { cloneDeep } from "lodash";

import types from "./types";

const initialState = {
    parametricAnalysis: null,
    parametricAnalysisToCompare: null,
    isParametricAnalysisLoading: true,
    isParametricAnalysisCreating: false,
    isSavingEcmInputMap: false,
    fetchParametricAnalysisError: null,
};

const parametricAnalysisReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_PARAMETRIC_ANALYSIS_START:
            return { ...state, isParametricAnalysisCreating: true, fetchParametricAnalysisError: null };
        case types.CREATE_PARAMETRIC_ANALYSIS_SUCCESS:
            return { ...state, isParametricAnalysisCreating: false };
        case types.CREATE_PARAMETRIC_ANALYSIS_ERROR:
            return { ...state, isParametricAnalysisCreating: false };
        case types.FETCH_PARAMETRIC_ANALYSIS_START:
            return { ...state, isParametricAnalysisLoading: true, fetchParametricAnalysisError: null };
        case types.SYNC_PARAMETRIC_ANALYSIS_SUCCESS:
            return {
                ...state,
                parametricAnalysis: {
                    ...state.parametricAnalysis,
                    ...action.payload,
                },
                parametricAnalysisToCompare: cloneDeep({ ...state.parametricAnalysis, ...action.payload }),
                isParametricAnalysisLoading: false,
                fetchParametricAnalysisError: null,
            };
        case types.FETCH_PARAMETRIC_ANALYSIS_SUCCESS:
            return {
                ...state,
                parametricAnalysis: {
                    ...state.parametricAnalysis,
                    ...action.payload,
                },
                parametricAnalysisToCompare: cloneDeep({ ...state.parametricAnalysis, ...action.payload }),
                isParametricAnalysisLoading: false,
                fetchParametricAnalysisError: null,
            };
        case types.FETCH_PARAMETRIC_ANALYSIS_ERROR:
            return {
                ...state,
                isParametricAnalysisLoading: false,
                fetchParametricAnalysisError: action.payload,
                parametricAnalysis: null,
            };
        case types.SAVE_ECM_INPUT_MAP_START:
            return { ...state, isSavingEcmInputMap: true };
        case types.SAVE_ECM_INPUT_MAP_SUCCESS:
            return {
                ...state,
                parametricAnalysisToCompare: cloneDeep(state.parametricAnalysis),
                isSavingEcmInputMap: false,
            };
        case types.SAVE_ECM_INPUT_MAP_ERROR:
            return { ...state, isSavingEcmInputMap: false };
        case types.CLEAR_PARAMETRIC_ANALYSIS:
            return { ...initialState };
        case types.ADD_UPGRADE_TO_PARAMETRIC_ANALYSIS:
            return {
                ...state,
                parametricAnalysis: {
                    ...state.parametricAnalysis,
                    ecmInputMap: {
                        ...state.parametricAnalysis.ecmInputMap,
                        [action.payload.sectionType]: {
                            ...state.parametricAnalysis.ecmInputMap[action.payload.sectionType],
                            ...action.payload.upgrade,
                        },
                    },
                },
            };
        case types.TOGGLE_UPGRADE_IN_PARAMETRIC_ANALYSIS:
            return {
                ...state,
                parametricAnalysis: {
                    ...state.parametricAnalysis,
                    ecmInputMap: {
                        ...state.parametricAnalysis.ecmInputMap,
                        [action.payload.sectionType]: {
                            ...state.parametricAnalysis.ecmInputMap[action.payload.sectionType],
                            [action.payload.upgradeId]: {
                                ...state.parametricAnalysis.ecmInputMap[action.payload.sectionType][
                                    action.payload.upgradeId
                                ],
                                active: action.payload.toggleValue,
                            },
                        },
                    },
                },
            };
        case types.REMOVE_UPGRADE_FROM_PARAMETRIC_ANALYSIS:
            delete state.parametricAnalysis.ecmInputMap[action.payload.sectionType][action.payload.upgradeId];

            // Otherwise, the object reference doesn't change, and the component doesn't re-render
            const ecmInputMap = { ...state.parametricAnalysis.ecmInputMap };

            return {
                ...state,
                parametricAnalysis: {
                    ...state.parametricAnalysis,
                    ecmInputMap,
                },
            };
        case types.UPDATE_ECM_INPUT_MAP:
            return {
                ...state,
                parametricAnalysisToCompare: {
                    ...state.parametricAnalysisToCompare,
                    ecmInputMap: cloneDeep(action.payload),
                },
                parametricAnalysis: {
                    ...state.parametricAnalysis,
                    ecmInputMap: action.payload,
                },
            };
        case types.UPDATE_ECM_INPUT_MAP_UPGRADE:
            const fullUpgrade =
                state.parametricAnalysis.ecmInputMap[action.payload.sectionType]?.[action.payload.upgradeId] || {};

            const { firstBatchId } = fullUpgrade;

            let passedAndIndividualSavings = {
                passed: fullUpgrade?.passed === false ? false : null,
                individualSavings: null,
            };

            if (firstBatchId) {
                passedAndIndividualSavings = {
                    passed: fullUpgrade?.passed || null,
                    individualSavings: fullUpgrade?.individualSavings || null,
                };
            }

            return {
                ...state,
                parametricAnalysis: {
                    ...state.parametricAnalysis,
                    ecmInputMap: {
                        ...state.parametricAnalysis.ecmInputMap,
                        [action.payload.sectionType]: {
                            ...state.parametricAnalysis.ecmInputMap[action.payload.sectionType],
                            [action.payload.upgradeId]: {
                                ...(state.parametricAnalysis.ecmInputMap[action.payload.sectionType]?.[
                                    action.payload.upgradeId
                                ] || {}),
                                label: action.payload.upgrade?.label
                                    ? Object.values(action.payload.upgrade.label)[0]
                                    : "",
                                ...passedAndIndividualSavings,
                                packageValues: {
                                    ...(state.parametricAnalysis.ecmInputMap[action.payload.sectionType]?.[
                                        action.payload.upgradeId
                                    ]?.packageValues || {}),
                                    ...action.payload.upgrade,
                                },
                            },
                        },
                    },
                },
            };
        case types.UPDATE_VALIDATION_PRE_CHECK_RUN_STATUS:
            return {
                ...state,
                parametricAnalysis: {
                    ...state.parametricAnalysis,
                    parametricDetails: {
                        ...state.parametricAnalysis.parametricDetails,
                        ...action.payload,
                    },
                },
                parametricAnalysisToCompare: {
                    ...state.parametricAnalysisToCompare,
                    parametricDetails: {
                        ...state.parametricAnalysisToCompare.parametricDetails,
                        ...action.payload,
                    },
                },
            };
        case types.DUPLICATE_PARAMETRIC_ANALYSIS_START:
            return { ...state, isParametricAnalysisLoading: true };
        case types.DUPLICATE_PARAMETRIC_ANALYSIS_SUCCESS:
            return { ...state, isParametricAnalysisLoading: false };
        case types.DUPLICATE_PARAMETRIC_ANALYSIS_ERROR:
            return { ...state, isParametricAnalysisLoading: false };
        case types.UPDATE_RUN_RESULTS:
            return {
                ...state,
                parametricAnalysis: {
                    ...state.parametricAnalysis,
                    runResults: action.payload,
                },
                parametricAnalysisToCompare: {
                    ...state.parametricAnalysisToCompare,
                    runResults: action.payload,
                },
            };
        case types.PIN_UNPIN_RECIPES_START:
            return { ...state };
        case types.PIN_UNPIN_RECIPES_SUCCESS:
            return {
                ...state,
                parametricAnalysis: {
                    ...state.parametricAnalysis,
                    parametricDetails: { ...state.parametricAnalysis.parametricDetails, pinnedRecipes: action.payload },
                },
                parametricAnalysisToCompare: {
                    ...state.parametricAnalysisToCompare,
                    parametricDetails: { ...state.parametricAnalysis.parametricDetails, pinnedRecipes: action.payload },
                },
            };
        case types.PIN_UNPIN_RECIPES_ERROR:
            return { ...state };
        default:
            return state;
    }
};

export default parametricAnalysisReducer;
