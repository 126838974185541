import React from "react";

import {
    getUpgradeEnvelopeRowLabels,
    getUpgradeHvacRowLabels,
    getUpgradeDhwRowLabels,
    getUpgradeOtherRowLabels,
    envelopeRowHeadings,
    hvacRowHeadings,
    dhwRowHeadings,
    otherRowHeadings,
    financingRowHeadings,
    getUpgradeFinancingRowsLabels,
} from "utils/upgrades";

import Table from "components/Table";

import classes from "./styles.module.scss";

const buildRow = ({ id, value, content, columns = {}, className }) => {
    return {
        id,
        house: {
            value,
            content: (
                <>
                    <strong>{content}</strong>{" "}
                </>
            ),
            className,
            // verticalAlign: "top",
        },
        ...columns,
    };
};

const baseDefault = {
    value: "base",
    content: "Base Value",
    upgradeSet: true,
    className: `${classes.regularCell} ${classes.baseCell}`,
};

const rowFilter = ({ id }) => true;

const ModelTable = ({
    components = {},
    airTightness = {},
    codes = {},
    heatingCooling = {},
    domesticHotWater = {},
    ventilation = {},
    generation = {},
    program = {},
    baseLoads = {},
}) => {
    return (
        <div style={{ border: "1px solid #e1eaf0", borderRadius: "4px" }}>
            <Table
                className={classes.upgradesTable}
                headClassName={classes.upgradesTableHead}
                headCells={[
                    {
                        id: "house",
                        label: "House Components",
                        align: "left",
                        className: `${classes.headCell}`,
                        width: "250",
                    },
                    {
                        id: "base",
                        label: "Base",
                        align: "left",
                        className: `${classes.headCell} ${classes.upgradeCell} ${classes.baseCell}`,
                        width: "250",
                    },
                ]}
                rows={[
                    {
                        house: {
                            id: "envelope",
                            value: "envelope",
                            content: (
                                <div>
                                    <strong>ENVELOPE</strong>
                                </div>
                            ),
                            className: `${classes.dividerCell}`,
                        },
                        base: {
                            value: "",
                            className: `${classes.dividerCell}`,
                        },
                    },
                    ...envelopeRowHeadings.filter(rowFilter).map((obj) =>
                        buildRow({
                            ...obj,
                            columns: {
                                base: {
                                    ...baseDefault,
                                    content: getUpgradeEnvelopeRowLabels({
                                        components,
                                        codes,
                                        airtightness: airTightness,
                                        rowId: obj.id,
                                    })?.label,
                                    className: `${classes.regularCell} ${classes.baseCell}`,
                                },
                            },
                            className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell}`,
                        })
                    ),
                    {
                        house: {
                            id: "hvac",
                            value: "hvac",
                            content: <strong>HVAC</strong>,
                            className: `${classes.dividerCell}`,
                        },
                        base: {
                            value: "",
                            className: `${classes.dividerCell}`,
                        },
                    },
                    ...hvacRowHeadings.filter(rowFilter).map((obj) =>
                        buildRow({
                            ...obj,
                            columns: {
                                base: {
                                    ...baseDefault,
                                    content: getUpgradeHvacRowLabels({
                                        heatingCooling,
                                        ventilation,
                                        rowId: obj.id,
                                    })?.label,
                                    className: `${classes.regularCell} ${classes.baseCell}`,
                                    // verticalAlign: "top",
                                },
                            },
                            className: `${classes.regularCell} ${classes.flexCell}`,
                        })
                    ),
                    {
                        house: {
                            id: "dhw",
                            value: "dhw",
                            content: <strong>DHW</strong>,
                            className: `${classes.dividerCell} ${classes.freezeCell}`,
                        },
                        base: {
                            value: "",
                            className: `${classes.dividerCell}`,
                        },
                    },
                    ...dhwRowHeadings.filter(rowFilter).map((obj) =>
                        buildRow({
                            ...obj,
                            columns: {
                                base: {
                                    ...baseDefault,
                                    content: getUpgradeDhwRowLabels({
                                        domesticHotWater,
                                        heatingCooling,
                                        rowId: obj.id,
                                    })?.label,
                                    className: `${classes.regularCell} ${classes.baseCell}`,
                                    // verticalAlign: "top",
                                },
                            },
                            className: `${classes.regularCell}  ${classes.flexCell}`,
                        })
                    ),
                    {
                        house: {
                            id: "other",
                            value: "other",
                            content: <strong>OTHER</strong>,
                            className: `${classes.dividerCell} ${classes.freezeCell}`,
                        },
                        base: {
                            value: "",
                            className: `${classes.dividerCell}`,
                        },
                    },
                    ...otherRowHeadings.filter(rowFilter).map((obj) =>
                        buildRow({
                            ...obj,
                            columns: {
                                base: {
                                    ...baseDefault,
                                    content: getUpgradeOtherRowLabels({
                                        generation,
                                        program,
                                        baseLoads,
                                        rowId: obj.id,
                                    })?.label,
                                    className: `${classes.regularCell} ${classes.baseCell}`,
                                    // verticalAlign: "top",
                                },
                            },
                            className: `${classes.regularCell} ${classes.flexCell}`,
                        })
                    ),
                    ...financingRowHeadings
                        .filter(({ id }) => id === "totalCapitalCost")
                        .map((obj) =>
                            buildRow({
                                ...obj,
                                columns: {
                                    base: {
                                        ...baseDefault,
                                        content: getUpgradeFinancingRowsLabels({
                                            rowId: obj.id,
                                        })?.label,
                                        className: `${classes.regularCell} ${classes.baseCell}`,
                                        // verticalAlign: "top",
                                    },
                                },
                                className: `${classes.regularCell} ${classes.freezeCell} ${classes.flexCell}`,
                            })
                        ),
                ]}
            />
        </div>
    );
};

export default ModelTable;
