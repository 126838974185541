import React, { useState, useEffect, useCallback } from "react";
import classes from "./style.module.scss";
import globalStyles from "components/globalStyles.module.scss";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import InputWithUnits from "components/Input/InputWithUnits";
import { required, requiredCodeRef, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import Select from "components/Input/Select";
import Add from "assets/images/icons/JSX/Add";
import Book from "assets/images/icons/JSX/Book";
import Edit from "assets/images/icons/JSX/Edit";
import { getBaseUnits, getUnitOptions } from "utils/fields";
import Tooltip from "components/Tooltip";
import Checkbox from "components/Input/Checkbox";
import { isEmpty, isEqual } from "lodash";

//Note that pony walls use the crawlspace wall construction type, which is identical to a standard wall
const ponyWallTypeValidation = [required, ...getValidation("basementPonyWallType")];
const ponyHeightValidation = getValidation("basementPonyWallHeight");

const CodeFooter = ({ toggleDrawer, setInitCode, codeType }) => (
    <div
        className={classes.codeFooter}
        onClick={async () => {
            await setInitCode({}, codeType);
            toggleDrawer(true);
        }}
    >
        <span>Create New Code</span>
        <Add />
    </div>
);

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

const LabelActionComponent = ({ codeRef, handleEdit, codeType }) => (
    <>
        <div
            className={globalStyles.editCode}
            data-tip="Edit Code"
            data-for={codeRef}
            onClick={(event) => handleEdit(event, codeRef, codeType)}
        >
            <Edit />
            <Tooltip id={codeRef} className={globalStyles.fieldTooltip} place="left" />
        </div>
    </>
);

// CrawlspaceWall Type codes found in code library
const crawlWallTypeLibCodesMap = (libCodes, handleEditLibCrawlWallCode) =>
    Object.keys(libCodes)
        .map((codeRef) => {
            const { label = "" } = libCodes[codeRef] || {};
            const [standardCode] = codeRef.split("CrawlspaceWall-U");

            return {
                label: label,
                labelIcon: Book,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () => (
                    <LabelActionComponent
                        codeRef={codeRef}
                        handleEdit={handleEditLibCrawlWallCode}
                        codeType={standardCode ? "S" : "U"}
                    />
                ),
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Crawlspace wall (pony wall) Type codes found in model, filtered by those in library
const crawlWallTypeModelCodesMap = (modelCodes, libCodes, handleEditModelCrawlWallCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCodes[codeRef]) {
                return (
                    (!libCodes[codeRef] && component === "CrawlspaceWall") ||
                    !isEqual(modelCodes[codeRef].layers, libCodes[codeRef].layers)
                );
            }
            return !libCodes[codeRef] && component === "CrawlspaceWall";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("CrawlspaceWall-U");

            return {
                label,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () => (
                    <LabelActionComponent
                        codeRef={codeRef}
                        handleEdit={handleEditModelCrawlWallCode}
                        codeType={standardCode ? "S" : "U"}
                    />
                ),
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

export default React.memo(
    ({
        accessor,
        selectedCodeRef,
        change,
        modelChange = change,
        id,
        modelUnits,
        rValUnits,
        ponyWallHeightUnits,
        libCodes,
        modelCodes,
        selectedNomRValue,
        selectedEffRValue,
        setInitCode,
        isEnabled,
        ponyWallHeightDrawing,
        ponyWallCodeWarning,
        isCalculatingRValue,
        updateCodeDrawer,
        ponyHeightWarning,
        ponyWallSubCompAreaWarning,
        compositePonyEffRValue,
        updateCompositeCalc,
        isUpgrade = false,
        formName,
        ponyWallHeightEquation,
        canBeEditedFromParametric = true,
    }) => {
        const handleTypeChange = useCallback(
            ({ codeRef = "" }) => {
                if (codeRef === "UserSpecified") {
                    change(`${accessor}.wall.ponyWall.ponyWallInsType_warning`, "");
                    change(`${accessor}.wall.ponyWall.ponyWallInsType_composite`, {});
                    return;
                }

                const modelMatch = modelCodes[codeRef] || {};
                if (!isEmpty(modelMatch)) {
                    change(`${accessor}.wall.ponyWall.ponyWallInsType_nomRVal`, modelMatch.nominalRValue || 0);
                    change(`${accessor}.wall.ponyWall.ponyWallInsType_effRVal`, modelMatch.nominalRValue || 0);
                    change(`${accessor}.wall.ponyWall.ponyWallInsType_warning`, modelMatch.warningType || "");
                } else {
                    const { nominalRValue = 0, warningType = "" } = libCodes[codeRef] || {};
                    change(`${accessor}.wall.ponyWall.ponyWallInsType_nomRVal`, nominalRValue || 0);
                    change(`${accessor}.wall.ponyWall.ponyWallInsType_effRVal`, nominalRValue || 0);
                    change(`${accessor}.wall.ponyWall.ponyWallInsType_warning`, warningType || "");

                    modelChange(`modelData.codes.${codeRef}`, { ...libCodes[codeRef], codeRef });
                }
                change(`${accessor}.wall.ponyWallInsType_composite`, {});

                //Redundancy
                modelChange(`stashedCodes.CrawlspaceWall.${accessor.split(".").slice(-1)[0]}`, codeRef);
            },
            [libCodes, modelCodes, change, modelChange, accessor]
        );

        // useEffect(() => {
        //     if ((selectedCodeRef || "").includes("CrawlspaceWall-")) {
        //         modelChange(`stashedCodes.CrawlspaceWall.${id}`, selectedCodeRef);
        //     }
        // }, [id, selectedCodeRef]);

        const handleEditModelCrawlWallCode = useCallback(
            async (event, codeRef, codeType) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const initCodeType = codeType === "U" ? "udefStandard" : "ponyWallInsType";
                const code = modelCodes[codeRef];
                await setInitCode(code, initCodeType);
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "Pony Wall",
                    isEditing: true,
                    componentType: "PonyWall",
                    codeType: codeType,
                    fieldAccessor: `${accessor}.wall.ponyWall.ponyWallInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibCrawlWallCode = useCallback(
            async (event, codeRef, codeType) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const initCodeType = codeType === "U" ? "udefStandard" : "ponyWallInsType";
                const code = { ...libCodes[codeRef], codeRef: codeRef, isLibCode: true };

                await setInitCode(code, initCodeType);
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "Pony Wall",
                    isEditing: true,
                    componentType: "PonyWall",
                    codeType: codeType,
                    fieldAccessor: `${accessor}.wall.ponyWall.ponyWallInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, libCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const gridTemplate = isUpgrade ? "2fr 1fr 2fr" : "31.85% 31.85% 23.25%";

        return (
            <>
                {!isUpgrade && (
                    <>
                        <h5 style={{ marginBottom: "0.5rem" }}>Pony Wall</h5>
                        <InputRow gridTemplate="1fr 1fr 1fr">
                            <Field
                                component={Checkbox}
                                name={`${accessor}.wall.ponyWall.enabled`}
                                label="Enable Pony Wall"
                                type="checkbox"
                                large
                                className={classes.ponyWallEnabled}
                                disabled={!isEnabled}
                            />
                        </InputRow>
                    </>
                )}
                {isEnabled ? (
                    <>
                        <InputRow gridTemplate={gridTemplate}>
                            {!isUpgrade && (
                                <Field
                                    component={InputWithUnits}
                                    type="number"
                                    name={`${accessor}.wall.ponyWall.ponyWallHeight`}
                                    label="Pony Wall Height"
                                    placeholder="Enter Value"
                                    validate={ponyHeightValidation}
                                    decimals={getDecimalPlaces("basementPonyWallHeight")}
                                    change={change}
                                    isDrawing={ponyWallHeightDrawing}
                                    units={{
                                        base: getBaseUnits("basementPonyWallHeight", modelUnits),
                                        options: getUnitOptions("length"),
                                        selected: ponyWallHeightUnits,
                                        unitType: "length",
                                        accessor: `${accessor}.wall.ponyWall.ponyWallHeight_u`,
                                    }}
                                    disabled={!isEnabled || ponyWallHeightDrawing || !canBeEditedFromParametric}
                                    withEquation
                                    equation={ponyWallHeightEquation}
                                    warning={ponyHeightWarning || ponyWallSubCompAreaWarning}
                                />
                            )}
                            <Field
                                component={Select}
                                type="number"
                                name={`${accessor}.wall.ponyWall.ponyWallInsType`}
                                options={[
                                    {
                                        label: "User Specified",
                                        value: {
                                            codeLabel: "User Specified",
                                            codeRef: "UserSpecified",
                                        },
                                    },
                                    ...crawlWallTypeModelCodesMap(modelCodes, libCodes, handleEditModelCrawlWallCode),
                                    ...crawlWallTypeLibCodesMap(libCodes, handleEditLibCrawlWallCode),
                                ]}
                                label="Pony Wall Type"
                                placeholder="Choose Pony Wall Type"
                                searchPlaceholder="Search Pony Wall Codes"
                                search={true}
                                footer={() => (
                                    <CodeFooter
                                        toggleDrawer={(value) =>
                                            updateCodeDrawer({
                                                isOpen: value,
                                                codeName: "Pony Wall",
                                                isEditing: false,
                                                componentType: "PonyWall",
                                                codeType: "S",
                                                fieldAccessor: `${accessor}.wall.ponyWall.ponyWallInsType`,
                                                componentId: id,
                                                modelFormChange: modelChange,
                                                currentFormChange: change,
                                            })
                                        }
                                        setInitCode={setInitCode}
                                        codeType="ponyWallInsType"
                                    />
                                )}
                                className={classes.ponyWallType}
                                disabled={!isEnabled || !canBeEditedFromParametric}
                                isLoading={isCalculatingRValue}
                                onChange={handleTypeChange}
                                validate={requiredCodeRef}
                            />
                            {selectedCodeRef === "UserSpecified" ? (
                                <Field
                                    component={InputWithUnits}
                                    type="number"
                                    name={`${accessor}.wall.ponyWall.ponyWallInsType_effRVal`}
                                    label={"Effective R-Value"}
                                    placeholder={0.0}
                                    disabled={selectedCodeRef !== "UserSpecified" || !canBeEditedFromParametric}
                                    setValue={selectedEffRValue}
                                    validate={ponyWallTypeValidation}
                                    setTouched={true}
                                    decimals={getDecimalPlaces("basementPonyWallType")}
                                    change={change}
                                    units={{
                                        base: getBaseUnits("basementPonyWallType", modelUnits),
                                        options: getUnitOptions("thermalResistance"),
                                        selected: rValUnits,
                                        unitType: "thermalResistance",
                                        accessor: `${accessor}.wall.ponyWall.ponyWallInsType_u`,
                                    }}
                                    onChange={(value) => {
                                        if (selectedCodeRef === "UserSpecified") {
                                            change(`${accessor}.wall.ponyWall.ponyWallInsType_nomRVal`, value);
                                        }
                                        if (value !== compositePonyEffRValue) {
                                            change(`${accessor}.wall.ponyWall.ponyWallInsType_composite`, {});
                                        }
                                    }}
                                    // info={"User specified values are assumed to be effective R-values."}
                                    editToggle={() =>
                                        updateCompositeCalc({
                                            open: true,
                                            fieldId: "basementPonyWallType",
                                            fieldAccessor: `${accessor}.wall.ponyWall.ponyWallInsType`,
                                            rValUnits,
                                            currentFormChange: change,
                                            formName,
                                        })
                                    }
                                />
                            ) : (
                                <Field
                                    component={InputWithUnits}
                                    type="number"
                                    name={`${accessor}.wall.ponyWall.ponyWallInsType_nomRVal`}
                                    label={"Nominal R-Value"}
                                    placeholder={0.0}
                                    disabled={true}
                                    validate={ponyWallTypeValidation}
                                    setTouched={true}
                                    decimals={getDecimalPlaces("basementPonyWallType")}
                                    change={change}
                                    setValue={selectedNomRValue}
                                    units={{
                                        base: getBaseUnits("basementPonyWallType", modelUnits),
                                        options: getUnitOptions("thermalResistance"),
                                        selected: rValUnits,
                                        unitType: "thermalResistance",
                                        accessor: `${accessor}.wall.ponyWall.ponyWallInsType_u`,
                                    }}
                                    info={
                                        "Values calculated by the Code Selector are nominal R-values. HOT2000 re-calculates R-values based on the content of the code when running a simulation."
                                    }
                                    warning={ponyWallCodeWarning}
                                    isLoading={isCalculatingRValue}
                                />
                            )}
                        </InputRow>
                    </>
                ) : (
                    <></>
                )}
            </>
        );
    }
);
