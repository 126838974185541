import { selectModelId } from "features/Model/_ducks/selectors";
import React, { useState, useEffect } from "react";
import Drawer from "components/ChbaChecklist/ChbaChecklistDrawer";
import { useCallback } from "react";
import ChbaChecklistItem from "components/ChbaChecklist/ChbaChecklistItem";
import ChbaChecklistAccordion from "components/ChbaChecklist/ChecklistAccordion";
// import ModelTags from "components/ModelTags";
import classes from "./style.module.scss";
import { PhaseHeader } from "./phaseHeader";
import { phaseTitles, followUpPhaseTitles, chbaRoadmapPhases, checkboxTitles } from "./templates/chbaRoadmapPhases";
import { phases } from "./_ducks/reducers";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectChbaCheckList } from "./_ducks/selectors";
import { getChbaNzChecklist, saveChbaNzChecklist } from "./_ducks/thunk";
import { isEmpty } from "lodash";
import ChecklistLinkOut from "components/ChbaChecklist/ChecklistLinkOut";

const ProjectPathChecklist = ({ open = false, toggleOpen, chbaCheckList, modelId, getChbaNzChecklist, handleSave }) => {
    const handleClose = useCallback(() => {
        toggleOpen();
    }, [toggleOpen]);

    useEffect(() => {
        if (modelId) getChbaNzChecklist(modelId);
    }, [modelId]);

    const [currentPhase, setCurrentPhase] = useState("1a");
    const [showPreview, setShowPreview] = useState(false);

    const getCheckedItems = (phaseKey) =>
        !isEmpty(chbaCheckList.items)
            ? Object.values(chbaCheckList.items[phaseKey]).reduce((acc, item) => {
                  // if truthy, mark as complete. (true or "n/a")
                  if (item?.checkboxValue === true) {
                      return acc + 1;
                  } else if (item?.checkboxValue === "n/a" && !isEmpty(item?.form)) {
                      return acc + 1;
                  } else {
                      return acc;
                  }
              }, 0)
            : [];

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    const phasesArray = Object.keys(phases).sort(sortAlphaNum);

    const sortedPhasesArray = phasesArray.reduce((acc, item, ind) => {
        return [
            ...acc,
            {
                [`${item}`]: phases[`${item}`],
            },
        ];
    }, []);

    return (
        <Drawer
            open={open}
            title="CHBA Net Zero Renovation Roadmap"
            subtitle="Track the progress of your CHBA Net Zero Renovation Project"
            close={handleClose}
            className={classes.drawer}
            currentPhase={currentPhase}
        >
            <div className={classes.sharedDocHeader}>
                <div>
                    <h1>CHBA NZ Renovation Documentation</h1>
                    <p>Click the link to request access.</p>
                </div>
                <div>
                    <ChecklistLinkOut
                        link={"https://drive.google.com/drive/folders/16lyLcVGIUt5B6XeYI_R8-U6sn1o8rz4n?usp=sharing"}
                        title={"All Documentation"}
                    />
                </div>
            </div>
            <div className={classes.checklistContainer}>
                {phaseTitles.map((title, phaseInd) => {
                    const phaseKey = Object.keys(sortedPhasesArray[phaseInd])[0];
                    return (
                        <ChbaChecklistAccordion
                            key={phaseInd}
                            className={classes.accordion}
                            phaseKey={phaseKey}
                            setCurrentPhase={setCurrentPhase}
                            heading={
                                <PhaseHeader
                                    phase={title}
                                    checkedItems={getCheckedItems(phaseKey)}
                                    totalCheckboxes={Object.keys(chbaRoadmapPhases[phaseKey]).length}
                                />
                            }
                        >
                            {Object.keys(Object.values(sortedPhasesArray[phaseInd])[0]).map(
                                (checklistItem, checkboxInd) => {
                                    const isLast =
                                        Object.keys(Object.values(sortedPhasesArray[phaseInd])[0]).length - 1 ===
                                        checkboxInd;

                                    return (
                                        <ChbaChecklistItem
                                            label={`${checkboxTitles[phaseInd][checklistItem].title}`}
                                            subtitle={`${checkboxTitles[phaseInd][checklistItem].subtitle}`}
                                            key={checklistItem}
                                            name={`${phaseKey}.${checklistItem}`}
                                            isLast={isLast}
                                            modelId={modelId}
                                            setShowPreview={setShowPreview}
                                            handleSave={handleSave}
                                            phasesTemplate={chbaRoadmapPhases}
                                        />
                                    );
                                }
                            )}
                        </ChbaChecklistAccordion>
                    );
                })}
            </div>

            <div className={`${classes.checklistContainer} ${classes.followUpRoadmap}`}>
                <h2>Follow-up Tasks</h2>
                {followUpPhaseTitles.map((title, phaseInd) => {
                    const phaseKey = Object.keys(sortedPhasesArray[phaseInd + phaseTitles.length])[0];
                    return (
                        <ChbaChecklistAccordion
                            key={phaseInd}
                            className={classes.accordion}
                            phaseKey={phaseKey}
                            setCurrentPhase={setCurrentPhase}
                            heading={
                                <PhaseHeader
                                    phase={title}
                                    checkedItems={getCheckedItems(phaseKey)}
                                    totalCheckboxes={
                                        !isEmpty(chbaCheckList.items)
                                            ? Object.values(chbaCheckList.items[phaseKey]).length
                                            : 0
                                    }
                                />
                            }
                        >
                            {Object.keys(Object.values(sortedPhasesArray[phaseInd + phaseTitles.length])[0]).map(
                                (checklistItem, checkboxInd) => {
                                    const isLast =
                                        Object.keys(Object.values(sortedPhasesArray[phaseInd + phaseTitles.length])[0])
                                            .length -
                                            1 ===
                                        checkboxInd;

                                    return (
                                        <ChbaChecklistItem
                                            label={`${
                                                checkboxTitles[phaseInd + phaseTitles.length][checklistItem].title
                                            }`}
                                            subtitle={`${
                                                checkboxTitles[phaseInd + phaseTitles.length][checklistItem].subtitle
                                            }`}
                                            key={checklistItem}
                                            name={`${phaseKey}.${checklistItem}`}
                                            isLast={isLast}
                                            modelId={modelId}
                                            setShowPreview={setShowPreview}
                                            handleSave={handleSave}
                                            phasesTemplate={chbaRoadmapPhases}
                                        />
                                    );
                                }
                            )}
                        </ChbaChecklistAccordion>
                    );
                })}
            </div>
        </Drawer>
    );
};

const mapStateToProps = createStructuredSelector({
    chbaCheckList: selectChbaCheckList,
    modelId: selectModelId,
});

const mapDispatchToProps = (dispatch) => ({
    getChbaNzChecklist: (modelId) => dispatch(getChbaNzChecklist(modelId)),
    saveChbaNzChecklist: (modelId, chbaCheckList) => dispatch(saveChbaNzChecklist(modelId, chbaCheckList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPathChecklist);
