import React, { useEffect, useState } from "react";
import classes from "../style.module.scss";
import moment from "moment";
import { isEmpty } from "lodash";
import { useOutsideClickHook } from "utils/outsideClick";
import Dropdown from "components/Dropdown";
import ModelTags from "components/ModelTags";
import ThreeDots from "assets/images/icons/JSX/ThreeDots";
import Tooltip from "components/Tooltip";
import ProjectChecklistProgress from "components/ProjectChecklistProgress";
import { phaseTitles, totalPhases } from "features/Model/ProjectPathChecklist/templates/chbaRoadmapPhases";
import Community from "./Community";
import CHBACommunityIcon from "assets/images/icons/communityCHBA.svg";
import CommunityIcon from "assets/images/icons/community.svg";
import Folder from "./Folder";
import FolderIcon from "assets/images/icons/folder.svg";
import SharedFolderIcon from "assets/images/icons/Shared-folder.svg";
import Model from "./Model";
import CommModel from "./CommModel";
import ModelIcon from "assets/images/icons/model.svg";
import CHBAModelIcon from "assets/images/icons/modelCHBA.svg";
import SharedModelIcon from "assets/images/icons/Shared-model.svg";
// import Ready from "assets/images/icons/Ready.svg";
// import Error from "assets/images/icons/warning.svg";
// import Unknown from "assets/images/icons/warning-yellow.svg";
import Edit from "assets/images/icons/JSX/Edit";
import Analysis from "./Analysis";
import ParametricIcon from "assets/images/icons/parametric-icon.svg";

export { Community, Folder, Model, CommModel, Analysis };

const DashboardItem = ({
    ref,
    className,
    itemId,
    sharedWithUser = false,
    itemType,
    details, // Optional Fields { lastEdited, totalModels, provTerr, userName, currentPhase, targetCompletionDate }
    tags = [],
    actions,
    setIsCountDialogOpen,
    ...props
}) => {
    const [subMenu, toggleSubMenu] = useState(false);
    // const [duplicates, setDuplicates] = useState(0);

    const subMenuRef = useOutsideClickHook(() => toggleSubMenu(false));

    const progress = (phase) => Math.round((phase / totalPhases) * 100);

    const icon = {
        folder: <img src={sharedWithUser ? SharedFolderIcon : FolderIcon} alt="Folder icon" />,
        model: <img src={sharedWithUser ? SharedModelIcon : ModelIcon} alt="model icon" />,
        CHBAModel: <img src={CHBAModelIcon} alt="CHBA model icon" />,
        community: <img src={sharedWithUser ? CHBACommunityIcon : CommunityIcon} alt="community icon" />,
        //  <img src={ParametricIcon} alt="parametric analysis icon" />,
        analysis: <img src={sharedWithUser ? SharedModelIcon : ModelIcon} alt="model icon" />,
    };

    const displayDirectionCount = (elem) => {
        const copies = elem?.copyDirections || {};
        let loops = Object.entries(copies).filter(([key, val]) => val > 0).length;
        return ["S", "SE", "E", "NE", "N", "NW", "W", "SW"].map((key) => {
            let count = copies[key];
            if (count > 0) {
                loops -= 1;
                return `${copies[key]} ${key}${loops > 0 ? ", " : ""} `;
            }
            return "";
        });
    };

    // useEffect(() => {
    //     setDuplicates(details.countDirection?.duplicates || 0);
    // }, [details]);

    return (
        <li
            ref={ref}
            className={`${classes.item} ${className}`}
            style={{
                padding: !isEmpty(tags) && "0.5rem 1rem",
                pointerEvents: "none",
            }}
            {...props}
        >
            {Object.entries(details).map(([key, elem]) => {
                if (["name"].includes(key)) {
                    return (
                        <div className={classes.itemTitle} key={key}>
                            {icon[itemType]} <p>{elem || ""}</p>
                            {itemType !== "CHBAModel" && tags.map((tag, i) => <ModelTags key={i} tag={tag} />)}
                        </div>
                    );
                }
                if (["size"].includes(key)) {
                    return (
                        <div className={classes.centered} key="key">
                            <span>{elem}</span>
                        </div>
                    );
                }
                if (["currentPhase"].includes(key)) {
                    return (
                        <div className={classes.phase} key={key}>
                            <span>
                                <ProjectChecklistProgress
                                    progress={progress(elem)}
                                    color={"#18c1ad"}
                                    radius={6}
                                    strokeWidth={"4px"}
                                />
                            </span>
                            <p>{elem === 0 ? "0. Roadmap not started" : phaseTitles[elem - 1]}</p>
                        </div>
                    );
                }
                if (["userName", "provTerr"].includes(key)) {
                    return (
                        <div className={classes.elementText} key={key}>
                            {elem}
                        </div>
                    );
                }
                if (["lastEdited", "targetCompletionDate"].includes(key)) {
                    return (
                        <div className={classes.lastEdited} key={key}>
                            {elem === "" ? "N/A" : moment(elem).format("MMM DD, YYYY")}
                        </div>
                    );
                }
                if (["countDirection"].includes(key)) {
                    return (
                        <div
                            key={key}
                            className={classes.direction}
                            style={{ pointerEvents: "auto" }}
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsCountDialogOpen(true);
                            }}
                        >
                            <div
                                className={classes.edit}
                                data-html
                                data-tip={`<span style="display:block;max-width:14rem;">Edit count & direction</span>`}
                                data-for={`${itemId}_countDirection`}
                            >
                                <Edit />
                                <Tooltip id={`${itemId}_countDirection`} />
                            </div>
                            <div>{displayDirectionCount(elem)}</div>
                        </div>
                    );
                }
                // if (["status"].includes(key)) {
                //     return (
                //         <div key={key}
                //             className={classes.status}
                //             style={{ pointerEvents: "auto" }}
                //             data-html
                //             data-tip={`<span style="display:block;max-width:14rem;">
                //                 ${elem === "ready" ? "File ready"
                //                     : elem === "error" ? "File incomplete with errors"
                //                         : elem === "unknown" ? "No results" : ""}
                //             </span>`}
                //             data-for={`${itemId}_status`}
                //         >
                //             <img src={
                //                 elem === "ready" ? Ready
                //                     : elem === "error" ? Error
                //                         : elem === "unknown" ? Unknown
                //                             : ""
                //             } />
                //             <Tooltip id={`${itemId}_status`} />
                //         </div>
                //     );
                // }
                return <div key={key}>{typeof elem === "object" ? "" : elem}</div>;
            })}

            <div className={classes.actions} ref={subMenuRef}>
                {actions && (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                            toggleSubMenu(!subMenu);
                        }}
                        className={classes.delete}
                        style={{ pointerEvents: "auto" }}
                    >
                        <ThreeDots />
                        <Dropdown
                            className={classes.subMenu}
                            open={subMenu}
                            options={actions.map(({ label, onClick, ...rest }) => ({
                                label: label,
                                onClick: (e) => {
                                    onClick(e);
                                    toggleSubMenu(false);
                                },
                                ...rest,
                            }))}
                        />
                    </div>
                )}
            </div>
            {/* <Tooltip id={`actions-${itemId}`} delay /> */}
        </li>
    );
};

export default DashboardItem;
