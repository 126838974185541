import { genEnvirocentreNZReport } from "utils/reportGen/api";

const generatePdf =
    (formData, dataToSend = {}) =>
    (dispatch) => {
        return genEnvirocentreNZReport(formData, dataToSend)
            .then(({ data }) => data)
            .catch((err) => {
                console.log(err);
            });
    };

export default {
    generatePdf,
};
