import React from "react";

import ModelSnapshot from "./ModelSnapshot";
import ParametricAnalysisTitleStatus from "../ParametricAnalysisTitleStatus";

import classes from "./styles.module.scss";

const ParametricAnalysisModelPage = ({
    lastModelSync,
    modelDetails,
    upgrades,
    modelData,
    analysisId,
    selectedModelId,
    selectedModelHasResults,
    canResyncModel,
}) => {
    const { name: modelName } = modelDetails;

    return (
        <div className={classes.parametricAnalysisModelPageContainer}>
            <ParametricAnalysisTitleStatus
                modelName={modelName}
                selectedModelHasResults={selectedModelHasResults}
                // ecmInputMap={ecmInputMap}
            />
            <div className={classes.modelSnapshotContainer}>
                <ModelSnapshot
                    lastModelSync={lastModelSync}
                    modelDetails={modelDetails}
                    upgrades={upgrades}
                    modelData={modelData}
                    analysisId={analysisId}
                    selectedModelId={selectedModelId}
                    canResyncModel={canResyncModel}
                />
            </div>
        </div>
    );
};

export default ParametricAnalysisModelPage;
