import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import UpgradeDrawer from "./";
import { actions } from "../_ducks";
import { updateUpgrade, selectUpgrade, deleteUpgrade } from "store/upgradeLibrary/actions";
import { addUpgradeToParametricAnalysis, updateEcmInputMapUpgrade } from "store/parametricAnalysis/actions";
import {
    getComponentOptions,
    getSubComponentOptions,
    upgradeOptionsComponents,
    upgradeOptionsSystems,
    stripWindowSkylights,
    stripCeilings,
    getUpgradeEnvelopeRowLabels,
    getUpgradeHvacRowLabels,
    getUpgradeDhwRowLabels,
    getUpgradeOtherRowLabels,
    getUpgradeFinancingRowsLabels,
} from "utils/upgrades";
import uniqid from "uniqid";
import moment from "moment";
import { getUpgradeToAdd } from "utils/parametric";
import { updatePriceStateParametric } from "store/parametricAnalysis/thunk";

// import { actions } from "../_ducks";

const { updateUpgradeCost } = actions;

const { updatePackageUpgrades, deletePackageUpgrade } = actions;

const mapStateToProps = (
    {
        form,
        user: { codeLib = {} } = {},
        upgrades: { packages = {} } = {},
        upgradeLibrary: { currentPackage = {} },
        parametricAnalysis: { parametricAnalysis } = {},
    },
    {
        selectedPackage,
        selectedUpgrade,
        toggleOpen,
        modelChange,
        selectedUpgradeLabel,
        selectedUpgradeType: upgradeType, //This will come in as "spaceHeating", for example
        selectedUpgradeH2kType: upgradeH2kType = upgradeType, //This will come in as "heatingCooling", for example
        initUpgradeData = {},
        isUpgradeLibrary,
        isParametricAnalysis,
        associatedHeatingSystem = "",
        canBeEditedFromParametric = true,
        resetState,
    }
) => {
    const {
        model: { upgrades: upgradesFromParametric = {}, modelData: modelDataFromParametric = {} } = {},
        ecmInputMap = {},
    } = parametricAnalysis !== null ? parametricAnalysis : {};

    const selector = formValueSelector("upgradeDrawer");
    const modelSelector = formValueSelector("model");

    const selectedComponentsField = selector({ form }, "selectedComponents") || [];
    const autoGenerateLabel = selector({ form }, "autoGenerateLabel");

    const allEcmInputMapUpgradesArr = ecmInputMap
        ? Object.keys(ecmInputMap)
              .map((key) =>
                  Object.entries(ecmInputMap[key])
                      .filter(([k, _]) => k !== "None")
                      .map(([k, v]) => ({ [k]: v }))
              )
              .flat()
        : {};

    const allEcmInputMapUpgrades = allEcmInputMapUpgradesArr.reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const {
        components = {},
        airTightness = {},
        codes = {},
        heatingCooling = {},
        ventilation = {},
        domesticHotWater = {},
        generation = {},
        program = {},
        baseLoads = {},
    } = isParametricAnalysis ? modelDataFromParametric : modelSelector({ form }, "modelData") || {};

    const { upgrades = {} } = isUpgradeLibrary
        ? currentPackage
        : isParametricAnalysis
        ? upgradesFromParametric
        : packages[selectedPackage] || {};
    const { created } = upgrades[selectedUpgrade] || {};

    let availableComponents = ["window", "door", "floorHeader"].includes(upgradeH2kType)
        ? getSubComponentOptions(upgradeH2kType, components, upgrades, selectedUpgrade)
        : getComponentOptions(upgradeH2kType, components, upgrades, selectedUpgrade);

    if (upgradeType === "window" || upgradeType === "skylights") {
        availableComponents = stripWindowSkylights(availableComponents, upgradeType);
    } else if (upgradeType === "ceilingWithAttic" || upgradeType === "cathedral") {
        availableComponents = stripCeilings(availableComponents, components, upgradeType);
    }

    const unavailableSystems = Object.keys(upgrades)
        .filter((key) => {
            const { upgradeH2kType } = upgrades[key];
            return upgradeOptionsSystems.find(({ value }) => value === upgradeH2kType);
        })
        .map((key) => upgrades[key].upgradeH2kType);

    const selectedComponents =
        selectedComponentsField.constructor === Array ? selectedComponentsField : [selectedComponentsField];

    // if (selectedComponents.length > 0 && Object.keys(initUpgradeData).includes(selectedComponents[0])) {
    //     // need to pick the right initial values
    //     initUpgradeData = initUpgradeData[selectedComponents[0]];
    // } else if (Object.keys(initUpgradeData)[0] !== "fields") {
    //     initUpgradeData = initUpgradeData[Object.keys(initUpgradeData)[0]];
    // }

    let initialValues = {
        label: { [upgradeType]: selectedUpgradeLabel }, // will be overwritten if the selected package has label
        autoGenerateLabel: true,
        ...(isParametricAnalysis && selectedUpgrade
            ? allEcmInputMapUpgrades[selectedUpgrade]?.packageValues
            : selectedPackage && selectedUpgrade
            ? upgrades[selectedUpgrade]
            : initUpgradeData),
    };

    if (!Object.keys(initialValues).includes("fields") && (isUpgradeLibrary || isParametricAnalysis)) {
        initialValues = {
            ...initialValues,
            ...initUpgradeData,
        };
    }

    return {
        initialValues,
        selectedPackage,
        selectedUpgrade,
        upgradeType,
        upgradeH2kType,
        selectedComponents,
        availableComponents,
        toggleOpen,
        modelChange,
        created,
        unavailableSystems,
        selectedUpgradeLabel,
        autoGenerateLabel,
        components,
        airTightness,
        codes,
        heatingCooling,
        ventilation,
        domesticHotWater,
        generation,
        program,
        baseLoads,
        isUpgradeLibrary,
        currentPackage,
        codeLib,
        associatedHeatingSystem,
        upgrades,
        isParametricAnalysis,
        ecmInputMap,
        canBeEditedFromParametric,
        resetState,
    };
};

const mapDispatchToProps = (dispatch) => ({
    deleteUpgrade: ({ packageId, upgradeId }) =>
        dispatch(
            deletePackageUpgrade({
                packageId,
                upgradeId,
            })
        ),
    deleteUpgradeFromLib: (upgradeId) => dispatch(deleteUpgrade(upgradeId)),
    selectUpgrade: (upgradeId) => dispatch(selectUpgrade(upgradeId)),
});

const onSubmit = async (
    form,
    dispatch,
    {
        isParametricAnalysis,
        selectedUpgrade,
        selectedPackage,
        selectedUpgradeType: upgradeType,
        selectedUpgradeH2kType: upgradeH2kType = upgradeType,
        toggleOpen,
        modelChange,
        reset,
        created,
        components,
        airTightness,
        codes,
        heatingCooling,
        ventilation,
        domesticHotWater,
        generation,
        program,
        baseLoads,
        isUpgradeLibrary,
        currentPackage,
        selectUpgrade,
        codeLib,
        isNew,
        ecmInputMap,
        resetState,
        analysisId,
    }
) => {
    const upgradeId = selectedUpgrade || uniqid("upgrade-");
    const dateTimeFormatted = moment().format("LLLL");
    const newlyCreated = { created: dateTimeFormatted };

    // if (!isParametricAnalysis) {
    //     upgradeH2kType = upgradeType;
    // }

    let {
        selectedComponents: selectedComponentsField = [],
        fields = {},
        cost = {},
        label: { [upgradeType]: label = "N/A" } = {},
        label: initLabels = {},
        autoGenerateLabel,
        codes: upgradeCodes = {},
    } = form;

    if (upgradeType === "grant" || upgradeType === "loan") {
        const fieldsArr = Object.entries(fields).reduce((acc, field) => {
            if (field[1]?.amount !== null) {
                return {
                    ...acc,
                    [`${field[0]}`]: field[1],
                };
            } else {
                return acc;
            }
        }, {});

        fields = fieldsArr;
    }

    let codesToAdd = {};

    if (isUpgradeLibrary || isParametricAnalysis) {
        const fieldsKeys = Object.keys(fields);

        const codeLibKeys = Object.keys(codeLib);

        let allCodes = {};

        codeLibKeys
            .map((key) => Object.keys(codeLib[key].codes).map((k) => ({ [k]: codeLib[key].codes[k] })))
            .flat()
            .reduce((obj, item) => Object.assign(allCodes, item), {});

        for (let i = 0; i < fieldsKeys.length; i++) {
            const key = fieldsKeys[i];

            if (!fields[key].codeRef) continue;

            if (allCodes[fields[key].codeRef]) {
                codesToAdd = {
                    ...codesToAdd,
                    [fields[key].codeRef]: { ...allCodes[fields[key].codeRef], codeRef: fields[key].codeRef },
                };
            }
        }
    }

    const selectedComponents =
        selectedComponentsField.constructor === Array ? selectedComponentsField : [selectedComponentsField];

    //Upgrade type could be "spaceHeating", "skylights", etc, while we need it to be the h2k upgrade here ("heatingCooling", "window")

    let initUpgrade = {
        upgradeType: upgradeH2kType,
        selectedComponents,
        fields,
        cost,
        codes: upgradeCodes ? { ...upgradeCodes, ...codesToAdd } : codesToAdd ? { ...codesToAdd } : {},
        autoGenerateLabel,
        ...(created ? { created } : newlyCreated),
    };

    //must do this because we use the presence of the key "skylights" in label to differentiate between regular windows and skylights
    if (upgradeType === "skylights") {
        initUpgrade = {
            ...initUpgrade,
            label: { skylights: "None" },
        };
    }

    const isEnvelopeComponent = upgradeOptionsComponents.find(({ value = "" }) => value === upgradeH2kType);
    let generatedLabel = "Error Generating Label";

    if (isEnvelopeComponent || upgradeH2kType === "airTightness") {
        generatedLabel =
            getUpgradeEnvelopeRowLabels({
                components,
                codes,
                airtightness: airTightness,
                rowId: upgradeType,
                upgradePackage: {
                    [selectedPackage]: {
                        upgrades: {
                            [upgradeId]: initUpgrade,
                        },
                    },
                },
                ecmInputMap,
            })?.label || "Error Generating Label";
    } else if (["heatingCooling", "ventilation"].includes(upgradeH2kType)) {
        generatedLabel =
            getUpgradeHvacRowLabels({
                heatingCooling,
                ventilation,
                rowId: upgradeType,
                upgradePackage: {
                    [selectedPackage]: {
                        upgrades: {
                            [upgradeId]: initUpgrade,
                        },
                    },
                },
            })?.label || "Error Generating Label";
    } else if (["domesticHotWater"].includes(upgradeH2kType)) {
        generatedLabel =
            getUpgradeDhwRowLabels({
                domesticHotWater,
                heatingCooling,
                rowId: upgradeType,
                upgradePackage: {
                    [selectedPackage]: {
                        upgrades: {
                            [upgradeId]: initUpgrade,
                        },
                    },
                },
            })?.label || "Error Generating Label";
    } else if (["generation", "baseLoads"].includes(upgradeH2kType)) {
        generatedLabel =
            getUpgradeOtherRowLabels({
                generation,
                program,
                baseLoads,
                rowId: upgradeType,
                upgradePackage: {
                    [selectedPackage]: {
                        upgrades: {
                            [upgradeId]: initUpgrade,
                        },
                    },
                },
            })?.label || "Error Generating Label";
    } else if (["loan", "grant", "financialValuation"].includes(upgradeH2kType)) {
        generatedLabel =
            getUpgradeFinancingRowsLabels({
                rowId: upgradeType,
                upgradePackage: {
                    [selectedPackage]: {
                        upgrades: {
                            [upgradeId]: initUpgrade,
                        },
                    },
                },
            })?.label || "Error Generating Label";
    }

    const setLabel = autoGenerateLabel ? generatedLabel : label;

    const upgrade = {
        ...initUpgrade,
        label: {
            ...initLabels,
            [upgradeType]: setLabel,
        },
    };

    if (isUpgradeLibrary) {
        await dispatch(updateUpgrade(upgradeId, upgrade));
    }

    if (isParametricAnalysis) {
        delete upgrade.upgradeType;
        delete upgrade.selectedComponents;
        // upgradeKey, upgradeSubType, upgradeType, parentUpgradeKey
        const upgradeToAdd = isNew
            ? getUpgradeToAdd(
                  upgrade,
                  {
                      upgradeKey: upgradeId,
                      upgradeSubType: upgradeType,
                      upgradeType: upgradeH2kType,
                      parentUpgradeKey: null,
                  },
                  ecmInputMap,
                  0,
                  true
              )
            : null;

        if (isNew) {
            dispatch(addUpgradeToParametricAnalysis(upgradeType, upgradeToAdd));
        } else {
            const { cost: { [upgradeType]: { total: newCost = 0 } = {} } = {} } = upgrade;
            const {
                packageValues: { cost: { [upgradeType]: { total: oldCost = 0 } = {} } = {} } = {},
                firstBatchId = null,
            } = ecmInputMap[upgradeType][upgradeId];

            if (newCost !== oldCost && firstBatchId) {
                await dispatch(updatePriceStateParametric(analysisId));
            }

            await dispatch(
                updateEcmInputMapUpgrade(upgradeType, upgradeId, { ...upgrade, upgradeSubType: upgradeType })
            );
        }

        if (resetState) {
            delete resetState.removeUpgradeFromParametricAnalysis;

            resetState();
        }
    }

    // Update upgrades redux
    if (!isUpgradeLibrary && !isParametricAnalysis) {
        await dispatch(
            updatePackageUpgrades({
                packageId: selectedPackage,
                dateTime: dateTimeFormatted,
                upgrades: { [upgradeId]: upgrade },
            })
        );
        await dispatch(
            updateUpgradeCost({
                packageId: selectedPackage,
                upgradeId: upgradeId,
                upgradeType: upgradeType,
                cost: cost[upgradeType]?.total || 0,
                items: cost[upgradeType]?.items || {},
            })
        );
    }

    // Update in Model
    const componentReference = {
        upgradeId,
    };

    if (isEnvelopeComponent) {
        selectedComponents.map((path) => {
            const componentModelPath = `modelData.components.${path}.upgradePackages.${selectedPackage}`;
            modelChange(componentModelPath, componentReference);
        });
    } else {
        // System component
        const systemPath = `modelData.${upgradeH2kType}.upgradePackages.${selectedPackage}`;
        modelChange(systemPath, componentReference);
    }

    toggleOpen({});
    selectUpgrade(null);
    reset();
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const form = reduxForm({
    form: "upgradeDrawer",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(UpgradeDrawer);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
