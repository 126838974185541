import React, { useState, useRef, useEffect } from "react";
import { TableCell } from "@material-ui/core";
import Tinput from "../components/TSelect/Tinput";
import {
    getFoundationTablecomponentsUnits,
    getTableFieldKey,
} from "features/Model/Enclosure/Takeoff/containers/TakeOffFoundation/utils/FoundationSubcomponentsFactory";
import { getTValidations } from "./tableValidations";
import { getBaseUnits } from "utils/fields";
import convertUnit from "utils/convertUnit";

const TinputCell = ({ editTableComponent, cell, dataRow, modelUnits }) => {
    const [selected, setSelected] = useState(false);

    // const { canBeEditedFromParametric = true } = dataRow;

    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setSelected(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    if (!selected) {
        //cell[1].value will be in the trueBase units, must convert for display
        const units = getFoundationTablecomponentsUnits(cell[0]);
        const fieldKey = getTableFieldKey(cell[0]);
        const { trueBase = "m", displayBase = "m" } = getBaseUnits(fieldKey, modelUnits) || {};
        const selectedUnit = cell[1].unit || displayBase || trueBase;

        const { canEdit = true } = cell[1];
        let displayValue = cell[1].value;

        if (["takeoffWindowLength", "takeoffLength"].includes(fieldKey)) {
            displayValue = parseFloat(
                convertUnit({
                    value: cell[1].value,
                    type: units.unitType,
                    inputUnit: trueBase,
                    outputUnit: selectedUnit,
                }).toFixed(selectedUnit === "mm" ? 0 : 2)
            );
        }

        return (
            <TableCell
                className={`tt-input ${cell[0] === "title" ? "tt-paddingLeft" : "tt-paddingRight--small"}`}
                align={cell[0] === "title" ? "left" : "right"}
                onClick={() => canEdit && setSelected(true)}
            >
                {displayValue}
            </TableCell>
        );
    } else {
        let units = getFoundationTablecomponentsUnits(cell[0]);
        const fieldKey = getTableFieldKey(cell[0]);
        units.selected = cell[1].unit;

        return (
            <TableCell align={cell[0] === "title" ? "left" : "right"} onBlur={() => setSelected(false)} ref={ref}>
                <Tinput
                    className={`${cell[0] === "title" && "table-tinput-title"} ${
                        cell[0] === "qty" && "table-tinput-qty"
                    } 
              ${cell[0] !== "qty" && cell[0] !== "title" && "table-tinput"} 
              tt-input`}
                    decimals={2}
                    inputType={cell[0] === "title" ? "string" : "num"}
                    property={cell[0]}
                    placeholder={cell[0] === "title" ? "Enter window description" : ""}
                    units={{
                        ...units,
                        base: getBaseUnits(fieldKey, modelUnits),
                    }}
                    input={{
                        value: cell[1].value,
                        onChange: (e) => editTableComponent(dataRow.id, cell[0], e, cell[1].unit),
                    }}
                    onUnitChange={(value, unit) => editTableComponent(dataRow.id, cell[0], value, unit)}
                    validation={getTValidations}
                />
                <style>
                    {`
                    .tt-paddingLeft {
                      padding-left: 15px !important;
                    }

                    .tt-paddingRight--small {
                        padding-right: 11px !important;
                      }
                  `}
                </style>
            </TableCell>
        );
    }
};
export default TinputCell;
