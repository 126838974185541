import React, { useState } from "react";
import { isEmpty } from "lodash";

import Dialog from "components/Dialog";
import Button from "components/Button";

import {
    headerContainer,
    headerText,
    dialogContainer,
    foldersContainer,
    noFoldersText,
    folderContainer,
    folderImg,
    selectedFolderContainer,
    headerTextContainer,
    goBackImg,
    moveToContainer,
    moveToText,
} from "../style.module.scss";

import closeCircle from "assets/images/icons/closeCircle.svg";
import blueArrowRight from "assets/images/icons/blueArrowRight.svg";
import goBackFolder from "assets/images/icons/goBackFolder.svg";

const MoveDialog = ({
    rootDir = "Root Directory",
    folders,
    itemId,
    fileName,
    openDialog,
    itemLoading,
    handleMove,
    onClose,
}) => {
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState("");

    const filteredFolders = !isEmpty(folders)
        ? Object.entries(folders).filter(([key, { parentFolderId }]) => {
              return parentFolderId === currentFolderId && key !== itemId;
          })
        : [];

    return (
        <Dialog open={!!openDialog} classes={{ paperClass: dialogContainer }}>
            <div className={headerContainer}>
                <div className={headerTextContainer}>
                    {currentFolderId && (
                        <img
                            className={goBackImg}
                            src={goBackFolder}
                            alt="go back"
                            onClick={() => {
                                setCurrentFolderId(folders[currentFolderId].parentFolderId);
                                setSelectedFolder("");
                            }}
                        />
                    )}
                    <span className={headerText}>{currentFolderId ? `${folders[currentFolderId].name}` : rootDir}</span>
                </div>
                <img
                    src={closeCircle}
                    alt="close circle"
                    onClick={() => {
                        onClose();
                        setCurrentFolderId(null);
                        setSelectedFolder("");
                    }}
                    style={{ cursor: "pointer" }}
                />
            </div>
            <div className={foldersContainer}>
                {isEmpty(filteredFolders) ? (
                    <div>
                        <span className={noFoldersText}>You haven't created any folders yet.</span>
                    </div>
                ) : (
                    filteredFolders
                        .sort(([aKey, aValues], [bKey, bValues]) => aValues.name.localeCompare(bValues.name))
                        .map(([key, { name }]) => (
                            <div
                                key={key}
                                className={`${folderContainer} ${selectedFolder === key && selectedFolderContainer}`}
                                onClick={() => {
                                    if (selectedFolder === key) {
                                        setSelectedFolder("");
                                        setCurrentFolderId(key);
                                    } else {
                                        setSelectedFolder(key);
                                    }
                                }}
                            >
                                {name}
                                <img
                                    className={`${selectedFolder !== key && folderImg}`}
                                    src={blueArrowRight}
                                    alt="blue arrow right"
                                />
                            </div>
                        ))
                )}
            </div>
            <div className={moveToContainer}>
                <span className={moveToText}>
                    Moving <strong style={{ color: "#0049c6" }}>{fileName}</strong> to{" "}
                    {selectedFolder || currentFolderId ? (
                        <strong>{folders[selectedFolder || currentFolderId].name}</strong>
                    ) : (
                        <strong>{rootDir}</strong>
                    )}
                </span>
                <Button
                    onClick={() => handleMove(selectedFolder || currentFolderId)}
                    disabled={itemLoading || isEmpty(folders)} //disabled in the case that we're at the root and have no folders at all
                >
                    {itemLoading ? "Moving..." : "Move"}
                </Button>
            </div>
        </Dialog>
    );
};

export default MoveDialog;
