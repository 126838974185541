import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import StandardLayers from "./";
import uDefCodeTemplate from "features/Model/Enclosure/Templates/standardUDefCode.json";
import { idIfyDate } from "utils";
import { actions as userActions } from "store/users";
import { updateUpgrade } from "store/upgradeLibrary/actions";
import { getNominalRValueUDef } from "utils/enclosure/getNominalRValue";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import isEmpty from "lodash/isEmpty";
import { getMatchCodeRefs, getMatchFloorHeaderCodeRefs } from "utils/enclosure/components";

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode } = enclosureActions;

const fieldPathCodeTypeMap = {
    Wall: "wallInsType",
    Window: "windowType",
    Floor: "expFloorInsType",
    FloorsAbove: "floor.floorsAboveInsType",
    CeilingFlat: "ceilingInsType",
    Ceiling: "ceilingInsType",
    FloorHeader: "floorHeaderInsType",
    CrawlspaceWall: "wall.crawlspaceWallInsType", //SPECIAL CASE also "ponyWallInsType"
    BasementWall: "wall.intAddedInsType",
    Lintel: "lintelInsType", //This one is tricky, it can be in walls or basements, but both use lintelInsType
    FloorsAdded: "floor.slabInsType", //NOT TESTED
};

const layerSort = (a, b) => {
    if (a.layer < b.layer) {
        return -1;
    }
    if (a.layer > b.layer) {
        return 1;
    }
    return 0;
};

const mapStateToProps = (
    {
        form,
        enclosure: { initCodeValues: { udefStandard = {} } = {} } = {},
        user: { uid },
        upgradeLibrary: { currentPackage, selectedUpgrade },
    },
    { fieldAccessor, modelFormChange, currentFormChange, componentType, isCodeLibrary = false, handleUdefSave }
) => {
    const selector = formValueSelector("uDefCode");
    const modelSelector = formValueSelector("model");
    // const { id:storeysId } = selector({form}, 'modelData.specifications.numStoreys') || {};

    const {
        continuousMedium: continuousMediumLayers = {},
        continuousInsulation: continuousInsulationLayers = {},
        woodFraming: woodFramingLayers = {},
        steelFraming: steelFramingLayers = {},
        strapping: strappingLayers = {},
    } = selector({ form }, "uDefCode.layers") || {};

    const medium = Object.keys(continuousMediumLayers).map((key) => ({
        ...continuousMediumLayers[key],
        id: key,
        type: "continuousMedium",
    }));

    const insulation = Object.keys(continuousInsulationLayers).map((key) => ({
        ...continuousInsulationLayers[key],
        id: key,
        type: "continuousInsulation",
    }));

    const woodFraming = Object.keys(woodFramingLayers).map((key) => ({
        ...woodFramingLayers[key],
        id: key,
        type: "woodFraming",
    }));

    const steelFraming = Object.keys(steelFramingLayers).map((key) => ({
        ...steelFramingLayers[key],
        id: key,
        type: "steelFraming",
    }));

    const strapping = Object.keys(strappingLayers).map((key) => ({
        ...strappingLayers[key],
        id: key,
        type: "strapping",
    }));

    const codeLayers = [...medium, ...insulation, ...woodFraming, ...steelFraming, ...strapping].sort(layerSort);

    const modelCodes = modelSelector({ form }, "modelData.codes") || {};
    const components = modelSelector({ form }, "modelData.components") || {};
    const { codeRef: editingCodeRef = null, isLibCode = false } = udefStandard;

    return {
        componentType,
        initialValues: {
            uDefCode: isEmpty(udefStandard) ? uDefCodeTemplate : udefStandard,
            addToLibrary: isEmpty(udefStandard) ? true : isLibCode,
        },
        codeLayers,
        numLayers: codeLayers.length,
        modelFormChange,
        fieldAccessor,
        modelCodes,
        editingCodeRef,
        isEditing: !!editingCodeRef,
        isLibCode,
        uid,
        isCodeLibrary,
        isUpgradeComponent: fieldAccessor.includes("fields."),
        handleUdefSave,
        components,
        currentFormChange,
        currentPackage,
        selectedUpgrade,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        fieldAccessor,
        editingCodeRef,
        modelFormChange,
        uid,
        componentType,
        isCodeLibrary,
        handleUdefSave,
        isEditing,
        modelCodes,
        components,
        currentFormChange,
        currentPackage,
        selectedUpgrade,
    }
) => {
    const { uDefCode = {}, addToLibrary = false, setAsModelDefault = false, selectedComponents = [] } = form;
    const { label = "", isLibCode } = uDefCode;

    //Close drawer
    handleUdefSave();

    // 1. Create and change code ref

    componentType = componentType === "PonyWall" ? "CrawlspaceWall" : componentType;

    const newCodeRef = `${componentType}-U-${idIfyDate()}`;
    const forceNewCodeRef = (isLibCode && !isCodeLibrary) || (isEditing && isCodeLibrary) || isEditing;
    const setCodeRef = forceNewCodeRef ? newCodeRef : editingCodeRef || newCodeRef;

    // ***************************************
    // 2. Fetch rValue
    // ***************************************
    const nomRVal = getNominalRValueUDef(uDefCode);

    // ***************************************
    // 3. If in model, do model things
    // ***************************************
    if (!isCodeLibrary) {
        const code = {
            ...uDefCode,
            codeRef: setCodeRef,
            nominalRValue: nomRVal,
        };

        //Add to upgrade codes if in upgrade library
        if (currentPackage && selectedUpgrade) {
            const currentUpgrade = currentPackage.upgrades?.[selectedUpgrade] || {};

            dispatch(
                updateUpgrade(selectedUpgrade, {
                    ...currentUpgrade,
                    codes: currentUpgrade
                        ? { ...currentUpgrade.codes, [code.codeRef]: code }
                        : { [code.codeRef]: code },
                })
            );
        }

        modelFormChange(`modelData.codes.${setCodeRef}`, code);

        const fieldValue = {
            codeLabel: label,
            codeRef: setCodeRef,
        };

        // Update model "type" field in component
        currentFormChange(fieldAccessor, fieldValue);
        currentFormChange(`${fieldAccessor}_effRVal`, nomRVal);
        currentFormChange(`${fieldAccessor}_nomRVal`, nomRVal);

        const compField = fieldPathCodeTypeMap[componentType];
        if (setAsModelDefault) {
            const defaultValue = {
                [compField]: fieldValue,
                [`${compField}_nomRVal`]: nomRVal,
                [`${compField}_effRVal`]: nomRVal,
            };
            modelFormChange(`modelData.defaultCodes.${componentType}`, defaultValue);
        }

        if (!isEmpty(selectedComponents)) {
            selectedComponents.forEach((path) => {
                currentFormChange(`modelData.components.${path}.${compField}`, fieldValue);
                currentFormChange(`modelData.components.${path}.${compField}_effRVal`, nomRVal);
                currentFormChange(`modelData.components.${path}.${compField}_nomRVal`, nomRVal);
            });
        }

        //If it is a code library code we must update all other Walls with the same reference
        if (isEditing && isLibCode) {
            const accessorMatches =
                componentType === "FloorHeader"
                    ? getMatchFloorHeaderCodeRefs(components, editingCodeRef)
                    : getMatchCodeRefs(components, setCodeRef, editingCodeRef);

            if (!isEmpty(accessorMatches)) {
                accessorMatches.forEach((path) => {
                    if (!path.includes(fieldAccessor)) {
                        modelFormChange(path, fieldValue);
                        modelFormChange(`${path}_effRVal`, nomRVal);
                        modelFormChange(`${path}_nomRVal`, nomRVal);
                    }
                });
            }
        }

        if (forceNewCodeRef && addToLibrary) {
            //Must delete from modelCodes here at the end, after updating every other codeRef
            // const otherComponentsWithCode =
            //     getMatchCodeRefs(components, setCodeRef, editingCodeRef).filter(
            //         (path) => !path.includes(fieldAccessor)
            //     ) || [];
            // if (isEmpty(otherComponentsWithCode) || isLibCode) {
            //     modelFormChange("modelData.codes", {
            //         ...Object.keys(modelCodes).reduce((acc, curr) => {
            //             return curr === editingCodeRef ? acc : { ...acc, [curr]: modelCodes[curr] };
            //         }, {}),
            //         [setCodeRef]: code,
            //     });
            // }
            // modelFormChange("modelData.codes", {
            //     ...Object.keys(modelCodes).reduce((acc, curr) => {
            //         return curr === editingCodeRef ? acc : { ...acc, [curr]: modelCodes[curr] };
            //     }, {}),
            //     [setCodeRef]: code,
            // });
        }
    }

    // ***************************************
    // 4. If in code library or "add to code library" is selected, do code library things
    // ***************************************
    if (addToLibrary || isCodeLibrary) {
        await dispatch(
            addToCodeLib(
                uid,
                {
                    ...uDefCode,
                    nominalRValue: nomRVal,
                    label,
                    codeType: "User Defined",
                    componentType: componentType,
                    codeRef: setCodeRef,
                },
                componentType,
                setCodeRef
            )
        )
            .then(() => {
                //If we're overwriting an existing code, delete the old one
                if (forceNewCodeRef) {
                    dispatch(deleteCode(uid, editingCodeRef, componentType));
                }
            })
            .catch(() => {});
    }

    // Clear udef code defaults for next time
    dispatch(setInitCode({}, "udefStandard"));
    // Reset form
    dispatch(reset("uDefCode"));
};

const mapDispatchToProps = (dispatch) => ({});

const form = reduxForm({
    form: "uDefCode",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(StandardLayers);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
