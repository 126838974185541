import React from "react";

import WarningIcon from "assets/images/icons/warning.svg";
import WarningOrangeIcon from "assets/images/icons/WarningOrange.svg";
import ModelPassIcon from "assets/images/icons/ModelPass.svg";

import classes from "./styles.module.scss";

const ParametricAnalysisTitleStatus = ({ modelName, selectedModelHasResults, ecmInputMap = {}, sideContent }) => {
    const allActiveUpgradesValidated = Object.values(ecmInputMap)
        .map((upgrades) => {
            return Object.values(upgrades).every(({ passed = false, active }) => {
                return active == passed || active == false;
            });
        })
        .every((el) => el);

    return (
        <div className={classes.modelTitleStatusContainer}>
            <div className={classes.modelTitleStatusWrapper}>
                <span className={classes.title}>{modelName}</span>
                <div className={classes.statusWrapper}>
                    {!allActiveUpgradesValidated ? (
                        <>
                            <img src={WarningOrangeIcon} alt={"Warning Icon"} />
                            <span className={classes.statusText}>Unvalidated upgrades present</span>
                        </>
                    ) : selectedModelHasResults ? (
                        <>
                            <img src={ModelPassIcon} alt={"Pass Icon"} />
                            <span className={classes.statusText}>Ready for simulation </span>
                        </>
                    ) : (
                        <>
                            <img src={WarningIcon} alt={"Warning Icon"} />
                            <span className={classes.statusText}>Model incomplete</span>
                        </>
                    )}
                </div>
            </div>
            {sideContent && <div className={classes.sideContent}>{sideContent}</div>}
        </div>
    );
};

export default ParametricAnalysisTitleStatus;
