import React, { useState } from "react";

import UpgradesSelection from "../UpgradesSelection";
import ParametricUpgradesTableBody from "../ParametricUpgradesTableBody";
import ParametricUpgradesTableFooter from "../ParametricUpgradesTableFooter";
import UpgradeDrawer from "features/Model/Upgrades/UpgradeDrawer/container";
import CodeDrawer from "features/Model/CodeDrawer/container";

import classes from "./styles.module.scss";

const ParametricAnalysisUpgradesTable = ({ upgrades, ecmInputMap, isValidationRunComplete, analysisId }) => {
    const [selectedUpgradeType, setSelectedUpgradeType] = useState({});
    const [upgradeOpen, toggleUpgrade] = useState({});
    const [selectedUpgradePackage, setSelectedUpgradePackage] = useState("");
    const [upgradeWhereToAddCode, setUpgradeWhereToAddCode] = useState({});

    return (
        <>
            <div className={classes.parametricAnalysisUpgradesTableContainer}>
                <UpgradesSelection
                    selectedUpgradeType={selectedUpgradeType}
                    setSelectedUpgradeType={setSelectedUpgradeType}
                    selectedUpgradePackage={selectedUpgradePackage}
                    setSelectedUpgradePackage={setSelectedUpgradePackage}
                    upgrades={upgrades}
                    ecmInputMap={ecmInputMap}
                    toggleUpgrade={toggleUpgrade}
                    upgradeWhereToAddCode={upgradeWhereToAddCode}
                    setUpgradeWhereToAddCode={setUpgradeWhereToAddCode}
                />
                <div style={{ marginTop: "15px" }}>
                    <ParametricUpgradesTableBody
                        ecmInputMap={ecmInputMap}
                        toggleUpgrade={toggleUpgrade}
                        setUpgradeWhereToAddCode={setUpgradeWhereToAddCode}
                        isValidationRunComplete={isValidationRunComplete}
                        analysisId={analysisId}
                    />
                    <ParametricUpgradesTableFooter ecmInputMap={ecmInputMap} />
                </div>
            </div>
            <UpgradeDrawer toggleOpen={toggleUpgrade} modelChange={() => {}} {...upgradeOpen} />
            <CodeDrawer upgradeWhereToAddCode={upgradeWhereToAddCode} />
        </>
    );
};

export default ParametricAnalysisUpgradesTable;
