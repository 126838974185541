import React, { useEffect, useState } from "react";
import classes from "../style.module.scss";
import InfoTooltip from "components/InfoTooltip";
import Units from "./Units";
import convertUnit from "utils/convertUnit";
import DrawingIcon from "assets/images/icons/JSX/Drawing";
import Tooltip from "components/Tooltip";
import EditTooltip from "../EditTooltip";
import { mixpanel } from "components/Mixpanel";
import LoadingDots from "components/LoadingDots";
import WarningTooltip from "components/WarningTooltip";
import NonH2kParamTooltip from "components/NonH2kParamTooltip";
import classNames from "classnames";
import { isEquation, parseEquation } from "utils/fieldEquation";
import EquationTooltip from "../EquationTooltip";

//TODO: make tooltip more unique
const InputWithUnits = ({
    label,
    input = {},
    meta = {},
    type,
    className,
    large,
    placeholder = "",
    hideLabel,
    autoComplete = "off",
    info = "",
    nonH2kParam = "",
    disabled = false,
    parentError = false,
    quantity = false,
    setValue,
    decimals = 0,
    hideField,
    change: modelFormChange,
    equation,
    units: { base: { trueBase = "m", displayBase = "m" } = {}, options = [], selected = "", unitType, accessor } = {},
    isDrawing = false,
    isDrawingTip = "",
    editToggle,
    withEquation = false,
    onUnitChange = () => "",
    isLoading = false,
    warning = "",
    reset = false,
    style,
}) => {
    const [selectedUnit, changeSelectedUnit] = useState(selected || displayBase || trueBase);
    const [displayValue, changeDisplayValue] = useState("");
    const [focused, toggleFocused] = useState(false);

    const [equationValue, changeEquationValue] = useState("");
    const [invalidEquation, setInvalidEquation] = useState(false);

    const { invalid = false, error = "" } = meta;
    // 'value' here represents the trueValue
    const { value, onChange, name = "" } = input;

    const equ_accessor = `${name}_eqn`;

    const mainClasses = [
        classes.inputField,
        className && className,
        large && classes.large,
        quantity && classes.hasQuantity,
        (parentError || invalid) && classes.invalid,
        warning && classes.warning,
        hideField && classes.hideField,
    ].filter((c) => c);

    const handleSetValue = () => {
        if (isNaN(setValue)) {
            // Sometimes setValue is an object
            // Add decimals to field when display value is 0
            changeDisplayValue(parseFloat("0.00").toFixed(decimals));
        } else {
            const newDisplayValue = parseFloat(
                convertUnit({
                    value: parseFloat(setValue),
                    type: unitType,
                    inputUnit: trueBase,
                    outputUnit: selectedUnit,
                })
            ).toFixed(decimals);

            changeDisplayValue(newDisplayValue);
            onChange(parseFloat(parseFloat(setValue).toFixed(decimals + 2)));
        }
    };

    // Handles any change made to the display field
    const handleChange = (e) => {
        const newDisplayValue = parseFloat(e.target.value);
        changeDisplayValue(e.target.value);

        const newTrueValue = parseFloat(
            convertUnit({
                value: newDisplayValue,
                type: unitType,
                inputUnit: selectedUnit,
                outputUnit: trueBase,
            }).toFixed(decimals + 2)
        );

        onChange(newTrueValue);
    };

    // Handles any change made to the equation field
    const handleEqnChange = (e) => {
        var eqnInput = e.target.value.trimStart();
        let newDisplayValue = parseEquation(eqnInput);
        let isInvalid = newDisplayValue === null ? true : false;

        // If input does not start with "=" and is not a number assume it's an equation and autofill =
        if (!isEquation(eqnInput) && isNaN(eqnInput)) {
            eqnInput = "=" + eqnInput;
        }

        setInvalidEquation(isInvalid);
        changeEquationValue(eqnInput);
        changeDisplayValue(newDisplayValue);

        // Save trueValue and equation value if equation is valid
        if (!isInvalid) {
            const newTrueValue = parseFloat(
                convertUnit({
                    value: newDisplayValue,
                    type: unitType,
                    inputUnit: selectedUnit,
                    outputUnit: trueBase,
                }).toFixed(decimals + 2)
            );

            onChange(newTrueValue);
            modelFormChange(equ_accessor, eqnInput);
        }
    };

    // Handles what happens when the user leaves the display field
    const handleBlur = () => {
        // Sets newTrueValue to a number (0) to fix incorrect input data type error
        const newTrueValue = parseFloat(parseFloat(value).toFixed(decimals + 2));
        // Sets newDisplayValue to a string (0.00) to autofill decimals in field
        const newDisplayValue = parseFloat(displayValue).toFixed(decimals);

        toggleFocused(false);
        onChange(newTrueValue);
        changeDisplayValue(newDisplayValue);

        if (setValue || setValue === 0) {
            handleSetValue();
        }
    };

    // Handles what happens when the user leaves the equation field
    const handleEqnBlur = () => {
        handleBlur();

        if (equationValue === "") {
            // If equation cleared and field exited, set equation value to 0
            changeEquationValue(parseFloat("0.00").toFixed(decimals));
        } else if (!isEquation(equationValue) && !isNaN(equationValue)) {
            // If number entered, autofill decimal places before saving
            changeEquationValue(parseFloat(equationValue).toFixed(decimals));
        }
    };

    // Handles a new unit selection
    const handleUnitChange = (unit) => {
        const newDisplayValue = parseFloat(
            convertUnit({
                value: value, //Always using value here so we don't lose decimals
                type: unitType,
                inputUnit: trueBase,
                outputUnit: unit,
            })
        ).toFixed(decimals);

        changeDisplayValue(parseFloat(newDisplayValue));
        changeSelectedUnit(unit);

        // Reset equation to display value
        changeEquationValue(newDisplayValue);

        // Changes the unit field in the form
        modelFormChange(accessor, unit);
        onUnitChange(unit);
    };

    useEffect(() => {
        // Get the right display value when component is initialized
        const newDisplayValue = parseFloat(
            convertUnit({
                value: value,
                type: unitType,
                inputUnit: trueBase,
                outputUnit: selectedUnit,
            })
        ).toFixed(decimals);

        if (value || value === 0) {
            onChange(parseFloat(value));
            changeDisplayValue(newDisplayValue);
            changeEquationValue(equation ? equation : newDisplayValue >= 0 ? newDisplayValue : ""); // assuming all values are positive
        }
    }, []);

    useEffect(() => {
        if (reset) {
            onChange(null);
            changeDisplayValue("");
            changeEquationValue("");
        }
    }, [reset]);

    // why goes into infinite loop?
    useEffect(() => {
        // If value is manually set, do stuff
        if ((setValue || setValue === 0) && !focused) {
            handleSetValue();
        }
    }, [setValue]);

    return (
        <div className={`${mainClasses.join(" ")} ${invalidEquation && classes.invalid}`} style={style}>
            <label className={`${hideLabel && "invisible"} ${isDrawing && classes.isDrawing}`} htmlFor={name}>
                {label}{" "}
                {isDrawing && (
                    <>
                        <span
                            data-html
                            data-for={`${name}_drawingTip`}
                            data-tip={`<span style="display:block;max-width:10.5rem;text-align:center;">${
                                isDrawingTip
                                    ? isDrawingTip
                                    : "This field can only be edited in the <strong>Drawing Capture</strong> tool."
                            }</span>`}
                        >
                            <DrawingIcon />
                        </span>
                        <Tooltip id={`${name}_drawingTip`} />
                    </>
                )}
            </label>
            {info && <InfoTooltip className={classes.infoTooltip} info={info} />}
            {warning && (
                <WarningTooltip
                    className={classNames(classes.warningTooltip, {
                        [classes.pushLeft]: editToggle || info,
                    })}
                    warning={warning}
                />
            )}
            {nonH2kParam && (
                <NonH2kParamTooltip
                    className={`${classes.nonH2kParamTooltip} ${warning && classes.pushLeft}`}
                    nonH2kParam={nonH2kParam}
                />
            )}
            {editToggle && (
                <EditTooltip
                    onClick={() => {
                        editToggle();
                        mixpanel.track("Summary Calculation Open", {
                            "Field Name": label,
                        });
                    }}
                    name={name}
                    label={label}
                />
            )}
            {withEquation && !disabled && (
                <EquationTooltip
                    className={classNames(classes.equationTooltip, {
                        [classes.pushLeft]: info || warning || nonH2kParam,
                        [classes.pushFarLeft]: (info && (warning || nonH2kParam)) || (warning && nonH2kParam),
                    })}
                    name={name}
                    hasEquation={isEquation(equationValue)}
                    isInvalid={invalidEquation}
                />
            )}
            {isLoading && (
                <div className={classes.loading}>
                    <LoadingDots />
                </div>
            )}
            {!isLoading && (
                <div className={classes.inputWithUnit}>
                    {!withEquation && (
                        <input
                            id={`${name}-front`}
                            name={`${name}-front`}
                            type={type}
                            placeholder={placeholder}
                            autoComplete={autoComplete}
                            disabled={disabled}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={() => toggleFocused(true)}
                            value={displayValue}
                        />
                    )}
                    {withEquation && (
                        <input
                            id={`${name}-eqn`}
                            name={`${name}-eqn`}
                            type="text"
                            placeholder={focused || invalidEquation ? "Enter equation or value" : placeholder}
                            disabled={disabled}
                            onChange={handleEqnChange}
                            onBlur={handleEqnBlur}
                            onFocus={() => toggleFocused(true)}
                            value={focused || invalidEquation ? equationValue : displayValue}
                            onKeyDown={(event) => {
                                event.key === "Enter" ? handleEqnBlur() : toggleFocused(true);
                            }}
                        />
                    )}

                    <input {...input} id={name} name={name} type="hidden" />
                    <Units selectedUnit={selectedUnit} unitOptions={options} changeSelected={handleUnitChange} />
                </div>
            )}

            {!invalidEquation && invalid && error && <span className={classes.errorMessage}>{error}</span>}
            {invalidEquation && <span className={classes.errorMessage}>Invalid equation entered</span>}
        </div>
    );
};

export default InputWithUnits;
