import React from "react";

import ModelTable from "./ModelTable";
import ModelSummary from "./ModelSummary";

import classes from "./styles.module.scss";

const ModelInformation = ({ modelData }) => {
    const {
        components = {},
        airTightness = {},
        codes = {},
        heatingCooling = {},
        domesticHotWater = {},
        ventilation = {},
        generation = {},
        program = {},
        baseLoads = {},
    } = modelData || {};

    return (
        <div className={classes.modelInformationContainer}>
            <ModelTable
                components={components}
                airTightness={airTightness}
                codes={codes}
                heatingCooling={heatingCooling}
                domesticHotWater={domesticHotWater}
                ventilation={ventilation}
                generation={generation}
                program={program}
                baseLoads={baseLoads}
            />
            <ModelSummary modelData={modelData} />
        </div>
    );
};

export default ModelInformation;
