import types from "./types";

export const createParametricAnalysisStart = () => ({
    type: types.CREATE_PARAMETRIC_ANALYSIS_START,
});

export const createParametricAnalysisSuccess = (analysis) => ({
    type: types.CREATE_PARAMETRIC_ANALYSIS_SUCCESS,
    payload: analysis,
});

export const createParametricAnalysisError = (error) => ({
    type: types.CREATE_PARAMETRIC_ANALYSIS_ERROR,
    payload: error,
});

export const clearParametricAnalysis = () => ({
    type: types.CLEAR_PARAMETRIC_ANALYSIS,
});

export const deleteParametricAnalysisStart = () => ({
    type: types.DELETE_PARAMETRIC_ANALYSIS_START,
});

export const deleteParametricAnalysisSuccess = () => ({
    type: types.DELETE_PARAMETRIC_ANALYSIS_SUCCESS,
});

export const deleteParametricAnalysisError = (error) => ({
    type: types.DELETE_PARAMETRIC_ANALYSIS_ERROR,
    payload: error,
});

export const moveParametricAnalysisStart = () => ({
    type: types.MOVE_PARAMETRIC_ANALYSIS_START,
});

export const moveParametricAnalysisSuccess = () => ({
    type: types.MOVE_PARAMETRIC_ANALYSIS_SUCCESS,
});

export const moveParametricAnalysisError = (error) => ({
    type: types.MOVE_PARAMETRIC_ANALYSIS_ERROR,
    payload: error,
});

export const dublicateParametricAnalysisStart = () => ({
    type: types.DUPLICATE_PARAMETRIC_ANALYSIS_START,
});

export const dublicateParametricAnalysisSuccess = () => ({
    type: types.DUPLICATE_PARAMETRIC_ANALYSIS_SUCCESS,
});

export const dublicateParametricAnalysisError = (error) => ({
    type: types.DUPLICATE_PARAMETRIC_ANALYSIS_ERROR,
    payload: error,
});

export const batchDeleteParametricAnalysisStart = () => ({
    type: types.BATCH_DELETE_PARAMETRIC_ANALYSIS_START,
});

export const batchDeleteParametricAnalysisSuccess = () => ({
    type: types.BATCH_DELETE_PARAMETRIC_ANALYSIS_SUCCESS,
});

export const batchDeleteParametricAnalysisError = (error) => ({
    type: types.BATCH_DELETE_PARAMETRIC_ANALYSIS_ERROR,
    payload: error,
});

export const fetchParametricAnalysisStart = () => ({
    type: types.FETCH_PARAMETRIC_ANALYSIS_START,
});

export const fetchParametricAnalysisSuccess = (analysis) => ({
    type: types.FETCH_PARAMETRIC_ANALYSIS_SUCCESS,
    payload: analysis,
});

export const fetchParametricAnalysisError = (error) => ({
    type: types.FETCH_PARAMETRIC_ANALYSIS_ERROR,
    payload: error,
});

export const syncParametricAnalysisStart = () => ({
    type: types.SYNC_PARAMETRIC_ANALYSIS_START,
});

export const syncParametricAnalysisSuccess = (analysis) => ({
    type: types.SYNC_PARAMETRIC_ANALYSIS_SUCCESS,
    payload: analysis,
});

export const syncParametricAnalysisError = (error) => ({
    type: types.SYNC_PARAMETRIC_ANALYSIS_ERROR,
    payload: error,
});

export const saveEcmInputMapStart = () => ({
    type: types.SAVE_ECM_INPUT_MAP_START,
});

export const saveEcmInputMapSuccess = () => ({
    type: types.SAVE_ECM_INPUT_MAP_SUCCESS,
});

export const saveEcmInputMapError = (error) => ({
    type: types.SAVE_ECM_INPUT_MAP_ERROR,
    payload: error,
});

export const pinUnpinRecipesStart = () => ({
    type: types.PIN_UNPIN_RECIPES_START,
});

export const pinUnpinRecipesSuccess = (pinnedRecipes) => ({
    type: types.PIN_UNPIN_RECIPES_SUCCESS,
    payload: pinnedRecipes,
});

export const pinUnpinRecipesError = (error) => ({
    type: types.PIN_UNPIN_RECIPES_ERROR,
    payload: error,
});

export const updatePriceStateParametricStart = () => ({
    type: types.UPDATE_PRICE_STATE_PARAMETRIC_START,
});

export const updatePriceStateParametricSuccess = () => ({
    type: types.UPDATE_PRICE_STATE_PARAMETRIC_SUCCESS,
});

export const updatePriceStateParametricError = (error) => ({
    type: types.UPDATE_PRICE_STATE_PARAMETRIC_ERROR,
    payload: error,
});

// ****************************************************
// SYNCHRONOUS ACTIONS
// ****************************************************

export const addUpgradeToParametricAnalysis = (sectionType, upgrade) => ({
    type: types.ADD_UPGRADE_TO_PARAMETRIC_ANALYSIS,
    payload: { sectionType, upgrade },
});

export const removeUpgradeFromParametricAnalysis = (sectionType, upgradeId) => ({
    type: types.REMOVE_UPGRADE_FROM_PARAMETRIC_ANALYSIS,
    payload: { sectionType, upgradeId },
});

export const toggleUpgradeInParametricAnalysis = (sectionType, upgradeId, toggleValue) => ({
    type: types.TOGGLE_UPGRADE_IN_PARAMETRIC_ANALYSIS,
    payload: { sectionType, upgradeId, toggleValue },
});

export const updateEcmInputMap = (ecmInputMap) => ({
    type: types.UPDATE_ECM_INPUT_MAP,
    payload: ecmInputMap,
});

export const updateEcmInputMapUpgrade = (sectionType, upgradeId, upgrade) => ({
    type: types.UPDATE_ECM_INPUT_MAP_UPGRADE,
    payload: { sectionType, upgradeId, upgrade },
});

export const updateValidationPreCheckRunStatus = (status) => ({
    type: types.UPDATE_VALIDATION_PRE_CHECK_RUN_STATUS,
    payload: status,
});

export const updateRunResults = (runResults) => ({
    type: types.UPDATE_RUN_RESULTS,
    payload: runResults,
});

// ****************************************************
// FOLDER ACTIONS
// ****************************************************
export const createParametricAnalysisFolderStart = () => ({
    type: types.CREATE_PARAMETRIC_ANALYSIS_FOLDER_START,
});

export const createParametricAnalysisFolderSuccess = () => ({
    type: types.CREATE_PARAMETRIC_ANALYSIS_FOLDER_SUCCESS,
});

export const createParametricAnalysisFolderError = (error) => ({
    type: types.CREATE_PARAMETRIC_ANALYSIS_FOLDER_ERROR,
    payload: error,
});

export const deleteParametricAnalysisFolderStart = () => ({
    type: types.DELETE_PARAMETRIC_ANALYSIS_FOLDER_START,
});

export const deleteParametricAnalysisFolderSuccess = () => ({
    type: types.DELETE_PARAMETRIC_ANALYSIS_FOLDER_SUCCESS,
});

export const deleteParametricAnalysisFolderError = (error) => ({
    type: types.DELETE_PARAMETRIC_ANALYSIS_FOLDER_ERROR,
    payload: error,
});

export const moveParametricAnalysisFolderStart = () => ({
    type: types.MOVE_PARAMETRIC_ANALYSIS_FOLDER_START,
});

export const moveParametricAnalysisFolderSuccess = () => ({
    type: types.MOVE_PARAMETRIC_ANALYSIS_FOLDER_SUCCESS,
});

export const moveParametricAnalysisFolderError = (error) => ({
    type: types.MOVE_PARAMETRIC_ANALYSIS_FOLDER_ERROR,
    payload: error,
});

export const renameParametricAnalysisFolderStart = () => ({
    type: types.RENAME_PARAMETRIC_ANALYSIS_FOLDER_START,
});

export const renameParametricAnalysisFolderSuccess = () => ({
    type: types.RENAME_PARAMETRIC_ANALYSIS_FOLDER_SUCCESS,
});

export const renameParametricAnalysisFolderError = (error) => ({
    type: types.RENAME_PARAMETRIC_ANALYSIS_FOLDER_ERROR,
    payload: error,
});

export const batchDeleteParametricAnalysisFoldersStart = () => ({
    type: types.BATCH_DELETE_PARAMETRIC_ANALYSIS_FOLDERS_START,
});

export const batchDeleteParametricAnalysisFoldersSuccess = () => ({
    type: types.BATCH_DELETE_PARAMETRIC_ANALYSIS_FOLDERS_SUCCESS,
});

export const batchDeleteParametricAnalysisFoldersError = (error) => ({
    type: types.BATCH_DELETE_PARAMETRIC_ANALYSIS_FOLDERS_ERROR,
    payload: error,
});
