import { connect } from "react-redux";
import { reduxForm, formValueSelector, reset } from "redux-form";
import StandardCode from "./";
import basementWallCodeTemplate from "features/Model/Enclosure/Templates/basementWallCode.json";
import { actions as userActions } from "store/users";
import { actions as enclosureActions } from "features/Model/Enclosure/_ducks";
import { actions as upgradeActions } from "features/Model/Upgrades/_ducks";
import isEmpty from "lodash/isEmpty";
import { standardCodeLogic } from "utils/codes";

const { addToCodeLib, deleteCode } = userActions;
const { setInitCode, getRValue } = enclosureActions;
const { updateUpgradeCodes } = upgradeActions;

const mapStateToProps = (
    {
        form,
        model: { modelId },
        user: { uid, codeLib: { BasementWall: { codes: basementWallCodeLib = {} } = {} } = {} },
        upgradeLibrary: { currentPackage, selectedUpgrade },
        enclosure: { initCodeValues: { basementWallInsType = {} } = {} } = {},
    },
    { fieldAccessor, modelFormChange, currentFormChange, toggleOpen, isCodeLibrary = false, componentId }
) => {
    const selector = formValueSelector("basementWallCode");
    const modelSelector = formValueSelector("model");
    const { id: framingId, value: framingValue } = selector({ form }, "basementWallCode.layers.framing") || {};
    const { value: spacingValue } = selector({ form }, "basementWallCode.layers.spacing") || {};
    const { value: studsValue } = selector({ form }, "basementWallCode.layers.studsCornerIntersection") || {};
    const { value: framingInsValue } = selector({ form }, "basementWallCode.layers.insulationFramingLayer") || {};
    const { value: extraInsValue } = selector({ form }, "basementWallCode.layers.extraInsulationLayer") || {};
    const { value: interiorFinishValue } = selector({ form }, "basementWallCode.layers.interiorFinish") || {};

    const basementWallLabel = selector({ form }, "basementWallCode.label") || "";
    const modelCodes = modelSelector({ form }, "modelData.codes") || {};

    const basementWallValue = `${framingValue}${spacingValue}${studsValue}${framingInsValue}${extraInsValue}${interiorFinishValue}`;

    const { codeRef: editingCodeRef = null, isLibCode = false } = basementWallInsType;

    const components = modelSelector({ form }, "modelData.components") || {};

    return {
        basementWallLabel,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        initialValues: {
            basementWallCode: isEmpty(basementWallInsType) ? basementWallCodeTemplate : basementWallInsType,
            addToLibrary: isLibCode,
        },
        framingId,
        noFraming: parseFloat(framingId) === 0,
        basementWallValue,
        modelId,
        uid,
        modelCodes,
        basementWallCodeLib,
        editingCodeRef,
        isEditing: !!editingCodeRef,
        isLibCode,
        toggleOpen,
        isCodeLibrary,
        componentId,
        components,
        currentPackage,
        selectedUpgrade,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearForm: () => dispatch(reset("basementWallCode")),
    clearInitCode: () => dispatch(setInitCode({}, "basementWallInsType")),
});

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const onSubmit = async (
    form,
    dispatch,
    {
        toggleOpen,
        uid,
        fieldAccessor,
        modelFormChange,
        currentFormChange,
        editingCodeRef,
        isCodeLibrary,
        componentId,
        isEditing,
        modelCodes,
        components,
        currentPackage,
        selectedUpgrade,
        upgradeWhereToAddCode = {},
    }
) => {
    const { basementWallCode = {}, addToLibrary = false, setAsModelDefault = false, selectedComponents = [] } = form;

    toggleOpen(false);

    const { ecmInputMapUpgrade, ecmInputMapUpgradeId, ecmInputMapUpgradeType } = upgradeWhereToAddCode || {};

    // Run standard code logic
    standardCodeLogic({
        componentCodeType: "BasementWall",
        componentId,
        code: basementWallCode,
        formInputName: "basementWallInsType",
        codeType: "S",
        isCodeLibrary,
        isEditing,
        editingCodeRef,
        dispatch,
        getRValue,
        modelFormChange,
        currentFormChange,
        fieldAccessor,
        components,
        modelCodes,
        addToLibrary,
        addToCodeLib,
        uid,
        deleteCode,
        updateUpgradeCodes,
        setAsModelDefault,
        selectedComponents,
        currentPackage,
        selectedUpgrade,
        ecmInputMapUpgrade,
        ecmInputMapUpgradeId,
        ecmInputMapUpgradeType,
        isEcmInputMap: !isEmpty(upgradeWhereToAddCode),
    });

    // Clear wall code defaults for next time
    dispatch(setInitCode({}, "basementWallInsType"));
    // Reset form
    dispatch(reset("basementWallCode"));
};

const form = reduxForm({
    form: "basementWallCode",
    enableReinitialize: true,
    onSubmit: onSubmit,
})(StandardCode);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form);
