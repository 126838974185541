import React, { useState, useEffect, useCallback } from "react";
import classes from "./style.module.scss";
import globalStyles from "components/globalStyles.module.scss";
import { Field } from "redux-form";
import InputRow from "components/Input/Row";
import InputWithUnits from "components/Input/InputWithUnits";
import { requiredCodeRef, requiredIdOrCodeRef, getValidation, getDecimalPlaces } from "utils/fieldValidation";
import Select from "components/Input/Select";
import Add from "assets/images/icons/JSX/Add";
import Book from "assets/images/icons/JSX/Book";
import Edit from "assets/images/icons/JSX/Edit";
import { getOptions, getBaseUnits, getUnitOptions, getFoundationConfigParams } from "utils/fields";
import Tooltip from "components/Tooltip";
import Checkbox from "components/Input/Checkbox";
import { isEmpty, isEqual } from "lodash";

const slabTypeValidation = getValidation("basementSlabInsType");
const floorsAboveTypeValidation = getValidation("basementFloorsAboveType");

const CodeFooter = ({ toggleDrawer, setInitCode, codeType }) => (
    <div
        className={classes.codeFooter}
        onClick={async () => {
            await setInitCode({}, codeType);
            toggleDrawer(true);
        }}
    >
        <span>Create New Code</span>
        <Add />
    </div>
);

const codeSort = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

const LabelActionComponent = ({ codeRef, handleEdit, codeType }) => (
    <>
        <div
            className={globalStyles.editCode}
            data-tip="Edit Code"
            data-for={codeRef}
            onClick={(event) => handleEdit(event, codeRef, codeType)}
        >
            <Edit />
            <Tooltip id={codeRef} className={globalStyles.fieldTooltip} place="left" />
        </div>
    </>
);

// Slab Ins. Type codes found in code library
// Slabs don't have user defined codes
const slabTypeLibCodesMap = (libCodes, handleEditLibSlabCode) =>
    Object.keys(libCodes)
        .map((codeRef) => {
            const { label = "" } = libCodes[codeRef] || {};
            const [standardCode] = codeRef.split("FloorsAdded-U");

            return {
                label: label,
                labelIcon: Book,
                labelActionComponent:
                    standardCode && handleEditLibSlabCode
                        ? () => <LabelActionComponent codeRef={codeRef} handleEdit={handleEditLibSlabCode} />
                        : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Slab Ins. Type codes found in model, filtered by those in library
// Slabs don't have user defined codes
const slabTypeModelCodesMap = (modelCodes, libCodes, handleEditModelSlabCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCodes[codeRef]) {
                return (
                    (!libCodes[codeRef] && component === "FloorsAdded") ||
                    !isEqual(modelCodes[codeRef].layers, libCodes[codeRef].layers)
                );
            }
            return !libCodes[codeRef] && component === "FloorsAdded";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("FloorsAdded-U");

            return {
                label,
                labelActionComponent: standardCode
                    ? () => <LabelActionComponent codeRef={codeRef} handleEdit={handleEditModelSlabCode} />
                    : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Floors Above Foundation Type codes found in code library
const floorsAboveTypeLibCodesMap = (libFloorsAboveCodes, handleEditLibFloorsAboveCode) =>
    Object.keys(libFloorsAboveCodes)
        .map((codeRef) => {
            const { label = "" } = libFloorsAboveCodes[codeRef] || {};
            const [standardCode] = codeRef.split("FloorsAbove-U");

            return {
                label: label,
                labelIcon: Book,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () =>
                    handleEditLibFloorsAboveCode ? (
                        <LabelActionComponent
                            codeRef={codeRef}
                            handleEdit={handleEditLibFloorsAboveCode}
                            codeType={standardCode ? "S" : "U"}
                        />
                    ) : null,
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

// Floors Above Foundation Type codes found in model, filtered by those in library
const floorsAboveTypeModelCodesMap = (modelCodes, libCodes, handleEditModelFloorsAboveCode) =>
    Object.values(modelCodes)
        .filter(({ codeRef }) => {
            const [component] = codeRef.split("-");
            if (!!libCodes[codeRef]) {
                return (
                    (!libCodes[codeRef] && component === "FloorsAbove") ||
                    !isEqual(modelCodes[codeRef].layers, libCodes[codeRef].layers)
                );
            }
            return !libCodes[codeRef] && component === "FloorsAbove";
        })
        .map(({ label, codeRef }) => {
            const [standardCode] = codeRef.split("FloorsAbove-U");

            return {
                label,
                labelTag: !standardCode ? "User Defined" : "",
                labelActionComponent: () => (
                    <LabelActionComponent
                        codeRef={codeRef}
                        handleEdit={handleEditModelFloorsAboveCode}
                        codeType={standardCode ? "S" : "U"}
                    />
                ),
                value: {
                    codeLabel: label,
                    codeRef,
                },
            };
        })
        .sort(codeSort);

export default React.memo(
    ({
        accessor,
        selectedSlabCodeRef,
        selectedFloorsAboveCodeRef,
        change,
        modelChange = change,
        id,
        modelUnits,
        rValUnitsSlab,
        rValUnitsFloorsAbove,
        libSlabCodes,
        libFloorsAboveCodes,
        modelCodes,
        selectedSlabRValue,
        selectedFloorsAboveNomRValue,
        setInitCode,
        slabPreBuiltValue,
        basementInsConfig,
        slabCodeWarning,
        floorsAboveCodeWarning,
        isCalculatingRValue,
        isCalculatingSlabRValue,
        updateCodeDrawer,
        disabledCodeEdit = false,
        isUpgrade = false,
        canBeEditedFromParametric = true,
    }) => {
        const handleSlabTypeChange = useCallback(
            ({ codeRef = "", id = "", value = "" }) => {
                if (codeRef === "UserSpecified") {
                    change(`${accessor}.floor.slabInsType_warning`, "");
                    return;
                }

                if (codeRef.includes("FloorsAdded")) {
                    const modelMatch = modelCodes[codeRef] || {};
                    if (!isEmpty(modelMatch)) {
                        change(`${accessor}.floor.slabInsType_nomRVal`, modelMatch.nominalRValue || 0);
                        change(`${accessor}.floor.slabInsType_effRVal`, modelMatch.nominalRValue || 0);
                        change(`${accessor}.floor.slabInsType_warning`, modelMatch.warningType || "");
                    } else {
                        const { nominalRValue = 0, warningType = "" } = libSlabCodes[codeRef] || {};
                        change(`${accessor}.floor.slabInsType_nomRVal`, nominalRValue || 0);
                        change(`${accessor}.floor.slabInsType_effRVal`, nominalRValue || 0);
                        change(`${accessor}.floor.slabInsType_warning`, warningType || "");

                        modelChange(`modelData.codes.${codeRef}`, { ...libSlabCodes[codeRef], codeRef });
                    }
                    //Redundancy
                    modelChange(`stashedCodes.FloorsAdded.${accessor.split(".").slice(-1)[0]}`, codeRef);
                } else if (id != null) {
                    change(`${accessor}.floor.slabInsType_nomRVal`, value || 0);
                    change(`${accessor}.floor.slabInsType_effRVal`, value || 0);
                    change(`${accessor}.floor.slabInsType_warning`, "");
                }
            },
            [libSlabCodes, modelCodes, change, modelChange, accessor, slabPreBuiltValue]
        );

        const handleFloorTypeChange = useCallback(
            ({ codeRef = "" }) => {
                if (codeRef === "UserSpecified") {
                    change(`${accessor}.floor.floorsAboveInsType_warning`, "");
                    change(`${accessor}.floor.floorsAboveInsType_nomRVal`, selectedFloorsAboveNomRValue || 0.1);
                    change(`${accessor}.floor.floorsAboveInsType_effRVal`, selectedFloorsAboveNomRValue || 0.1);
                    return;
                }

                const modelMatch = modelCodes[codeRef] || {};
                if (!isEmpty(modelMatch)) {
                    change(`${accessor}.floor.floorsAboveInsType_nomRVal`, modelMatch.nominalRValue || 0.1);
                    change(`${accessor}.floor.floorsAboveInsType_effRVal`, modelMatch.nominalRValue || 0.1);
                    change(`${accessor}.floor.floorsAboveInsType_warning`, modelMatch.warningType || "");
                } else {
                    const { nominalRValue = 0, warningType = "" } = libFloorsAboveCodes[codeRef] || {};
                    change(`${accessor}.floor.floorsAboveInsType_nomRVal`, nominalRValue || 0.1);
                    change(`${accessor}.floor.floorsAboveInsType_effRVal`, nominalRValue || 0.1);
                    change(`${accessor}.floor.floorsAboveInsType_warning`, warningType || "");

                    modelChange(`modelData.codes.${codeRef}`, { ...libFloorsAboveCodes[codeRef], codeRef });
                }
                //Redundancy
                modelChange(`stashedCodes.FloorsAbove.${accessor.split(".").slice(-1)[0]}`, codeRef);
            },
            [libFloorsAboveCodes, modelCodes, change, modelChange, accessor]
        );

        // useEffect(() => {
        //     if ((selectedSlabCodeRef || "").includes("FloorsAdded-")) {
        //         modelChange(`stashedCodes.FloorsAdded.${id}`, selectedSlabCodeRef);
        //     }
        // }, [id, selectedSlabCodeRef]);

        // useEffect(() => {
        //     if ((selectedFloorsAboveCodeRef || "").includes("FloorsAbove-")) {
        //         modelChange(`stashedCodes.FloorsAbove.${id}`, selectedFloorsAboveCodeRef);
        //     }
        // }, [id, selectedFloorsAboveCodeRef]);

        const handleEditModelSlabCode = useCallback(
            async (event, codeRef) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const code = modelCodes[codeRef];
                await setInitCode(code, "slabInsType");
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "FloorsAdded",
                    isEditing: true,
                    componentType: "FloorsAdded",
                    codeType: "S",
                    fieldAccessor: `${accessor}.slabInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibSlabCode = disabledCodeEdit
            ? null
            : useCallback(
                  async (event, codeRef) => {
                      event.stopPropagation(); //Don't let click event bubble up to parent
                      const code = { ...libSlabCodes[codeRef], codeRef: codeRef, isLibCode: true };
                      await setInitCode(code, "slabInsType");
                      updateCodeDrawer({
                          isOpen: true,
                          codeName: "FloorsAdded",
                          isEditing: true,
                          componentType: "FloorsAdded",
                          codeType: "S",
                          fieldAccessor: `${accessor}.slabInsType`,
                          componentId: id,
                          id: codeRef,
                          modelFormChange: modelChange,
                          currentFormChange: change,
                          lastEdited: code.lastEdited || "",
                      });
                  },
                  [accessor, id, libSlabCodes, setInitCode, updateCodeDrawer, change, modelChange]
              );

        const handleEditModelFloorsAboveCode = useCallback(
            async (event, codeRef, codeType) => {
                event.stopPropagation(); //Don't let click event bubble up to parent
                const initCodeType = codeType === "U" ? "udefStandard" : "floorsAboveInsType";
                const code = modelCodes[codeRef];
                await setInitCode(code, initCodeType);
                updateCodeDrawer({
                    isOpen: true,
                    codeName: "FloorsAbove",
                    isEditing: true,
                    componentType: "FloorsAbove",
                    codeType: codeType,
                    fieldAccessor: `${accessor}.floor.floorsAboveInsType`,
                    componentId: id,
                    id: codeRef,
                    modelFormChange: modelChange,
                    currentFormChange: change,
                    lastEdited: code.lastEdited || "",
                });
            },
            [accessor, id, modelCodes, setInitCode, updateCodeDrawer, change, modelChange]
        );

        const handleEditLibFloorsAboveCode = disabledCodeEdit
            ? null
            : useCallback(
                  async (event, codeRef, codeType) => {
                      event.stopPropagation(); //Don't let click event bubble up to parent
                      const initCodeType = codeType === "U" ? "udefStandard" : "floorsAboveInsType";
                      const code = { ...libFloorsAboveCodes[codeRef], codeRef: codeRef, isLibCode: true };
                      await setInitCode(code, initCodeType);
                      updateCodeDrawer({
                          isOpen: true,
                          codeName: "FloorsAbove",
                          isEditing: true,
                          componentType: "FloorsAbove",
                          codeType: codeType,
                          fieldAccessor: `${accessor}.floor.floorsAboveInsType`,
                          componentId: id,
                          id: codeRef,
                          modelFormChange: modelChange,
                          currentFormChange: change,
                          lastEdited: code.lastEdited || "",
                      });
                  },
                  [accessor, id, libFloorsAboveCodes, setInitCode, updateCodeDrawer, change, modelChange]
              );

        const { slabIns: slabInsAllowed, slabCode: slabCodesAllowed } = getFoundationConfigParams({
            fieldKey: "basementInsConfig",
            configKey: basementInsConfig,
        });

        const lockSlabRVal = selectedSlabCodeRef == null;

        let slabDisplayRValue = 0;
        if (selectedSlabCodeRef !== "UserSpecified") {
            if (lockSlabRVal) {
                //Slab is locked to pre-build option
                slabDisplayRValue = slabPreBuiltValue;
            } else {
                //Allow code r-value
                slabDisplayRValue = selectedSlabRValue;
            }
        } else {
            slabDisplayRValue = null;
        }

        //Pre-define slab options, for the case that code selector options are not permitted
        let slabOpts = [
            {
                label: "User Specified",
                value: {
                    codeLabel: "User Specified",
                    codeRef: "UserSpecified",
                },
            },
        ];

        if (slabCodesAllowed) {
            slabOpts = [
                ...slabOpts,
                ...slabTypeModelCodesMap(modelCodes, libSlabCodes, handleEditModelSlabCode),
                ...slabTypeLibCodesMap(libSlabCodes, handleEditLibSlabCode),
            ];
        }

        slabOpts = [...slabOpts, ...getOptions({ fieldKey: "basementSlabInsType" })];

        const gridTemplate = isUpgrade ? "2fr 1fr 2fr" : "31.85% 23.25% 15% 15%";

        return (
            <>
                <InputRow gridTemplate={gridTemplate}>
                    <Field
                        component={Select}
                        type="number"
                        name={`${accessor}.floor.slabInsType`}
                        options={slabOpts}
                        label="Slab Insulation Type"
                        placeholder="Choose Slab Insulation Type"
                        searchPlaceholder="Search Slab Insulation Codes"
                        search={slabCodesAllowed}
                        footer={() =>
                            slabCodesAllowed ? (
                                <CodeFooter
                                    toggleDrawer={(value) =>
                                        updateCodeDrawer({
                                            isOpen: value,
                                            codeName: "FloorsAdded",
                                            isEditing: false,
                                            componentType: "FloorsAdded",
                                            codeType: "S",
                                            fieldAccessor: `${accessor}.slabInsType`,
                                            componentId: id,
                                            modelFormChange: modelChange,
                                            currentFormChange: change,
                                        })
                                    }
                                    setInitCode={setInitCode}
                                    codeType="slabInsType"
                                />
                            ) : (
                                <></>
                            )
                        }
                        className={classes.slabType}
                        disabled={!slabInsAllowed || !canBeEditedFromParametric}
                        isLoading={isCalculatingSlabRValue}
                        onChange={handleSlabTypeChange}
                        validate={slabInsAllowed ? requiredIdOrCodeRef : undefined}
                    />
                    <Field
                        component={InputWithUnits}
                        type="number"
                        name={
                            lockSlabRVal
                                ? `${accessor}.floor.slabInsType.value`
                                : `${accessor}.floor.slabInsType_nomRVal`
                        }
                        label={
                            selectedSlabCodeRef === "UserSpecified" || lockSlabRVal
                                ? "Effective R-Value"
                                : "Nominal R-Value"
                        }
                        placeholder={0.0}
                        disabled={
                            selectedSlabCodeRef !== "UserSpecified" ||
                            lockSlabRVal ||
                            !slabInsAllowed ||
                            !canBeEditedFromParametric
                        }
                        validate={slabTypeValidation}
                        setTouched={true}
                        decimals={getDecimalPlaces("basementSlabInsType")}
                        change={change}
                        setValue={slabDisplayRValue}
                        units={{
                            base: getBaseUnits("basementSlabInsType", modelUnits),
                            options: getUnitOptions("thermalResistance"),
                            selected: rValUnitsSlab,
                            unitType: "thermalResistance",
                            accessor: `${accessor}.floor.slabInsType_u`,
                        }}
                        onChange={(value) => {
                            if (selectedSlabCodeRef === "UserSpecified") {
                                change(`${accessor}.floor.slabInsType_nomRVal`, value);
                                change(`${accessor}.floor.slabInsType_effRVal`, value);
                            }
                        }}
                        info={
                            "Values calculated by the Code Selector are nominal R-values. HOT2000 re-calculates R-values based on the content of the code when running a simulation."
                        }
                        isLoading={isCalculatingSlabRValue}
                        warning={slabCodeWarning}
                    />
                    {!isUpgrade && (
                        <>
                            <Field
                                component={Checkbox}
                                name={`${accessor}.floor.isBelowFrostline`}
                                label="Is Below Frostline?"
                                large
                                className={classes.floorCheckbox}
                                type="checkbox"
                                disabled={!canBeEditedFromParametric}
                            />
                            <Field
                                component={Checkbox}
                                name={`${accessor}.floor.heatedFloor`}
                                label="Is Floor Heated?"
                                large
                                className={classes.floorCheckbox}
                                type="checkbox"
                                disabled={!canBeEditedFromParametric}
                            />
                        </>
                    )}
                </InputRow>
                <InputRow gridTemplate={gridTemplate}>
                    <Field
                        component={Select}
                        type="number"
                        name={`${accessor}.floor.floorsAboveInsType`}
                        options={[
                            {
                                label: "User Specified",
                                value: {
                                    codeLabel: "User Specified",
                                    codeRef: "UserSpecified",
                                },
                            },
                            ...floorsAboveTypeModelCodesMap(
                                modelCodes,
                                libFloorsAboveCodes,
                                handleEditModelFloorsAboveCode
                            ),
                            ...floorsAboveTypeLibCodesMap(libFloorsAboveCodes, handleEditLibFloorsAboveCode),
                        ]}
                        label="Floors Above Foundation Type"
                        placeholder="Choose Type"
                        searchPlaceholder="Search Codes"
                        search={true}
                        validate={requiredCodeRef}
                        footer={() => (
                            <CodeFooter
                                toggleDrawer={(value) =>
                                    updateCodeDrawer({
                                        isOpen: value,
                                        codeName: "FloorsAbove",
                                        isEditing: false,
                                        componentType: "FloorsAbove",
                                        codeType: "S",
                                        fieldAccessor: `${accessor}.floor.floorsAboveInsType`,
                                        componentId: id,
                                        modelFormChange: modelChange,
                                        currentFormChange: change,
                                    })
                                }
                                setInitCode={setInitCode}
                                codeType="floorsAboveInsType"
                            />
                        )}
                        className={classes.floorsAboveType}
                        info={"The floor above the foundation would typically be the main level's floor"}
                        isLoading={isCalculatingRValue}
                        onChange={handleFloorTypeChange}
                        disabled={!canBeEditedFromParametric}
                    />
                    {selectedFloorsAboveCodeRef === "UserSpecified" ? (
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.floor.floorsAboveInsType_effRVal`}
                            label={"Effective R-Value"}
                            placeholder={0.0}
                            disabled={selectedFloorsAboveCodeRef !== "UserSpecified" || !canBeEditedFromParametric}
                            validate={floorsAboveTypeValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("basementFloorsAboveType")}
                            change={change}
                            units={{
                                base: getBaseUnits("basementFloorsAboveType", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: rValUnitsFloorsAbove,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.floor.floorsAboveInsType_u`,
                            }}
                            onChange={(value) => {
                                if (selectedFloorsAboveCodeRef === "UserSpecified") {
                                    change(`${accessor}.floor.floorsAboveInsType_nomRVal`, value || 0.1);
                                }
                            }}
                            info={"User specified values are assumed to be effective R-values."}
                            // warning={selectedFloorsAboveNomRValue === 0 ? 'floorsAboveZeroRVal' : ''}
                        />
                    ) : (
                        <Field
                            component={InputWithUnits}
                            type="number"
                            name={`${accessor}.floor.floorsAboveInsType_nomRVal`}
                            label={"Nominal R-Value"}
                            placeholder={0.0}
                            disabled={true}
                            validate={floorsAboveTypeValidation}
                            setTouched={true}
                            decimals={getDecimalPlaces("basementFloorsAboveType")}
                            change={change}
                            setValue={selectedFloorsAboveNomRValue || 0.1}
                            units={{
                                base: getBaseUnits("basementFloorsAboveType", modelUnits),
                                options: getUnitOptions("thermalResistance"),
                                selected: rValUnitsFloorsAbove,
                                unitType: "thermalResistance",
                                accessor: `${accessor}.floor.floorsAboveInsType_u`,
                            }}
                            info={
                                "Values calculated by the Code Selector are nominal R-values. HOT2000 re-calculates R-values based on the content of the code when running a simulation."
                            }
                            warning={floorsAboveCodeWarning}
                            isLoading={isCalculatingRValue}
                        />
                    )}
                </InputRow>
            </>
        );
    }
);
