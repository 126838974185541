import React from "react";

// import classes from "./styles.module.scss";
import sharedClasses from "../styles.module.scss";
import { getNumParametricOptionsCombinations } from "utils/parametric";

const ParametricUpgradesTableFooter = ({ ecmInputMap }) => {
    const { totalUniqueOptions, totalCombinations } = getNumParametricOptionsCombinations(ecmInputMap);

    return (
        <>
            <div
                className={`${sharedClasses.parametricUpgradesTableRow} ${sharedClasses.parametricUpgradesTableRowHeading} ${sharedClasses.parametricUpgradesTableRowBorderTop}`}
            >
                <div className={sharedClasses.parametricUpgradesTableRowAutoMarginLeft}>
                    <span>Total unique options: {totalUniqueOptions}</span>
                </div>
            </div>
            <div
                className={`${sharedClasses.parametricUpgradesTableRow} ${sharedClasses.parametricUpgradesTableRowHeading} ${sharedClasses.parametricUpgradesTableRowFooter}`}
            >
                <div className={sharedClasses.parametricUpgradesTableRowAutoMarginLeft}>
                    <span>Total combinations: {totalCombinations}</span>
                </div>
            </div>
        </>
    );
};

export default ParametricUpgradesTableFooter;
