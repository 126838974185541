import React, { useEffect } from "react";
import { useRouteMatch, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { actions as userActions } from "store/users";
import { selectUserUid } from "store/users/selectors";

import { PrivateRoute } from "utils/routes";

import ParametricAnalysisPage from "./ParametricAnalysisPage";
import ParametricAnalysisDashboard from "features/Dashboards/ParametricAnalysisDashboard";
import CreateParametricAnalysis from "./CreateParametricAnalysis";

const { fetchParametricDir } = userActions;

const ParametricAnalysis = ({ userUid, fetchParametricDir }) => {
    const { path } = useRouteMatch();

    useEffect(() => {
        if (!userUid) return;

        fetchParametricDir(userUid);
    }, [fetchParametricDir, userUid]);

    return (
        <>
            <Switch>
                <PrivateRoute path={`${path}/dashboard`} component={ParametricAnalysisDashboard} />
                <PrivateRoute path={`${path}/analysis/:analysisId`} component={ParametricAnalysisPage} />
                <PrivateRoute path={`${path}/create`} component={CreateParametricAnalysis} />
                <Redirect to="/404-page-not-found" />
            </Switch>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    userUid: selectUserUid,
});

const mapDispatchToProps = (dispatch) => ({
    fetchParametricDir: (uid) => dispatch(fetchParametricDir(uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParametricAnalysis);
