import React, { useState } from "react";
import classes from "../style.module.scss";
import sharedClasses from "features/Model/sharedStyles.module.scss";
import { Field } from "redux-form";
import { getOptions } from "utils/fields";
import {
    getCoolingSystemTemplate,
    getEStarOptions,
    getEStarManufacturerOptions,
    getEStarModelOptions,
} from "utils/mechanicals";
import { getValidation } from "utils/fieldValidation";
import InputRow from "components/Input/Row";
import Select from "components/Input/Select";
import Button from "components/Button";
import Drawer from "components/Drawer";
import AddIcon from "assets/images/icons/JSX/Add";
import AirConditioning from "./AirConditioning/container";
import AirHeatPump from "./AirHeatPump/container";
import WaterGroundHeatPump from "./WaterGroundHeatPump/container";
import FansAndPumps from "./FansAndPumps/container";
import AddBox from "components/AddBox";
import Actions from "./Actions/container";

const primaryCoolingValidation = getValidation("coolingPrimarySelected");
const eStarManufacturerValidation = getValidation("coolingEStarManufacturer");
const eStarModelValidation = getValidation("coolingEStarModel");
const seasonStartValidation = getValidation("coolingSeasonStart");
const seasonEndValidation = getValidation("coolingSeasonEnd");
const seasonDesignValidation = getValidation("coolingSeasonDesign");

const typeContent = (type) =>
    ({
        airConditioning: {
            name: "Air Conditioning",
            Component: AirConditioning,
        },
        airHeatPump: {
            name: "Air Source Heat Pump",
            Component: AirHeatPump,
        },
        waterHeatPump: {
            name: "Water Source Heat Pump",
            Component: WaterGroundHeatPump,
        },
        groundHeatPump: {
            name: "Ground Source Heat Pump",
            Component: WaterGroundHeatPump,
        },
    }[type]);

const coolingSystemAddOns = ({ type, h2kMinor }) => {
    if (h2kMinor >= 11 && type !== "airConditioning") {
        return {
            ahri: 0,
            ccashp: false,
            numberOfHeads: 1,
            performanceCurve:
                type === "airHeatPump"
                    ? {
                          cooling: {
                              highCooling: { temperature: 35 }, //95F
                              lowCooling: { temperature: 27.778 }, //82F
                          },
                          heating: {
                              highHeating: { temperature: 8.333 }, // 47F
                              midHeating: { temperature: -8.333 }, // 17F
                              lowHeating: { temperature: -15 }, // 5F
                          },
                      }
                    : {},
        };
    }
    return {};
};

export default ({
    accessor,
    currentPrimarySystem,
    primarySystemType,
    eStarManufacturerValue,
    eStarModelId,
    favId,
    modelUnits,
    currentHeatingSystem,
    change,
    h2kMinor,
    isUpgrade = false,
    formName,
    canBeEditedFromParametric = true,
}) => {
    const [open, toggleOpen] = useState(false);

    const createButtonLabel = () => {
        if (favId) {
            return "Favourite";
        } else if (eStarManufacturerValue && eStarModelId) {
            return "Energy Star";
        } else if (primarySystemType) {
            return "Standard";
        }
        return "";
    };

    const fansAndPumpsObj = {
        flowRate: 0,
        hasEnergyEfficientMotor: false,
        mode: {
            id: 0,
        },
        power: {
            isCalculated: true,
            value: null,
        },
    };

    const handleCreateSystem = ({ type, manufacturer, modelId, favId, h2kMinor }) => {
        let coolingTemplate = {};

        if (favId) {
            //Favourite System
        } else if (manufacturer && modelId) {
            //EStar
            coolingTemplate = getEStarOptions(type)[modelId] || {};
            //Hangle GSHP case
            coolingTemplate =
                type === "groundHeatPump" ? { groundHeatPump: coolingTemplate["waterHeatPump"] } : coolingTemplate;
        } else {
            //Create standard system from template
            coolingTemplate = getCoolingSystemTemplate(type) || {};
        }

        change(`${accessor}.cooling.system`, {
            [type]: {
                ...coolingTemplate[type],
                ...coolingSystemAddOns({ type, h2kMinor }),
            },
        });
        change(`${accessor}.cooling.fansAndPumps`, fansAndPumpsObj);

        if (type.includes("HeatPump") && ["furnace", "boiler"].includes(currentHeatingSystem)) {
            change(`${accessor}.heating.system.${currentHeatingSystem}.isBiEnergy`, false);
            change(`${accessor}.heating.system.${currentHeatingSystem}.switchoverTemp`, 0);
        }

        if (h2kMinor >= 13 && type === "airHeatPump") {
            //Add "units" to object for 11.13 and onwards
            change(`${accessor}.cooling.system.airHeatPump.specifications.heatingEfficiency.unit`, 0);
            change(`${accessor}.cooling.system.airHeatPump.specifications.coolingEfficiency.unit`, 0);
        }

        // if (type === "airHeatPump") {
        //     change(`${accessor}.cooling.system.airHeatPump.performanceCurve`, {
        //         cooling: {
        //             highCooling: { temperature: 35, }, //95F
        //             lowCooling: { temperature: 27.8 }, //82F
        //         },
        //         heating: {
        //             highHeating: { temperature: 8.3,}, // 47F
        //             midHeating: { temperature: -8.3, }, // 17F
        //             lowHeating: { temperature: -15, }, // 5F
        //         },
        //     })
        // }
    };

    const activeCoolingSystem = typeContent(currentPrimarySystem);

    return (
        <div className={!isUpgrade && sharedClasses.section}>
            {!currentPrimarySystem && <AddBox onClick={() => toggleOpen(true)} title="Create Cooling System" icon="" />}
            {currentPrimarySystem && (
                <div className={classes.heading}>
                    <div className={classes.systemsTitle}>
                        <h3>{activeCoolingSystem.name}</h3>
                        <Actions type={currentPrimarySystem} accessor={accessor} change={change} formName={formName} />
                    </div>
                    <Button className={classes.createButton} onClick={() => toggleOpen(true)} icon={AddIcon}>
                        {currentPrimarySystem ? "Change Cooling System" : "Create New System"}
                    </Button>
                </div>
            )}
            <Drawer
                open={open}
                close={() => toggleOpen(false)}
                preTitle="New System"
                title={currentPrimarySystem ? "Change Cooling System" : "Create Cooling System"}
                topBarColor="#0eb0a9"
            >
                <div>
                    <InputRow>
                        <Field
                            component={Select}
                            name={`${accessor}.cooling.selected.type`}
                            options={[
                                {
                                    value: false,
                                    label: "None",
                                },
                                {
                                    value: "airConditioning",
                                    label: "Air Conditioning",
                                },
                                {
                                    value: "airHeatPump",
                                    label: "Air Source Heat Pump",
                                },
                                {
                                    value: "waterHeatPump",
                                    label: "Water Source Heat Pump",
                                },
                                {
                                    value: "groundHeatPump",
                                    label: "Ground Source Heat Pump",
                                },
                            ]}
                            label="Cooling or Heat Pump System Type"
                            placeholder="Choose Cooling or Heat Pump System"
                            validate={primaryCoolingValidation}
                            info={'"Type 2" system in HOT2000'}
                            onChange={() => {
                                change(`${accessor}.cooling.selected.eStar.manufacturer`, false);
                                change(`${accessor}.cooling.selected.eStar.modelId`, false);
                            }}
                            disabled={!canBeEditedFromParametric}
                        />
                    </InputRow>
                    <InputRow>
                        <Field
                            component={Select}
                            name={`${accessor}.cooling.selected.eStar.manufacturer`}
                            options={[
                                {
                                    value: false,
                                    label: "None",
                                },
                                ...getEStarManufacturerOptions(primarySystemType),
                            ]}
                            label="Energy Star Manufacturer"
                            placeholder="Choose Manufacturer"
                            validate={eStarManufacturerValidation}
                            disabled={!primarySystemType || !canBeEditedFromParametric}
                            info={"Select a manufacturer of an Energy Star cooling system"}
                            search={true}
                        />
                    </InputRow>
                    <InputRow>
                        <Field
                            component={Select}
                            name={`${accessor}.cooling.selected.eStar.modelId`}
                            options={[
                                {
                                    value: false,
                                    label: "None",
                                },
                                ...getEStarModelOptions({
                                    systemType: primarySystemType,
                                    manufacturer: eStarManufacturerValue,
                                }),
                            ]}
                            label="Energy Star Model"
                            placeholder="Choose Model"
                            validate={eStarModelValidation}
                            disabled={!primarySystemType || !eStarManufacturerValue || !canBeEditedFromParametric}
                            info={"Select an Energy Star certified model"}
                            search={true}
                        />
                    </InputRow>
                    <div className={classes.buttons}>
                        <Button large type="hollow" onClick={() => toggleOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleCreateSystem({
                                    type: primarySystemType,
                                    manufacturer: eStarManufacturerValue,
                                    modelId: eStarModelId,
                                    h2kMinor,
                                });
                                toggleOpen(false);
                            }}
                            disabled={!primarySystemType || !canBeEditedFromParametric}
                        >
                            {`Create ${createButtonLabel()} System`}
                        </Button>
                    </div>
                </div>
            </Drawer>
            {currentPrimarySystem && (
                <activeCoolingSystem.Component accessor={`${accessor}.cooling`} formName={formName} change={change} />
            )}
            <FansAndPumps accessor={`${accessor}.cooling`} formName={formName} change={change} />
            <h4 style={{ marginBottom: "1.25rem", marginTop: "1.75rem" }}>Cooling Season</h4>
            <InputRow gridTemplate="1fr 1fr 1fr">
                <Field
                    component={Select}
                    name={`${accessor}.cooling.season.start`}
                    options={getOptions({ fieldKey: "coolingSeasonStart" })}
                    label="Start Month"
                    placeholder="Choose Start Month"
                    validate={seasonStartValidation}
                    info={"For EnerGuide compliance leave at the default value (January)."}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={Select}
                    name={`${accessor}.cooling.season.end`}
                    options={getOptions({ fieldKey: "coolingSeasonEnd" })}
                    label="End Month"
                    placeholder="Choose End Month"
                    validate={seasonEndValidation}
                    info={"For EnerGuide compliance leave at the default value (December)."}
                    disabled={!canBeEditedFromParametric}
                />
                <Field
                    component={Select}
                    name={`${accessor}.cooling.season.design`}
                    options={getOptions({ fieldKey: "coolingSeasonDesign" })}
                    label="Design Month"
                    placeholder="Choose Design Month"
                    validate={seasonDesignValidation}
                    info={"For EnerGuide compliance leave at the default value (July)."}
                    disabled={!canBeEditedFromParametric}
                />
            </InputRow>
        </div>
    );
};
