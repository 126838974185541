import React from "react";
import classes from "../style.module.scss";
import { CloseDialog } from "components/Dialog";
import Button from "components/Button";
import Dialog from "components/Dialog";
import Input from "components/Input";
import { isEmpty, isEqual } from "lodash";
import CommunityIcon from "assets/images/icons/community.svg";

const dialogText = {
    folder: {
        icon: "",
        title: "Enter New Folder Name",
        label: "New Folder Name",
        placeholder: "Type new folder name...",

    },
    community: {
        icon: <img src={CommunityIcon} alt={`Community icon`} />,
        title: "Edit Community Name",
        label: "New Community Name",
        placeholder: "Type new community name...",
    },
};

const RenameDialog = ({
    itemType,
    openDialog,
    oldName,
    newName,
    setNewName,
    onClick,
    onClose,
    isLoading,
}) => {
    const text = dialogText[itemType.toLowerCase()];

    return (
        <Dialog open={!!openDialog} actionkey={"Enter"}>
            <CloseDialog closeActions={() => onClose()} />
            <div className={classes.dialog}>
                {text.icon}
                <h3>{text.title}</h3>
                <Input
                    label={text.label}
                    placeholder={text.placeholder}
                    disabled={isLoading}
                    input={{
                        value: newName,
                        onChange: (value) => setNewName(value),
                    }}
                />
                <div className={classes.footer}>
                    <Button type="hollow" onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button onClick={onClick} type="" disabled={isLoading || isEmpty(newName) || isEqual(newName, oldName)}>
                        {isLoading ? "Renaming..." : "Rename"}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
};

export default RenameDialog;
