import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import classes from "../style.module.scss";
import { Field } from "redux-form";
import { required, maxLength, getValidation } from "utils/fieldValidation";
import { mixpanel } from "components/Mixpanel";
import { MODEL_COLL } from "_firebase";

import Select from "components/Input/Select";
import Button from "components/Button";
import DayPicker from "components/Input/DayPicker";
import Input from "components/Input";
import InputRow from "components/Input/Row";
import InputFile from "components/Input/FileUpload";
import SelectFolder from "components/SelectModelFolder";

import { getProgramType } from "utils/evaluationProgram";

const charLim100 = maxLength(100);

const templates = [
    {
        value: "default-v11.13",
        label: "None (HOT2000 v11.13)",
    },
    {
        value: "default-v11.12",
        label: "None (HOT2000 v11.12)",
    },
    {
        value: "default-v11.11",
        label: "None (HOT2000 v11.11)",
    },
    {
        value: "default-v11.10",
        label: "None (HOT2000 v11.10)",
    },
    {
        value: "default-v11.9",
        label: "None (HOT2000 v11.9)",
    },
];

const Basic = ({ change, modelId, handleSubmit, submitting, invalid, folders, history, uid, fetchUserDir }) => {
    const { search } = useLocation();
    const values = queryString.parse(search);

    const [currentFolderId, setCurrentFolderId] = useState(values.folderId || null);
    const [isSelectingFolderOpen, setIsSelectingFolderOpen] = useState(false);
    const [versionError, setVersionError] = useState("");

    useEffect(() => {
        fetchUserDir(uid);
    }, []);

    change("parentFolderId", currentFolderId);

    const handleUpload = (data) => {
        if (!data) {
            return;
        }

        const {
            modelData = {},
            modelDetails = {},
            modelPath = "",
            version: { major = 11, minor = 9 },
        } = data;
        const { name = "" } = modelDetails;
        const modelId = modelPath.split(`${MODEL_COLL}/`)[1];

        if (name !== "") {
            //Handles setting the name on import
            change("name", name);
        }

        change("modelDetails", modelDetails);
        change("modelData", modelData);
        change("modelId", modelId);
        change("template", "");

        if (minor < 9 || major < 11) {
            setVersionError(`${major}.${minor}`);
        }

        mixpanel.track("H2K Import");
    };

    const handleProgramTypeChange = (value) => {
        const programType = getProgramType(value);

        change("modelData.program.type", programType);
    };

    return (
        <div className={classes.createForm}>
            <div className={classes.sectionHeader}>
                <h3>Create a Model from Scratch</h3>
            </div>
            <InputRow>
                <Field
                    component={Input}
                    name="name"
                    type="text"
                    label="Name*"
                    placeholder="Name Your Model"
                    validate={[required, charLim100]}
                />
            </InputRow>

            <InputRow gridTemplate="1fr 1fr">
                <Field
                    component={DayPicker}
                    formatting="YYYY-MM-DD"
                    name="modelData.general.file.evaluationDate"
                    label="Evaluation Date*"
                    validate={getValidation("evaluationDate")}
                />
                <Field
                    component={Select}
                    name="modelData.uiSettings.primaryUnits"
                    options={[
                        {
                            value: "Metric",
                            label: "Metric",
                        },
                        {
                            value: "Imperial",
                            label: "Imperial",
                        },
                        {
                            value: "US",
                            label: "US",
                        },
                    ]}
                    label="Primary Display Units*"
                    placeholder="Choose Display Units"
                />
            </InputRow>
            <InputRow
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    fontSize: "0.875rem",
                }}
            >
                <p>
                    Current folder -{" "}
                    <strong>{currentFolderId ? `${folders[currentFolderId].name}` : "Model Directory"}</strong>
                </p>
                <p
                    style={{
                        cursor: "pointer",
                        color: "#0049c6",
                        fontWeight: 500,
                    }}
                    className={classes.folderText}
                    onClick={() => setIsSelectingFolderOpen(!isSelectingFolderOpen)}
                >
                    Select Folder
                </p>
            </InputRow>
            <InputRow>
                <Field
                    component={Select}
                    name="modelData.program.class"
                    options={[
                        {
                            value: "",
                            label: "General",
                        },
                        {
                            value: "ca.nrcan.gc.OEE.ERS.ErsProgram",
                            label: "EnerGuide Rating System",
                        },
                        {
                            value: "ca.nrcan.gc.OEE.ERS2020NBC.ErsProgram",
                            label: "EnerGuide Rating System 2020 NBC",
                        },
                        {
                            value: "ca.nrcan.gc.OEE.ONrh.OnProgram",
                            label: "Ontario Reference House",
                        },
                    ]}
                    label="Program Type"
                    placeholder="None"
                    onChange={handleProgramTypeChange}
                />
            </InputRow>
            <div className={classes.divider}></div>
            <h3>Upload a HOT2000 File (Optional)</h3>
            <InputRow className={classes.lastRow}>
                <Field
                    component={InputFile}
                    name="H2kFileInput"
                    id="H2kFileInput"
                    label="Import Now"
                    text="Working from a file newer than v11.9?"
                    handleUpload={handleUpload}
                    accept=".h2k,.xml"
                    parentFolderId={currentFolderId}
                />
            </InputRow>

            {versionError && (
                <h5
                    style={{ color: "#c61717", marginTop: "1rem" }}
                >{`The file uploaded was created using HOT2000 version ${versionError}, which is not supported. We have attempted to update it to 11.9 but this may produce calculation errors. It is recommended that you first open and save the file in a version of HOT2000 newer than 11.9 prior to upload.`}</h5>
            )}
            <Button
                large
                className={classes.createButton}
                onClick={() => handleSubmit()}
                disabled={submitting || invalid}
            >
                {submitting ? "Creating Model..." : "Create"}
            </Button>
            <SelectFolder
                folderToMove={isSelectingFolderOpen}
                folders={folders}
                close={() => setIsSelectingFolderOpen(!isSelectingFolderOpen)}
                isMoving={false}
                onSelect={(selectedFolder) => setCurrentFolderId(selectedFolder)}
            />
        </div>
    );
};

export default Basic;
