import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import WallConstruction from "./";
import { actions } from "features/Model/Enclosure/_ducks";

const { setInitCode, updateCodeDrawer, updateCompositeCalc } = actions;

const mapStateToProps = (
    {
        form,
        user: { codeLib: { BasementWall: { codes = {} } = {}, Lintel: { codes: lintelCodes = {} } = {} } = {} },
        enclosure: { calculatingRValue = {} } = {},
        upgradeLibrary: { currentPackage = {} },
        parametricAnalysis: { parametricAnalysis } = {},
    },
    { accessor, formName = "model", componentAccessor = accessor, id, selectedUpgrade, canBeEditedFromParametric }
) => {
    const selector = formValueSelector(formName);
    const modelSelector = formValueSelector("model");

    const ecmInputMap = parametricAnalysis?.ecmInputMap || {};

    const allEcmInputMapUpgradesArr = ecmInputMap
        ? Object.keys(ecmInputMap)
              .map((key) =>
                  Object.entries(ecmInputMap[key])
                      .filter(([k, _]) => k !== "None")
                      .map(([k, v]) => ({ [k]: v }))
              )
              .flat()
        : {};

    const allEcmInputMapUpgrades = allEcmInputMapUpgradesArr.reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const modelCodes =
        allEcmInputMapUpgrades[selectedUpgrade]?.packageValues?.codes ||
        currentPackage?.upgrades?.[selectedUpgrade]?.codes ||
        modelSelector({ form }, "modelData.codes") ||
        {};
    const modelUnits = modelSelector({ form }, `modelData.uiSettings.primaryUnits`) || {};
    const { type: basementInsConfig } = modelSelector({ form }, `${componentAccessor}.configuration`) || {};

    const { codeRef: selectedCodeRef } = selector({ form }, `${accessor}.wall.intAddedInsType`) || {};
    const selectedNomRValue = selector({ form }, `${accessor}.wall.intAddedInsType_nomRVal`) || 0;
    const selectedEffRValue = selector({ form }, `${accessor}.wall.intAddedInsType_effRVal`) || 0;
    const compositeIntEffRValue = selector({ form }, `${accessor}.wall.intAddedInsType_composite.effRVal`) || 0;
    const compositeExtEffRValue = selector({ form }, `${accessor}.wall.extAddedInsType_composite.effRVal`) || 0;
    const basementWallCodeWarning = selector({ form }, `${accessor}.wall.intAddedInsType_warning`) || "";
    const { codeRef: selectedLintelCodeRef } = selector({ form }, `${accessor}.wall.lintelInsType`) || {};
    const { id: extRValId, value: extRValue } = selector({ form }, `${accessor}.wall.extAddedInsType`) || {};
    const intRValUnits = selector({ form }, `${accessor}.wall.intAddedInsType_u`);
    const extRValUnits = selector({ form }, `${accessor}.wall.extAddedInsType_u`);
    const { [id]: { basementWallInsType: isCalculatingRValue = false } = {} } = calculatingRValue;

    return {
        id,
        accessor,
        libCodes: codes,
        libLintelCodes: lintelCodes,
        modelCodes,
        selectedCodeRef,
        selectedLintelCodeRef,
        modelUnits,
        intRValUnits,
        extRValUnits,
        extRValId,
        extRValue,
        selectedNomRValue,
        basementInsConfig,
        basementWallCodeWarning,
        isCalculatingRValue,
        selectedEffRValue,
        compositeIntEffRValue,
        compositeExtEffRValue,
        isConcrete: basementInsConfig?.slice(1, 2) !== "W",
        formName,
        canBeEditedFromParametric,
    };
};

const mergeProps = (state, dispatch, own) => ({
    ...state,
    ...dispatch,
    ...own,
});

const mapDispatchToProps = (dispatch) => ({
    setInitCode: async (code, type) => {
        return dispatch(setInitCode(code, type));
    },
    updateCodeDrawer: (updates) => dispatch(updateCodeDrawer({ updates })),
    updateCompositeCalc: (updates) => dispatch(updateCompositeCalc(updates)),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(WallConstruction);
