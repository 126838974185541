import { connect } from "react-redux";
import LegacyParametricAnalysis from ".";

import { actions as userActions } from "store/users";

const { fetchUserBatchDir } = userActions;

const mapStateToProps = ({
    user: { uid, userPermissions: { CBAT_INPUT = false } = {}, batchDir = {}, batchDirLoading = false },
}) => {
    return {
        uid,
        CBAT_INPUT,
        batchDir,
        batchDirLoading,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchUserBatchDir: (uid) => dispatch(fetchUserBatchDir(uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LegacyParametricAnalysis);
